import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import Dashboard from "../../components/Dashboard";
import CommonLayout from "../../components/Layouts/CommonLayout";
import useAuthChecker from "../../Logic/useAuthChecker";

const DashboardPage = ({ languageOpen, setLanguageOpen }) => {
  return (
    <CommonLayout>
      <Dashboard
        languageOpen={languageOpen}
        setLanguageOpen={setLanguageOpen}
      />
    </CommonLayout>
  );
};

export default DashboardPage;
