import Style from "./UserProfileGalleryImages.module.scss";
import { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import GalleryImage from "../../Gallery/ImageGallery/GalleryImage";
import Masonry from "react-masonry-css";
import useGallery from "../../../Logic/useGallery";
import { useNavigate } from "react-router-dom";


const UserProfileGalleryImages = ({
    breakpointColumnsObj
    }) => {
    const observer = useRef();
    const imgRootRef = useRef(null);
    const imgItemRef = useRef(null);
    const loaction = useLocation();
    const navigate = useNavigate();
    const { sections, currentTab } = useSelector((state) => state.gallery);
    const { images, handleReArrange, getImagesForSection, loadMore } = useGallery();

    useEffect(() => {
        getImagesForSection("all");
    },[]);

 
    useEffect(() => {

         if(loaction.pathname.indexOf("gallery")!=-1){
            if (imgItemRef.current) {
                const interceptConfig = {
                  root: imgRootRef.current,
                  rootMargin: "0px",
                  threshold: 0.1,
                };
                observer.current = new IntersectionObserver((entries) => {
                  if (entries.every((entry) => entry.isIntersecting)) {
                    loadMore("All");
                  }
                });
                observer.current.observe(imgItemRef.current);
                return () => {
                  observer.current.disconnect();
                };
              }
         }
      
      }, [sections]);
      

     return(
        <div
            ref={imgRootRef}
            className={Style.ImageGallery}
            data-masonry='{"percentPosition": true }'
            >
            <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
            >  
                {sections[currentTab]?.data?.map((image, i) => {

                const lastIndex = sections[currentTab]?.data?.length - 1;
                return (
                    <div key={i} ref={lastIndex == i ? imgItemRef : null}>
                    <GalleryImage
                        handleOpenImageFullView={{}}
                        key={image.id}
                        image={image}
                        i={i}
                    />
                    </div>
                );
                })}
            </Masonry>
        </div>



    );
};

export default UserProfileGalleryImages;