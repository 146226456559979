import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PreLogin from '../../Layouts/PreLogin';
import Style from "./NotFoundPage.module.scss";

const NotFoundPage = () => {
  const location = useLocation();

  useEffect(() => {
    const allSections = document.querySelectorAll(".anim");
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const element = entry.target;
          element.classList.add("visible");
        }
      });
    });
    allSections.forEach((section) => {
      observer.observe(section);
    });
  }, [location.pathname]);

  return (
    <PreLogin>
        <div className={`${Style.not_found_page} inner-page`}>
            <div className="inner-page-wrap">
                <h1 className={Style.error_code}>404</h1>
                <h2 className={Style.error_name}>Page Not Found</h2>
            </div>
        </div>
    </PreLogin>
  )
}

export default NotFoundPage;