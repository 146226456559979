import Style from "./ImageGallery.module.scss";
import placeholderImages from "../../../constants/images";
import { useState, useCallback, useEffect, useRef, useLayoutEffect } from "react";
import GalleryImage from "./GalleryImage";
import Masonry from "react-masonry-css";
import { useOutletContext, useLocation } from "react-router-dom";
import useGallery from "../../../Logic/useGallery";
import { useDispatch, useSelector } from "react-redux";
import {
  currentTabSelection,
  uploadModalAction,
} from "../../../store/slices/gallerySlice";
import AlbumFullView from "../../Album/AlbumFullView";
import { DashboardLoader } from "../../DashboardLoader";
import GalleryBlankPage from "../../BlankGallery";
import { useNavigate } from "react-router-dom";
import UploadImageModal from "../GalleryFilters/RightFilteredCategory/UploadImageModal";

const breakpointColumnsObj = {
  default: 5,
  1599: 4,
  991: 3,
  575: 2,
  424: 1,
};

const ImageGallery = ({ setShowSelect, showSelect, slideshow, sectionTo }) => {
  const observer = useRef();
  const imgRootRef = useRef(null);
  const imgItemRef = useRef(null);
  const loaction = useLocation();
  let navigate = useNavigate();
  let dispatch = useDispatch();

  const { images, handleReArrange, getImagesForSection, loadMore } =
    useGallery();
  const { sections, currentTab, allImageStatus } = useSelector(
    (state) => state.gallery
  );
  const [showFullView, setShowFullView] = useState({ index: 0, isOpen: false });

  const [openActionModal, setOpenModal] = useState(false);


  useEffect(() => {
    getImagesForSection(sectionTo ? sectionTo : "all");
  }, []);

  useLayoutEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);


  const moveListItem = useCallback((dragIndex, dropIndex, imgData) => {
    const dragItem = imgData[dragIndex];
    const hoverItem = imgData[dropIndex];
    let changeArray = () => {
      const updatedImages = [...imgData];
      updatedImages[dragIndex] = hoverItem;
      updatedImages[dropIndex] = dragItem;
      handleReArrange(updatedImages, dragItem, hoverItem);
    };
    changeArray();
  }, []);

  useEffect(() => {
    if (imgItemRef.current) {
      const interceptConfig = {
        root: imgRootRef.current,
        rootMargin: "0px",
        threshold: 0.1,
      };
      observer.current = new IntersectionObserver((entries) => {
        if (entries.every((entry) => entry.isIntersecting)) {
          loadMore(sectionTo ? sectionTo : "all");
        }
      });
      observer.current.observe(imgItemRef.current);
      return () => {
        observer.current.disconnect();
      };
    }
  }, [sections[sectionTo ? sectionTo : "all"]?.data]);

  const handleOpenImageFullView = (i) => {
    setShowFullView({
      index: i,
      isOpen: true,
    });
  };

  const handleNavigateTo = () => {
    // setOpenModal(true);
    dispatch(uploadModalAction(true));
  };

  return (
    <div
      ref={imgRootRef}
      className={`${Style.image_gallery} masonry-wrap`}
      data-masonry='{"percentPosition": true }'
    >
      {
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {sections[sectionTo ? sectionTo : "all"]?.data?.map((image, i) => {
            const lastIndex = sections[currentTab]?.data?.length - 1;
            return (
              <div key={i} ref={lastIndex == i ? imgItemRef : null}>
                <GalleryImage
                  handleOpenImageFullView={handleOpenImageFullView}
                  key={image.id}
                  image={image}
                  showSelect={showSelect}
                  i={i}
                  moveListItem={moveListItem}
                  slideshow={slideshow}
                />
              </div>
            );
          })}
        </Masonry>
      }

      {sections[currentTab]?.data?.length == 0 &&
      currentTab != "slideshow" &&
      allImageStatus == "succeeded" ? (
        <GalleryBlankPage
          title={`You haven't uploaded any Images`}
          description={`The collections you create are shown here`}
          buttonText={`Upload Image`}
          handleClick={() => handleNavigateTo()}
        />
      ) : (
        ""
      )}

      {showFullView.isOpen ? (
        <AlbumFullView
          setShowFullView={setShowFullView}
          showFullView={showFullView}
          data={sections[currentTab]?.data}
        />
      ) : (
        ""
      )}

      {allImageStatus == "loading" ? (
        <DashboardLoader data={sections[currentTab]?.data} />
      ) : (
        ""
      )}
    </div>
  );
};

export default ImageGallery;
