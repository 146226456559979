import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getSlideShows,
  callFunction,
  openPreSelect,
  currentTabSelection,
} from "../store/slices/gallerySlice";
import {
  editCurrentSlideShow,
  getSlideShowList,
} from "../store/slices/gallerySlice";
import { useNavigate } from "react-router-dom";

const useSlideshow = () => {
  const { sections, currentTab, allImageStatus, isSlideshowFetching } =
    useSelector((state) => state.gallery);
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [slideShowName, setSlideShowName] = useState("");
  const [showImageGallery, setShowImageGallery] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    let obj = {
      url: "list-slideshow",
      page: "1",
      slug: "desc",
    };
    if (sections[currentTab]?.data == undefined) {
      dispatch(getSlideShowList(obj));
    }
  }, []);

  useEffect(() => {
    if (showImageGallery) {
      dispatch(openPreSelect());
    }
  }, [showImageGallery]);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  const handleChange = (e) => {
    setSlideShowName(e.target.value);
  };

  useEffect(() => {
    sections[currentTab]?.currentActions?.imageGalleryOpen && setShow(false);
  }, [sections[currentTab]?.slideshow]);

  const handleSubmit = () => {
    setShow(false);
    dispatch(editCurrentSlideShow(slideShowName));
    const slideshowGalleryAction = (state) => {
      state.sections.slideshow = {
        ...state.sections.slideshow,
        currentSlideShowID: "",
        currentSlideShow: {},
        currentActions: {
          ...state.sections.slideshow.currentActions,
          imageGalleryOpen: true,
          preselect: true,
        },
      };
    };
    dispatch(callFunction(slideshowGalleryAction));
  };

  return {
    isSlideshowFetching,
    allImageStatus,
    show,
    setShow,
    showImageGallery,
    setShowImageGallery,
    showImageGallery,
    setShowImageGallery,
    handleClose,
    handleShow,
    slideShowName,
    handleChange,
    handleSubmit,
    galleryState: sections[currentTab],
  };
};

export default useSlideshow;
