import React, { Suspense, useEffect, useState } from "react";
import "../styles/common.scss";
import { BrowserRouter } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import StyleGuidePage from "../pages/StyleGuidePage";

import HomePage from "../pages/HomePage";
import DashboardPage from "../pages/DashboardPage";
import Gallery from "./Gallery";
import FamilyTree from "./FamilyTreePage";
import Requests from "./Requests";
import Invitations from "./Invitations";
import Preference from "./Preference";
import FamilyTreeForm from "./FamilyTreePage/FamilyTreeForm";
import GetStartedPage from "../pages/GetStartedPage";
import { SearchProfile } from "./SearchProfile";
import { TellUsAbout } from "./TellUsAboutYourFamily";
import ImageGallery from "./Gallery/ImageGallery";
import Favorites from "./Favorites";
import Album from "./Album";
import Archive from "./Archive";
import Slideshow from "./Slideshow";
import useGallery from "../Logic/useGallery";
import UserProfile from "./UserProfile";
import UserProfileOverview from "./UserProfile/UserProfileOverview";
import UserProfileGallery from "./UserProfile/UserProfileGallery";
import UserProfileViewOnTree from "./UserProfile/UserProfileViewOnTree";
import NotFoundPage from "./UtilityPages/NotFoundPage";
import PrivacyPolicy from "./UtilityPages/PrivacyPolicy";
import InformationAndGuidelines from "./UtilityPages/InfomationAndGuidelines";
import FAQ from "./UtilityPages/FAQ";
import ProtectedRoute from "../utils/RouteChecker/ProtectedRoute";
import UserProfileFamilyMembers from "./UserProfile/UserProfileFamilyMembers";
import DarkThemeToggler from "./DarkThemeToggler";
import Contact from "./UtilityPages/Contact";
import useDarkmode from "../utils/utilityHooks/useDarkmode";
import { SlideShowEditNew } from "./Slideshow/SlideShowEditNew";
import SlideShowTest from "./Slideshow/Test/SlideShowTest";
import ImageEdit from "./ImageEdit";

const App = () => {
  const { theme, themeToggler } = useDarkmode();
  const [languageOpen, setLanguageOpen] = useState(false);

  return (
    <Suspense fallback={null}>
      <BrowserRouter>
        <Routes>
          <Route path="/style-guide" element={<StyleGuidePage />} />
          <Route
            path="/"
            element={
              <>
                <HomePage />
                <DarkThemeToggler theme={theme} toggleTheme={themeToggler} />
              </>
            }
          />
          <Route
            path="/get-started"
            element={
              <>
                <GetStartedPage />
                <DarkThemeToggler theme={theme} toggleTheme={themeToggler} />
              </>
            }
          />
          <Route
            path="/dashboard"
            element={
              <DashboardPage
                languageOpen={languageOpen}
                setLanguageOpen={setLanguageOpen}
              />
            }
          >
            <Route path="" element={<Gallery />}>
              <Route path="" element={<ImageGallery />} />
              <Route path="favorites" element={<Favorites />} />
              <Route path="album" element={<Album />} />
              <Route path="slideshow" element={<Slideshow />} />
              <Route path="archive" element={<Archive />} />
            </Route>

            <Route path="image-edit/:image_id" element={<ImageEdit />} />
            <Route path="slideshow/test" element={<SlideShowTest />} />
            <Route path="slideshow/edit" element={<SlideShowEditNew />} />
            <Route path="slideshow/edit/:id" element={<SlideShowEditNew />} />
            <Route path="family-tree" element={<FamilyTree />} />
            <Route path="requests" element={<Requests />} />
            <Route path="invitations" element={<Invitations />} />
            <Route
              path="preference"
              element={
                <Preference
                  languageOpen={languageOpen}
                  setLanguageOpen={setLanguageOpen}
                />
              }
            />
            <Route path="searchprofile" element={<SearchProfile />} />
            <Route path="tellusabout" element={<TellUsAbout />} />

            <Route path="user-profile" element={<UserProfile />}>
              <Route path="" element={<UserProfileOverview />} />
              <Route path="gallery" element={<UserProfileGallery />} />
              <Route path="view-on-tree" element={<UserProfileViewOnTree />} />
              <Route path="members" element={<UserProfileFamilyMembers />} />
            </Route>
          </Route>
          <Route path="family-tree-form" element={<FamilyTreeForm />} />
          <Route
            path="/contact"
            element={
              <>
                <Contact />
                <DarkThemeToggler theme={theme} toggleTheme={themeToggler} />
              </>
            }
          />
          <Route
            path="/404"
            element={
              <>
                <NotFoundPage />
                <DarkThemeToggler theme={theme} toggleTheme={themeToggler} />
              </>
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <>
                <PrivacyPolicy />
                <DarkThemeToggler theme={theme} toggleTheme={themeToggler} />
              </>
            }
          />
          <Route
            path="/Information-and-guidelines"
            element={
              <>
                <InformationAndGuidelines />
                <DarkThemeToggler theme={theme} toggleTheme={themeToggler} />
              </>
            }
          />
          <Route
            path="/faq"
            element={
              <>
                <FAQ />
                <DarkThemeToggler theme={theme} toggleTheme={themeToggler} />
              </>
            }
          />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};

export default App;
