
import Style from "./UserProfileFamilyMember.module.scss";

const UserProfileFamilyMember = ({
    imgUrl,
    memberName,
    memberRelation
}) => {
    return(
        <div className={Style.user_profile_family_member}>
            <figure className={Style.user_profile_family_member_figure}>
                <img src={imgUrl} />
            </figure>
            <h4 className={Style.user_profile_family_member_name}>{memberName}</h4>
            <span className={Style.user_profile_family_member_relation}>{memberRelation}</span>
        </div>
    )
}
export default UserProfileFamilyMember;
