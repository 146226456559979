import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../api";

const initialState = {
  isLoading: true,
  sign_me_automatically: false,
  auto_adjust_positions: false,
  enable_edit_access: false,
  availableLanguage: [],
  selected_language:"",
  background_theme: "dark",
  is_data_loaded_from_api: false,
};

export const getCurrentPreferenceStatus = createAsyncThunk(
  "userpreference/getCurrentPreferenceStatus",
  async (user_id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`user-preference`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const getLanguages = createAsyncThunk(
  "userpreference/getLanguages",
  async (user_id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`list-languages`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updatePreferenceStatus = createAsyncThunk(
  "userpreference/updatePreferenceStatus",
  async (obj, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {

      const {action,value} = obj;

      const packet = new FormData();
      packet.append("name",action);
      packet.append("value",value);
    
      const response = await api.post(`update-preference`, packet);
      
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const checkPassowrdForDeleteUserAccount = createAsyncThunk(
  "userpreference/checkPassowrdForDeleteUserAccount",
  async (obj, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {

      const {password} = obj;

      const packet = new FormData();
      packet.append("password",password);
    
      const response = await api.post(`password-match`, packet);
      
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const DeleteUserAccount = createAsyncThunk(
  "userpreference/deleteUserAccount",
  async (obj, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {

      const response = await api.get(`delete-user`);
      
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);




const userPreferenceSlice = createSlice({
  name: "userpreference",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.isLoading = true;
    },
  },
  extraReducers: {
    [getCurrentPreferenceStatus.pending]: (state) => {
      state.isLoading = true;
    },
    [getCurrentPreferenceStatus.fulfilled]: (state, action) => {
      const {
        auto_adjust,
        background,
        enable_edit_access,
        language,
        sign_me_auto,
      } = action.payload.data.result;
     
      state.isLoading = false;
      state.sign_me_automatically = sign_me_auto;
      state.auto_adjust_positions = auto_adjust;
      state.enable_edit_access = enable_edit_access;
      state.background_theme = background;
      state.selected_language = language;
      state.is_data_loaded_from_api = true;
    },
    [getCurrentPreferenceStatus.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getLanguages.pending]: (state) => {
     // state. = true;
    },
    [getLanguages.fulfilled]: (state, action) => {
      state.availableLanguage = action.payload.data?.result;
    },
    [getLanguages.rejected]: (state, action) => {
     
    },
  },
});

export const { showLoading } = userPreferenceSlice.actions;

export default userPreferenceSlice.reducer;
