import React, { useEffect, useState } from "react";
import Style from "./PreLoginMobileMenu.module.scss";
import { Link } from "react-router-dom";
import Assets from "../../Assets";
import Icons from "../../Icons";
import SignInModal from "../../../Modals/SignInModal";
import RegisterModal from "../../../Modals/RegisterModal";
import ForgotPasswordModal from "../../../Modals/ForgotPasswordModal";
import OTPVerificationModal from "../../../Modals/OTPVerificationModal";
import LanguageMenu from "../../LanguageMenu/LanguageMenu";
import { Dropdown, Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  showSignInState,
  showforgotpasswordState,
  logOut,
  changeScreen,
} from "../../../../store/slices/authSlice";
import CommonModal from "../../../Modals/commonModal";
import AuthenticationModal from "../../../Modals/AuthenticationModal";
import { getProfileDetails } from "../../../../store/slices/userprofileSlice";
import { useRegister } from "../../../../Logic/useRegister";

const EditModals = React.lazy(() => import("../../../Modals/Modals"));

const PreLoginMobileMenu = ({
  showSignIn,
  setShowSignIn,
  showRegister,
  setShowRegister,
  showForgotPassword,
}) => {
  const {
    showModal,
    setShowModal,
    showFP,
    setShowFP,
    showOtp,
    setShowOtp,
    registerStatus,
    loginStatus,
  } = useRegister();

  const dispatch = useDispatch();
  const [language, setLanguage] = useState("English");

  const [showMenu, setShowMenu] = useState(false);
  const [showLang, setShowLang] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const {
    showSignInComponent,
    showForgotComponent,
    currentScreen,
    registerData,
  } = useSelector((state) => state.auth);
  const { userdata } = useSelector((store) => store.userprofile);

  const { i18n, t } = useTranslation(["common"]);
  let currLan = localStorage.getItem("i18nextLng");

  useEffect(() => {
    if (Object.keys(userdata).length == 0) {
      dispatch(getProfileDetails(registerData?.user_id));
    }
  }, [registerData]);

  useEffect(() => {
    const homePage = document.querySelector(".home-page");
    const footerSec = document.querySelector(".footer-section");
    showMenu
      ? homePage.classList.add("lock-scroll-home")
      : homePage.classList.remove("lock-scroll-home");
    showMenu
      ? footerSec.classList.add("lock-scroll-footer")
      : footerSec.classList.remove("lock-scroll-footer");
  }, [showMenu]);

  useEffect(() => {
    const homePage = document.querySelector(".home-page");
    const footerSec = document.querySelector(".footer-section");
    showModal
      ? homePage.classList.add("lock-scroll-home-modal")
      : homePage.classList.remove("lock-scroll-home-modal");
    showModal
      ? footerSec.classList.add("lock-scroll-footer-modal")
      : footerSec.classList.remove("lock-scroll-footer-modal");
  }, [showModal]);

  useEffect(() => {
    showModal && setShowMenu(false);
  }, [showModal]);

  useEffect(() => {
    switch (currLan) {
      case "en":
        setLanguage("English");
        break;
      case "fr":
        setLanguage("Français");
        break;
      case "esp":
        setLanguage("Español");
        break;
      case "chi":
        setLanguage("日本語");
        break;
      case "pol":
        setLanguage("Pусский");
        break;
      case "ity":
        setLanguage("Italiano");
        break;

      default:
        setLanguage("English");
        break;
    }
  }, [currLan]);

  const languageSwitch = (language, flag, code) => {
    setLanguage(language);
    // setFlag(flag);
    i18n.changeLanguage(code);
  };

  const handleClickRegister = () => {
    setShowModal(true);
    dispatch(changeScreen("register"));
  };
  const handleClickLogin = () => {
    setShowModal(true);
    dispatch(changeScreen("login"));
  };
  const handleClickFP = () => {
    dispatch(changeScreen("forgotPassword"));
  };

  const handleClose = () => {
    dispatch(changeScreen(""));
  };

  const handleClickLogout = () => {
    setOpenModal(true);
  };
  const handleLogout = () => {
    dispatch(logOut());
    loggingOut();
  };

  const loggingOut = () => {
    setTimeout(() => {
      window.location.href = "/";
    }, 1000);
  };

  const renderScreen = (page) => {
    switch (page) {
      case "register":
        return (
          <RegisterModal
            handleClickLogin={handleClickLogin}
            handleClickFP={handleClickFP}
            status={registerStatus}
            handleClose={handleClose}
          />
        );
        break;
      case "login":
        return (
          <SignInModal
            setShowSignIn={setShowSignIn}
            handleClickRegister={handleClickRegister}
            handleClickFP={handleClickFP}
            status={loginStatus}
          />
        );
        break;
      case "forgotPassword":
        return (
          <ForgotPasswordModal
            setShowSignIn={setShowSignIn}
            setShowFP={setShowFP}
          />
        );
      case "otp":
        return <OTPVerificationModal />;
    }
  };

  return (
    <>
      <header className={Style.prelogin_mobile_header}>
        {showLang ? (
          <LanguageMenu
            showLang={showLang}
            setShowLang={setShowLang}
            languageSwitch={languageSwitch}
            currentLanguage={language}
          />
        ) : (
          <></>
        )}
        <div className="container">
          <div className={Style.prelogin_mobile_header_wrap}>
            <figure className={Style.prelogin_mobile_header_logo}>
              <Link to={"/"} title={"Family Photos"}>
                <img
                  src={
                    localStorage.getItem("theme") == "light"
                      ? Assets.main_logo
                      : Assets.main_logo_dark
                  }
                  alt="Family Photos"
                />
              </Link>
            </figure>
            <div className={Style.prelogin_mobile_header_nav}>
              <div className={Style.prelogin_mobile_header_search}>
                <Icons icon={"search"} size={22} />
              </div>
              <button
                className={Style.prelogin_mobile_header_hamburger}
                onClick={() => setShowMenu(true)}
              >
                <span></span>
                <span></span>
                <span></span>
              </button>
            </div>
          </div>
        </div>
        <nav
          className={`${Style.prelogin_mobile_header_menu} ${
            showMenu ? Style.menu_open : ""
          }`}
        >
          <button
            className={Style.close_menu}
            onClick={() => setShowMenu(false)}
          >
            <Icons icon={"icon-close"} size={12} />
          </button>
          <div className="container">
            <div className={Style.prelogin_mobile_header_menu_wrap}>
              <ul className={Style.prelogin_mobile_header_links}>
                <li className={Style.active}>
                  <Link to={"/"} onClick={() => setShowMenu(false)}>
                    {t("home")}
                  </Link>
                </li>
                {registerData?.name ? (
                  ""
                ) : (
                  <li>
                    <button onClick={() => handleClickRegister()}>
                      {t("register")}
                    </button>
                  </li>
                )}

                <li>
                  <Link to={"/dashboard"} onClick={() => setShowMenu(false)}>
                    {t("upload_photos")}
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/dashboard/family-tree"}
                    onClick={() => setShowMenu(false)}
                  >
                    {t("family_tree")}
                  </Link>
                </li>
                <li>
                  <Link to={"/"} onClick={() => setShowMenu(false)}>
                    {t("contact")}
                  </Link>
                </li>
                <li className={Style.submenu}>
                  <button
                    onClick={() => {
                      setShowLang(true);
                    }}
                  >
                    {t("language")}
                  </button>
                </li>
              </ul>
              <div className={Style.prelogin_mobile_header_buttons}>
                <button className="btn btn-tertiary">{t("get_a_demo")}</button>
                {registerData?.name ? (
                  <button
                    className="btn btn-border"
                    onClick={() => handleClickLogout()}
                  >
                    {t("logout")}
                  </button>
                ) : (
                  <button
                    className="btn btn-border"
                    onClick={() => handleClickLogin()}
                  >
                    {t("login")}
                  </button>
                )}
              </div>
            </div>
          </div>
        </nav>
        <div
          className={Style.menu_overlay}
          onClick={() => setShowMenu(false)}
        ></div>
      </header>
      <AuthenticationModal showModal={showModal} setShowModal={setShowModal}>
        {renderScreen(currentScreen)}
      </AuthenticationModal>
      <CommonModal
        showModal={openModal}
        hide={() => setOpenModal(false)}
        className="modal-width logout-modal"
        content={
          <>
            <div className={Style.logoutSection}>
              <div className={`${Style.title} mb-4`}>Are You Sure!</div>

              <div
                className={`w-100 text-center ${Style.logoutsection_btn_wrap}`}
              >
                <button
                  onClick={() => handleLogout()}
                  className="btn btn-primary"
                >
                  Yes
                </button>
                <button
                  onClick={() => setOpenModal(false)}
                  className="btn btn-light"
                >
                  No
                </button>
              </div>
            </div>
          </>
        }
      />
    </>
  );
};

export default PreLoginMobileMenu;
