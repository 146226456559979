import React, { useState } from "react";
import Style from "./ContactForm.module.scss";
import { Dropdown } from "react-bootstrap";
import { useFormik } from "formik";
import { Formik, Field, Form } from "formik";
import CountryObj from "../../utils/country.json";
import { useContactForm } from "../../Logic/useContactForm";

const ContactForm = () => {
  const { contactFormSubmit,success,submitButtonLabel,message,formError } = useContactForm();
  const [contactCountry, setContactCountry] = useState("INDIA");
 

  const contactCountries = [
    {
      id: 1,
      name: "INDIA",
    },
    {
      id: 2,
      name: "ITALY",
    },
    {
      id: 3,
      name: "RUSSIA",
    },
    {
      id: 4,
      name: "FRANCE",
    },
  ];

  function validateEmail(value) {
    let error;
    if (!value) {
      error = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = "Invalid email address";
    }
    return error;
  }

  function validatePhone(value) {
    let error;
    var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    if (!value) {
      error = "Required";
    } else if (!re.test(value)) {
      error = "Please enter a valid phone number";
    }
    return error;
  }

  function validateCountry(value) {
    let error;
    if (value == "") {
      error = "Required";
    }
    return error;
  }

  function validateName(value) {
    let error;
    if (value == "") {
      error = "Required";
    }
    return error;
  }

  const CountryComponent = ({ field, form, ...props }) => {
    return (
      <Dropdown
        {...field}
        className={`${Style.contact_form_input} anim header-anim`}
      >
        <Dropdown.Toggle>
          <span className={Style.contact_form_label}>
            {form.values.country}
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {CountryObj.map((country) => {
            return (
              <Dropdown.Item
                key={country.code}
                onClick={(e) => {
                  form.setFieldValue("country", country.name);
                }}
              >
                {country.name}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  return (
    <div className={Style.contact_form}>
      <h2 className={Style.contact_form_title}>We are here to help</h2>

      <div className={`${Style.contact_form_inputs}`}>
        <Formik
          initialValues={{
            name: "",
            email: "",
            phone: "",
            country: "INDIA",
            message: "",
          }}
          onSubmit={(values,{resetForm }) => {
            // same shape as initial values

            contactFormSubmit(values,resetForm);

          }}
        >
          {({ errors, values, touched, handleSubmit }) => {
            return (
              <>
                <div className="form-group">
                  <Field
                    name="name"
                    className={`${Style.contact_form_input} form-control`}
                    id="contact-name"
                    onFocus={(e) => e.target.classList.add("focused")}
                    onBlur={(e) =>
                      !e.target.value && e.target.classList.remove("focused")
                    }
                    validate={validateName}
                  />
                  {errors.name && touched.name && <div>{errors.name}</div>}
                  <label
                    className={Style.contact_form_label}
                    for="contact-name"
                  >
                    Name*
                  </label>
                </div>

                <div className="form-group">
                  <Field
                    name="email"
                    className={`${Style.contact_form_input} form-control`}
                    id="contact-email"
                    onFocus={(e) => e.target.classList.add("focused")}
                    onBlur={(e) =>
                      !e.target.value && e.target.classList.remove("focused")
                    }
                    validate={validateEmail}
                  />
                  {errors.email && touched.email && <div>{errors.email}</div>}
                  <label
                    className={Style.contact_form_label}
                    for="contact-email"
                  >
                    Email*
                  </label>
                </div>

                <div className="form-group">
                  <Field
                    name="phone"
                    className={`${Style.contact_form_input} form-control`}
                    id="contact-phone"
                    type="number"
                    inputMode="numeric"
                    onFocus={(e) => e.target.classList.add("focused")}
                    onBlur={(e) =>
                      !e.target.value && e.target.classList.remove("focused")
                    }
                    validate={validatePhone}
                  />
                  {errors.phone && touched.phone && <div>{errors.phone}</div>}
                  <label
                    className={Style.contact_form_label}
                    for="contact-phone"
                  >
                    Phone*
                  </label>
                </div>

                <div className="form-group">
                  <Field name="country" component={CountryComponent} />
                </div>

                <div className="form-group textarea">
                  <Field
                    as="textarea"
                    name="message"
                    className={`${Style.contact_form_input} form-control`}
                    id="contact-message"
                    onFocus={(e) => e.target.classList.add("focused")}
                    onBlur={(e) =>
                      !e.target.value && e.target.classList.remove("focused")
                    }
                  ></Field>
                  {errors.message && touched.message && (
                    <div>{errors.message}</div>
                  )}
                  <label
                    className={Style.contact_form_label}
                    for="contact-message"
                  >
                    Message
                  </label>
                </div>

                <div className={Style.contact_form_submit}>
                  <button
                    onClick={handleSubmit}
                    className="btn btn-primary"
                    type="submit"
                  >
                    {submitButtonLabel}
                  </button>
                </div>
              </>
            );
          }}
        </Formik>
        { (success || formError) && (
          <div className={Style.success_message}>
            { success &&  message.success }
            { formError && message.error }
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactForm;
