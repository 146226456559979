import React, { useRef, useState, useEffect } from "react";
import Style from "./SlideShowEditNew.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { FreeMode, Navigation, Thumbs, Autoplay } from "swiper";
// import { useSlideShow } from "../../../Logic/useSlideShow";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import Icons from "../../../components/Layouts/Icons";
import useSlideShowEdit from "../../../Logic/useSlideShowEdit";
//  import { slideShowSliceActions } from "../../store/slices/slideShowSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import slideShowPlaceholder from "../../../assets/slideShowPlaceholder.png";
import visualizer from "../../../assets/musicVisualizer.gif";
import { getSlideShows } from "../../../store/slices/gallerySlice";
import { currentTabSelection } from "../../../store/slices/gallerySlice";
import SlideShowFullView from "../SlideShowFullView";
import { useDimensions } from "../../../Logic/Dimensions";
import { useSwiper } from "swiper/react";
import tracks from "../../../tracks";
import useAudioPlayer from "../../../Logic/useAudioPlayer";

export const SlideShowEditNew = () => {
  const swiper = useSwiper();
  const progressBar = useRef();

  const dispatch = useDispatch();
  let navigate = useNavigate();
  let { id } = useParams();

  const {
    sections,
    musicList,
    slides,
    setSlides,
    selectedItems,
    slideShowState,
    handleSave,
    getSlideShowsData,
    addMoreImages,
    slideData,
    setSlideData,
    currentSelected,
    isSlideShowSaving,
    handleGoto,
    deleteImageFromState,
  } = useSlideShowEdit();

  const {
    trackIndex,
    duration,
    name,
    currentTime,
    isPlaying,
    progressBarRef,
    playTrack,
    calculateTime,
    changeRange,
    isSelected,
  } = useAudioPlayer(tracks);

  const [activeThumb, setActiveThumb] = useState(null);
  const [audio, setAudio] = useState("");
  const [effects, setEffects] = useState("");
  const [activeIndex, setActiveIndex] = useState(0);
  const [showScreen, setShowScreen] = useState(true);
  const [playPauseToggle, setPlayPauseToggle] = useState(false);
  const swiperRef = useRef(null);
  const [source, setSource] = useState(null);
  const [playing, setPlaying] = useState();
  const [showSlideShow, setShowSlideShow] = useState(false);
  const [showPlayPause, setShowPlayPause] = useState(false);

  const [time, setTime] = useState(0);
  const audioRef = useRef(null);
  const location = useLocation();
  const windowDimensions = useDimensions();

  const convertHMS = (value) => {
    const sec = parseInt(value, 10); // convert value to number if it's string

    let hours = Math.floor(sec / 3600); // get hours
    let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
    let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
    // add 0 if value < 10; Example: 2 => 02
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return +minutes + ":" + seconds; // Return is HH : MM : SS
  };

  useEffect(() => {
    dispatch(currentTabSelection("slideshow"));
  }, [location]);

  useEffect(() => {
    let currentId = slideShowState?.currentSlideShowID;
    if (id && slideShowState?.currentSlideShow?.[currentId] == undefined) {
      currentSelected(id);
      getSlideShowsData(id);
    }
  }, [id]);

  useEffect(() => {
    setSlideData({
      ...slideData,
      slideshow_id: id,
    });
  }, [id]);

  useEffect(() => {
    let currentId = slideShowState?.currentSlideShowID;
    if (slideShowState?.currentSlideShow?.[currentId]) {
      let filteredImages = sections["all"]?.data?.filter((d) => {
        return selectedItems?.includes(d?.id);
      });
      let images =
        typeof selectedItems !== "object"
          ? slideShowState?.currentSlideShow?.[currentId]?.images
          : filteredImages;

      setSlides(images);
      setSlideData({
        ...slideData,
        name: slideShowState?.currentSlideShow?.[currentId]?.slideshow?.name,
        slideshow_id:
          slideShowState?.currentSlideShow?.[currentId]?.slideshow?.id,
        add:
          typeof selectedItems !== "object"
            ? slideShowState?.currentSlideShow?.[currentId]?.slideshow
            : selectedItems,
      });
    } else if (selectedItems) {
      setSlideData({
        ...slideData,
        isEditing: true,
        name: slideShowState?.name,
        add: [...selectedItems],
      });
    }
  }, [id]);

  const pauseSlideShow = () => {
    setShowPlayPause(true);
    swiperRef.current.swiper.autoplay.start();
    swiperRef.current.swiper.params.autoplay.delay = 3000;
    playTrack(trackIndex);
  };

  const playSlideShow = () => {
    setShowPlayPause(false);
    swiperRef.current.swiper.autoplay.stop();
    playTrack(trackIndex);
  };

  const slideChangeHandler = (e) => {
    setActiveIndex(e.realIndex);
  };

  useEffect(() => {
    if (audio !== "") {
      setPlaying(true);
      audio.play();
    }
  }, [audio]);

  const handleClickAddImages = (data, id) => {
    addMoreImages(data, id);
    navigate("/dashboard/slideshow");
  };

  const handleClickGoBack = () => {
    navigate(-1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSlideData({
      ...slideData,
      [name]: value,
      isEditing: true,
    });
  };

  const handleClickMusic = (item) => {
    setSlideData({
      ...slideData,
      music_id: item?.id,
    });
  };

  const handleEffectClick = (effectName) => {
    //swiperRef.current.swiper.autoplay.stop();
    setEffects(effectName);
    //  swiperRef.current.swiper.autoplay.start();
    setSlideData({
      ...slideData,
      effect: effectName,
      isEditing: true,
    });
  };

  useEffect(() => {
    let timerID = setInterval(() => tick(), 1000);
    return () => clearInterval(timerID);
  });

  const tick = () => {
    let totalTime = 2 * slides?.length;
    if (!showPlayPause) return;
    if (time < totalTime) {
      setTime(time + 1);
    }
  };

  const handleSlideShowEnd = () => {
    setTimeout(() => {
      setTime(0);
    }, 2000);
  };

  const handleInitialization = (e) => {
    if (showPlayPause) {
      e.autoplay.start();
    }
  };

  const handleChangeMusic = () => {
    setSlideData({
      ...slideData,
      isEditing: true,
    });
  };

  console.log(slideData, "slide save....");

  return (
    <>
      {!showSlideShow ? (
        <>
          <div className={Style.slideshow_section}>
            <div className={Style.slideshow_header}>
              <button
                className={Style.back_button}
                onClick={() => handleClickGoBack()}
              >
                <Icons icon={"back-arrow"} size={20} />
                {windowDimensions.width >= 1200 && <span>Back</span>}
              </button>
              <div className={Style.slideshow_name}>
                <input
                  name="name"
                  placeholder="Type here..."
                  value={slideData?.name}
                  onChange={handleChange}
                  spellCheck={false}
                />
              </div>
              <div className={Style.slideshow_actions}>
                <button
                  disabled={!slideData.isEditing}
                  className={`${Style.slideshow_save} btn btn-border`}
                  onClick={() => handleSave(slideData)}
                >
                  {isSlideShowSaving == "loading" ? "Saving..." : "Save"}
                </button>
                {/* } */}

                {/* <button className={`${Style.slideshow_export} btn btn-border`}>
                  Export
                </button> */}
              </div>
            </div>
            <div className={Style.slideshow_display} id="slideshow">
              {slides?.length > 0 ? (
                <Swiper
                  ref={swiperRef}
                  spaceBetween={10}
                  speed={100} //Duration of transition between slides (in ms)
                  modules={[FreeMode, Navigation, Thumbs, Autoplay]}
                  className={`${Style.slideshow_swiper} mySwiper2`}
                  thumbs={{ swiper: activeThumb }}
                  onSlideChange={(e) => slideChangeHandler(e)}
                  allowTouchMove={false}
                  onReachEnd={(e) => handleSlideShowEnd()}
                  onInit={(e) => handleInitialization(e)}

                // autoplay={{ delay: 1500 }}
                >
                  {slides?.map((imgItem, index) => {
                    return (
                      <SwiperSlide
                        key={imgItem.id}
                        className={activeIndex == index ? effects : "non"}
                      >
                        <figure className={Style.slideshow_image}>
                          <img src={imgItem?.src?.original} alt="" />
                        </figure>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              ) : (
                <div className={Style.noDataSection}>
                  <img src={slideShowPlaceholder}></img>
                </div>
              )}
              <div className={Style.slideshow_controls}>
                <span className={Style.slideshow_duration}>
                  {"0" +
                    ":" +
                    time +
                    "s" +
                    "/" +
                    convertHMS(2 * slides?.length)}
                  s
                </span>

                <div className={Style.slideshow_options}>
                  <ul className={Style.slideshow_options_list}>
                    <li>
                      <Dropdown
                        className={`${Style.slideshow_options_music} dropdown-lg`}
                        autoClose="outside"
                      >
                        <Dropdown.Toggle
                          className={`${Style.music_dropdown
                            } ${"btn btn-border-grey"}`}
                        >
                          <div className={Style.music_icon}>
                            <Icons icon={"icon-music"} size={15} />
                          </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <h4 className="dropdown_title">Music</h4>
                          <div className={Style.listItems}>
                            {tracks?.map((item, i) => {
                              return (
                                <Dropdown.Item key={item.id}>
                                  {item.name}
                                  {
                                    <span
                                      onClick={() => {
                                        playTrack(i);
                                        handleChangeMusic();
                                      }}
                                    >
                                      {trackIndex == i && isPlaying ? (
                                        <Icons
                                          icon={"icon-slideshow-pause"}
                                          size={15}
                                        />
                                      ) : (
                                        <Icons
                                          icon={"icon-slideshow-play"}
                                          size={15}
                                        />
                                      )}
                                    </span>
                                  }
                                  {trackIndex == i && isPlaying && (
                                    <img
                                      className={Style.musicVisualizer}
                                      src={visualizer}
                                    ></img>
                                  )}
                                </Dropdown.Item>
                              );
                            })}
                          </div>
                          {isSelected ? (
                            // <div className={Style.playerControls}>
                            //   <p
                            //     className={Style.audioName}
                            //     style={{ marginTop: "20px" }}
                            //   >
                            //     {name}
                            //   </p>

                            //   <div className={Style.playerOptions}>
                            //     <span onClick={() => playTrack(trackIndex)}>
                            //       {isPlaying ? (
                            //         <Icons
                            //           icon={"icon-slideshow-pause"}
                            //           size={15}
                            //         />
                            //       ) : (
                            //         <Icons
                            //           icon={"icon-slideshow-play"}
                            //           size={15}
                            //         />
                            //       )}
                            //     </span>
                            //     <div className={Style.playerProgress}>
                            //       <p>{calculateTime(currentTime)}</p>
                            //       <input
                            //         type="range"
                            //         className={Style.progress}
                            //         defaultValue="0"
                            //         max={duration ? duration : `${duration}`}
                            //         ref={progressBarRef}
                            //         onChange={changeRange}
                            //       />
                            //       <p>
                            //         {duration &&
                            //           isNaN(calculateTime(duration)) &&
                            //           calculateTime(duration)}
                            //       </p>
                            //     </div>
                            //   </div>

                            //   {/* <div>mute</div> */}
                            // </div>
                            <></>
                          ) : (
                            ""
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </li>
                    <li>
                      <Dropdown
                        className={`${Style.slideshow_options_filter} dropdown-lg`}
                      >
                        <Dropdown.Toggle
                          className={`${Style.filter_dropdown
                            } ${"btn btn-border-grey"}`}
                        >
                          <div className={Style.filter_icon}>
                            <Icons icon={"icon-filter"} size={15} />
                          </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <h4 className="dropdown_title">Transitions</h4>
                          <div className="scroll-dropdown">
                            {transitions?.map((obj) => {
                              let value = Object.values(obj)[0];
                              let key = Object.keys(obj)[0];
                              return (
                                <Dropdown.Item
                                  key={value}
                                  onClick={() => handleEffectClick(value)}
                                >
                                  {key}
                                </Dropdown.Item>
                              );
                            })}
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </li>
                    <li>
                      <div className={Style.slideshow_options_play}>
                        {!showPlayPause ? (
                          <Icons
                            icon={"icon-slideshow-play"}
                            size={15}
                            onClick={() => pauseSlideShow()}
                          />
                        ) : (
                          <Icons
                            icon={"icon-slideshow-pause"}
                            size={15}
                            onClick={() => playSlideShow()}
                          />
                        )}
                      </div>
                    </li>
                    <li>
                      <div className={Style.slideshow_options_loop}>
                        <Icons icon={"icon-loop"} size={15} />
                      </div>
                    </li>
                    <li>
                      <div className={Style.slideshow_options_fullscreen}>
                        {showSlideShow ? (
                          <span onClick={() => setShowSlideShow(false)}>
                            <Icons icon={"icon-fullscreen"} size={15} />
                          </span>
                        ) : (
                          <span>
                            <Icons
                              icon={"icon-miniscreen"}
                              size={15}
                              onClick={() => setShowSlideShow(true)}
                            // onClick={() => document.exitFullscreen()}
                            />
                          </span>
                        )}
                      </div>
                    </li>
                  </ul>
                </div>
                <span className={Style.slideshow_slides_count}>
                  {slides?.length} slides
                </span>
              </div>
            </div>
            <nav className={Style.slideshow_slides_nav}>
              <div className={Style.slideshow_slides_nav_wrap}>
                <Swiper
                  loop={false}
                  onSwiper={setActiveThumb}
                  spaceBetween={23}
                  slidesPerView="auto"
                  freeMode={true}
                  watchSlidesProgress={true}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className={`${Style.slideshow_nav_swiper} mySwiper`}
                >
                  {slides?.map((imgItem, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <figure className={`${Style.slides_nav_image}`}>
                          <img
                            // onClick={playSlideShow}
                            src={imgItem?.src?.thumbnail2}
                            className={`${index === activeIndex && Style.slider_active
                              }`}
                            alt=""
                          />
                        </figure>
                        <button
                          className={Style.delete_img}
                          // onClick={() => handleDeleteImge(index)}
                          onClick={() => deleteImageFromState(index)}
                        >
                          <Icons icon={"icon-close"} size={14} />
                        </button>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
                <span
                  className={Style.slideshow_add_file}
                  onClick={() => handleClickAddImages(slides, id)}
                >
                  <label>
                    <Icons icon={"icon-close"} size={14} />
                  </label>
                </span>
              </div>
            </nav>
          </div>
        </>
      ) : (
        <>
          <SlideShowFullView
            playTrack={playTrack}
            trackIndex={trackIndex}
            effects={effects}
            showSlideShow={showSlideShow}
            setShowSlideShow={setShowSlideShow}
            audio={audio}
            slides={slides}
            setActiveThumb={setActiveThumb}
            showPlayPause={showPlayPause}
            setShowPlayPause={setShowPlayPause}
          />
        </>
      )}
    </>
  );
};

let transitions = [
  { "Classic": "fade-effect" },
  { "Ken Burns": "zoom-out" },
  { "Bounce": "bounce" },
];
