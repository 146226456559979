import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import Style from "./GetStarted.module.scss";
import Assets from "../Layouts/Assets";
import { Link, useLocation } from "react-router-dom";
import HomeChooseAPlan from "../HomeChooseAPlan";
import VideoPlayer from "../../utils/VideoPlayer";
import Icons from "../../components/Layouts/Icons";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const GetStarted = () => {
  const [showPlans, setShowPlans] = useState(false);
  const [play, setPlay] = useState(false);
  
  const { registerData } = useSelector((state) => state.auth);
  
  const { t } = useTranslation(["intro"]);

  const location = useLocation();


  useEffect(() => {
    const allSections = document.querySelectorAll(".anim");
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const element = entry.target;
          element.classList.add("visible");
        }
      });
    });
    allSections.forEach((section) => {
      observer.observe(section);
    });
  }, [location.pathname]);

  return (
    <>
      {!showPlans ? (
        <div className={Style.get_started}>
          <div className={Style.anim_elements}>
            <figure className={`${Style.anim_elem} ${Style.circle_yellow} anim_element anim`}>
              <img
                src={Assets.get_started_intro_circle_yellow}
                alt="circle_yellow"
              />
            </figure>
            <figure className={`${Style.anim_elem} ${Style.donut_grey} anim_element anim`}>
              <img src={Assets.get_started_intro_donut_grey} alt="donut_grey" />
            </figure>
            <figure className={`${Style.anim_elem} ${Style.donut_yellow} anim_element anim`}>
              <img
                src={Assets.get_started_intro_donut_yellow}
                alt="donut_yellow"
              />
            </figure>
            <figure className={`${Style.anim_elem} ${Style.donut_green} anim_element anim`}>
              <img
                src={Assets.get_started_intro_donut_green}
                alt="donut_green"
              />
            </figure>
          </div>
          <div className="container">
            <div className={Style.get_started_wrapper}>
              <div className="anim load-anim">
                <VideoPlayer
                  play={play}
                  setPlay={setPlay}
                  vidUrl="https://youtu.be/-uleG_Vecis"
                />
              </div>
              <h1 className={`${Style.get_started_title} anim load-anim`}>
                Welcome, {registerData?.name}
              </h1>
              <p className={`${Style.get_started_description} anim load-anim`}>{t("intro_desc")}</p>
              <div className={Style.get_started_btn_wrap}>
                <button
                  className="btn btn-primary anim load-anim"
                  onClick={() => setShowPlans(true)}
                >
                  {t("intro_btn")}
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <section className={Style.get_started_choose_plan}>
          <div className={Style.anim_elements}>
            <figure className={`${Style.anim_elem} ${Style.circle_green} anim_element anim`}>
              <img
                src={Assets.get_started_plan_circle_green}
                alt="circle_green"
              />
            </figure>
            <figure className={`${Style.anim_elem} ${Style.donut_grey} anim_element anim`}>
              <img src={Assets.get_started_plan_donut_grey} alt="donut_grey" />
            </figure>
            <figure className={`${Style.anim_elem} ${Style.donut_yellow} anim_element anim`}>
              <img
                src={Assets.get_started_plan_donut_yellow}
                alt="donut_yellow"
              />
            </figure>
          </div>
          <HomeChooseAPlan />
          <div className={`${Style.btn_wrap} anim load-anim`}>
            <Link to="/dashboard" className="btn-link">
              {t("skip_btn")}
            </Link>
          </div>
        </section>
      )}
    </>
  );
};

export default GetStarted;
