import React, { useState } from "react";
import Style from "./ForgotPasswordModal.module.scss";
import Assets from "../../Layouts/Assets";
import { Formik } from "formik";
import * as Yup from "yup";
import { useOtp } from "../../../Logic/useOtp";
import { useTranslation } from "react-i18next";


const ChangePassword = ({
  showForgotOtpResponse,
  setShowForgotPassword,
  setShowFP,
  setShowSignIn,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const { i18n, t } = useTranslation(["authentication"]);


  const { resetPasswordHandler, resetStatus } = useOtp(
    setShowForgotPassword,
    setShowFP,
    setShowSignIn
  );

  const handleVisibility = (e) => {
    e.preventDefault();
    isVisible ? setIsVisible(false) : setIsVisible(true);
  };

  const SignupSchema = Yup.object().shape({
    password: Yup.string()
      .required("Please Provide A Valid Password")
      .min(8, "*Password Should Be Minimum 8"),
  });

  return (
    <div className={`${showForgotOtpResponse?.status ? "" : Style.hideReset}`}>
      <Formik
        initialValues={{ password: "" }}
        validationSchema={SignupSchema}
        onSubmit={(values, { setSubmitting, resetForm, setErrors }) => {
          resetPasswordHandler(values?.password, {
            setShowForgotPassword,
            setShowFP,
            setShowSignIn,
          });
        }}
      >
        {({
          handleChange,
          values,
          errors,
          touched,
          handleBlur,
          handleSubmit,
          setFieldValue,
          dirty
        }) => {
          let hasErrors = Object.keys(errors).length ==0
          return (
            <>
              <div className="form-group password">
                <label htmlFor="signin_input_password">{`${t("reset_password_title2")} *`}</label>
                <div className="input-holder">
                  <input
                    type={isVisible ? "text" : "password"}
                    name="password"
                    id="signin_input_password"
                    className="form-control"
                    placeholder="Enter your password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <button className="eye" onClick={(e) => handleVisibility(e)}>
                    <img
                      src={
                        !isVisible ? Assets.icon_eye : Assets.icon_eye_visible
                      }
                      alt="icon-eye"
                    />
                  </button>
                </div>
              </div>
              <span className={`${errors?.password && Style.otpErrorMessage} ${Style.otp_error_msg_item}`}>
                {errors?.password}
              </span>

              <button
              disabled={!hasErrors || !dirty}
                onClick={handleSubmit}
                className={`${
                  Style.signin_button
                } ${"btn btn-secondary w-100"}`}
              >
                {t("otp_btn")}
              </button>
              
              <div className={Style.successMessage_wrap}>
                <span className={Style.successMessage}>
                  <p>{resetStatus}</p>
                </span>
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default ChangePassword;
