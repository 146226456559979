import React from "react";
import Style from "./Advertisement.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import Assets from "../../components/Layouts/Assets";
import Icons from "../../components/Layouts/Icons";
import "swiper/css";
import { Autoplay } from "swiper";

const Advertisement = () => {
  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={30}
      loop={true}
      modules={[Autoplay]}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      className={`${Style.home_external_links_slider} advertisement-tab-swiper`}
    >
      <SwiperSlide>
        <div className={Style.home_external_links_card}>
            <a target="_blank" href ="https://www.fpaphotography.com" rel="noreferrer" className={Style.home_external_links_card_overlay}> </a>
            <figure className={Style.home_external_links_card_image}>
                <img
                    src={Assets.ext_link_1}
                    alt ="external-link-icon"
                />
            </figure>
            <span className={Style.home_external_links_card_link}>
                <span className={Style.link_icon}><Icons icon={"external-link"} size={11} /></span>
                <span className={Style.link_text}>fpaphotography.com</span>
            </span>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className={Style.home_external_links_card}>
            <a target="_blank" href ="https://www.fpaphotography.com" rel="noreferrer" className={Style.home_external_links_card_overlay}> </a>
            <figure className={Style.home_external_links_card_image}>
                <img
                    src={Assets.ext_link_2}
                    alt ="external-link-icon"
                />
            </figure>
            <span className={Style.home_external_links_card_link}>
                <span className={Style.link_icon}><Icons icon={"external-link"} size={11} /></span>
                <span className={Style.link_text}>fpaphotography.com</span>
            </span>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className={Style.home_external_links_card}>
            <a target="_blank" href ="https://www.fpaphotography.com" rel="noreferrer" className={Style.home_external_links_card_overlay}> </a>
            <figure className={Style.home_external_links_card_image}>
                <img
                    src={Assets.ext_link_3}
                    alt ="external-link-icon"
                />
            </figure>
            <span className={Style.home_external_links_card_link}>
                <span className={Style.link_icon}><Icons icon={"external-link"} size={11} /></span>
                <span className={Style.link_text}>fpaphotography.com</span>
            </span>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className={Style.home_external_links_card}>
            <a target="_blank" href ="https://www.fpaphotography.com" rel="noreferrer" className={Style.home_external_links_card_overlay}> </a>
            <figure className={Style.home_external_links_card_image}>
                <img
                    src={Assets.ext_link_4}
                    alt ="external-link-icon"
                />
            </figure>
            <span className={Style.home_external_links_card_link}>
                <span className={Style.link_icon}><Icons icon={"external-link"} size={11} /></span>
                <span className={Style.link_text}>fpaphotography.com</span>
            </span>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className={Style.home_external_links_card}>
            <a target="_blank" href ="https://www.fpaphotography.com" rel="noreferrer" className={Style.home_external_links_card_overlay}> </a>
            <figure className={Style.home_external_links_card_image}>
                <img
                    src={Assets.ext_link_5}
                    alt ="external-link-icon"
                />
            </figure>
            <span className={Style.home_external_links_card_link}>
                <span className={Style.link_icon}><Icons icon={"external-link"} size={11} /></span>
                <span className={Style.link_text}>fpaphotography.com</span>
            </span>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

export default Advertisement;
