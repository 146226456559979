import { useEffect, useState } from 'react';


 const useDarkmode=()=> {
    const [theme, setTheme] = useState('light');
    

    useEffect(() => {
        const localTheme = window.localStorage.getItem('theme');
        localTheme && setTheme(localTheme)
        if(localTheme === 'dark'){
            setMode('dark')
            document.body.classList.add("dark")
        }else{
            setMode('light')
            document.body.classList.remove("dark")
        }
    }, []);


    const setMode = mode => {
        window.localStorage.setItem('theme', mode)
        setTheme(mode)
    };

    const themeToggler = () => {
        if(theme === 'light'){
            setMode('dark')
            document.body.classList.add("dark")
        }else{
            setMode('light')
            document.body.classList.remove("dark")
        }   
    };
    
    return{
    theme,
    themeToggler
    }
}


export default useDarkmode;