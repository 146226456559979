import React from "react";
import Style from "./Requests.module.scss";
import { MyRequests } from "../MyRequests";
import Assets from "../Layouts/Assets";

const profileData = [
  {
    id: 1,
    name: 'Mathew John',
    image: Assets.prof_1,
    date: 'Just Now',
  },
  {
    id: 2,
    name: 'Jaquelin',
    image: Assets.prof_2,
    date: '2 min',
  },
  {
    id: 3,
    name: 'John David',
    image: Assets.prof_3,
    date: 'Mon 15 Jan 2021',
  },
  {
    id: 4,
    name: 'Irin Perry',
    image: Assets.prof_4,
    date: 'Mon 15 Jan 2021',
  },
  {
    id: 5,
    name: 'Monica Geller',
    image: Assets.prof_5,
    date: 'Mon 15 Jan 2021',
  },
  {
    id: 6,
    name: 'Irvin James',
    image: Assets.prof_6,
    date: 'Mon 15 Jan 2021',
  },
  {
    id: 7,
    name: 'Mathew John',
    image: Assets.prof_1,
    date: 'Just Now',
  },
  {
    id: 8,
    name: 'Jaquelin',
    image: Assets.prof_2,
    date: '2 min',
  },
  {
    id: 9,
    name: 'John David',
    image: Assets.prof_3,
    date: 'Mon 15 Jan 2021',
  },
  {
    id: 10,
    name: 'Irin Perry',
    image: Assets.prof_4,
    date: 'Mon 15 Jan 2021',
  },
  {
    id: 11,
    name: 'Monica Geller',
    image: Assets.prof_5,
    date: 'Mon 15 Jan 2021',
  },
  {
    id: 12,
    name: 'Irvin James',
    image: Assets.prof_6,
    date: 'Mon 15 Jan 2021',
  },
]



const Requests = () => {

  return (
    <div className={Style.invitations}>
      <div className={Style.invitations_container}>
        <div className={Style.tab_item_title}>Pending Requests</div>
        <div className={Style.invitations_wrap}>
          {
            profileData.map((data) => {
              return (
                <MyRequests 
                  key={data.id}
                  name={data.name}
                  image={data.image}
                  date={data.date}
                />
              )
            })
          }
        </div>
      </div>
    </div>
  )
};

export default Requests;
