import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDimensions } from '../../../Logic/Dimensions';
import ContactForm from '../../ContactForm';
import Assets from '../../Layouts/Assets';
import PreLogin from '../../Layouts/PreLogin';
import Style from "./Contact.module.scss";

const Contact = () => {
  const windowDimensions = useDimensions()
  const location = useLocation();


  useEffect(() => {
    (location.pathname === "/contact") && document.body.classList.add('small-header');
    return () => {
      document.body.classList.remove('small-header');
    }
  }, [location.pathname])

  useEffect(() => {
    const allSections = document.querySelectorAll(".anim");
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const element = entry.target;
          element.classList.add("visible");
        }
      });
    });
    allSections.forEach((section) => {
      observer.observe(section);
    });
  }, [location.pathname]);

  return (
    <PreLogin>
        <div className={`${Style.contact_page}`}>
            <section className={Style.contact_banner}>
                <figure className={Style.contact_banner_image}>
                    <img src={Assets.contact_banner} alt="" />
                </figure>
                {windowDimensions.width < 1279 && (
                  <div className='container'>
                    <div className={Style.contact_contents_head}>
                        <h1 className={Style.contact_title}>Get In Touch With Us</h1>
                    </div>
                  </div>
                )}
            </section>
            <section className={Style.contact_contents}>
                <div className='container'>
                  <div className={Style.contact_wrap}>
                      <div className={Style.contact_contents_head}>
                          {windowDimensions.width >= 1279 && (
                            <h1 className={Style.contact_title}>Get In Touch With Us</h1>
                          )}
                          <span className={Style.contact_email_label}>Email</span>
                          <a href="mailto:info@familyphotos.com" target="_blank" className={Style.contact_email_address}>info@familyphotos.com</a>
                      </div>
                    <div className={Style.contact_form_wrap}>
                        <figure className={`${Style.anim_elem} ${Style.circle_green} anim_element anim`}>
                            <img src={Assets.browse_circle_green} alt="circle_yellow" />
                        </figure>
                        <ContactForm />
                    </div>
                  </div>
                </div>
            </section>
        </div>
    </PreLogin>
  )
}

export default Contact;