import { SearchProfileOverView } from "../SearchProfileOverView";
import { SearchProfileGallery } from "../SearchProfileGallery";

export const SearchProfileResult = ({ data }) => {
  return (
    <div>
      {data === "overview" ? (
        <SearchProfileOverView />
      ) : (
        <SearchProfileGallery />
      )}
    </div>
  );
};
