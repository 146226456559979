import Style from "./LeftFilteredCategory.module.scss";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { currentTabSelection } from "../../../../store/slices/gallerySlice";
import { useDispatch, useSelector } from "react-redux";

const LeftFilteredCategory = () => {
  const [floater, setFloater] = useState({
    floaterWidth: 22,
    leftValue: 0,
  });

  let navigate = useNavigate();

  const { currentTab } = useSelector((state) => state.gallery);

  const tabRef = useRef(null);

  const dispatch = useDispatch();

  const handleFloater = (e, pathName, path) => {
    setFloater({
      floaterWidth: e.target.offsetWidth + 4,
      leftValue: e.target.offsetLeft - 2,
    });

    dispatch(currentTabSelection(pathName));
    navigate(`${path}`);
  };

  useEffect(() => {
    setFloater({
      floaterWidth: tabRef?.current?.offsetWidth,
      leftValue: tabRef?.current?.offsetLeft,
    });
  }, [currentTab]);

  const { t } = useTranslation(["dashboard"]);
  const left_filters = t("left_filters", { returnObjects: true });

  return (
    <nav className={Style.left_filtered_category}>
      <div
        className={Style.floater}
        style={{
          width: `${floater.floaterWidth}px`,
          left: `${floater.leftValue}px`,
        }}
      />
      <div className={Style.left_filtered_category_list}>
        {routeData.map((item) => {
          return (
            <div
              key={item?.name}
              ref={currentTab == item?.name ? tabRef : null}
              onClick={(e) => handleFloater(e, item.name, item.path)}
            >
              <a>{item.name}</a>
            </div>
          );
        })}
      </div>
    </nav>
  );
};

let routeData = [
  {
    name: "all",
    path: "",
  },
  {
    name: "favorites",
    path: "favorites",
  },
  {
    name: "album",
    path: "album",
  },
  {
    name: "slideshow",
    path: "slideshow",
  },
  {
    name: "archive",
    path: "archive",
  },
];

export default LeftFilteredCategory;
