import React from "react";

import Style from "./SearchProfileOverView.module.scss";
import { SearchProfileGallery } from "../SearchProfileGallery";
import { SearchProfileFamilyMembers } from "../SearchProfileFamilyMembers";
import { SearchProfileAbout } from "../SearchProfileAbout";

export const SearchProfileOverView = () => {
  return (
    <div className={Style.overview}>
      <div className={Style.overview_about}>
        <SearchProfileAbout />
        <SearchProfileGallery />
      </div>
      <div>
        <SearchProfileFamilyMembers />
      </div>
    </div>
  );
};
