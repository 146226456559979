import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../api";

const initialState = {
  plansData: null,
  testimonialData: null,
  browsePhotosData: null,
  liveStatusData: null,
  status: "idle",
  message: null,
};

export const getPlansData = createAsyncThunk(
  "home/getPlansData",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("list-plans");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getTestimonials = createAsyncThunk(
  "home/testimonials",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("list-testimonials");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getBrowsePhotos = createAsyncThunk(
  "home/browsePhotos",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("list-images");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getStatus = createAsyncThunk(
  "home/getStatus",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("count");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {},
  extraReducers: {
    [getPlansData.pending]: (state, action) => {
      state.status = "loading";
      state.message = "";
    },
    [getPlansData.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.plansData = action.payload.result;
    },
    [getPlansData.rejected]: (state, action) => {
      state.status = "error";
    },
    [getTestimonials.pending]: (state, action) => {
      state.status = "loading";
    },
    [getTestimonials.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.testimonialData = action.payload.result;
    },
    [getTestimonials.rejected]: (state, action) => {
      state.status = "error";
    },
    [getBrowsePhotos.pending]: (state, action) => {
      state.status = "loading";
    },
    [getBrowsePhotos.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.browsePhotosData = action.payload.result;
    },
    [getBrowsePhotos.rejected]: (state, action) => {
      state.status = "error";
    },
    [getStatus.pending]: (state, action) => {
      state.status = "loading";
    },
    [getStatus.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.liveStatusData = action.payload.result;
    },
    [getStatus.rejected]: (state, action) => {
      state.status = "error";
    },
  },
});

export default homeSlice.reducer;
