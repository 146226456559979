import Style from './SearchProfileAbout.module.scss'
export const SearchProfileAbout = () => {
  
  return (
    <div className={Style.searchprofileabout}>
      <div className="h5">About</div>
      <p>
        Thank you for visiting my portfolio.Iam a professionalThank you for
        visiting my portfolio.Iam a professionaThank you for visiting my
        portfolio.
      </p>
    </div>
  );
};
