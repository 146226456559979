import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../api";

const initialState = {
  isLoading: true,
  images: {},
};

export const getMediaURLs = createAsyncThunk(
  "user-image-edit/getMediaURLs",
  async (media_id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const params = {
        params: {
          media_id,
        },
      };
      const response = await api.get(`image-edit/${media_id}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const userImageEditor = createSlice({
  name: "user-image-edit",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.isLoading = true;
    },
  },
  extraReducers: {
    [getMediaURLs.pending]: (state) => {
      state.isLoading = true;
    },
    [getMediaURLs.fulfilled]: (state, action) => {
      console.log("action.payload.data.result", action.payload.data.result);
      state.images = action.payload.data.result;
      state.isLoading = false;
    },
    [getMediaURLs.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
});

export const { showLoading } = userImageEditor.actions;

export default userImageEditor.reducer;
