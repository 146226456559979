import Style from "./Slideshow.module.scss";

import CommonModal from "../Modals/commonModal";
import GalleryBlankPage from "../BlankGallery";
import useSlideshow from "../../Logic/useSlideshow";
import ImageGallery from "../Gallery/ImageGallery";
import SlideshowList from "../SlideshowList";
import Icons from "../Layouts/Icons";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect, useLayoutEffect, useRef } from "react";
import useGallery from "../../Logic/useGallery";
import useSlideShowEdit from "../../Logic/useSlideShowEdit";

const Slideshow = () => {
  const observer = useRef();
  const slideRef = useRef();
  const {
    show,
    slideShowName,
    showImageGallery,
    setShowImageGallery,
    handleShow,
    handleClose,
    handleChange,
    handleSubmit,
    galleryState,
    isSlideshowFetching,
  } = useSlideshow();
  const { currentSelected } = useSlideShowEdit();

  const { loadMore } = useGallery();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);

  useEffect(() => {
    if (slideRef.current) {
      const interceptConfig = {
        root: slideRef.current,
        rootMargin: "0px",
        threshold: 0.1,
      };
      observer.current = new IntersectionObserver((entries) => {
        if (entries.every((entry) => entry.isIntersecting)) {
          loadMore("slideShow");
        }
      });
      observer.current.observe(slideRef.current);
      return () => {
        observer.current.disconnect();
      };
    }
  }, [galleryState?.data]);

  console.log(
    galleryState?.currentActions?.imageGalleryOpen,
    galleryState?.data?.length,
    isSlideshowFetching,
    "hey slide..."
  );

  return (
    <div className={Style.slideshow}>
      {galleryState?.currentActions?.imageGalleryOpen ? (
        <ImageGallery slideshow sectionTo={"all"} />
      ) : (
        <>
          {galleryState?.data?.length >0 &&
            <>
              <div className={Style.slideshow_list}>
                {galleryState?.data.map((item, index) => {
                  const lastIndex = galleryState?.data?.length - 1;
                  return (
                    <>
                      <SlideshowList
                        currentSelected={currentSelected}
                        lastIndex={lastIndex}
                        index={index}
                        ref={slideRef}
                        action={galleryState}
                        item={item}
                      />
                    </>
                  );
                })}
                <div className={Style.add_new_slideshow} onClick={handleShow}>
                  <div className={Style.add_new_slideshow_content}>
                    <div>
                      <Icons icon={"add"} size={24} />
                      Add new slideshow
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
        </>
      )}

      {galleryState?.data?.length == 0 &&
      isSlideshowFetching == "succeeded" &&
      galleryState?.currentActions?.imageGalleryOpen !== true ? (
        <GalleryBlankPage
          title={`You haven't created any slideshow`}
          description={`The collections you create are shown here`}
          buttonText={`Create Slideshow`}
          handleClick={handleShow}
        />
      ) : (
        ""
      )}

      <CommonModal
        showModal={show}
        hide={handleClose}
        className={Style.create_slideshow_modal}
        content={
          <div className={Style.create_slideshow_modal_content}>
            <h3 className={Style.create_slideshow_modal_title}>
              Create Slideshow
            </h3>
            <div className="form-group">
              <label className={Style.slideshow_name_label}>
                Slideshow name
              </label>
              <input
                className={`${Style.slideshow_name_input} form-control`}
                placeholder="Untitled Slideshow"
                value={slideShowName}
                onChange={handleChange}
              />
            </div>
            <div className={Style.create_slideshow_modal_actions}>
              <button
                onClick={handleSubmit}
                className="btn btn-sm btn-secondary"
              >
                Create
              </button>
              <button
                className="btn btn-sm btn-border-grey"
                onClick={() => handleClose()}
              >
                Cancel
              </button>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default Slideshow;
