import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import Style from "./Popup.module.scss";
import SelectMother from "./SelectMother";
import UseFamilyPopup from "../../../Logic/FamilyTree/useFamilyPopup";
import { useDimensions } from "../../../Logic/Dimensions";
import BackHeader from "../../BackHeader";

const Popup = (props) => {
  const {
    handleClose,
    popupData,
    data,
    currentUserData,
    handleNewNode,
    setSelectParent,
    selectParent,
    open,
  } = UseFamilyPopup(props);

  const openClass = open ? Style.open : "";
  const windowDimensions = useDimensions();

  return ReactDOM.createPortal (
    <div className={`${Style.popupWrapper} ${openClass}`}>
      {windowDimensions.width >= 992 ? (
        <div className={Style.closeWrapper}>
          <button onClick={handleClose}>close</button>
        </div>
      ) : (
        <BackHeader onClick={handleClose} />
      )}

      <div className={Style.listWrapper}>
        <div className={Style.listTop}>
          {windowDimensions.width < 992 && (
              <div className={Style.mobileHead}>
                <h2 className={Style.mobileHead_title}>Add a relative</h2>
                <p className={Style.mobileHead_description}>Which relative are you adding?</p>
              </div>
            )}
            <div className={`${Style.listMain} ${selectParent && Style.parent_active}`}>
              <div className={Style.listInner}>
                {/* loop popup data */}
                {popupData.map((connection, index) => {
                  {
                    /* user node */
                  }
                  const MainNode = ({ childData }) => {
                    return (
                      <div className={Style.nodeMainInner} key={childData.id}>
                        <div className={Style.nodeImage}>
                          <div className={Style.nodeImageContainer}>
                            <img
                              src={data && currentUserData.img}
                              alt="user image"
                            />
                          </div>
                        </div>
                        <div className={Style.nodeContent}>
                          <h3 className={Style.title}>
                            {data && currentUserData.name}
                          </h3>
                          <p className={Style.para}>
                            {data && currentUserData.family_name}
                          </p>
                        </div>
                      </div>
                    );
                  };

                  {
                    /* child node  parent - sibling - child - father - mother (handleNewNode function pass connection string) */
                  }
                  const ChildNode = ({ childData }) => {
                    return (
                      <button
                        className={Style.nodeItem}
                        key={childData.id}
                        onClick={() => {
                          childData.connection !== "children"
                            ? handleNewNode({ connection: childData.connection })
                            : setSelectParent(true);
                        }}
                      >
                        <div className={Style.nodeImage}>
                          <div className={Style.nodeImageContainer}>
                            <img src={childData && childData.img} alt="" />
                          </div>
                        </div>
                        <div className={Style.nodeName}>
                          {childData && childData.connectionName}
                        </div>
                      </button>
                    );
                  };

                  {
                    /* node wrapper structure */
                  }
                  const Structure = ({ connectionData }) => {
                    {
                      /* check last child */
                    }
                    const extraClass =
                      popupData.length === index + 1
                        ? Style.extraNode
                        : Style.nodeWrapper;

                    return (
                      <>
                        {connectionData.user ? (
                          <div className={Style.nodeMain} key={connectionData.id}>
                            {connectionData.data.map((child) => {
                              return <MainNode childData={child} key={child.id} />;
                            })}
                          </div>
                        ) : (
                          <div className={extraClass} key={connectionData.id}>
                            <ul className={Style.nodeGroup}>
                              <li className={Style.nodeList}>
                                {connectionData.data.map((child) => {
                                  return (
                                    <ChildNode childData={child} key={child.id} />
                                  );
                                })}
                              </li>
                            </ul>
                          </div>
                        )}
                      </>
                    );
                  };

                  return (
                    <Structure connectionData={connection} key={connection.id} />
                  );
                })}
              </div>
              {selectParent && (
                <SelectMother data={data} handleNewNode={handleNewNode} />
              )}
            </div>
          </div>
          {windowDimensions.width < 992 && (
            <div className={Style.continue_btn_wrap}>
              <button className="btn btn-secondary">Continue</button>
            </div>
          )}
        </div>
    </div>,
    document.getElementById('slideshow-root')
  );
};

export default Popup;
