import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addMemberToTree } from "../../store/slices/familyTreeSlice";
import { useNavigate } from "react-router-dom";

const useFamilyTreeForm = () => {
  const [activeTab, setActiveTab] = useState({
    top: "",
    left: "",
    height: "",
    width: "",
    index: 0,
  });

  const userData = useSelector((state) => state.auth.registerData);

  const [formData, setFormData] = useState([
    {
      name: userData?.name || "",
      family_name: "",
      dob: "",
      bio: "",
    },
    {
      name: "",
      family_name: "",
      dob: "",
      bio: "",
    },
    {
      name: "",
      family_name: "",
      dob: "",
      bio: "",
    },
  ]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = (e, i) => {
    setActiveTab({
      top: e.target.offsetTop,
      left: e.target.offsetLeft,
      height: e.target.offsetHeight,
      width: e.target.offsetWidth,
      index: i,
    });
  };

  const handleFirstRender = (ref, i) => {
    setActiveTab({
      top: ref.current.offsetTop,
      left: ref.current.offsetLeft,
      height: ref.current.offsetHeight,
      width: ref.current.offsetWidth,
      index: i,
    });
  };

  const handleValue = (e) => {
    const { name, value } = e.target;
    let data = formData;
    data[activeTab.index][name] = value;
    setFormData(data);
  };

  const handleSkip = (index) => {
    if (index < 2) {
      setActiveTab((state) => {
        return { ...state, index: state.index + 1 };
      });
    } else {
      handleSubmit(2);
    }
    let tempData = formData;
    tempData[index] = {
      name: "",
      family_name: "",
      dob: "",
      bio: "",
    };

    setFormData(tempData);
  };

  const handleSubmit = (index) => {
    if (index === 2) {
      let tempData = formData;
      formData.map((_, i) => {
        tempData[i] = {
          ...tempData[i],
          id:
            i === 0
              ? 1
              : tempData[1].name.length !== 0
              ? i === 1
                ? 2
                : i === 2
                ? 3
                : ""
              : tempData[2].name.length !== 0
              ? i === 2
                ? 2
                : ""
              : "",
          mid: tempData[1].name.length !== 0 ? (i === 0 ? 2 : null) : null,
          fid:
            tempData[2].name.length !== 0
              ? i === 0
                ? tempData[1].name.length !== 0
                  ? 3
                  : 2
                : null
              : i === 0 && tempData[1].name.length !== 0 && i !== 0
              ? 2
              : null,
          pids:
            tempData[2].name.length !== 0 && tempData[1].name.length !== 0
              ? i === 1
                ? tempData[2].name.length !== 0
                  ? [3]
                  : []
                : i === 2
                ? tempData[1].name.length !== 0
                  ? [2]
                  : []
                : []
              : [],
          user_id: userData?.user_id,
          connection: i === 0 ? "user" : i === 1 ? "mother" : "father",
          gender: i === 0 ? null : i === 1 ? 2 : i === 2 ? 1 : null,
        };
      });
      tempData.map((a, i) => {
        if (i === 2) {
          dispatch(addMemberToTree(a))
            .unwrap()
            .then(() => {
              navigate("/dashboard/family-tree");
            })
            .catch(() => navigate("/dashboard/family-tree"));
        } else {
          dispatch(addMemberToTree(a));
        }
      });
    } else {
      setActiveTab((state) => {
        return { ...state, index: state.index + 1 };
      });
    }
  };

  return {
    activeTab,
    setActiveTab,
    handleClick,
    handleFirstRender,
    handleSubmit,
    formData,
    handleValue,
    handleSkip,
  };
};

export default useFamilyTreeForm;
