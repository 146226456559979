import React from "react";
import PreLogin from "../../components/Layouts/PreLogin";
import StyleGuide from "../../components/StyleGuide";

const StyleGuidePage = () => {
  return (
    <PreLogin>
      <StyleGuide />
    </PreLogin>
  );
};

export default StyleGuidePage;
