import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  customFetchImages,
  addImages,
  selectAllImages,
  unSelectAllImages,
  reArrangeImage,
  removeAllActions,
  getGalleryImages,
  filterImages,
  addToFavorites,
  removeFromFavorites,
  favoriteActions,
  reArrange,
  reArrangedItems,
  updateImageFunctions,
  addtoArchive,
  deleteImage,
  deleteImageAction,
  deleteMultipleImages,
  removeArchive,
  removeArchiveFunction,
  archiveActions,
} from "../store/slices/gallerySlice";

let rearrangeArray = [];

const useGallery = () => {
  const dispatch = useDispatch();
  const [isActiveOptions, setIsActiveOptions] = useState(false);
  const [preSelect, setPreSelect] = useState(false);
  const [editGallery, setEditGallery] = useState(false);
  const {
    galleryImages,
    selectedImages,
    currentPage,
    sections,
    currentTab,
    allImageStatus,
  } = useSelector((state) => state.gallery);

  const getUrls = (slug) => {
    switch (slug) {
      case "all":
        return "list-user-images";
        break;
      case "favorites":
        return "list-favourite";
        break;
      case "album":
        return "album-list";
        break;
      case "slideshow":
        return "list-slideshow";
        break;
      case "archive":
        return "list-archive";
        break;
    }
  };

  const addImageHandler = (img) => {
    dispatch(addImages(img));
  };

  const selectAllImageHandler = () => {
    let imgIds = sections[currentTab].data.map((item, index) => item.id);
    dispatch(selectAllImages(imgIds));
  };

  const unSelectAllImagesHandler = () => {
    dispatch(unSelectAllImages());
  };

  const handleReArrange = (data, draggedItem, changedItem) => {
    let obj = [
      {
        image_id: draggedItem?.id,
        position: changedItem?.position,
      },
      {
        image_id: changedItem?.id,
        position: draggedItem?.position,
      },
    ];

    //rearrangeArray=[...rearrangeArray,...obj]

    if (changedItem?.position !== draggedItem?.position) {
      dispatch(reArrange({ data: obj }));
    }
    dispatch(reArrangedItems(obj));
    dispatch(
      reArrangeImage({
        data: data,
        draggedItem: draggedItem,
        changedItem: changedItem,
      })
    );
  };

  const saveReArrange = () => {};

  const hideSelectHandler = () => {
    setPreSelect(false);
    dispatch(removeAllActions());
  };

  const getImagesForSection = (slug) => {
    let obj = {
      url: getUrls(slug),
      page: "1",
      slug: "desc",
    };

    let customTab = currentTab == "slideshow" ? "all" : currentTab;

    console.log(customTab, "heyy tabb..");

    if (sections[customTab]?.data == undefined) {
      dispatch(getGalleryImages(obj));
    }
  };

  const loadMore = (slug) => {
    if (sections[currentTab]?.current_page < sections[currentTab]?.last_page) {
      let obj = {
        url: getUrls(slug),
        page: sections[currentTab]?.current_page + 1,
        slug: sections[currentTab]?.currentActions?.filterOption
          ? sections[currentTab]?.currentActions?.filterOption
          : "",
      };

      dispatch(customFetchImages(obj));
    }
  };

  const handleFilterImages = (slug) => {
    let obj = {
      url: getUrls(currentTab),
      page: sections[currentTab]?.current_page,
      slug: slug,
    };
    console.log(currentTab, obj, "slide.....filter...");
    dispatch(filterImages(obj));
  };

  const handleClickFavorite = (id) => {
    let newItem = sections[currentTab].data.filter((item, i) => item.id == id);
    if (newItem[0].favourite == 0) {
      dispatch(addToFavorites({ id: id }));
    } else {
      dispatch(removeFromFavorites({ id: id }));
    }
    dispatch(favoriteActions(id));
    let obj = {
      url: "list-favourite",
      page: "1",
      slug: "desc",
    };

    let keyArr = Object.keys(sections);
    if (currentTab == "all" && !keyArr.includes("favorites")) {
      dispatch(updateImageFunctions(obj));
    }
  };

  const handleDeleteImages = (id) => {
    let image_id = [id];
    dispatch(deleteImageAction(id));
    dispatch(deleteImage({ image_id }));
  };

  const multiDelete = () => {
    let image_id = sections[currentTab].selectedImages;
    dispatch(deleteImage({ image_id }));
    dispatch(deleteMultipleImages());
  };

  const handleArchive = () => {
    let image_id = sections[currentTab].selectedImages;
    if (currentTab == "archive") {
      dispatch(removeArchive({ image_id }));
    } else {
      dispatch(addtoArchive({ image_id }));
    }
    dispatch(archiveActions());
  };

  const removeFromArchive = (id) => {
    let image_id = [id];
    dispatch(removeArchiveFunction(id));
    dispatch(removeArchive({ image_id }));
  };

  return {
    editGallery,
    selectedImages,
    isActiveOptions,
    preSelect,
    handleReArrange,
    setEditGallery,
    addImageHandler,
    hideSelectHandler,
    selectAllImageHandler,
    unSelectAllImagesHandler,
    getImagesForSection,
    setPreSelect,
    loadMore,
    // saveReArrange,
    handleFilterImages,
    handleClickFavorite,
    handleDeleteImages,
    multiDelete,
    handleArchive,
    removeFromArchive,
    allImageStatus,
  };
};

export default useGallery;
