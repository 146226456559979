import React from "react";
import { useSelector } from "react-redux";

const useAuthChecker = () => {
  const { registerData } = useSelector((state) => state.auth);

  console.log(registerData, "popppp...");

  let isAuthCompleted = registerData?.user_id ? true : false;

  return { isAuthCompleted };
};

export default useAuthChecker;
