import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../api";

const initialState = {
  isLoading: true,
  userdata: {},
  sections: {
    galleryItems: {},
  },
  familyMembers: {
    data: [],
  },
  currentTab: "overview",
};

export const getFamilyMembersData = createAsyncThunk(
  "userprofile/getFamilyMembersData",
  async (user_id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`show-family`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getProfileDetails = createAsyncThunk(
  "userprofile/getProfileDetails",
  async (user_id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`show-user-profile?id=${user_id}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getGalleryitems = createAsyncThunk(
  "userprofile/getGalleryitems",
  async (user_id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`list-user-images`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const userProfileSlice = createSlice({
  name: "userprofile",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.isLoading = true;
    },
    updateUserProfile: (state, action) => {
      state.userdata = {
        ...state.userdata,
        image: action.payload.img,
        name: action.payload.full_name,
      };
      console.log(action.payload, "profile update...");
    },
  },
  extraReducers: {
    [getProfileDetails.pending]: (state) => {
      state.isLoading = true;
    },
    [getProfileDetails.fulfilled]: (state, action) => {
      state.userdata = action.payload.data.result;
      state.isLoading = false;
    },
    [getProfileDetails.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getFamilyMembersData.pending]: (state) => {
      state.familyMembers.isLoading = true;
    },
    [getFamilyMembersData.fulfilled]: (state, action) => {
      state.familyMembers.isLoading = false;
      state.familyMembers.data = action.payload?.data.result;
    },
    [getFamilyMembersData.rejected]: (state, action) => {
      state.familyMembers.isLoading = false;
      state.familyMembers.status = "error";
    },
  },
});

export const { showLoading, updateUserProfile } = userProfileSlice.actions;

export default userProfileSlice.reducer;
