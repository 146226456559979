import React from "react";
import {Modal} from "react-bootstrap";

const AuthenticationModal =({showModal,setShowModal, children})=>{
    return(
    <Modal
    show={showModal}
    onHide={() => setShowModal(false)}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    backdrop="static"
  >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
      
      </Modal.Title>
    </Modal.Header>

    <Modal.Body>
     {children}
    </Modal.Body>
  </Modal>
    )

}

export default AuthenticationModal