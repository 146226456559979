import React, { useEffect, useLayoutEffect, useRef } from "react";
import useGallery from "../../Logic/useGallery";
import GalleryImage from "../Gallery/ImageGallery/GalleryImage";
import Masonry from "react-masonry-css";
import { useSelector } from "react-redux";
import Style from "../Gallery/ImageGallery/ImageGallery.module.scss";
import { DashboardLoader } from "../DashboardLoader";
import GalleryBlankPage from "../BlankGallery";

const breakpointColumnsObj = {
  default: 5,
  1599: 4,
  991: 3,
  575: 2,
  424: 1
};

const Archive = () => {
  const observer = useRef();
  const imgRootRef = useRef(null);
  const imgItemRef = useRef(null);
  const { getImagesForSection, loadMore } =useGallery();
  const { sections, currentTab,allImageStatus } = useSelector((state) => state.gallery);

  useEffect(() => {
    getImagesForSection("archive");
  }, []);

  useLayoutEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);

  useEffect(() => {
    if (imgItemRef.current) {
      const interceptConfig = {
        root: imgRootRef.current,
        rootMargin: "0px",
        threshold: 0.1,
      };
      observer.current = new IntersectionObserver((entries) => {
        if (entries.every((entry) => entry.isIntersecting)) {
          loadMore("archive");
        }
      });
      observer.current.observe(imgItemRef.current);
      return () => {
        observer.current.disconnect();
      };
    }
  }, [sections[currentTab]?.data]);

  return (
    <div
      ref={imgRootRef}
      className={`${Style.image_gallery} masonry-wrap`}
      data-masonry='{"percentPosition": true }'
    >
      {allImageStatus == "succeeded" &&
      sections[currentTab]?.data?.length > 0 ? (
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {sections[currentTab]?.data?.map((image, i) => {
          const lastIndex = sections[currentTab]?.data?.length - 1;
          return (
            <div key={i} ref={lastIndex == i ? imgItemRef : null}>
              <GalleryImage
                handleOpenImageFullView={{}}
                key={image.id}
                image={image}
                i={i}
              />
            </div>
          );
        })}
      </Masonry>
      )
      : allImageStatus == "loading" ? (
        <DashboardLoader data={sections[currentTab]?.data} />
      ) : (
        <GalleryBlankPage
          title={``}
          description={`The Archive collections you create are shown here`}
          buttonText={``}
          handleClick={{}}
        />
      )}
     
    </div>
  );
};

export default Archive;
