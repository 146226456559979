import { SearchProfileHeader } from "../SearchProfileHeader";
import { SearchProfileResult } from "../SearchProfileResult";
import { useState } from "react";

export const SearchProfile = () => {
  const [content, setContent] = useState("overview");

  return (
    <div>
      <SearchProfileHeader setContent={setContent} />
      <SearchProfileResult data={content} />
    </div>
  );
};
