import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Style from "../utils/VideoPlayer/VideoPlayer.module.scss";

export const useVideoPlayer = ({ play, setPlay }) => {
  const player = useRef();
  const dispatch = useDispatch();

  const [currentDuration, setCurrentDuration] = useState("0.00");
  const [totalDuration, setTotalDuration] = useState(0);
  const [fullScreen, setFullScreen] = useState(false);
  const [volume, setVolume] = useState(1);

  const progress = (e) => {

    setCurrentDuration(e.playedSeconds);
  };

  const puaseAndPlay = () => {
    setPlay(!play);
  };

  const fullScreenHandler = () => {
    let elem = document.querySelector('.custom_player}');
    let videoElem = document.querySelector(`#myvideo`);
    // full_screen;

    if (!fullScreen) {
      setFullScreen(true);
      videoElem.classList.add("full_screen");
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        /* Firefox */
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Chrome, Safari & Opera */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE/Edge */
        elem = window.top.document.body; //To break out of frame in IE
        elem.msRequestFullscreen();
      }
    } else {
      videoElem.classList.remove("full_screen");
      setFullScreen(false);
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        window.top.document.msExitFullscreen();
      }
    }
  };

  function secondsToTime(e) {
    var h = Math.floor(e / 3600)
      .toString()
      .padStart(2, "0"),
      m = Math.floor((e % 3600) / 60)
        .toString()
        .padStart(2, "0"),
      s = Math.floor(e % 60)
        .toString()
        .padStart(2, "0");

    return m + ":" + s;
  }

  const duration = (e) => {
    setTotalDuration(e);
  };

  const seekHandler = (value) => {
    player.current.seekTo(value, "seconds");
    // setCurrentDuration(value);
  };

  const playBackSpeedHandler = () => {
    player.current.playbackRate = 3;
  };

  return {
    player,
    currentDuration,
    totalDuration,
    fullScreen,
    volume,
    setCurrentDuration,
    setTotalDuration,
    setFullScreen,
    setVolume,
    seekHandler,
    duration,
    secondsToTime,
    puaseAndPlay,
    progress,
    fullScreenHandler,
    playBackSpeedHandler,
  };
};
