import main_logo from "../../assets/images/main_logo.svg";
import main_logo_dark from "../../assets/images/main_logo_dark.svg";
import dummyVideoImg from "../../assets/images/dummyVideoImg.png";
import kidTempVideo from "../../assets/kidTempVideo.mp4";
import homeBannerVideo from "../../assets/homeBannerVideo.mp4";
import homeBannerVideoMob from "../../assets/homeBannerVideoMob.mp4";
import homeBannerVideoPoster from "../../assets/banner_video_poster.png";
import homeBannerVideoPosterMob from "../../assets/banner_video_poster_mob.png";
import en_flag from "../../assets/images/en_flag.svg";
import flag_india from "../../assets/images/flag_india.svg";
import scroll_arrow from "../../assets/images/scroll_arrow.svg";

import icon_eye from "../../assets/images/eye.svg";
import icon_eye_visible from "../../assets/images/icon-eye-visible.svg";

import collapse_holder from "../../assets/images/dashboard/collapse-holder.svg";
import play_lg from "../../assets/images/play_lg.svg";
import pause_lg from "../../assets/images/pause_lg.svg";

import google_logo from "../../assets/images/google_icon.svg";
import fb_logo from "../../assets/images/fb_icon.svg";

import explore_bg from "../../assets/images/create-and-explore-bg.png";

import english_flag from "../../assets/images/flags/english_flag.svg";
import french_flag from "../../assets/images/flags/french_flag.svg";
import german_flag from "../../assets/images/flags/german_flag.svg";
import italian_flag from "../../assets/images/flags/italian_flag.svg";
import japanese_flag from "../../assets/images/flags/japanese_flag.svg";
import russian_flag from "../../assets/images/flags/russian_flag.svg";
import spanish_flag from "../../assets/images/flags/spanish_flag.svg";

import ext_link_1 from "../../assets/images/external_links/ext_link_1.png";
import ext_link_2 from "../../assets/images/external_links/ext_link_2.png";
import ext_link_3 from "../../assets/images/external_links/ext_link_3.png";
import ext_link_4 from "../../assets/images/external_links/ext_link_4.png";
import ext_link_5 from "../../assets/images/external_links/ext_link_5.png";

import browse_1 from "../../assets/images/browse_photos_gallery/bpg_1.png";
import browse_2 from "../../assets/images/browse_photos_gallery/bpg_2.png";
import browse_3 from "../../assets/images/browse_photos_gallery/bpg_3.png";
import browse_4 from "../../assets/images/browse_photos_gallery/bpg_4.png";
import browse_5 from "../../assets/images/browse_photos_gallery/bpg_5.png";
import browse_6 from "../../assets/images/browse_photos_gallery/bpg_6.png";
import browse_7 from "../../assets/images/browse_photos_gallery/bpg_7.png";
import browse_8 from "../../assets/images/browse_photos_gallery/bpg_8.png";
import browse_9 from "../../assets/images/browse_photos_gallery/bpg_9.png";
import browse_10 from "../../assets/images/browse_photos_gallery/bpg_10.png";
import browse_11 from "../../assets/images/browse_photos_gallery/bpg_11.png";
import browse_12 from "../../assets/images/browse_photos_gallery/bpg_12.png";
import browse_13 from "../../assets/images/browse_photos_gallery/bpg_13.png";

import testimony_1 from "../../assets/images/testimony/testimony_1.png";
import testimony_2 from "../../assets/images/testimony/testimony_2.png";
import testimony_3 from "../../assets/images/testimony/testimony_3.png";

import browse_mob_1 from "../../assets/images/browse_photos_gallery/responsive/browse_mob_1.png";
import browse_mob_2 from "../../assets/images/browse_photos_gallery/responsive/browse_mob_2.png";
import browse_mob_3 from "../../assets/images/browse_photos_gallery/responsive/browse_mob_3.png";
import browse_mob_4 from "../../assets/images/browse_photos_gallery/responsive/browse_mob_4.png";
import browse_mob_5 from "../../assets/images/browse_photos_gallery/responsive/browse_mob_5.png";
import browse_mob_6 from "../../assets/images/browse_photos_gallery/responsive/browse_mob_6.png";
import browse_mob_7 from "../../assets/images/browse_photos_gallery/responsive/browse_mob_7.png";

import memory_1 from "../../assets/images/memories/memory_1.png";
import memory_2 from "../../assets/images/memories/memory_2.png";
import memory_3 from "../../assets/images/memories/memory_3.png";
import memory_4 from "../../assets/images/memories/memory_4.png";
import memory_5 from "../../assets/images/memories/memory_5.png";
import memory_6 from "../../assets/images/memories/memory_6.png";
import memory_7 from "../../assets/images/memories/memory_7.png";
import memory_8 from "../../assets/images/memories/memory_8.png";
import memory_9 from "../../assets/images/memories/memory_9.png";
import memory_10 from "../../assets/images/memories/memory_10.png";
import memory_11 from "../../assets/images/memories/memory_11.png";
import memory_12 from "../../assets/images/memories/memory_12.png";
import memory_13 from "../../assets/images/memories/memory_13.png";
import memory_14 from "../../assets/images/memories/memory_14.png";

import browse_circle_yellow from "../../assets/images/anim_elements/browse_photos/circle_yellow.svg";
import browse_circle_green from "../../assets/images/anim_elements/browse_photos/circle_green.svg";
import browse_circle_grey from "../../assets/images/anim_elements/browse_photos/circle_grey.svg";
import browse_donut_yellow from "../../assets/images/anim_elements/browse_photos/donut_yellow.svg";
import browse_donut_grey from "../../assets/images/anim_elements/browse_photos/donut_grey.svg";

import plan_circle_green from "../../assets/images/anim_elements/plans/circle_green.svg";
import plan_donut_yellow from "../../assets/images/anim_elements/plans/donut_yellow.svg";

import testimonial_donut_blue from "../../assets/images/anim_elements/testimonials/donut_blue.svg";

import get_started_plan_circle_green from "../../assets/images/anim_elements/get_started_plans/circle_green.svg";
import get_started_plan_donut_grey from "../../assets/images/anim_elements/get_started_plans/donut_grey.svg";
import get_started_plan_donut_yellow from "../../assets/images/anim_elements/get_started_plans/donut_yellow.svg";

import memories_circle_blue from "../../assets/images/anim_elements/memories/circle_blue.svg";
import memories_circle_green from "../../assets/images/anim_elements/memories/circle_green.svg";
import memories_donut_yellow from "../../assets/images/anim_elements/memories/donut_yellow.svg";

import gallery_1 from "../../assets/images/dashboard/my_gallery/gallery_1.png";
import gallery_2 from "../../assets/images/dashboard/my_gallery/gallery_2.png";
import gallery_3 from "../../assets/images/dashboard/my_gallery/gallery_3.png";
import gallery_4 from "../../assets/images/dashboard/my_gallery/gallery_4.png";
import gallery_5 from "../../assets/images/dashboard/my_gallery/gallery_5.png";
import gallery_6 from "../../assets/images/dashboard/my_gallery/gallery_6.png";
import gallery_7 from "../../assets/images/dashboard/my_gallery/gallery_7.png";
import gallery_8 from "../../assets/images/dashboard/my_gallery/gallery_8.png";
import gallery_9 from "../../assets/images/dashboard/my_gallery/gallery_9.png";
import gallery_10 from "../../assets/images/dashboard/my_gallery/gallery_10.png";
import gallery_11 from "../../assets/images/dashboard/my_gallery/gallery_11.png";
import gallery_12 from "../../assets/images/dashboard/my_gallery/gallery_12.png";
import gallery_13 from "../../assets/images/dashboard/my_gallery/gallery_13.png";
import gallery_14 from "../../assets/images/dashboard/my_gallery/gallery_14.png";
import gallery_15 from "../../assets/images/dashboard/my_gallery/gallery_15.png";
import get_started_intro_circle_yellow from "../../assets/images/anim_elements/get_started_intro/circle_yellow.svg";
import get_started_intro_donut_grey from "../../assets/images/anim_elements/get_started_intro/donut_grey.svg";
import get_started_intro_donut_green from "../../assets/images/anim_elements/get_started_intro/donut_green.svg";
import get_started_intro_donut_yellow from "../../assets/images/anim_elements/get_started_intro/donut_yellow.svg";

import photo_frame01 from "../../assets/images/photo_frames/photo-frame01.png"
import photo_frame02 from "../../assets/images/photo_frames/photo-frame02.svg"

import file_upload_image from "../../assets/images/dashboard/file_upload_image.svg";

import AVATAR from "../../assets/images/family_tree/avatar.png";
import AVATAR_01 from "../../assets/images/family_tree/avatar-01.png";
import AVATAR_02 from "../../assets/images/family_tree/avatar-02.png";
import AVATAR_03 from "../../assets/images/family_tree/avatar-03.png";

import logo_icon from '../../assets/images/loader/logo_icon.svg';
import logo_dot_yellow from '../../assets/images/loader/logo-dot-yellow.svg';
import logo_dot_green from '../../assets/images/loader/logo-dot-green.svg';
import logo_dot_blue from '../../assets/images/loader/logo-dot-blue.svg';

import contact_banner from '../../assets/images/contact/contact-banner.png';
import blank_folder from '../../assets/images/blank-folder.svg';

import prof_1 from '../../assets/images/profile_images/prof-1.png';
import prof_2 from '../../assets/images/profile_images/prof-2.png';
import prof_3 from '../../assets/images/profile_images/prof-3.png';
import prof_4 from '../../assets/images/profile_images/prof-4.png';
import prof_5 from '../../assets/images/profile_images/prof-5.png';
import prof_6 from '../../assets/images/profile_images/prof-6.png';

const Assets = {
  main_logo,
  main_logo_dark,
  dummyVideoImg,
  kidTempVideo,
  homeBannerVideo,
  homeBannerVideoMob,
  homeBannerVideoPoster,
  homeBannerVideoPosterMob,
  en_flag,
  flag_india,
  scroll_arrow,

  icon_eye,
  icon_eye_visible,

  collapse_holder,
  play_lg,
  pause_lg,

  google_logo,
  fb_logo,

  explore_bg,

  english_flag,
  german_flag,
  italian_flag,
  spanish_flag,
  russian_flag,
  french_flag,
  japanese_flag,

  ext_link_1,
  ext_link_2,
  ext_link_3,
  ext_link_4,
  ext_link_5,

  testimony_1,
  testimony_2,
  testimony_3,

  browse_1,
  browse_2,
  browse_3,
  browse_4,
  browse_5,
  browse_6,
  browse_7,
  browse_8,
  browse_9,
  browse_10,
  browse_11,
  browse_12,
  browse_13,

  browse_mob_1,
  browse_mob_2,
  browse_mob_3,
  browse_mob_4,
  browse_mob_5,
  browse_mob_6,
  browse_mob_7,

  memory_1,
  memory_2,
  memory_3,
  memory_4,
  memory_5,
  memory_6,
  memory_7,
  memory_8,
  memory_9,
  memory_10,
  memory_11,
  memory_12,
  memory_13,
  memory_14,

  browse_circle_yellow,
  browse_circle_green,
  browse_circle_grey,
  browse_donut_yellow,
  browse_donut_grey,

  plan_circle_green,
  plan_donut_yellow,

  testimonial_donut_blue,

  get_started_plan_circle_green,
  get_started_plan_donut_grey,
  get_started_plan_donut_yellow,

  memories_circle_blue,
  memories_circle_green,
  memories_donut_yellow,

  gallery_1,
  gallery_2,
  gallery_3,
  gallery_4,
  gallery_5,
  gallery_6,
  gallery_7,
  gallery_8,
  gallery_9,
  gallery_10,
  gallery_11,
  gallery_12,
  gallery_13,
  gallery_14,
  gallery_15,
  get_started_intro_circle_yellow,
  get_started_intro_donut_grey,
  get_started_intro_donut_green,
  get_started_intro_donut_yellow,

  photo_frame01,
  photo_frame02,

  AVATAR,
  AVATAR_01,
  AVATAR_02,
  AVATAR_03,
  file_upload_image,

  logo_icon,
  logo_dot_yellow,
  logo_dot_green,
  logo_dot_blue,
  
  contact_banner,
  blank_folder,

  prof_1,
  prof_2,
  prof_3,
  prof_4,
  prof_5,
  prof_6,
};

export default Assets;
