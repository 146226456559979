import { Link, Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import Style from "./UserProfile.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { currentTabSelection } from "../../store/slices/gallerySlice";
import { ListGroup } from "react-bootstrap";
import { useDimensions } from "../../Logic/Dimensions";
import { useProfile } from "../../Logic/useProfile";
import { useLocation } from "react-router-dom";

const UserProfile = () => {
  const dispatch = useDispatch();

  const { userdata, connections_store } = useProfile();
  const location = useLocation();

  const [floater, setFloater] = useState({
    floaterWidth: 121,
    leftValue: 0,
  });

  const [tabLinkActive, setTabLinkActive] = useState("Overview");

  const handleFloater = (e, pathName) => {
    setFloater({
      floaterWidth: e.target.offsetWidth + 40,
      leftValue: e.target.offsetLeft - 20,
    });
    if (pathName == "Gallery") {
      dispatch(currentTabSelection("all"));
    }

    setTabLinkActive(pathName);
  };

  useEffect(() => {
    dispatch(currentTabSelection("all"));
  }, []);

  const windowDimensions = useDimensions();

  return (
    <div className={Style.user_profile}>
      <div className={Style.user_profile_header}>
        <div className={Style.user_profile_row}>
          {windowDimensions.width >= 768 ? (
            <div className={Style.user_profile_profimg}>
              <figure>
                <img src={userdata?.image} />
              </figure>
            </div>
          ) : (
            <></>
          )}
          <div className={Style.user_profile_details}>
            <div className={Style.user_profile_details_row}>
              <div className={Style.user_profile_details_main}>
                <div className={Style.user_profile_details_name_header}>
                  {windowDimensions.width < 768 ? (
                    <div className={Style.user_profile_profimg}>
                      <figure>
                        <img src={userdata?.image} />
                      </figure>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className={Style.user_profile_details_name_wrap}>
                    <h1 className={Style.user_profile_details_name}>
                      {userdata?.name}
                    </h1>
                    {windowDimensions.width < 1440 ? (
                      <div className={Style.user_profile_details_links}>
                        <button className={Style.user_profile_details_btn}>
                          Request to Link
                        </button>
                        <button className={Style.user_profile_details_btn}>
                          Add on tree
                        </button>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <ListGroup as="ul">
                  <ListGroup.Item as="li">
                    {userdata?.family_name}
                  </ListGroup.Item>
                  <ListGroup.Item as="li">
                    {userdata?.gender_id == 1
                      ? "Male"
                      : userdata?.gender_id == 2
                      ? "Female"
                      : "Other"}
                  </ListGroup.Item>
                  <ListGroup.Item as="li">
                    Born {userdata?.dob_in_format}
                  </ListGroup.Item>
                </ListGroup>
              </div>
              {windowDimensions.width >= 1440 ? (
                <div className={Style.user_profile_details_links}>
                  <button className={Style.user_profile_details_btn}>
                    Request to Link
                  </button>
                  <button className={Style.user_profile_details_btn}>
                    Add on tree
                  </button>
                </div>
              ) : (
                <></>
              )}
            </div>

            <div className={Style.left_filtered_category_list}>
              {routeData.map((item, index) => {
                return (
                  <div
                    onClick={(e) => handleFloater(e, item?.name)}
                    className={`${
                      tabLinkActive === item?.name
                        ? Style.left_filtered_category_list_active
                        : ""
                    }`}
                  >
                    <Link to={`${item.path}`} data-name={item?.name}>
                      {item?.name}
                    </Link>
                  </div>
                );
              })}

              {windowDimensions.width < 1440 ? (
                <div
                  onClick={(e) => handleFloater(e, "Members")}
                  className={`${
                    tabLinkActive === "Members"
                      ? Style.left_filtered_category_list_active
                      : ""
                  }`}
                >
                  <Link to={"members"} data-name="Members">
                    Members
                  </Link>
                </div>
              ) : (
                <></>
              )}
              <div
                className={Style.floater}
                style={{
                  width: `${floater.floaterWidth}px`,
                  left: `${floater.leftValue}px`,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <Outlet />
    </div>
  );
};

let routeData = [
  {
    name: "Overview",
    path: "",
  },
  {
    name: "Gallery",
    path: "gallery",
  },
  /*{
    name: "View on tree",
    path: "view-on-tree",
  },*/
];

export default UserProfile;
