import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  albumList,
  addAlbum,
  createAlbum,
  removeAlbum,
  editAlbum,
  removeAlbumFromState,
  clearAlbumState,
  saveAlbum,
  frameList,
  getAlbumItem,
  addImages,
  deleteImageAction,
  deleteMultipleImages,
} from "../store/slices/gallerySlice";

const useAlbum = () => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [show, setShow] = useState(false);
  const {
    sections,
    currentTab,
    albumListStatus,
    isAlbumCreating,
    isAddtoToAlbum,
    isAlbumEditing,
    frameListStatus,
    frames,
  } = useSelector((state) => state.gallery);

  const dispatch = useDispatch();

  const getFrameList = () => {
    if (frames?.length == 0) {
      dispatch(frameList());
    }
  };

  const getAlbumsList = () => {
    if (albumListStatus == "idle") {
      let obj = {
        page: "1",
        slug: "desc",
      };
      dispatch(albumList(obj));
    }
  };

  const addNewAlbum = () => {
    dispatch(createAlbum({ image_id: sections[currentTab].selectedImages }));
  };

  const handleRemoveAlbum = (id) => {
    let album_id = [id];
    dispatch(deleteImageAction(id));
    dispatch(removeAlbum({ album_id }));
  };

  const multiDeleteAlbums = () => {
    let album_id = sections[currentTab].selectedImages;
    dispatch(deleteMultipleImages());
    dispatch(removeAlbum({ album_id }));
  };

  const handleEditAlbum = (item) => {
    let data = {
      album_id: item?.id,
      image_id: sections[currentTab].selectedImages,
    };
    dispatch(editAlbum(data));
  };

  const handleClearAlbumState = () => {
    dispatch(clearAlbumState());
  };

  const handleSaveAlbum = async (e, data) => {
    e.preventDefault();
    let newData = {
      title: data.title,
      description: data.description,
      frame_id: data.frame_id,
      cover_photo: data.cover_photo,
      album_id: data.id,
      frameClass: data.frameClass,
      cropValue: data.crop_value ? data.crop_value : 0,
    };
    const resultAction = await dispatch(editAlbum(newData));

    if (editAlbum.fulfilled.match(resultAction)) {
      console.log(resultAction.payload, "success");
      setOpenEditModal(!openEditModal);
    } else {
      console.log(resultAction.payload, "fail...");
    }
  };

  const getAlbum = (slug) => {
    dispatch(getAlbumItem(slug));
  };

  const selectAlbumItems = (id) => {
    dispatch(addImages(id));
  };

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  return {
    addNewAlbum,
    getAlbumsList,
    albumListStatus,
    isAlbumCreating,
    isAddtoToAlbum,
    isAlbumEditing,
    handleRemoveAlbum,
    sections,
    currentTab,
    albumData: sections["album"]?.data,
    handleEditAlbum,
    handleClearAlbumState,
    handleSaveAlbum,
    openEditModal,
    setOpenEditModal,
    getAlbum,
    frames,
    selectAlbumItems,
    multiDeleteAlbums,
    getFrameList,
    handleShow,
    show,
    handleClose,
  };
};

export default useAlbum;
