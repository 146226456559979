import React, { useState } from "react";
import { Link, Navigate, Outlet } from "react-router-dom";
import Advertisement from "../Advertisement";
import FamilyUsersPrice from "../FamilyUsersPrice";
import Style from "./Dashboard.module.scss";
import Icons from "../../components/Layouts/Icons";
import { useTranslation } from "react-i18next";
import { useDimensions } from "../../Logic/Dimensions";
import { DashboardMenu } from "../DashboardMenu";
import useOnlineStatus from "../../utils/utilityHooks/onlineStatus";
import useAuthChecker from "../../Logic/useAuthChecker";

const Dashboard = ({ languageOpen, setLanguageOpen }) => {
  let online = useOnlineStatus();
  const [collapseMenu, setCollapseMenu] = useState(false);
  const { t } = useTranslation(["dashboard"]);
  const windowDimensions = useDimensions();
  const sidebar_links = t("sidebar_links", { returnObjects: true });
  const { isAuthCompleted } = useAuthChecker();

  return isAuthCompleted ? (
    <div
      className={`${Style.dashboard} ${
        collapseMenu
          ? Style.dashboard_sidebar_collapse
          : Style.dashboard_sidebar_expand
      }`}
    >
      {windowDimensions.width >= 1280 ? (
        <div
          className={`${Style.dashboard_sidebar} ${
            collapseMenu ? Style.sidebar_collapse : ""
          }`}
        >
          <figure className={Style.collapse_holder}>
            <svg
              className={Style.collapse_holder_image}
              xmlns="http://www.w3.org/2000/svg"
              width="95.318"
              height="138.926"
              viewBox="0 0 95.318 138.926"
            >
              <path
                id="Path_76279"
                data-name="Path 76279"
                d="M0,137.926H64.73v-12.3a28.256,28.256,0,0,1,10.625-22.08l3.8-3.033a40.34,40.34,0,0,0,.09-62.971l-3.949-3.171A28.257,28.257,0,0,1,64.73,12.345V0H0Z"
                transform="translate(0.5 0.5)"
                fill="#fff"
                stroke="rgba(0,0,0,0)"
                strokeMiterlimit="10"
                strokeWidth="1"
              />
            </svg>
            <button
              className={`${Style.collapse_holder_btn} ${
                !collapseMenu ? "" : Style.collapse_menu_closed
              }`}
              onClick={() => {
                !collapseMenu ? setCollapseMenu(true) : setCollapseMenu(false);
              }}
            >
              <Icons
                icon={!collapseMenu ? "collapse-button" : "options"}
                size={!collapseMenu ? 42 : 30}
                className={!collapseMenu ? "" : Style.collapse_menu_closed_icon}
              />
            </button>
          </figure>
          <div className={Style.dashboard_sidebar_menu}>
            <h5 className={Style.dashboard_sidebar_title}>
              {t("sidebar_title")}
            </h5>
            <ul className={Style.dashboard_sidebar_links}>
              {collapseMenu ? (
                <>
                  <li className={Style.dashboard_tab_active} title="My Gallery">
                    <Link to="">
                      <Icons icon={"icon-image"} size={17} />
                      <span>My Gallery</span>
                    </Link>
                  </li>
                  <li title="Family Tree">
                    <Link to="family-tree">
                      <Icons icon={"icon-people"} size={17} />
                      <span>Family Tree</span>
                    </Link>
                  </li>
                  <li title="Requests">
                    <Link to="requests">
                      <Icons icon={"icon-recieve"} size={17} />
                      <span>Requests</span>
                    </Link>
                  </li>
                  <li title="Invitations">
                    <Link to="invitations">
                      <Icons icon={"icon-invitation"} size={17} />
                      <span>Invitations</span>
                    </Link>
                  </li>
                  <li title="Preference">
                    <Link to="preference">
                      <Icons icon={"icon-settings"} size={17} />
                      <span>Preference</span>
                    </Link>
                  </li>
                </>
              ) : (
                <>
                  <li className={Style.dashboard_tab_active}>
                    <Link to="">
                      <Icons icon={"icon-image"} size={17} />
                      <span>My Gallery</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="family-tree">
                      <Icons icon={"icon-people"} size={17} />
                      <span>Family Tree</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="requests">
                      <Icons icon={"icon-recieve"} size={17} />
                      <span>Requests</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="invitations">
                      <Icons icon={"icon-invitation"} size={17} />
                      <span>Invitations</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="preference">
                      <Icons icon={"icon-settings"} size={17} />
                      <span>Preference</span>
                    </Link>
                  </li>
                </>
              )}
            </ul>
            <Advertisement />
            <FamilyUsersPrice />
            {/* <DashboardMenu /> */}
            {/* <DashboardCard />  */}
          </div>
        </div>
      ) : (
        <div className={Style.dashboard_sidebar_mob}>
          <ul className={Style.dashboard_sidebar_links}>
            <li className={Style.dashboard_tab_active}>
              <Link to="">
                <Icons icon={"icon-image"} size={22} />
              </Link>
            </li>
            <li>
              <Link to="family-tree">
                <Icons icon={"icon-people"} size={22} />
              </Link>
            </li>
            <li>
              <Link to="requests">
                <Icons icon={"icon-recieve"} size={22} />
              </Link>
            </li>
            <li>
              <Link to="invitations">
                <Icons icon={"icon-invitation"} size={22} />
              </Link>
            </li>
            <li>
              <Link to="preference">
                <Icons icon={"icon-settings"} size={22} />
              </Link>
            </li>
          </ul>
        </div>
      )}
      <div
        className={`${Style.dashboard_outlet} ${
          languageOpen && "language-open"
        }`}
      >
        <Outlet />
      </div>
      <span className={Style.onlineStatus}>{online}</span>
    </div>
  ) : (
    <Navigate to="/" />
  );
};

export default Dashboard;
