import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./components/App";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import rootReducers from "./store";
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore, persistReducer } from "redux-persist";
import { getDefaultMiddleware } from "@reduxjs/toolkit";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./i18n";

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);
const store = configureStore({
  reducer: rootReducers,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});

const persistor = persistStore(store);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <DndProvider backend={HTML5Backend}>
        <App />
        <ToastContainer />
      </DndProvider>
    </PersistGate>
  </Provider>
);
