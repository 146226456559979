var ImageFilters = {};

ImageFilters.utils = {
  initSampleCanvas: function () {
    var _canvas = document.createElement("canvas"),
      _context = _canvas.getContext("2d");

    _canvas.width = 0;
    _canvas.height = 0;

    this.getSampleCanvas = function () {
      return _canvas;
    };
    this.getSampleContext = function () {
      return _context;
    };
    this.createImageData = _context.createImageData
      ? function (w, h) {
          return _context.createImageData(w, h);
        }
      : function (w, h) {
          return new ImageData(w, h);
        };
  },
  getSampleCanvas: function () {
    this.initSampleCanvas();
    return this.getSampleCanvas();
  },
  getSampleContext: function () {
    this.initSampleCanvas();
    return this.getSampleContext();
  },
  createImageData: function (w, h) {
    this.initSampleCanvas();
    return this.createImageData(w, h);
  },
  clamp: function (value) {
    return value > 255 ? 255 : value < 0 ? 0 : value;
  },
  buildMap: function (f) {
    for (var m = [], k = 0, v; k < 256; k += 1) {
      m[k] = (v = f(k)) > 255 ? 255 : v < 0 ? 0 : v | 0;
    }
    return m;
  },
  applyMap: function (src, dst, map) {
    for (var i = 0, l = src.length; i < l; i += 4) {
      dst[i] = map[src[i]];
      dst[i + 1] = map[src[i + 1]];
      dst[i + 2] = map[src[i + 2]];
      dst[i + 3] = src[i + 3];
    }
  },
  mapRGB: function (src, dst, func) {
    this.applyMap(src, dst, this.buildMap(func));
  },
  getPixelIndex: function (x, y, width, height, edge) {
    if (x < 0 || x >= width || y < 0 || y >= height) {
      switch (edge) {
        case 1: // clamp
          x = x < 0 ? 0 : x >= width ? width - 1 : x;
          y = y < 0 ? 0 : y >= height ? height - 1 : y;
          break;
        case 2: // wrap
          x = (x %= width) < 0 ? x + width : x;
          y = (y %= height) < 0 ? y + height : y;
          break;
        default: // transparent
          return null;
      }
    }
    return (y * width + x) << 2;
  },
  getPixel: function (src, x, y, width, height, edge) {
    if (x < 0 || x >= width || y < 0 || y >= height) {
      switch (edge) {
        case 1: // clamp
          x = x < 0 ? 0 : x >= width ? width - 1 : x;
          y = y < 0 ? 0 : y >= height ? height - 1 : y;
          break;
        case 2: // wrap
          x = (x %= width) < 0 ? x + width : x;
          y = (y %= height) < 0 ? y + height : y;
          break;
        default: // transparent
          return 0;
      }
    }

    var i = (y * width + x) << 2;

    // ARGB
    return (src[i + 3] << 24) | (src[i] << 16) | (src[i + 1] << 8) | src[i + 2];
  },
  getPixelByIndex: function (src, i) {
    return (src[i + 3] << 24) | (src[i] << 16) | (src[i + 1] << 8) | src[i + 2];
  },
  /**
   * one of the most important functions in this library.
   * I want to make this as fast as possible.
   */
  copyBilinear: function (src, x, y, width, height, dst, dstIndex, edge) {
    var fx = x < 0 ? (x - 1) | 0 : x | 0, // Math.floor(x)
      fy = y < 0 ? (y - 1) | 0 : y | 0, // Math.floor(y)
      wx = x - fx,
      wy = y - fy,
      i,
      nw = 0,
      ne = 0,
      sw = 0,
      se = 0,
      cx,
      cy,
      r,
      g,
      b,
      a;

    if (fx >= 0 && fx < width - 1 && fy >= 0 && fy < height - 1) {
      // in bounds, no edge actions required
      i = (fy * width + fx) << 2;

      if (wx || wy) {
        nw =
          (src[i + 3] << 24) | (src[i] << 16) | (src[i + 1] << 8) | src[i + 2];

        i += 4;
        ne =
          (src[i + 3] << 24) | (src[i] << 16) | (src[i + 1] << 8) | src[i + 2];

        i = i - 8 + (width << 2);
        sw =
          (src[i + 3] << 24) | (src[i] << 16) | (src[i + 1] << 8) | src[i + 2];

        i += 4;
        se =
          (src[i + 3] << 24) | (src[i] << 16) | (src[i + 1] << 8) | src[i + 2];
      } else {
        // no interpolation required
        dst[dstIndex] = src[i];
        dst[dstIndex + 1] = src[i + 1];
        dst[dstIndex + 2] = src[i + 2];
        dst[dstIndex + 3] = src[i + 3];
        return;
      }
    } else {
      // edge actions required
      nw = this.getPixel(src, fx, fy, width, height, edge);

      if (wx || wy) {
        ne = this.getPixel(src, fx + 1, fy, width, height, edge);
        sw = this.getPixel(src, fx, fy + 1, width, height, edge);
        se = this.getPixel(src, fx + 1, fy + 1, width, height, edge);
      } else {
        // no interpolation required
        dst[dstIndex] = (nw >> 16) & 0xff;
        dst[dstIndex + 1] = (nw >> 8) & 0xff;
        dst[dstIndex + 2] = nw & 0xff;
        dst[dstIndex + 3] = (nw >> 24) & 0xff;
        return;
      }
    }

    cx = 1 - wx;
    cy = 1 - wy;
    r =
      (((nw >> 16) & 0xff) * cx + ((ne >> 16) & 0xff) * wx) * cy +
      (((sw >> 16) & 0xff) * cx + ((se >> 16) & 0xff) * wx) * wy;
    g =
      (((nw >> 8) & 0xff) * cx + ((ne >> 8) & 0xff) * wx) * cy +
      (((sw >> 8) & 0xff) * cx + ((se >> 8) & 0xff) * wx) * wy;
    b =
      ((nw & 0xff) * cx + (ne & 0xff) * wx) * cy +
      ((sw & 0xff) * cx + (se & 0xff) * wx) * wy;
    a =
      (((nw >> 24) & 0xff) * cx + ((ne >> 24) & 0xff) * wx) * cy +
      (((sw >> 24) & 0xff) * cx + ((se >> 24) & 0xff) * wx) * wy;

    dst[dstIndex] = r > 255 ? 255 : r < 0 ? 0 : r | 0;
    dst[dstIndex + 1] = g > 255 ? 255 : g < 0 ? 0 : g | 0;
    dst[dstIndex + 2] = b > 255 ? 255 : b < 0 ? 0 : b | 0;
    dst[dstIndex + 3] = a > 255 ? 255 : a < 0 ? 0 : a | 0;
  },
  /**
   * @param r 0 <= n <= 255
   * @param g 0 <= n <= 255
   * @param b 0 <= n <= 255
   * @return Array(h, s, l)
   */
  rgbToHsl: function (r, g, b) {
    r /= 255;
    g /= 255;
    b /= 255;

    //        var max = Math.max(r, g, b),
    //            min = Math.min(r, g, b),
    var max = r > g ? (r > b ? r : b) : g > b ? g : b,
      min = r < g ? (r < b ? r : b) : g < b ? g : b,
      chroma = max - min,
      h = 0,
      s = 0,
      // Lightness
      l = (min + max) / 2;

    if (chroma !== 0) {
      // Hue
      if (r === max) {
        h = (g - b) / chroma + (g < b ? 6 : 0);
      } else if (g === max) {
        h = (b - r) / chroma + 2;
      } else {
        h = (r - g) / chroma + 4;
      }
      h /= 6;

      // Saturation
      s = l > 0.5 ? chroma / (2 - max - min) : chroma / (max + min);
    }

    return [h, s, l];
  },
  /**
   * @param h 0.0 <= n <= 1.0
   * @param s 0.0 <= n <= 1.0
   * @param l 0.0 <= n <= 1.0
   * @return Array(r, g, b)
   */
  hslToRgb: function (h, s, l) {
    var m1,
      m2,
      hue,
      r,
      g,
      b,
      rgb = [];

    if (s === 0) {
      r = g = b = (l * 255 + 0.5) | 0;
      rgb = [r, g, b];
    } else {
      if (l <= 0.5) {
        m2 = l * (s + 1);
      } else {
        m2 = l + s - l * s;
      }

      m1 = l * 2 - m2;
      hue = h + 1 / 3;

      var tmp;
      for (var i = 0; i < 3; i += 1) {
        if (hue < 0) {
          hue += 1;
        } else if (hue > 1) {
          hue -= 1;
        }

        if (6 * hue < 1) {
          tmp = m1 + (m2 - m1) * hue * 6;
        } else if (2 * hue < 1) {
          tmp = m2;
        } else if (3 * hue < 2) {
          tmp = m1 + (m2 - m1) * (2 / 3 - hue) * 6;
        } else {
          tmp = m1;
        }

        rgb[i] = (tmp * 255 + 0.5) | 0;

        hue -= 1 / 3;
      }
    }

    return rgb;
  },
};

/**
 * TV based algorithm
 */
ImageFilters.Brightness = function (srcImageData, brightness) {
  var srcPixels = srcImageData.data,
    srcWidth = srcImageData.width,
    srcHeight = srcImageData.height,
    srcLength = srcPixels.length,
    dstImageData = this.utils.createImageData(srcWidth, srcHeight),
    dstPixels = dstImageData.data;

  this.utils.mapRGB(srcPixels, dstPixels, function (value) {
    value += brightness;
    return value > 255 ? 255 : value;
  });

  return dstImageData;
};

/**
 * Algorithm based on BoxBlurFilter.java by Huxtable.com
 * @see http://www.jhlabs.com/ip/blurring.html
 * Copyright 2005 Huxtable.com. All rights reserved.
 */
ImageFilters.BoxBlur = (function () {
  var blur = function (src, dst, width, height, radius) {
    console.log("radius", radius);
    var tableSize = radius * 2 + 1;
    var radiusPlus1 = radius + 1;
    var widthMinus1 = width - 1;

    var r, g, b, a;

    var srcIndex = 0;
    var dstIndex;
    var p, next, prev;
    var i, l, x, y, nextIndex, prevIndex;

    var sumTable = [];
    for (i = 0, l = 256 * tableSize; i < l; i += 1) {
      sumTable[i] = (i / tableSize) | 0;
    }

    for (y = 0; y < height; y += 1) {
      r = g = b = a = 0;
      dstIndex = y;

      p = srcIndex << 2;
      r += radiusPlus1 * src[p];
      g += radiusPlus1 * src[p + 1];
      b += radiusPlus1 * src[p + 2];
      a += radiusPlus1 * src[p + 3];

      for (i = 1; i <= radius; i += 1) {
        p = (srcIndex + (i < width ? i : widthMinus1)) << 2;
        r += src[p];
        g += src[p + 1];
        b += src[p + 2];
        a += src[p + 3];
      }

      for (x = 0; x < width; x += 1) {
        p = dstIndex << 2;
        dst[p] = sumTable[r];
        dst[p + 1] = sumTable[g];
        dst[p + 2] = sumTable[b];
        dst[p + 3] = sumTable[a];

        nextIndex = x + radiusPlus1;
        if (nextIndex > widthMinus1) {
          nextIndex = widthMinus1;
        }

        prevIndex = x - radius;
        if (prevIndex < 0) {
          prevIndex = 0;
        }

        next = (srcIndex + nextIndex) << 2;
        prev = (srcIndex + prevIndex) << 2;

        r += src[next] - src[prev];
        g += src[next + 1] - src[prev + 1];
        b += src[next + 2] - src[prev + 2];
        a += src[next + 3] - src[prev + 3];

        dstIndex += height;
      }
      srcIndex += width;
    }
  };

  return function (srcImageData, hRadius, vRadius, quality) {
    var srcPixels = srcImageData.data,
      srcWidth = srcImageData.width,
      srcHeight = srcImageData.height,
      srcLength = srcPixels.length,
      dstImageData = this.utils.createImageData(srcWidth, srcHeight),
      dstPixels = dstImageData.data,
      tmpImageData = this.utils.createImageData(srcWidth, srcHeight),
      tmpPixels = tmpImageData.data;

    for (var i = 0; i < quality; i += 1) {
      // only use the srcPixels on the first loop
      blur(i ? dstPixels : srcPixels, tmpPixels, srcWidth, srcHeight, hRadius);
      blur(tmpPixels, dstPixels, srcHeight, srcWidth, vRadius);
    }

    return dstImageData;
  };
})();

function loadThumbnailImage(url) {
  this.image = null;
  this.ImgWidth = null;
  this.ImgHeight = null;
  this.ImgSrc = url;

  this.loadImage = (ImgURL) => {
    return new Promise(
      function (resolve, reject) {
        this.image = new Image();
        this.image.addEventListener(
          "load",
          function () {
            resolve({
              msg: "Image is ready",
              width: this.width,
              height: this.height,
            });
          },
          false
        );
        this.image.addEventListener(
          "error",
          function (error) {
            console.log(error);
            reject("Image loading failed!");
          },
          false
        );
        this.image.crossOrigin = "anonymous";
        this.image.setAttribute("src", this.ImgSrc);
      }.bind(this)
    );
  };

  this.loadImage(this.ImgSrc)
    .then(
      function (data) {
        this.ready = true;
        this.ImgWidth = data.width;
        this.ImgHeight = data.height;
      }.bind(this)
    )
    .catch(
      function (error) {
        console.log(error);
      }.bind(this)
    );
}

function ImageEdit(container, ImgURL) {
  if (container.hasOwnProperty("current")) {
    this.container = container.current;
  } else {
    return;
  }

  this.saveImage = false;
  this.image = null;
  this.imagePromise = null;
  this.ImgWidth = null;
  this.ImgHeight = null;
  this.sourceImageData = null;
  this.imgCopy = null;
  this.ImgSrc = ImgURL;
  this.ready = false;
  this.canvas2D = null;
  this.isCanvasCreated = false;

  this.cropped = false;
  this.cropConfig = null;

  this.brightnessApplied = false;
  this.contrastApplied = false;
  this.saturationApplied = false;

  this.filterApplied = false;
  this.ImageUpdate = true;

  this.setcanvas = function (container) {
    if (container.hasOwnProperty("current")) {
      this.container = container.current;
    } else {
      return;
    }
  };
  this.saveAll = function (url) {
    this.canvas = "";
    this.ImgSrc = url;

    this.loadImage(this.ImgSrc)
      .then(
        function (data) {
          this.ready = true;
          this.ImgWidth = data.width;
          this.ImgHeight = data.height;

          console.log(data);

          let promises = [];

          promises.push(this.createCanvas());

          /*if (this.cropped == true) {
            promises.push(this.getCroppedImage(this.cropConfig));
          }*/

          /*if (this.filterApplied !== false) {
            promises.push(this.hookFilter(this.filterApplied));
          }*/

          /*if (this.brightnessApplied !== false) {
            promises.push(this.brightness(this.brightnessApplied));
          }*/

          /*if (this.contrastApplied !== false) {
            promises.push(this.contrast(this.contrastApplied));
          }*/

          /*if (this.saturationApplied !== false) {
            promises.push(this.saturation(this.saturationApplied));
          }*/

          Promise.all(promises)
            .then((total) => {
              this.getImageURL();
            })
            .catch((error) => {
              console.error(error);
            });
        }.bind(this)
      )
      .catch(
        function (error) {
          console.log(error);
        }.bind(this)
      );
  };

  this.hookFilter = function (filter) {
    this.filterApplied = filter;
    this.setFilter({
      brightness: { value: 50, v: "" },
      contrast: { value: 50, v: "" },
      saturation: { value: 50, v: "" },
    });
    switch (filter) {
      case "none":
        return this.reset();
        break;

      case "sepia":
        return this.sepia(0.3);
        break;

      case "grayscale":
        return this.grayscale();
        break;

      case "invert":
        return this.invert();
        break;

      case "clarendon":
        return this.clarendon();
        break;

      case "moon":
        return this.moon();
        break;

      case "lark":
        return this.lark();
        break;

      case "reyes":
        return this.reyes();
        break;

      case "juno":
        return this.juno();
        break;

      case "crema":
        return this.crema();
        break;

      case "ludwig":
        return this.ludwig();
        break;

      case "aden":
        return this.aden();
        break;

      case "perpetua":
        return this.perpetua();
        break;

      case "amaro":
        return this.amaro();
        break;

      case "mayfair":
        return this.mayfair();
        break;

      case "rise":
        return this.rise();
        break;

      case "hudson":
        return this.hudson();
        break;

      case "valencia":
        return this.valencia();
        break;

      case "xpro2":
        return this.xpro2();
        break;

      case "sierra":
        return this.sierra();
        break;

      case "willow":
        return this.willow();
        break;

      case "lofi":
        return this.lofi();
        break;

      case "inkwell":
        return this.inkwell();
        break;

      case "hefe":
        return this.hefe();
        break;

      case "nashville":
        return this.nashville();
        break;

      case "stinson":
        return this.stinson();
        break;

      case "vesper":
        return this.vesper();
        break;

      case "earlybird":
        return this.earlybird();
        break;

      case "brannan":
        return this.brannan();
        break;

      case "sutro":
        return this.sutro();
        break;

      case "toaster":
        return this.toaster();
        break;

      case "walden":
        return this.walden();
        break;

      case "one_thousand_nine_hundred_seventy_seven":
        return this.one_thousand_nine_hundred_seventy_seven();
        break;

      case "kelvin":
        return this.kelvin();
        break;

      case "maven":
        return this.maven();
        break;

      case "ginza":
        return this.ginza();
        break;

      case "skyline":
        return this.skyline();
        break;

      case "dogpatch":
        return this.dogpatch();
        break;

      case "brooklyn":
        return this.brooklyn();
        break;

      case "helena":
        return this.helena();
        break;

      case "ashby":
        return this.ashby();
        break;

      case "charmes":
        return this.charmes();
        break;

      default:
        return this.reset();
    }
  };

  this.getImageURL = function () {
    const link = this.canvas.toDataURL("image/jpeg", 1);
    //return link;
    var newWindow = window.open();
    newWindow.document.write('<img style="height: 100%" src="' + link + '" />');
  };

  this.getImageDataURL = function () {
    const link = this.canvas.toDataURL("image/jpeg", 1);
    return link;
  };

  this.getFilterThumbnail = function (filter) {
    this.hookFilter(filter);
    return { data_url: this.canvas.toDataURL("image/jpeg", 0.5), name: filter };
  };

  this.cancelCrop = function () {
    this.cropped = false;
    this.loadCanvas();
  };

  this.applyCrop = function () {
    const promise = [];

    if (this.cropConfig !== null) {
      promise.push(this.getCroppedImage(this.cropConfig));
    }

    if (this.brightnessApplied !== false && !this.filterApplied) {
      promise.push(this.brightness(this.brightnessApplied));
    }

    if (this.contrastApplied !== false && !this.filterApplied) {
      promise.push(this.contrast(this.contrastApplied));
    }

    if (this.saturationApplied !== false && !this.filterApplied) {
      promise.push(this.saturation(this.saturationApplied));
    }

    if (this.filterApplied !== false) {
      promise.push(this.hookFilter(this.filterApplied));
    }
  };

  this.SetCropConfig = function (cropConfig) {
    this.cropConfig = cropConfig;
  };

  this.getCroppedImage = function (cropConfig) {
    return new Promise((resolve, reject) => {
      // creating the cropped image from the source image

      try {
        const scaleX = this.image.width / this.thubnailObj.current.width; // 749
        const scaleY = this.image.height / this.thubnailObj.current.height; // 750

        this.canvas.width = cropConfig.width * scaleX;
        this.canvas.height = cropConfig.height * scaleX;

        this.canvas2D.drawImage(
          this.image,
          cropConfig.x * scaleX,
          cropConfig.y * scaleY,
          cropConfig.width * scaleX,
          cropConfig.height * scaleY,
          0,
          0,
          cropConfig.width * scaleX,
          cropConfig.height * scaleY
        );

        this.sourceImageData = this.canvas2D.getImageData(
          0,
          0,
          this.canvas.width * scaleX,
          this.canvas.height * scaleX
        );
        this.cropped = true;
        this.cropConfig = cropConfig;

        this.appendImg();

        resolve(true);
      } catch (error) {
        console.log("error", error);
      }
    });
  };
  this.appendImg = function () {
    if (this.ImageUpdate) {
      this.container.innerHTML = "";
      this.container.appendChild(this.canvas);
    }
  };

  this.loadCanvas = function () {
    this.createCanvas();
    this.appendImg();
  };

  this.reset = function () {
    if (this.cropped == true) {
      this.getCroppedImage(this.cropConfig);
    } else {
      this.createCanvas();
      this.appendImg();
    }
  };

  this.recallImage = function (cropConfig) {
    if (this.cropped) {
      const scaleX = this.image.width / this.thubnailObj.current.width; // 749
      const scaleY = this.image.height / this.thubnailObj.current.height; // 750

      this.canvas.width = cropConfig.width * scaleX;
      this.canvas.height = cropConfig.height * scaleX;

      this.canvas2D.drawImage(
        this.image,
        cropConfig.x * scaleX,
        cropConfig.y * scaleY,
        cropConfig.width * scaleX,
        cropConfig.height * scaleY,
        0,
        0,
        cropConfig.width * scaleX,
        cropConfig.height * scaleY
      );

      this.sourceImageData = this.canvas2D.getImageData(
        0,
        0,
        this.canvas.width * scaleX,
        this.canvas.height * scaleX
      );
      this.cropped = true;
      this.cropConfig = cropConfig;
    } else {
      this.canvas2D.drawImage(this.image, 0, 0);
      this.sourceImageData = this.canvas2D.getImageData(
        0,
        0,
        this.canvas.width,
        this.canvas.height
      );
    }

    if (this.filterApplied !== false) {
      this.hookFilter(this.filterApplied);
    }

    this.appendImg();
  };

  this.createCanvas = function () {
    //var dpr = window.devicePixelRatio || 1;
    return new Promise((resolve, reject) => {
      this.canvas = document.createElement("canvas");

      this.canvas.width = this.image.width;
      this.canvas.height = this.image.height;
      console.log("width", this.canvas.width);
      console.log("height", this.canvas.height);
      this.canvas.setAttribute(
        "style",
        "object-fit: contain;position:absolute;top:50%;left:50%;transform:translate(-50%, -50%); width: 100% !important"
      );

      this.canvas2D = this.canvas.getContext("2d");
      this.canvas2D.drawImage(this.image, 0, 0);

      if (this.image === null || this.image === undefined) {
        console.log("image null in createCanvas!");
        reject(false);
      }
      this.sourceImageData = this.canvas2D.getImageData(
        0,
        0,
        this.ImgWidth,
        this.ImgHeight
      );

      this.outputImageData = this.canvas2D.createImageData(
        this.ImgWidth,
        this.ImgHeight
      );
      this.isCanvasCreated = true;

      resolve(true);
    });
  };

  this.loadImage = (ImgURL) => {
    this.imagePromise = new Promise(
      function (resolve, reject) {
        this.image = new Image();

        this.image.addEventListener(
          "load",
          function () {
            resolve({
              msg: "Image is ready",
              width: this.width,
              height: this.height,
            });
          },
          false
        );
        this.image.addEventListener(
          "error",
          function (error) {
            console.log(error);
            reject("Image loading failed!");
          },
          false
        );
        this.image.crossOrigin = "anonymous";
        if (ImgURL === undefined) {
          this.image.setAttribute("src", this.ImgSrc);
        } else {
          this.image.setAttribute("src", ImgURL);
        }

        console.log("src", this.ImgSrc);
        console.log("passed src", ImgURL);
      }.bind(this)
    );

    console.log("image", this.image);

    return this.imagePromise;
  };

  this.loadImage(this.src)
    .then(
      function (data) {
        this.ready = true;
        this.ImgWidth = data.width;
        this.ImgHeight = data.height;
        this.loadCanvas();
      }.bind(this)
    )
    .catch(
      function (error) {
        console.log(error);
      }.bind(this)
    );

  this.applyThreshold = function (threshold = 150) {
    if (!this.ready) {
      console.log("Image is not ready");
      return "";
    }

    if (!this.isCanvasCreated) {
      this.createCanvas();
    }

    const imgData = this.sourceImageData;

    for (let i = 0; i < imgData.data.length; i += 4) {
      const r = imgData.data[i];
      const g = imgData.data[i + 1];
      const b = imgData.data[i + 2];
      // thresholding the current value
      const v = 0.2126 * r + 0.7152 * g + 0.0722 * b >= threshold ? 255 : 0;
      imgData.data[i] = imgData.data[i + 1] = imgData.data[i + 2] = v;
    }

    this.canvas2D.putImageData(imgData, 0, 0);
    this.appendImg();
  };

  this.applyConvolution = function (kernel) {
    if (!this.isCanvasCreated) {
      this.createCanvas();
    }

    const src = this.sourceImageData.data;
    const dst = this.outputImageData.data;

    const srcWidth = this.sourceImageData.width;
    const srcHeight = this.sourceImageData.height;

    const side = Math.round(Math.sqrt(kernel.length));
    const halfSide = Math.floor(side / 2);

    // padding the output by the convolution kernel
    const w = srcWidth;
    const h = srcHeight;
    // console.log(w, h);

    // iterating through the output image
    for (let y = 0; y < h; y++) {
      for (let x = 0; x < w; x++) {
        let r = 0,
          g = 0,
          b = 0,
          a = 0;

        // calculating the weighed sum of the source image  that
        // fall under the convolution kernel
        for (let cy = 0; cy < side; cy++) {
          for (let cx = 0; cx < side; cx++) {
            const scy = y + cy - halfSide;
            const scx = x + cx - halfSide;

            if (scy >= 0 && scy < srcHeight && scx >= 0 && scx < srcWidth) {
              let srcOffset = (scy * srcWidth + scx) * 4;
              let wt = kernel[cy * side + cx];
              r += src[srcOffset] * wt;
              g += src[srcOffset + 1] * wt;
              b += src[srcOffset + 2] * wt;
              a += src[srcOffset + 3] * wt;
            }
          }
        }

        const dstOffset = (y * w + x) * 4;
        dst[dstOffset] = r;
        dst[dstOffset + 1] = g;
        dst[dstOffset + 2] = b;
        dst[dstOffset + 3] = a;
      }
    }
    this.canvas2D.putImageData(this.outputImageData, 0, 0);
    this.appendImg();
    // return outputImageData;
  };

  this.BrightnessFilter = function (brightness) {
    this.reset();

    const src = this.sourceImageData.data;
    const dst = this.outputImageData.data;

    var res = ImageFilters.Brightness(this.sourceImageData, brightness);

    if (!this.ready) {
      console.log("Image is not ready");
      return "";
    }

    this.sourceImageData = res;
    this.outputImageData = res;
    this.canvas2D.putImageData(res, 0, 0);
    this.appendImg();
  };

  this.actionBoxBlur = function (height, width, quality) {
    this.reset();

    const src = this.sourceImageData;
    const dst = this.outputImageData;

    if (!this.ready) {
      console.log("Image is not ready");
      return "";
    }

    var res = ImageFilters.BoxBlur(src, height, width, quality);

    this.sourceImageData = res;
    this.outputImageData = res;
    this.canvas2D.putImageData(res, 0, 0);
    this.appendImg();
  };

  this.grayscale = function () {
    return new Promise((resolve, reject) => {
      if (this.cropped == true) {
        this.getCroppedImage(this.cropConfig);
      }

      this.reset();

      const src = this.sourceImageData;
      const dst = this.outputImageData;

      var d = src.data;
      for (var i = 0; i < d.length; i += 4) {
        var r = d[i],
          g = d[i + 1],
          b = d[i + 2];
        var avg = 0.2126 * r + 0.7152 * g + 0.0722 * b;
        d[i] = d[i + 1] = d[i + 2] = avg;
      }

      this.sourceImageData = src;
      this.outputImageData = src;
      this.canvas2D.putImageData(src, 0, 0);
      this.appendImg();
      resolve(true);
    });
  };

  this.sepia = function (adj) {
    return new Promise((resolve, reject) => {
      if (this.cropped == true) {
        this.getCroppedImage(this.cropConfig);
      }

      this.reset();

      const src = this.sourceImageData;
      const dst = this.outputImageData;

      var d = src.data;
      for (var i = 0; i < d.length; i += 4) {
        var r = d[i],
          g = d[i + 1],
          b = d[i + 2];
        d[i] = r * (1 - 0.607 * adj) + g * 0.769 * adj + b * 0.189 * adj;
        d[i + 1] = r * 0.349 * adj + g * (1 - 0.314 * adj) + b * 0.168 * adj;
        d[i + 2] = r * 0.272 * adj + g * 0.534 * adj + b * (1 - 0.869 * adj);
      }

      this.sourceImageData = src;
      this.outputImageData = src;
      this.canvas2D.putImageData(src, 0, 0);
      this.appendImg();
      resolve(true);
    });
  };

  this.invert = function () {
    return new Promise((resolve, reject) => {
      if (!this.isCanvasCreated) {
        this.createCanvas();
      }

      if (this.cropped == true) {
        this.getCroppedImage(this.cropConfig);
      }

      this.reset();

      const src = this.sourceImageData;
      const dst = this.outputImageData;

      var d = src.data;
      for (var i = 0; i < d.length; i += 4) {
        d[i] = 255 - d[i];
        d[i + 1] = 255 - d[i + 1];
        d[i + 2] = 255 - d[i + 2];
      }

      this.sourceImageData = src;
      this.outputImageData = src;
      this.canvas2D.putImageData(src, 0, 0);
      this.appendImg();

      resolve(true);
    });
  };

  this.brightness = function (adj) {
    return new Promise((resolve, reject) => {
      this.brightnessApplied = adj;

      if (!this.isCanvasCreated) {
        this.createCanvas();
      }

      const src = this.sourceImageData;
      const dst = this.outputImageData;

      var d = src.data;
      adj = adj > 1 ? 1 : adj;
      adj = adj < -1 ? -1 : adj;
      adj = ~~(255 * adj);
      for (var i = 0; i < d.length; i += 4) {
        d[i] += adj;
        d[i + 1] += adj;
        d[i + 2] += adj;
      }

      this.sourceImageData = src;
      this.outputImageData = src;

      this.canvas2D.putImageData(src, 0, 0);
      this.appendImg();
      this.filterApplied = false;
      resolve(true);
    });
  };

  // Contrast - the adj value should be -1 to 1

  this.contrast = function (adj) {
    return new Promise((resolve, reject) => {
      this.contrastApplied = adj;

      if (!this.isCanvasCreated) {
        this.createCanvas();
      }

      const src = this.sourceImageData;
      const dst = this.outputImageData;

      adj *= 255;
      var d = src.data;
      var factor = (259 * (adj + 255)) / (255 * (259 - adj));
      for (var i = 0; i < d.length; i += 4) {
        d[i] = factor * (d[i] - 128) + 128;
        d[i + 1] = factor * (d[i + 1] - 128) + 128;
        d[i + 2] = factor * (d[i + 2] - 128) + 128;
      }

      this.sourceImageData = src;
      this.outputImageData = src;
      this.canvas2D.putImageData(src, 0, 0);
      this.appendImg();
      this.filterApplied = false;
      resolve(true);
    });
  };

  this.saturation = function (adj) {
    return new Promise((resolve, reject) => {
      this.saturationApplied = adj;

      if (!this.isCanvasCreated) {
        this.createCanvas();
      }

      const src = this.sourceImageData;
      const dst = this.outputImageData;

      var d = src.data;

      adj = adj < -1 ? -1 : adj;
      for (var i = 0; i < d.length; i += 4) {
        var r = d[i],
          g = d[i + 1],
          b = d[i + 2];
        var gray = 0.2989 * r + 0.587 * g + 0.114 * b; //weights from CCIR 601 spec
        d[i] = -gray * adj + d[i] * (1 + adj);
        d[i + 1] = -gray * adj + d[i + 1] * (1 + adj);
        d[i + 2] = -gray * adj + d[i + 2] * (1 + adj);
      }

      this.sourceImageData = src;
      this.outputImageData = src;
      this.canvas2D.putImageData(src, 0, 0);
      this.appendImg();
      this.filterApplied = false;
      resolve(true);
    });
  };

  this.colorFilter = function (rgbColor) {
    if (!this.isCanvasCreated) {
      this.createCanvas();
    }

    if (this.cropped == true) {
      this.getCroppedImage(this.cropConfig);
    }

    const src = this.sourceImageData;
    const dst = this.outputImageData;

    var d = src.data;

    var adj = rgbColor[3];
    for (var i = 0; i < d.length; i += 4) {
      d[i] -= (d[i] - rgbColor[0]) * adj;
      d[i + 1] -= (d[i + 1] - rgbColor[1]) * adj;
      d[i + 2] -= (d[i + 2] - rgbColor[2]) * adj;
    }

    this.sourceImageData = src;
    this.outputImageData = src;
    this.canvas2D.putImageData(src, 0, 0);
    this.appendImg();
  };

  this.rgbAdjust = function (rgbAdj) {
    if (!this.isCanvasCreated) {
      this.createCanvas();
    }

    const src = this.sourceImageData;
    const dst = this.outputImageData;

    var d = src.data;

    for (var i = 0; i < d.length; i += 4) {
      d[i] *= rgbAdj[0]; //R
      d[i + 1] *= rgbAdj[1]; //G
      d[i + 2] *= rgbAdj[2]; //B
    }

    this.sourceImageData = src;
    this.outputImageData = src;
    this.canvas2D.putImageData(src, 0, 0);
    this.appendImg();
  };

  // Effects Listed Below

  this.clarendon = function () {
    return new Promise((resolve, reject) => {
      this.reset();
      this.brightness(0.08);
      this.rgbAdjust([1, 1.03, 1.05]);
      this.saturation(0.12);

      this.canvas2D.putImageData(this.sourceImageData, 0, 0);
      this.appendImg();
      resolve(true);
    });
  };

  this.moon = function () {
    return new Promise((resolve, reject) => {
      this.reset();

      this.grayscale(1);
      this.contrast(-0.04);
      this.brightness(0.1);

      this.canvas2D.putImageData(this.sourceImageData, 0, 0);
      this.appendImg();

      resolve(true);
    });
  };

  // Lark: Brightens and intensifies colours but not red hues
  this.lark = function () {
    return new Promise((resolve, reject) => {
      this.reset();
      this.brightness(0.08);
      this.rgbAdjust([1, 1.03, 1.05]);
      this.saturation(0.12);

      this.canvas2D.putImageData(this.sourceImageData, 0, 0);
      this.appendImg();
      resolve(true);
    });
  };

  // Reyes: a new vintage filter, gives your photos a “dusty” look
  this.reyes = function () {
    return new Promise((resolve, reject) => {
      this.reset();
      this.sepia(0.4);
      this.brightness(0.13);
      this.contrast(-0.05);

      this.canvas2D.putImageData(this.sourceImageData, 0, 0);
      this.appendImg();
      resolve(true);
    });
  };

  // Juno: Brightens colors, and intensifies red and yellow hues
  this.juno = function () {
    return new Promise((resolve, reject) => {
      this.reset();
      this.rgbAdjust([1.01, 1.04, 1]);
      this.saturation(0.3);

      this.canvas2D.putImageData(this.sourceImageData, 0, 0);
      this.appendImg();
      resolve(true);
    });
  };

  // Slumber: Desaturates the image as well as adds haze for a retro, dreamy look – with an emphasis on blacks and blues
  this.slumber = function () {
    return new Promise((resolve, reject) => {
      this.reset();
      this.brightness(0.1);
      this.saturation(-0.5);

      this.canvas2D.putImageData(this.sourceImageData, 0, 0);
      this.appendImg();
      resolve(true);
    });
  };

  // Crema: Adds a creamy look that both warms and cools the image
  this.crema = function () {
    return new Promise((resolve, reject) => {
      this.reset();
      this.rgbAdjust([1.04, 1, 1.02]);
      this.saturation(-0.05);

      this.canvas2D.putImageData(this.sourceImageData, 0, 0);
      this.appendImg();
      resolve(true);
    });
  };

  // Ludwig: A slight hint of desaturation that also enhances light
  this.ludwig = function () {
    return new Promise((resolve, reject) => {
      this.reset();
      this.brightness(0.05);
      this.saturation(-0.03);

      this.canvas2D.putImageData(this.sourceImageData, 0, 0);
      this.appendImg();
      resolve(true);
    });
  };

  // Aden: This filter gives a blue/pink natural look
  this.aden = function () {
    return new Promise((resolve, reject) => {
      this.reset();
      this.colorFilter([228, 130, 225, 0.13]);
      this.saturation(-0.2);

      this.canvas2D.putImageData(this.sourceImageData, 0, 0);
      this.appendImg();
      resolve(true);
    });
  };

  // Perpetua: Adding a pastel look, this filter is ideal for portraits
  this.perpetua = function () {
    return new Promise((resolve, reject) => {
      this.reset();
      this.rgbAdjust([1.05, 1.1, 1]);

      this.canvas2D.putImageData(this.sourceImageData, 0, 0);
      this.appendImg();
      resolve(true);
    });
  };

  // Amaro: Adds light to an image, with the focus on the centre
  this.amaro = function () {
    return new Promise((resolve, reject) => {
      this.reset();
      this.saturation(0.3);
      this.brightness(0.15);

      this.canvas2D.putImageData(this.sourceImageData, 0, 0);
      this.appendImg();
      resolve(true);
    });
  };

  // Mayfair: Applies a warm pink tone, subtle vignetting to brighten the photograph center and a thin black border
  this.mayfair = function () {
    return new Promise((resolve, reject) => {
      this.reset();
      this.colorFilter([230, 115, 108, 0.05]);
      this.saturation(0.15);

      this.canvas2D.putImageData(this.sourceImageData, 0, 0);
      this.appendImg();
      resolve(true);
    });
  };

  // Rise: Adds a "glow" to the image, with softer lighting of the subject
  this.rise = function () {
    return new Promise((resolve, reject) => {
      this.reset();
      this.colorFilter([255, 170, 0, 0.1]);
      this.brightness(0.09);
      this.saturation(0.1);

      this.canvas2D.putImageData(this.sourceImageData, 0, 0);
      this.appendImg();
      resolve(true);
    });
  };

  // Hudson: Creates an "icy" illusion with heightened shadows, cool tint and dodged center
  this.hudson = function () {
    return new Promise((resolve, reject) => {
      this.reset();
      this.rgbAdjust([1, 1, 1.25]);
      this.contrast(0.1);
      this.brightness(0.15);

      this.canvas2D.putImageData(this.sourceImageData, 0, 0);
      this.appendImg();
      resolve(true);
    });
  };

  // Valencia: Fades the image by increasing exposure and warming the colors, to give it an antique feel
  this.valencia = function () {
    return new Promise((resolve, reject) => {
      this.reset();
      this.colorFilter([255, 225, 80, 0.08]);
      this.saturation(0.1);
      this.contrast(0.05);

      this.canvas2D.putImageData(this.sourceImageData, 0, 0);
      this.appendImg();
      resolve(true);
    });
  };

  // X-Pro II: Increases color vibrance with a golden tint, high contrast and slight vignette added to the edges
  this.xpro2 = function () {
    return new Promise((resolve, reject) => {
      this.reset();
      this.colorFilter([255, 255, 0, 0.07]);
      this.saturation(0.2);
      this.contrast(0.15);

      this.canvas2D.putImageData(this.sourceImageData, 0, 0);
      this.appendImg();
      this.appendImg();
      resolve(true);
    });
  };

  // Sierra: Gives a faded, softer look
  this.sierra = function () {
    return new Promise((resolve, reject) => {
      this.reset();
      this.contrast(-0.15);
      this.saturation(0.1);

      this.canvas2D.putImageData(this.sourceImageData, 0, 0);
      this.appendImg();
      resolve(true);
    });
  };

  // Willow: A monochromatic filter with subtle purple tones and a translucent white border
  this.willow = function () {
    return new Promise((resolve, reject) => {
      this.reset();
      this.grayscale(1);
      this.colorFilter([100, 28, 210, 0.03]);
      this.brightness(0.1);

      this.canvas2D.putImageData(this.sourceImageData, 0, 0);
      this.appendImg();
      resolve(true);
    });
  };

  // Lo-Fi: Enriches color and adds strong shadows through the use of saturation and "warming" the temperature
  this.lofi = function () {
    return new Promise((resolve, reject) => {
      this.reset();
      this.contrast(0.15);
      this.saturation(0.2);

      this.canvas2D.putImageData(this.sourceImageData, 0, 0);
      this.appendImg();
      resolve(true);
    });
  };

  // Inkwell: Direct shift to black and white
  this.inkwell = function () {
    return new Promise((resolve, reject) => {
      this.reset();
      this.grayscale(1);

      this.canvas2D.putImageData(this.sourceImageData, 0, 0);
      this.appendImg();
      resolve(true);
    });
  };

  // Hefe: Hight contrast and saturation, with a similar effect to Lo-Fi but not quite as dramatic
  this.hefe = function () {
    return new Promise((resolve, reject) => {
      this.reset();
      this.contrast(0.1);
      this.saturation(0.15);

      this.canvas2D.putImageData(this.sourceImageData, 0, 0);
      this.appendImg();
      resolve(true);
    });
  };

  // Nashville: Warms the temperature, lowers contrast and increases exposure to give a light "pink" tint – making it feel "nostalgic"
  this.nashville = function () {
    return new Promise((resolve, reject) => {
      this.reset();
      this.colorFilter([220, 115, 188, 0.12]);
      this.contrast(-0.05);

      this.canvas2D.putImageData(this.sourceImageData, 0, 0);
      this.appendImg();
      resolve(true);
    });
  };

  // Stinson: washing out the colors ever so slightly
  this.stinson = function () {
    return new Promise((resolve, reject) => {
      this.reset();
      this.brightness(0.1);
      this.sepia(0.3);

      this.canvas2D.putImageData(this.sourceImageData, 0, 0);
      this.appendImg();
      resolve(true);
    });
  };

  // Vesper: adds a yellow tint that
  this.vesper = function () {
    return new Promise((resolve, reject) => {
      this.reset();
      this.colorFilter([255, 225, 0, 0.05]);
      this.brightness(0.06);
      this.contrast(0.06);

      this.canvas2D.putImageData(this.sourceImageData, 0, 0);
      this.appendImg();
      resolve(true);
    });
  };

  // Earlybird: Gives an older look with a sepia tint and warm temperature
  this.earlybird = function () {
    return new Promise((resolve, reject) => {
      this.reset();
      this.colorFilter([255, 165, 40, 0.2]);

      this.canvas2D.putImageData(this.sourceImageData, 0, 0);
      this.appendImg();
      resolve(true);
    });
  };

  // Brannan: Increases contrast and exposure and adds a metallic tint
  this.brannan = function () {
    return new Promise((resolve, reject) => {
      this.reset();
      this.contrast(0.2);
      this.colorFilter([140, 10, 185, 0.1]);

      this.canvas2D.putImageData(this.sourceImageData, 0, 0);
      this.appendImg();
      resolve(true);
    });
  };

  // Sutro: Burns photo edges, increases highlights and shadows dramatically with a focus on purple and brown colors
  this.sutro = function () {
    return new Promise((resolve, reject) => {
      this.reset();
      this.brightness(-0.1);
      this.saturation(-0.1);

      this.canvas2D.putImageData(this.sourceImageData, 0, 0);
      this.appendImg();
      resolve(true);
    });
  };

  // Toaster: Ages the image by "burning" the centre and adds a dramatic vignette
  this.toaster = function () {
    return new Promise((resolve, reject) => {
      this.reset();
      this.sepia(0.1);
      this.colorFilter([255, 145, 0, 0.2]);

      this.canvas2D.putImageData(this.sourceImageData, 0, 0);
      this.appendImg();
      resolve(true);
    });
  };

  // Walden: Increases exposure and adds a yellow tint
  this.walden = function () {
    return new Promise((resolve, reject) => {
      this.reset();
      this.brightness(0.1);
      this.colorFilter([255, 255, 0, 0.2]);

      this.canvas2D.putImageData(this.sourceImageData, 0, 0);
      this.appendImg();
      resolve(true);
    });
  };

  // 1977: The increased exposure with a red tint gives the photograph a rosy, brighter, faded look.
  this.one_thousand_nine_hundred_seventy_seven = function () {
    return new Promise((resolve, reject) => {
      this.reset();
      this.colorFilter([255, 25, 0, 0.15]);
      this.brightness(0.1);

      this.canvas2D.putImageData(this.sourceImageData, 0, 0);
      this.appendImg();
      resolve(true);
    });
  };

  // Kelvin: Increases saturation and temperature to give it a radiant "glow"
  this.kelvin = function () {
    return new Promise((resolve, reject) => {
      this.reset();
      this.colorFilter([255, 140, 0, 0.1]);
      this.rgbAdjust([1.15, 1.05, 1]);
      this.saturation(0.35);

      this.canvas2D.putImageData(this.sourceImageData, 0, 0);
      this.appendImg();
      resolve(true);
    });
  };

  // Maven: darkens images, increases shadows, and adds a slightly yellow tint overal
  this.maven = function () {
    return new Promise((resolve, reject) => {
      this.reset();
      this.colorFilter([225, 240, 0, 0.1]);
      this.saturation(0.25);
      this.contrast(0.05);
      this.canvas2D.putImageData(this.sourceImageData, 0, 0);
      this.appendImg();
      resolve(true);
    });
  };

  // Ginza: brightens and adds a warm glow
  this.ginza = function () {
    return new Promise((resolve, reject) => {
      this.reset();
      this.sepia(0.06);
      this.brightness(0.1);
      this.canvas2D.putImageData(this.sourceImageData, 0, 0);
      this.appendImg();
      resolve(true);
    });
  };

  // Skyline: brightens to the image pop
  this.skyline = function () {
    return new Promise((resolve, reject) => {
      this.reset();
      this.saturation(0.35);
      this.brightness(0.1);
      this.canvas2D.putImageData(this.sourceImageData, 0, 0);
      this.appendImg();
      resolve(true);
    });
  };

  // Dogpatch: increases the contrast, while washing out the lighter colors
  this.dogpatch = function () {
    return new Promise((resolve, reject) => {
      this.reset();
      this.contrast(0.15);
      this.brightness(0.1);
      this.canvas2D.putImageData(this.sourceImageData, 0, 0);
      this.appendImg();
      resolve(true);
    });
  };

  // Brooklyn
  this.brooklyn = function () {
    return new Promise((resolve, reject) => {
      this.reset();
      this.colorFilter([25, 240, 252, 0.05]);
      this.sepia(0.3);
      this.canvas2D.putImageData(this.sourceImageData, 0, 0);
      this.appendImg();
      resolve(true);
    });
  };

  // Helena: adds an orange and teal vibe
  this.helena = function () {
    return new Promise((resolve, reject) => {
      this.reset();
      this.colorFilter([208, 208, 86, 0.2]);
      this.contrast(0.15);
      this.canvas2D.putImageData(this.sourceImageData, 0, 0);
      this.appendImg();
      resolve(true);
    });
  };

  // Ashby: gives images a great golden glow and a subtle vintage feel
  this.ashby = function () {
    return new Promise((resolve, reject) => {
      this.reset();
      this.colorFilter([255, 160, 25, 0.1]);
      this.brightness(0.1);
      this.canvas2D.putImageData(this.sourceImageData, 0, 0);
      this.appendImg();
      resolve(true);
    });
  };

  // Charmes: a high contrast filter, warming up colors in your image with a red tint
  this.charmes = function () {
    return new Promise((resolve, reject) => {
      this.reset();
      this.colorFilter([255, 50, 80, 0.12]);
      this.contrast(0.05);
      this.canvas2D.putImageData(this.sourceImageData, 0, 0);
      this.appendImg();
      resolve(true);
    });
  };

  this.applyFilter = function (filter) {
    if (filter === "noFilter") {
      return this.sourceImageData;
    } else if (filter === "threshold") {
      return this.applyThreshold(70);
    } else if (filter === "sharpen") {
      return this.applyConvolution([0, -1, 0, -1, 5, -1, 0, -1, 0]);
    } else if (filter === "blur") {
      return this.applyConvolution([
        1 / 16,
        2 / 16,
        1 / 16,
        2 / 16,
        4 / 16,
        2 / 16,
        1 / 16,
        2 / 16,
        1 / 16,
      ]);
    } else if (filter === "emboss") {
      return this.applyConvolution([-2, 1, 0, -1, 1, 1, 0, 1, 2]);
    } else if (filter === "sharpenless") {
      return this.applyConvolution([0, -1, 0, -1, 10, -1, 0, -1, 0]);
    } else if (filter === "shadow") {
      return this.applyConvolution([1, 2, 1, 0, 1, 0, -1, -2, -1]);
    } else if (filter === "edge") {
      return this.applyConvolution([0, 1, 0, 1, -4, 1, 0, 1, 0]);
    } else {
      // fallback option
      return this.sourceImageData;
    }
  };
}

export { ImageEdit, loadThumbnailImage };
