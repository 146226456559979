import Style from "./SignInModal.module.scss";
import { useEffect, useState } from "react";
import { Field, Form, Formik, ErrorMessage } from "formik";
import { useLogin } from "../../../Logic/useLogin";
import { Dropdown } from "react-bootstrap";
import * as Yup from "yup";
import CountryCodeInput from "../../../utils/CountryCodeInput";
import { useSelector } from "react-redux";
import Assets from "../../Layouts/Assets";
import { useTranslation } from "react-i18next";
import { changeScreen } from "../../../store/slices/authSlice";

let hasErrors = false;
let hasErrorsPhone = false;

const SignInModal = ({
  handleClickRegister,
  handleClickFP,
  status,
}) => {
  const { currentAuthData } = useSelector((state) => state.auth);
  const { i18n, t } = useTranslation(["authentication"]);

  const [tab, setTab] = useState("login_email");
  const [isVisible, setIsVisible] = useState(false);
  const [code, setCode] = useState(+91);
  const {
    loginUserFunction,
    loginCreateOtpFunction,
    showOtpVerificationFunction,
    loginErrors,
  } = useLogin();

  const [loginInputs, setLoginInputs] = useState({
    phone_number: "",
    country_dial_code: "",
  });

  const handleVisibility = (e) => {
    e.preventDefault();
    isVisible ? setIsVisible(false) : setIsVisible(true);
  };

  useEffect(() => {
    if (currentAuthData?.pageFrom) {
      setTab(currentAuthData?.pageFrom);
    }

    setLoginInputs({
      ...loginInputs,
      phone_number: currentAuthData?.phone_number,
    });
  }, [currentAuthData]);

  const changeNumCode = (lang) => {
    setCode(lang);
  };

  const nameRegExp = /^[a-zA-Z ]+$/;
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const loginEmailSchema = Yup.object().shape({
    mail: Yup.string()
      .email("Invalid Email Format")
      .required("Email Is Required"),
    password: Yup.string()
      .required("Please Provide A Valid Password")
      .min(8, "*Password Should Be Minimum 8"),
  });

  const loginPhoneSchema = Yup.object().shape({
    phone_number: Yup.string()
      .required("required")
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10, "to short")
      .max(10, "to long"),
  });

  return (
    <div className={Style.signin_modal} >
      <div
        className={Style.signin_modal_content}
        onClick={(e) => e.stopPropagation()}
      >
        <>
          <div className={Style.signin_modal_header}>
            <h4>{t("sign_in_title")}</h4>
          </div>
          <div className={Style.signin_modal_tabs}>
            <div
              className={Style.tab_item}
              onClick={() => setTab("login_email")}
            >
              <span>{t("sign_in_tab1")}</span>
            </div>
            <div
              className={Style.tab_item}
              onClick={() => setTab("login_phone")}
            >
              <span>{t("sign_in_tab2")}</span>
            </div>
            <div
              className={Style.tab}
              style={{ left: tab === "login_email" ? "1%" : "51%" }}
            />
          </div>
          {tab === "login_email" ? (
            <Formik
              initialValues={{
                mail: "",
                password: "",
              }}
              onSubmit={(values) => {
                loginUserFunction(values);
              }}
              validationSchema={loginEmailSchema}
            >
              {({
                errors,
                touched,
                values,
                handleBlur,
                handleSubmit,
                setFieldValue,
                dirty,
              }) => {
                hasErrors = Object.keys(errors).length == 0;
                return (
                  <Form className={Style.signin_modal_form}>
                    <div
                      className={
                        errors.mail && touched.mail
                          ? "form-group error"
                          : "form-group"
                      }
                    >
                      <label htmlFor="mail">{`${t("register_email")} *`}</label>
                      <div className="input-holder">
                        <Field
                          type="email"
                          name="mail"
                          id="mail"
                          className="form-control"
                          placeholder={t("register_email_placeholder")}
                        />
                      </div>
                      <ErrorMessage name="mail" className="field_error_message">
                        {(msg) => (
                          <div
                            style={{
                              color: "#FF6261",
                              fontSize: "13px",
                              marginTop: "5px",
                            }}
                            className="error-msg"
                          >
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </div>
                    <div
                      className={
                        errors.password && touched.password
                          ? "form-group error password"
                          : "form-group password"
                      }
                    >
                      <label htmlFor="password">{`${t(
                        "register_pwd"
                      )} *`}</label>
                      <div className="input-holder">
                        <Field
                          type={isVisible ? "text" : "password"}
                          name="password"
                          id="password"
                          className="form-control"
                          placeholder={t("register_password_placeholder")}
                        />
                        <button
                          className={`eye ${isVisible ? "show-pwd" : ""}`}
                          onClick={(e) => handleVisibility(e)}
                        >
                          {isVisible ? (
                            <>
                              <img
                                src={Assets.icon_eye_visible}
                                alt="icon-eye"
                              />
                            </>
                          ) : (
                            <>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15.192"
                                height="15.192"
                                viewBox="0 0 15.192 15.192"
                              >
                                <g
                                  id="Icon_feather-eye-off"
                                  data-name="Icon feather-eye-off"
                                  transform="translate(1.061 1.061)"
                                >
                                  <path
                                    id="Path_68039"
                                    data-name="Path 68039"
                                    d="M11.564,14.282a5.983,5.983,0,0,1-3.529,1.224c-4.159,0-6.535-4.753-6.535-4.753A10.961,10.961,0,0,1,4.506,7.224M6.788,6.143A5.418,5.418,0,0,1,8.035,6c4.159,0,6.535,4.753,6.535,4.753a10.991,10.991,0,0,1-1.283,1.9m-3.992-.636A1.782,1.782,0,1,1,6.776,9.493"
                                    transform="translate(-1.5 -4.218)"
                                    fill="none"
                                    stroke="#a2a4ac"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.5"
                                  />
                                  <path
                                    id="Path_68040"
                                    data-name="Path 68040"
                                    d="M1.5,1.5,14.57,14.57"
                                    transform="translate(-1.5 -1.5)"
                                    fill="none"
                                    stroke="#a2a4ac"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.5"
                                  />
                                </g>
                              </svg>
                            </>
                          )}
                        </button>
                        <ErrorMessage
                          name="password"
                          className="field_error_message"
                        >
                          {(msg) => (
                            <div
                              style={{
                                color: "#FF6261",
                                fontSize: "13px",
                                marginTop: "5px",
                              }}
                              className="error-msg"
                            >
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </div>

                      <div className={Style.btn_forgot_password}>
                        <button onClick={() => handleClickFP()}>
                          {t("register_forgot_pwd")}
                        </button>
                      </div>
                    </div>
                    <div className={Style.errorMessage_wrap}>
                      {loginErrors ? (
                        <>
                          <span className={Style.errorMessage}>
                            <p>{loginErrors}</p>
                          </span>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    <button
                      disabled={!hasErrors || !dirty}
                      type="submit"
                      className={`${
                        Style.signin_button
                      } ${"btn btn-secondary w-100"} ${
                        status == "loading" ? Style.otp_continue_btn_loader : ""
                      } `}
                    >
                      {t("sign_in")}
                    </button>
                  </Form>
                );
              }}
            </Formik>
          ) : (
            <Formik
              initialValues={loginInputs}
              onSubmit={(values, { setErrors, setSubmitting, resetForm }) => {
                loginCreateOtpFunction(values);
              }}
              validationSchema={loginPhoneSchema}
              enableReinitialize={true}
            >
              {({
                errors,
                touched,
                values,
                handleBlur,
                handleSubmit,
                setFieldValue,
                dirty,
              }) => {
                hasErrorsPhone = Object.keys(errors).length == 0;
                return (
                  <form
                    onSubmit={handleSubmit}
                    className={Style.signin_modal_form}
                  >
                    <CountryCodeInput
                      handleChangeHandler={(e) =>{
                        if(e.target.value.length < 11){
                          setFieldValue("phone_number", e.target.value)
                        }
                      }
                      }
                      handleChangeCode={(c) =>
                        setFieldValue("country_dial_code", c)
                      }
                 
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      placeholder={t("register_phone_placeholder")}
                    />
                    {/* <span className={Style.errorMessage}>
                      <p>{loginErrors}</p>
                    </span> */}
                    <div className="my-1">
                      <div className={`${Style.errorMessage_wrap} py-1`}>
                        {loginErrors ? (
                          <>
                            <span className={Style.errorMessage}>
                              <p>{loginErrors}</p>
                            </span>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <button
                      disabled={!hasErrorsPhone || !dirty}
                      type="submit"
                      className={`${
                        Style.signin_button
                      } ${"btn btn-secondary w-100"} ${
                        status == "loading" ? Style.otp_continue_btn_loader : ""
                      }`}
                    >
                      {t("sign_in_btn")}
                    </button>
                  </form>
                );
              }}
            </Formik>
          )}
          <div className={Style.signin_modal_other_logins}>
            <span className={Style.continue}>{t("register_info1")}</span>
            <div className={Style.other_login_icons}>
              <button className={`${Style.other_login_icon} google-icon`}>
                <svg
                  id="_x31__stroke"
                  xmlns="http://www.w3.org/2000/svg"
                  width="26.396"
                  height="26.395"
                  viewBox="0 0 26.396 26.395"
                >
                  <g id="Group_25172" data-name="Group 25172">
                    <g id="Google">
                      <rect
                        id="Rectangle_12432"
                        data-name="Rectangle 12432"
                        width="26.217"
                        height="25.936"
                        transform="translate(0 0.458)"
                        fill="none"
                      />
                      <path
                        id="Path_31189"
                        data-name="Path 31189"
                        d="M6.831,41.494a7.726,7.726,0,0,1,.4-2.45l-4.5-3.4a13.16,13.16,0,0,0,0,11.688l4.493-3.4a7.731,7.731,0,0,1-.4-2.439"
                        transform="translate(-1.081 -28.297)"
                        fill="#fbbc05"
                        fillRule="evenodd"
                      />
                      <path
                        id="Path_31190"
                        data-name="Path 31190"
                        d="M19.927,5.4a7.781,7.781,0,0,1,4.918,1.74L28.732,3.3A13.432,13.432,0,0,0,7.938,7.351l4.5,3.4A7.865,7.865,0,0,1,19.927,5.4"
                        transform="translate(-6.283)"
                        fill="#ea4335"
                        fillRule="evenodd"
                      />
                      <path
                        id="Path_31191"
                        data-name="Path 31191"
                        d="M19.927,81.229a7.865,7.865,0,0,1-7.493-5.348l-4.5,3.4a13.333,13.333,0,0,0,11.989,7.351,12.809,12.809,0,0,0,8.7-3.289l-4.268-3.264a8.332,8.332,0,0,1-4.436,1.154"
                        transform="translate(-6.283 -60.234)"
                        fill="#34a853"
                        fillRule="evenodd"
                      />
                      <path
                        id="Path_31192"
                        data-name="Path 31192"
                        d="M78.209,54.763a10.82,10.82,0,0,0-.3-2.4H65.457v5.1h7.165a5.943,5.943,0,0,1-2.729,3.945l4.268,3.264a13.056,13.056,0,0,0,4.048-9.908"
                        transform="translate(-51.813 -41.566)"
                        fill="#4285f4"
                        fillRule="evenodd"
                      />
                    </g>
                  </g>
                </svg>
              </button>
              <button className={`${Style.other_login_icon} fb-icon`}>
                <svg
                  id="iconfinder_1_Facebook_colored_svg_copy_5296499"
                  xmlns="http://www.w3.org/2000/svg"
                  width="26.396"
                  height="26.395"
                  viewBox="0 0 26.396 26.395"
                >
                  <path
                    id="Path_31188"
                    data-name="Path 31188"
                    d="M26.4,13.278A13.2,13.2,0,1,0,11.135,26.4V17.116H7.785V13.278h3.351V10.353c0-3.328,1.97-5.166,4.985-5.166a20.175,20.175,0,0,1,2.954.259V8.714H17.41a1.913,1.913,0,0,0-2.151,2.074v2.491h3.66l-.585,3.838H15.26V26.4A13.257,13.257,0,0,0,26.4,13.278Z"
                    fill="#1877f2"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className={Style.signin_modal_create_account}>
            <span>{t("sign_in_create_account")}</span>
            <button onClick={() => handleClickRegister()}>
              {t("sign_in_create_account_btn")}
            </button>
          </div>
        </>
      </div>
    </div>
  );
};

export default SignInModal;
