import { useState } from "react";

const useGalleryImage = () => {
  const [toBeDragged, setToBeDragged] = useState(false);

  const handleMouseDown = () => {
    setToBeDragged(true);
  };

  const handleMouseUp = () => {
    setToBeDragged(false);
  };

  return {
    toBeDragged,
    setToBeDragged,
    handleMouseDown,
    handleMouseUp,
  };
};

export default useGalleryImage;
