import Style from "./UserProfileFamilyMembers.module.scss";
import { currentTabSelection } from "../../../store/slices/gallerySlice";
import UserProfileFamilyMember from "./UserProfileFamilyMember";
import { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useProfile } from "../../../Logic/useProfile";

const UserProfileFamilyMembers = () => {
  const {
    familyMembers,
    family_filter,
    is_relation_exist,
    is_parents_exist,
    family_filter_array,
    capitalizeFirstLetter,
    handleFloater,
    floater,
    familyMembersSelectedTab,
    TabRouteData
  } = useProfile();



  return (
    <div className={Style.User_profile_overview_right_side}>
      <div className={Style.User_profile_overview_sec}>
        <h2 className={Style.User_profile_overview_title}>Family Members</h2>
        <div className={Style.user_profile_members}>
          <div className={Style.left_filtered_category_list_wrap}>
            <div className={Style.left_filtered_category_list}>
              
              { TabRouteData.map((tabData, index) => {

                var obj = {
                  is_it_all_tab: false,
                  is_data_exist_in_this_tab: false,
                };

                if (tabData.name !== "All") {
                  tabData.relation.forEach((data) => {
                    if (typeof data === "object") {
                      data.parents.forEach((item) => {
                        obj.is_data_exist_in_this_tab = is_relation_exist(
                          familyMembers,
                          item
                        )
                          ? true
                          : false;

                        if (obj.is_data_exist_in_this_tab) {
                          return true;
                        }
                      });
                    } else {
                      obj.is_data_exist_in_this_tab = is_relation_exist(
                        familyMembers,
                        data
                      )
                        ? true
                        : false;
                    }
                  });
                } else {
                  obj.is_it_all_tab = true;
                }

                if (obj.is_it_all_tab || obj.is_data_exist_in_this_tab) {
                  return (
                    <div onClick={(e) => handleFloater(e, tabData.relation)}>
                      <Link to={`${tabData.path}`} data-name={tabData.name}>
                        {tabData.name}
                      </Link>
                    </div>
                  );
                }
              })}

              <div
                className={Style.floater}
                style={{
                  width: `${floater.floaterWidth}px`,
                  left: `${floater.leftValue}px`,
                }}
              />
            </div>
          </div>

          <div className={Style.left_filtered_category_members_list}>
            {familyMembersSelectedTab.map((connection) => {
              if (typeof connection === "object") {
                return (
                  <>
                    {is_parents_exist(familyMembers) && (
                      <h5
                        className={
                          Style.left_filtered_category_members_relation
                        }
                      >
                        Parents
                      </h5>
                    )}
                    <div className={Style.left_filtered_category_members_row}>
                      {connection.parents.map(
                        (connection) =>
                          is_relation_exist(familyMembers, connection) && (
                            <UserProfileFamilyMember
                              imgUrl={family_filter(
                                familyMembers,
                                connection,
                                "img"
                              )}
                              memberName={family_filter(
                                familyMembers,
                                connection,
                                "name"
                              )}
                              memberRelation={capitalizeFirstLetter(connection)}
                            />
                          )
                      )}
                    </div>
                  </>
                );
              } else {
                return (
                  <>
                    {family_filter_array(familyMembers, connection).length >
                      0 && (
                      <h5
                        className={
                          Style.left_filtered_category_members_relation
                        }
                      >
                        {capitalizeFirstLetter(connection)}
                      </h5>
                    )}

                    <div className={Style.left_filtered_category_members_row}>
                      {family_filter_array(familyMembers, connection).map(
                        (member,index) => (
                          <UserProfileFamilyMember
                            imgUrl={member.img}
                            memberName={member.name}
                            memberRelation={capitalizeFirstLetter(
                              member.connection + " " + ( family_filter_array(familyMembers, connection).length >= 2 ? index + 1 : "" )
                            )}
                          />
                        )
                      )}
                    </div>
                  </>
                );
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfileFamilyMembers;
