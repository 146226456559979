
import audio1 from "./assets/sample1.mp3"
import audio2 from "./assets/sample2.mp3"
import audio3 from "./assets/sample3.mp3"
import audio4 from "./assets/sample4.mp3"



export default [
    {
        name: "audio1",
        url: audio1,
        image: "https://images.pexels.com/photos/2311713/pexels-photo-2311713.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
    },
    {
        name: "audio2",
        url: audio2,
        image: "https://images.pexels.com/photos/426976/pexels-photo-426976.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
    },
    {
        name: "audio3",
        url: audio3,
        image: "https://images.pexels.com/photos/2747446/pexels-photo-2747446.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
    },
    {
        name: "audio4",
        url: audio4,
        image: "https://images.pexels.com/photos/2240766/pexels-photo-2240766.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
    }
]