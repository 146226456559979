import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs, Autoplay } from "swiper";
import React, { useState, useRef } from "react";
import Style from "./SlideShowTest.module.scss";

const SlideShowTest = () => {
  const [currentIndex, setCurrentIndex] = useState(1);
  const [togglePlay, setTogglePlay] = useState(false);
  const [activeThumb, setActiveThumb] = useState(null);

  const swiperRef = useRef(null);

  const handleClickPlay = () => {
    console.log("start...");
    swiperRef.current.swiper.autoplay.start();
  };

  const handleClickPause = () => {
    console.log("stop...");
    swiperRef.current.swiper.autoplay.stop();
  };

  const slideChangeHandler = (e) => {
    setCurrentIndex(e.realIndex);
  };
  console.log(swiperRef?.current?.swiper?.autoplay);

  return (
    <div>
      <div className={Style.actions}>
        <div onClick={() => handleClickPlay()}>play</div>
        <div onClick={() => handleClickPause()}>pause</div>
      </div>
      <div className={Style.slideshow_fullscreen}>
        <Swiper
          ref={swiperRef}
          loop={true}
          slidesPerView="auto"
          spaceBetween={10}
          navigation={true}
          modules={[FreeMode, Navigation, Thumbs, Autoplay]}
          className={`${Style.slideshow_swiper} mySwiper2`}
          thumbs={{ swiper: activeThumb }}
          onSlideChange={slideChangeHandler}
          allowTouchMove={true}
          // onClick={(e) => (e.autoplay = true)}
        >
          {slides?.map((imgItem, index) => {
            return (
              <SwiperSlide
                className={currentIndex === index ? "fade" : "non"}
                key={imgItem.id}
              >
                <figure className={Style.slideshow_fullscreen_image}>
                  <img src={imgItem?.url} alt="" />
                </figure>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <nav className={Style.slideshow_slides_nav}>
          <div className={Style.slideshow_slides_nav_wrap}>
            <Swiper
              loop={false}
              onSwiper={setActiveThumb}
              spaceBetween={23}
              slidesPerView="auto"
              freeMode={true}
              watchSlidesProgress={true}
              modules={[FreeMode, Navigation, Thumbs]}
              className={`${Style.slideshow_nav_swiper} mySwiper`}
            >
              {slides?.map((imgItem, index) => {
                return (
                  <SwiperSlide key={index}>
                    <figure className={`${Style.slides_nav_image}`}>
                      <img
                        // onClick={playSlideShow}
                        src={imgItem?.url}
                        className={`${
                          index === currentIndex && Style.slider_active
                        }`}
                        alt=""
                      />
                    </figure>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default SlideShowTest;

let slides = [
  {
    id: "1",
    url: "https://images.pexels.com/photos/12491573/pexels-photo-12491573.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    id: "2",
    url: "https://images.pexels.com/photos/7126017/pexels-photo-7126017.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    id: "3",
    url: "https://images.pexels.com/photos/11890219/pexels-photo-11890219.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    id: "4",
    url: "https://images.pexels.com/photos/6552131/pexels-photo-6552131.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    id: "5",
    url: "https://images.pexels.com/photos/11915269/pexels-photo-11915269.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    id: "6",
    url: "https://images.pexels.com/photos/9374402/pexels-photo-9374402.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
];
