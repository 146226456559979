import Style from "./MyInvitations.module.scss";
import { useDimensions } from "../../Logic/Dimensions";
import Icons from "../Layouts/Icons";


export const MyInvitations = ({name, image, date}) => {
  const windowDimensions = useDimensions();
  return (
    <div className={Style.myinvitations}>
      <div>
        <figure className={`${Style.myinvitations_figure} mb-0`}>
          <img src={image} alt="profile-img"></img>
        </figure>
      </div>
      <div className={Style.myinvitations_text}>
        <div className={Style.myinvitations_p}>
          <strong className={Style.myinvitations_user_name}>{name}</strong> sent an invitation to link his family tree with yours.
        </div>
        <div className={Style.myinvitations_time}>{date}</div>
      </div>
      
      {(windowDimensions.width >= 992) ? (
          <>
          <div className={Style.myinvitations_buttoncontainer}>
            <div className={Style.myinvitations_buttonaccept}>
              <button className="btn btn-sm btn-green">Accept</button>
            </div>
            <div className={Style.myinvitations_buttoncancel}>
              <button className="btn btn-sm btn-outline-grey">Reject</button>
            </div>
          </div>
          </>
        ) : (
          <>
            <div className={Style.myinvitations_buttoncontainer_mob}>
              <div className={Style.myinvitations_buttonaccept}>
                <button className="btn btn-sm btn-green">
                  <Icons icon={"icon-tick"} size={14} />
                </button>
              </div>
              <div className={Style.myinvitations_buttoncancel}>
                <button className="btn btn-sm btn-outline-grey">
                  <Icons icon={"icon-close"} size={11} />
                </button>
              </div>
            </div>          
          </>
      )}
    </div>
  );
};
