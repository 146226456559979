import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  loginCreateOtp,
  loginUser,
  verifyOtp,
  storeCurrentAuthData,
  forgotPassword,
  showSignInState,
  changeScreen,
} from "../store/slices/authSlice";

import { useRegister } from "./useRegister";

export const useLogin = () => {
  const [loginErrors, setLoginErrors] = useState("");
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loginUserFunction = async (data) => {
    const resultAction = await dispatch(loginUser(data));
    if (loginUser.fulfilled.match(resultAction)) {
      navigate("/dashboard");
      
    } else {
      if (resultAction.payload) {
        setLoginErrors(resultAction.payload?.message);
        setTimeout(() => {
          setLoginErrors("");
        }, 5000);
      }
    }
  };

  const loginCreateOtpFunction = async (data) => {
    data = {
      ...data,
      pageFrom: "login_phone",
    };
    dispatch(storeCurrentAuthData(data));
    const resultAction = await dispatch(
      loginCreateOtp({ phone_number: data?.phone_number })
    );
    if (loginCreateOtp.fulfilled.match(resultAction)) {
      data = {
        ...data,
        token: resultAction.payload.data.result.token,
        user_id: resultAction.payload.data.result.user_id,
      };
      dispatch(storeCurrentAuthData(data));
      dispatch(changeScreen("otp"));
    } else {
      if (resultAction.payload) {
        setLoginErrors(resultAction.payload?.message);
        setTimeout(() => {
          setLoginErrors("");
        }, 1500);
      }
    }
  };

  const forgotPasswordHandler = async (data) => {
    dispatch(storeCurrentAuthData({ mail: data.mail }));
    const resultAction = await dispatch(forgotPassword({ email: data.mail }));
    if (forgotPassword.fulfilled.match(resultAction)) {
      setShowForgotPassword(true);
    } else {
      setLoginErrors("Please enter valid Email ID ");
      setTimeout(() => {
        setLoginErrors("");
      }, 1500);
    }
  };

  return {
    loginUserFunction,
    loginCreateOtpFunction,
    loginErrors,
    forgotPasswordHandler,
    showForgotPassword,
    setShowForgotPassword,
  };
};
