import { useEffect, useLayoutEffect, useState } from "react";
import FamilyTree from "@balkangraph/familytree.js";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getAxiosInstance } from "../../api";
import { useReactToPrint } from "react-to-print";
import { getFamilyDetails } from "../../store/slices/familyTreeSlice";

const SAMPLE_DATA = [
  {
    id: 1,
    connection: "user",
    name: "you",
    mid: "",
    fid: "",
    pids: "",
    gender: 1,
    bio: "hello",
    dob: "2022-06-01",
    img: "https://cdn.pixabay.com/photo/2018/11/13/21/43/avatar-3814049_960_720.png",
    family_name: "Kalathilparambil",
  },
];

const UseFamilyTree = ({ divRef, componentRef }) => {
  let family;
  const [size, setSize] = useState([0]);
  const [myData, setData] = useState([]);
  const [zoom, setZoom] = useState(1);
  const [active, setActive] = useState(false);
  const [newData, setNewData] = useState([]);
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
  const [drawerData, setDrawerData] = useState([SAMPLE_DATA[0]]);
  const [defaultView, setDefaultView] = useState("");
  const [showDeleteMemberModal, setShowDeleteMemberModal] = useState(false);
  const [firstTime, setFirstTime] = useState(false);
  let exportPDF;
  const navigate = useNavigate();

  const userID = useSelector((state) => state.auth.registerData?.user_id);

  useEffect(() => {
    setDrawerData((state) => {
      return { ...state, user_id: userID };
    });
  }, [userID]);

  const dispatch = useDispatch();

  useEffect(() => {
    const getUserData = async () => {
      const resultAction = await dispatch(getFamilyDetails());
      if (getFamilyDetails.fulfilled.match(resultAction)) {
        const resData = resultAction.payload?.data?.result;
        setData(resData);
        if (resData?.length === 0) {
           navigate("/family-tree-form");
        }
      } else {
      }
    };
    getUserData();
    // setData(SAMPLE_DATA);
  }, []);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth]);
    }
    window.addEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    //adding dynamic html into the template.
    FamilyTree.templates.myTemplate = Object.assign(
      {},
      FamilyTree.templates.tommy
    );
    FamilyTree.templates.myTemplate.size = [168, 236];
    FamilyTree.templates.myTemplate.node = "";
    FamilyTree.templates.myTemplate.html = "{val}";
    FamilyTree.templates.myTemplate_male = Object.assign(
      {},
      FamilyTree.templates.myTemplate
    );
    FamilyTree.templates.myTemplate_female = Object.assign(
      {},
      FamilyTree.templates.myTemplate
    );
    FamilyTree.events.on("scroll", (node) => {
      console.log(node);
    });

    //assign template
    family = new FamilyTree(divRef.current, {
      exportUrl: "https://familyphotos.webc.in/staging/api/v1/export",
      nodes: myData,
      enableSearch: false,
      template: "myTemplate",
      scaleInitial: zoom,
      levelSeparation: 70,
      siblingSeparation: 70,
      nodeMouseClick: FamilyTree.action.none,
      mouseScrool: FamilyTree.action.zoom,
      zoom: zoom,
      collapse: { level: 3, allChildren: true },
      nodeBinding: {
        html: "html",
      },
      exportPDF: exportPDF,
    });

    //creating dynamic html and values
    family.on("field", function (semder, args) {
      if (args.name == "html") {
        if (args.data.name == "") {
          args.value = `
                    <foreignobject class="mainWrapper"  x="0" y="0" width="${args.node.w}" height="${args.node.h}">
                        <div class="cardWrapper" style="width: ${args.node.w}px; height: ${args.node.h}px;">
                            <div class="image-wrapper">
                                <div class="image-container no-image">
                                </div>
                            </div>
                            <div class="content-wrapper">
                                <div class="content-head">
                                    <h3>Add Relative</h3>
                                    <p class="desc">Family Name</p>
                                </div>
                            </div>
                        </div>
                    </foreignobject>`;
        } else {
          args.value = `
                    <foreignobject class="mainWrapper"  x="0" y="0" width="${
                      args.node.w
                    }" height="${args.node.h}">
                        <div class="cardWrapper" style="width: ${
                          args.node.w
                        }px; height: ${args.node.h}px;">
                            <div class="image-wrapper">
                                <div class="image-container">
                                    <img src="${
                                      typeof args.data.img !== "string"
                                        ? URL.createObjectURL(args.data.img)
                                        : args.data.img
                                    }" />
                                </div>
                            </div>
                            <div class="content-wrapper">
                                <div class="content-head">
                                    <h3>${
                                      args.data.connection !== "user"
                                        ? args.data.name
                                        : "You"
                                    }</h3>
                                    <p class="desc">${args.data.family_name}</p>
                                </div>
                            </div>
                        </div>
                        <button style="display:${
                          args.data.connection !== "user" && "none"
                        }" id="addNew"><span></span></button>
                    </foreignobject>`;
        }
      }
    });

    //click top open Popup
    family.onNodeClick((props) => {
      if (props.event.target.id === "addNew") {
        setNewData([
          {
            userData: myData.filter((item) => item.id === props.node.id),
            allData: myData,
          },
        ]);
        setActive(!active);
      } else {
        setSideDrawerOpen(true);
        setDrawerData(myData.filter((item) => item.id === props.node.id));
      }
    });
  }, [myData, sideDrawerOpen, size, zoom]);

  const print = useReactToPrint({
    content: () => componentRef.current,
  });
  const handlePrint = () => {
    handleRecenter();
    setTimeout(() => {
      print();
    }, 400);
  };

  const handleRecenter = () => {
    family.fit();
    setZoom(1);
  };

  return {
    size,
    myData,
    setData,
    zoom,
    setZoom,
    defaultView,
    setDefaultView,
    firstTime,
    setFirstTime,
    active,
    setActive,
    newData,
    setNewData,
    sideDrawerOpen,
    setSideDrawerOpen,
    drawerData,
    setDrawerData,
    showDeleteMemberModal,
    setShowDeleteMemberModal,
    handleRecenter,
    handlePrint,
  };
};

export default UseFamilyTree;
