import { Col, Row } from "react-bootstrap";
import Style from "./UserProfileOverview.module.scss";
import { useState, useRef, useEffect } from "react";
import UserProfileGalleryImages from "../UserProfileGalleryImages";
import UserProfileFamilyMembers from "../UserProfileFamilyMembers";
import { useDimensions } from "../../../Logic/Dimensions";
import { useProfile } from "../../../Logic/useProfile";

const UserProfileOverview = () => {

  const breakpointColumnsObj = {
    default: 4,
    1830: 3,
    1599: 2,
    1439: 4,
    1349: 3,
    1199: 4,
    1024: 3,
    800: 2,
    379: 1,
  };

  const { userdata } = useProfile();

  const windowDimensions = useDimensions();
  return (
    <div className={Style.User_profile_overview}>
      <Row className={windowDimensions.width >= 768 ? "gx-4" : "gx-0"}>
        <Col xl={windowDimensions.width >= 1440 ? 8 : 12}>
          <div className={Style.User_profile_overview_main_col}>
            <div className={Style.User_profile_overview_sec}>
              <h2 className={Style.User_profile_overview_title}>Bio</h2>
              <p className={Style.User_profile_overview_p}>
               {userdata.bio}
              </p>
            </div>
            {/* gallery */}
            <div className={Style.User_profile_overview_sec}>
              <h2 className={Style.User_profile_overview_title}>Gallery</h2>

              <UserProfileGalleryImages
                breakpointColumnsObj={breakpointColumnsObj}
              />
            </div>
          </div>
        </Col>
        {windowDimensions.width >= 1440 ? (
          <Col xl={4}>
            
            <UserProfileFamilyMembers />

          </Col>
        ) : (
          <></>
        )}
      </Row>
    </div>
  );
};

export default UserProfileOverview;
