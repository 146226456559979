import React, { useEffect } from "react";
import { useState } from "react";
import { Dropdown, Container } from "react-bootstrap";
import Style from "./Preference.module.scss";
import { useDimensions } from "../../Logic/Dimensions";
import DarkThemeToggler from "../DarkThemeToggler";
import LanguageMenu from "../Layouts/LanguageMenu/LanguageMenu";
import { usePreference } from "../../Logic/usePreference";
import CommonModal from "../../components/Modals/commonModal";
import useDarkmode from "../../utils/utilityHooks/useDarkmode";


const Preference = () => {
  const {
    password,
    deleteFormObj,
    errorMessages,
    setPassword,
    themes,
    availableLanguage,
    checkedOne,
    checkedTwo,
    checkedThree,
    deleteAccountModal,
    setCheckedOne,
    windowDimensions,
    showLang,
    languageCategory,
    setShowLang,
    setDeleteFormObj,
    setCheckedTwo,
    setCheckedOneThree,
    setDeleteAccountModal,
    setTheme,
    handleLanguageClick,
    handleSignMeAutomatically,
    handleAutoAdjustPositions,
    handleEnableEditAccess,
    languageCategorySwitch,
    ChangeTheme,
    deleteActionConfirmed,
    capitalizeFirstLetter,
    languageOpen, 
    setLanguageOpen
  } = usePreference();

  const {theme,themeToggler}=useDarkmode();
  const [view, setView] = useState('all');

  const changeView = (selectedView) => {
    setView(selectedView);
  }


  return (
    <div className={Style.preference}>
      {showLang ? (
        <div className={Style.language_menu_mob}>
          <LanguageMenu showLang={showLang} setShowLang={setShowLang} />
        </div>
      ) : (
        <></>
      )}
      <div className={Style.preference_wrap}>
        <div className={Style.tab_item_title}>Preference</div>
        <div className={Style.preference_container}>
          <div className={Style.preference_box}>
            <div className={Style.pr_4}>
              <div className={Style.preference_subtitle}>
                Sign me automatically
              </div>
              <div className={Style.preference_dis}>
                enabling this will make you to sign in automatically when the
                program starts.
              </div>
            </div>
            <div className={`my-auto ${Style.preference_form_group}`}>
              <div className={Style.preference_checkbox_holder}>
                <label htmlFor="test">
                  <span className={Style.preference_slider_round}></span>
                </label>
                <div>
                  <label className="toggle-switch">
                    <input
                      type="checkbox"
                      id="test"
                      checked={checkedOne}
                      onChange={(e)=>handleSignMeAutomatically(e)}
                    />
                    <span className="slider slider-sm"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className={Style.preference_box}>
            <div className={Style.pr_4}>
              <div className={Style.preference_subtitle}>
                Auto adjust positions
              </div>
              <div className={Style.preference_dis}>
                enabling this will make spacing and position of family members
                automatically adjusted
              </div>
            </div>
            <div className={`my-auto ${Style.preference_form_group}`}>
              <div className={Style.preference_checkbox_holder}>
                <label htmlFor="test">
                  <span className={Style.preference_slider_round}></span>
                </label>
                <label className="toggle-switch">
                  <input
                    type="checkbox"
                    id="test"
                    checked={checkedTwo}
                    onChange={handleAutoAdjustPositions}
                  />
                  <span className="slider slider-sm"></span>
                </label>
              </div>
            </div>
          </div>
          <div className={Style.preference_box}>
            <div className={Style.pr_4}>
              <div className={Style.preference_subtitle}>
                Enable edit access
              </div>
              <div className={Style.preference_dis}>
                Enable edit access to a linked tree when sharing the link.
              </div>
            </div>
            <div className={`my-auto ${Style.preference_form_group}`}>
              <div className={Style.preference_checkbox_holder}>
                <label htmlFor="test">
                  <span className={Style.preference_slider_round}></span>
                </label>
                <label className="toggle-switch">
                  <input
                    type="checkbox"
                    id="test"
                    checked={checkedThree}
                    onChange={handleEnableEditAccess}
                  />
                  <span className="slider slider-sm"></span>
                </label>
              </div>
            </div>
          </div>
          <div className={Style.preference_box}>
            <div className={Style.pr_4}>
              <div className={Style.preference_subtitle}>Language</div>
              <div className={Style.preference_dis}>
                Change language based on your preference
              </div>
            </div>
            <div className={`my-auto ${Style.preference_form_group}`}>
              {windowDimensions.width > 768 ? (
                <Dropdown style={{ width: "100%" }}>
                  <Dropdown.Toggle className={Style.language_bar_toggle}>
                    {capitalizeFirstLetter(languageCategory?.name)}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {availableLanguage.map((langObj) => {
                      return (
                        <Dropdown.Item
                          onClick={() => languageCategorySwitch(langObj)}
                        >
                          {capitalizeFirstLetter(langObj.name)}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <>
                  <button
                    className={Style.language_select_btn}
                    onClick={handleLanguageClick}
                  ></button>
                </>
              )}
            </div>
          </div>
          <div className={Style.preference_box}>
            <div className={Style.pr_4}>
              <div className={Style.preference_subtitle}>Background theme</div>
              <div className={Style.preference_dis}>
                enabling this will make you to sign in automatically when the
                program starts.
              </div>
            </div>
            <div className={`my-auto ${Style.preference_form_group}`}>
              {windowDimensions.width > 768 ? (
                <>
                  <div className={Style.preference_box_radio_wrap}>
                    <div
                      className={Style.preference_radio_holder}
                      onClick={() => {
                        setTheme("light");
                      }}
                    >
                      <label className="custom-checkbox" htmlFor="normal">
                        <input
                          type="radio"
                          id="normal"
                          value="normal"
                          checked={themes == "light"}
                          onClick={(e)=>{ChangeTheme(e);themeToggler()}}
                        ></input>
                        <div className="checkmark"></div>
                        <span
                          className={
                            themes === "light" ? Style.checkmark_checked : ""
                          }
                        >
                          Light
                        </span>
                      </label>
                    </div>
                    <div
                      onClick={() => {
                        setTheme("dark");
                      }}
                    >
                      <label className="custom-checkbox" htmlFor="dark">
                        <input
                          type="radio"
                          id="dark"
                          value="dark"
                          onClick={(e)=>{ChangeTheme(e); themeToggler()}}
                          checked={themes == "dark"}
                        ></input>
                        <div className="checkmark"></div>
                        <span
                          className={
                            themes === "dark" ? Style.checkmark_checked : ""
                          }
                        >
                          Dark
                        </span>
                      </label>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <DarkThemeToggler theme={theme} toggleTheme={themeToggler} />
                </>
              )}
            </div>
          </div>
          <div className={Style.preference_box}>
            <div className={Style.pr_4}>
              <div className={Style.preference_subtitle}>Default View</div>
              <div className={Style.preference_dis}>
                enabling this will make you to sign in automatically when the
                program starts.
              </div>
            </div>
            <div className={`my-auto ${Style.preference_form_group}`}>
              {windowDimensions.width > 768 ? (
                <>
                  <div className={Style.preference_box_radio_wrap}>
                    <div
                      className={Style.preference_radio_holder}
                    >
                      <label className="custom-checkbox" htmlFor="all">
                        <input
                          type="radio"
                          id="all"
                          value="all"
                          checked={view == "all"}
                          onClick={()=>{changeView('all')}}
                        ></input>
                        <div className="checkmark"></div>
                        <span
                          className={
                            view === "all" ? Style.checkmark_checked : ""
                          }
                        >
                          All
                        </span>
                      </label>
                    </div>
                    <div
                      className={Style.preference_radio_holder}
                    >
                      <label className="custom-checkbox" htmlFor="album">
                        <input
                          type="radio"
                          id="album"
                          value="album"
                          onClick={()=>{changeView('album')}}
                          checked={view == "album"}
                        ></input>
                        <div className="checkmark"></div>
                        <span
                          className={
                            view === "album" ? Style.checkmark_checked : ""
                          }
                        >
                          Album
                        </span>
                      </label>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <Dropdown style={{ width: "100%" }}>
                    <Dropdown.Toggle className={Style.language_bar_toggle} style={{ minWidth: "112px", width: "100px" }}>
                      {view == "all" ? "All" : "Album"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                          <Dropdown.Item onClick={() => {changeView('all')}}>
                            All
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => {changeView('album')}}>
                            Album
                          </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </>
              )}
            </div>
          </div>
          <div className={Style.preference_box}>
            <div className={Style.pr_4}>
              <div className={Style.preference_subtitle}>Change password</div>
              <div className={Style.preference_dis}>
                enabling this will make you to sign in automatically when the
                program starts.
              </div>
            </div>
            <div></div>
          </div>
          <div className={Style.preference_box}>
            <div className={Style.pr_4}>
              <div className={Style.preference_subtitle}>Delete account</div>
              <div className={Style.preference_dis}>
                Deleting an account means you will lose all your information forever.
              </div>
            </div>
            <div className={`${Style.preference_form_button} my-auto`}>
              <button
                onClick={() => setDeleteAccountModal(!deleteAccountModal)}
                className="btn btn-sm btn-red"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      <CommonModal
        showModal={deleteAccountModal}
        hide={() => setDeleteAccountModal(!deleteAccountModal)}
        className={Style.delete_account_modal}
        title={""}
        content={
          <div className={Style.delete_account}>
            <h3 className={Style.delete_account_title}>Delete this Account</h3>
            <p className={Style.delete_account_description}>
              Once you delete your account , there's no getting it back.
              Make sure you want to do this.
            </p>

            <div className={`${deleteFormObj.error && Style.field_error} form-group`}>
              <input
                className={"form-control"}
                placeholder="Enter Your Password"
                id={"contact-name"}
                type={"text"}
                onChange={(e) => {
                  setDeleteFormObj({
                    ...deleteFormObj,
                    password: e.target.value,
                    error: false,
                  });
                }}
                value={deleteFormObj.password}
              />
              {deleteFormObj.error && (
                <span className="error-msg">
                  {errorMessages[deleteFormObj.error_code]}
                </span>
              )}
            </div>

            <div className={Style.delete_account_btn_wrap}>
              {/* <button
                className="btn btn-border btn-border-grey"
                onClick={() => setDeleteAccountModal(!deleteAccountModal)}
              >
                Cancel
              </button> */}
              <button className="btn btn-red" onClick={deleteActionConfirmed}>
                Yes, Delete it
              </button>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default Preference;
