import Style from "./SearchProfileGallery.module.scss";

export const SearchProfileGallery = () => {
  return (
    <div className={Style.searchprofilegallery}>
      <div>
        <div className="h5">Gallery</div>
      </div>
      <div className={Style.searchprofilegallery_image}>
        <figure>
          <img src="https://thumbs.dreamstime.com/b/indian-family-three-portrait-happy-standing-indoors-56830036.jpg"></img>
        </figure>
        <figure>
          <img src="https://images.unsplash.com/photo-1609220136736-443140cffec6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8MXx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60"></img>
        </figure>
        <figure>
          <img src="https://images.unsplash.com/photo-1588979355313-6711a095465f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8ZmFtaWx5JTIwcGljdHVyZXN8ZW58MHx8MHx8&w=1000&q=80"></img>
        </figure>
        <figure>
          <img src="https://thumbs.dreamstime.com/b/indian-family-three-portrait-happy-standing-indoors-56830036.jpg"></img>
        </figure>
      </div>
    </div>
  );
};
