import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../api";

export const contactFormDataSubmit = createAsyncThunk(
  "contactform/contactFormDataSubmit",
  async (obj, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {

      const {action,value} = obj;

      const packet = new FormData();
      
      packet.append("first_name",obj.name);
      packet.append("email",obj.email);
      packet.append("phone_number",obj.phone);
      packet.append("country",obj.country);
      packet.append("message",obj.message);

      const response = await api.post(`add-contact-us`, packet);
      
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);



