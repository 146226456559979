import Style from "./PreLoginMenu.module.scss";
import React, { useState, useEffect, lazy, Suspense } from "react";
import { Dropdown, Button, Modal } from "react-bootstrap";
import SignInModal from "../../../Modals/SignInModal";
import RegisterModal from "../../../Modals/RegisterModal";
import ForgotPasswordModal from "../../../Modals/ForgotPasswordModal";
import OTPVerificationModal from "../../../Modals/OTPVerificationModal";
import { Link, useLocation } from "react-router-dom";
import Assets from "../../Assets";
import Icons from "../../Icons";
import Languages from "../../../../constants/languages";
import { useRegister } from "../../../../Logic/useRegister";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  showSignInState,
  showforgotpasswordState,
  logOut,
  changeScreen,
} from "../../../../store/slices/authSlice";
import CommonModal from "../../../Modals/commonModal";
import AuthenticationModal from "../../../Modals/AuthenticationModal";
import avatar from "../../../../assets/avatar.png";
import { getProfileDetails } from "../../../../store/slices/userprofileSlice";
import useAuthChecker from "../../../../Logic/useAuthChecker";

const EditModals = lazy(() => import("../../../Modals/Modals"));

const PreLoginMenu = ({
  showSignIn,
  setShowSignIn,
  showRegister,
  setShowRegister,
}) => {
  const {
    showModal,
    setShowModal,
    showFP,
    setShowFP,
    showOtp,
    setShowOtp,
    registerStatus,
    loginStatus,
  } = useRegister();

  const dispatch = useDispatch();
  const location = useLocation();

  const { registerData, currentScreen } = useSelector((state) => state.auth);
  const { userdata } = useSelector((store) => store.userprofile);

  const [openModal, setOpenModal] = useState(false);

  const handleLogout = () => {
    dispatch(logOut());
    loggingOut();
  };

  const loggingOut = () => {
    setTimeout(() => {
      window.location.href = "/";
    }, 1000);
  };

  const { isAuthCompleted } = useAuthChecker();

  const [searchBar, setSearchBar] = useState(false);
  const [language, setLanguage] = useState("English");
  const [flag, setFlag] = useState(Assets.en_flag);
  const { i18n, t } = useTranslation(["common"]);
  let currLan = localStorage.getItem("i18nextLng");

  useEffect(() => {
    if (Object.keys(userdata).length == 0) {
      dispatch(getProfileDetails(registerData?.user_id));
    }
  }, [registerData]);

  useEffect(() => {
    switch (currLan) {
      case "en":
        setLanguage("English");
        break;
      case "fr":
        setLanguage("Français");
        break;
      case "esp":
        setLanguage("Español");
        break;
      case "chi":
        setLanguage("日本語");
        break;
      case "pol":
        setLanguage("Pусский");
        break;
      case "ity":
        setLanguage("Italiano");
        break;

      default:
        setLanguage("English");
        break;
    }
  }, [currLan]);

  const languageSwitch = (language, flag, code) => {
    setLanguage(language);
    setFlag(flag);
    i18n.changeLanguage(code);
  };
  const handleClickRegister = () => {
    setShowModal(true);
    dispatch(changeScreen("register"));
  };
  const handleClickLogin = () => {
    setShowModal(true);
    dispatch(changeScreen("login"));
  };
  const handleClickFP = () => {
    dispatch(changeScreen("forgotPassword"));
  };

  const handleClose = () => {
    dispatch(changeScreen(""));
  };

  const renderScreen = (page) => {
    switch (page) {
      case "register":
        return (
          <RegisterModal
            handleClickLogin={handleClickLogin}
            handleClickFP={handleClickFP}
            status={registerStatus}
            handleClose={handleClose}
          />
        );
        break;
      case "login":
        return (
          <SignInModal
            setShowSignIn={setShowSignIn}
            handleClickRegister={handleClickRegister}
            handleClickFP={handleClickFP}
            status={loginStatus}
          />
        );
        break;
      case "forgotPassword":
        return (
          <ForgotPasswordModal
            setShowSignIn={setShowSignIn}
            setShowFP={setShowFP}
          />
        );
      case "otp":
        return <OTPVerificationModal />;
    }
  };

  return (
    <>
      <div className={Style.page_loader}></div>
      <header className={Style.prelogin_header}>
        <div className="container">
          <div
            className={`${Style.prelogin_header_inner_wrap} ${
              searchBar ? Style.search_open : ""
            }`}
          >
            <figure
              className={`${Style.prelogin_header_logo} anim header-anim`}
            >
              <Link to={"/"} title={"Family Photos"}>
                <img
                  src={
                    localStorage.getItem("theme") == "light"
                      ? Assets.main_logo
                      : Assets.main_logo_dark
                  }
                  alt="Family Photos"
                />
              </Link>
            </figure>
            <div className={Style.prelogin_header_nav}>
              <div className={Style.prelogin_header_nav_links}>
                <ul className="anim header-anim">
                  <li className={Style.active}>
                    <Link to={"/"}>{t("home")}</Link>
                  </li>
                  <li>
                    <Link to={"/dashboard"}>{t("upload_photos")}</Link>
                  </li>
                  <li>
                    <Link to={"/dashboard/family-tree"}>
                      {t("family_tree")}
                    </Link>
                  </li>
                  <li>
                    <Link to={"/contact"}>{t("contact")}</Link>
                  </li>
                  {registerData?.name ? (
                    ""
                  ) : (
                    <>
                      <li>
                        <button onClick={() => handleClickRegister()}>
                          {t("register")}
                        </button>
                      </li>
                      <li>
                        <button onClick={() => handleClickLogin()}>
                          {t("login")}
                        </button>
                      </li>
                    </>
                  )}
                </ul>
              </div>

              <Dropdown
                className={`${Style.prelogin_header_nav_language} anim header-anim`}
              >
                <Dropdown.Toggle>
                  <figure className={Style.dropdown_flag}>
                    <img src={flag} alt="flag_image" />
                  </figure>
                  <div className={Style.dropdown_label}>{language}</div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {Languages.map((lang, i) => {
                    return (
                      <Dropdown.Item
                        onClick={() =>
                          languageSwitch(lang.language, lang.flag, lang.code)
                        }
                        key={lang.id}
                      >
                        {lang.language}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
              {isAuthCompleted ? (
                <Dropdown>
                  <Dropdown.Toggle className={Style.profile_dropdown}>
                    <figure className={Style.profile_image}>
                      <img
                        src={userdata?.image ? userdata?.thumbnail : avatar}
                        alt="profile-pic"
                      />
                    </figure>
                    <div className={Style.profile_details}>
                      <span className={Style.username}>{userdata?.name}</span>
                      <span className={Style.ownership}>Admin</span>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {location.pathname == "/" ||
                    location.pathname == "/get-started" ? (
                      <Dropdown.Item as={Link} to="/dashboard/user-profile">
                        Your Profile
                      </Dropdown.Item>
                    ) : (
                      <Dropdown.Item as={Link} to="/">
                        Home
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item
                      onClick={() => {
                        setOpenModal(true);
                      }}
                    >
                      Log Out
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <div
                  className={`${Style.prelogin_header_nav_getdemo} anim header-anim`}
                >
                  <Button
                    className={`btn btn-border ${Style.prelogin_header_nav_getdemo_btn}`}
                  >
                    {t("get_a_demo")}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
        <AuthenticationModal showModal={showModal} setShowModal={setShowModal}>
          {renderScreen(currentScreen)}
        </AuthenticationModal>

        <CommonModal
          showModal={openModal}
          hide={() => setOpenModal(false)}
          className="modal-width logout-modal"
          content={
            <>
              <div className={Style.logoutSection}>
                <div className={`${Style.title} mb-4`}>Are You Sure!</div>

                <div
                  className={`w-100 text-center ${Style.logoutsection_btn_wrap}`}
                >
                  <button
                    onClick={() => handleLogout()}
                    className="btn btn-primary"
                  >
                    Yes
                  </button>
                  <button
                    onClick={() => setOpenModal(false)}
                    className="btn btn-light"
                  >
                    No
                  </button>
                </div>
              </div>
            </>
          }
        />
      </header>
    </>
  );
};

export default PreLoginMenu;
