import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  verifyForgotPasswordOtp,
  resetPassword,
  showSignInState,
  showforgotpasswordState,
  storeCurrentAuthData,
  changeScreen,
} from "../store/slices/authSlice";

export const useOtp = () => {
  const [showForgotOtpResponse, setShowForgotOtpResponse] = useState({
    message: "",
    status: null,
  });
  const [verifyOtpStatus, setVerifyOtpStatus] = useState(false);
  const [code, setCode] = useState("");
  const [resetStatus, setResetStatus] = useState("");

  const { currentAuthData } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const verifyForgotOtp = async (code) => {
    setShowForgotOtpResponse({
      message: "",
      status: null,
    });
    setCode(code);
    if (code.length == 4) {
      let params = {
        otp: code,
        user_id: currentAuthData.user_id,
        phone_number: currentAuthData.phone_number,
        token:currentAuthData.token
      };
      const resultAction = await dispatch(verifyForgotPasswordOtp(params));
      if (verifyForgotPasswordOtp.fulfilled.match(resultAction)) {
        params={
          ...params,
          email:resultAction?.payload?.data.result.mail
        }
        dispatch(storeCurrentAuthData(params));
        setVerifyOtpStatus(true);
        setShowForgotOtpResponse(resultAction?.payload?.data);
      } else {
        setVerifyOtpStatus(false);
        setShowForgotOtpResponse({
          message: resultAction?.payload?.message,
          status: false,
        });
      }
    } else {
      console.log("check otp inputs....");
    }
  };

  const resetPasswordHandler = async (data) => {
    const params = {
      password: data,
      password_confirmation: data,
      id: currentAuthData.user_id,
      email:currentAuthData.email
    };
    const resultAction = await dispatch(resetPassword(params));
    if (resetPassword.fulfilled.match(resultAction)) {
      setResetStatus(resultAction?.payload?.data?.message);
      setTimeout(() => {
        setResetStatus("");
        dispatch(changeScreen("login"));
      }, 1000);
    } else {
      console.log(resultAction?.payload?.data);
    }
  };

  return {
    verifyForgotOtp,
    code,
    setShowForgotOtpResponse,
    showForgotOtpResponse,
    resetPasswordHandler,
    resetStatus,
  };
};
