import { useEffect, useState } from "react";
import Assets from "../../components/Layouts/Assets";
const FATHER = `father`;
const MOTHER = `mother`;
const YOU = `user`;
const PARTNER = `partner`;
const FEMALE = 2;
const MALE = 1;
const AVATAR = Assets.AVATAR;
const CHILDREN = "children";
const AVATAR_01 = Assets.AVATAR_01;
const AVATAR_02 = Assets.AVATAR_02;
const AVATAR_03 = Assets.AVATAR_03;

//dummy data for update nods
const popupData = [
  {
    id: 1,
    data: [
      {
        id: 1,
        connectionWithNode: FATHER,
        connectionName: "Father",
        connection: FATHER,
        img: AVATAR,
      },
      {
        id: 2,
        connectionWithNode: "brother",
        connectionName: "Brother",
        connection: "sibling",
        img: AVATAR_01,
      },
      {
        id: 3,
        connectionWithNode: "spouse",
        connectionName: "Spouse",
        connection: "partner",
        img: AVATAR_02,
      },
    ],
    user: false,
  },
  {
    id: 2,
    data: [
      {
        id: 4,
        connectionWithNode: YOU,
        connectionName: YOU,
      },
    ],
    user: true,
  },
  {
    id: 3,
    data: [
      {
        id: 5,
        connectionWithNode: "mother",
        connectionName: "Mother",
        connection: "mother",
        img: AVATAR_02,
      },
      {
        id: 6,
        connectionWithNode: "sister",
        connectionName: "Sister",
        connection: "sibling",
        img: AVATAR_02,
      },
      {
        id: 7,
        connectionWithNode: "child",
        connectionName: "Child",
        connection: CHILDREN,
        img: AVATAR_01,
      },
    ],
    user: false,
  },
  {
    id: 4,
    data: [
      {
        id: 1,
        connectionWithNode: "pet",
        connectionName: "Pet",
        connection: "pet",
        img: AVATAR_03,
      },
    ],
    user: false,
  },
];

const UseFamilyPopup = (props) => {
  const { checkActive, data, active, checkDrawerOpen, setDrawerData } = props;
  const [open, setOpen] = useState(false);
  const currentUserData = data[0]?.userData[0];
  const [selectParent, setSelectParent] = useState(false);

  useEffect(() => {
    active &&
      !open &&
      setTimeout(() => {
        setOpen(true);
      }, 100);
  }, []);

  //close popup
  const handleClose = () => {
    active &&
      setTimeout(() => {
        checkActive(false);
      }, 300);
    active && setOpen(false);
  };

  //updating nwe member
  const handleNewNode = ({ connection, spouse }) => {
    // get all ids
    const getIDs = data[0]?.allData.map((item) => {
      return item.id;
    });

    // create new Id
    const newID = Math.max(...getIDs) + 1;

    // define connection with node
    const parentConnection = connection;
    //defineChild mid and fid
    const childMid =
      parentConnection === CHILDREN && spouse?.gender === FEMALE
        ? spouse?.id
        : parentConnection === CHILDREN && currentUserData?.gender === FEMALE
        ? currentUserData.id
        : "";
    const childFid =
      parentConnection === CHILDREN && spouse?.gender === MALE
        ? spouse?.id
        : parentConnection === CHILDREN && currentUserData?.gender === MALE
        ? currentUserData.id
        : "";

    // define mid fid and pids
    const mid =
      parentConnection === "sibling"
        ? currentUserData.mid
        : parentConnection === "pet"
        ? currentUserData.id
        : parentConnection === CHILDREN
        ? childMid
        : "";
    const fid =
      parentConnection === "sibling"
        ? currentUserData.fid
        : parentConnection === CHILDREN
        ? childFid
        : "";
    const pids = parentConnection === "partner" ? [currentUserData.id] : [];

    // check new node is parent or not
    const checkIsParent = data[0]?.allData.filter((item, index) => {
      if (
        (item.connection === connection &&
          item.connection === MOTHER &&
          data[0]?.userData?.[0]?.mid === item.id) ||
        (item.connection === connection &&
          item.connection === FATHER &&
          data[0]?.userData?.[0]?.fid === item.id)
      ) {
        return data[0]?.allData[index];
      }
    })[0];

    const checkMotherAndFatherExist = data[0]?.allData.filter(
      (item, index) =>
        (item.connection === MOTHER &&
          data[0]?.userData?.[0]?.mid === item.id) ||
        (item.connection === FATHER && data[0]?.userData?.[0]?.fid === item.id)
    );

    const isFatherExist =
      checkMotherAndFatherExist.length < 2 &&
      checkMotherAndFatherExist[0]?.connection === FATHER;
    const isMotherExist =
      checkMotherAndFatherExist.length < 2 &&
      checkMotherAndFatherExist[0]?.connection === MOTHER;
    const checkConnectionParent =
      connection === FATHER || connection === MOTHER;

    // store new data with new Id
    setDrawerData([
      {
        id: checkIsParent ? checkIsParent.id : newID,
        mid: mid,
        fid: fid,
        pids: checkIsParent ? checkIsParent?.pids : pids,
        name: checkIsParent ? checkIsParent.name : "",
        family_name: checkIsParent ? checkIsParent?.family_name : "",
        gender: checkIsParent ? checkIsParent?.gender : "",
        dob: checkIsParent ? checkIsParent?.dob : "",
        bio: checkIsParent ? checkIsParent?.bio : "",
        img: checkIsParent ? checkIsParent?.img : "",
        connection: checkIsParent
          ? checkIsParent?.connection
          : parentConnection,
        newParentId: !checkConnectionParent
          ? [{ newParent: newID }]
          : checkConnectionParent && checkMotherAndFatherExist.length < 2
          ? [
              {
                connection: !isFatherExist
                  ? FATHER
                  : !isMotherExist
                  ? MOTHER
                  : "",
                motherId: isMotherExist
                  ? checkMotherAndFatherExist[0].id
                  : connection === MOTHER
                  ? newID
                  : null,
                fatherId: isFatherExist
                  ? checkMotherAndFatherExist[0].id
                  : connection === FATHER
                  ? newID
                  : null,
                currentId: currentUserData.id,
              },
            ]
          : null,
        newPartnerId:
          connection === PARTNER
            ? [
                {
                  partnerId: newID,
                  currentId: currentUserData.id,
                },
              ]
            : null,
      },
    ]);
    checkDrawerOpen(true);
    handleClose();
  };

  return {
    handleClose,
    popupData,
    data,
    currentUserData,
    handleNewNode,
    setSelectParent,
    selectParent,
    open,
  };
};

export default UseFamilyPopup;
