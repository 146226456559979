import React, { useEffect } from "react";
import Style from "./ForgotPasswordModal.module.scss";
import { useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useLogin } from "../../../Logic/useLogin";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const VerifyEmail = ({ status, onError, forgotPasswordHandler }) => {
  const [initialData, setInitialData] = useState({
    mail: "",
  });
  const { currentAuthData } = useSelector((state) => state.auth);
  const { i18n, t } = useTranslation(["authentication"]);


  useEffect(() => {
    if (currentAuthData?.mail) {
      setInitialData({
        mail: currentAuthData?.mail,
      });
    }
  }, [currentAuthData]);

  const validationSchema = Yup.object().shape({
    mail: Yup.string().email("Invalid email format").required("Required"),
  });
  return (
    <Formik
      initialValues={initialData}
      onSubmit={(values, { setErrors, setSubmitting, resetForm }) => {
        forgotPasswordHandler(
          values
        );
      }}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      {({
        errors,
        touched,
        values,
        handleBlur,
        handleSubmit,
        setFieldValue,
        dirty
      }) => {
        let hasErrors = Object.keys(errors).length == 0
        return (
          <>
            <div
              className={`${Style.email_with_change} ${
                errors.mail && touched.email ? "form-group error" : "form-group"
              }`}
            >
              <label htmlFor="fp_input_email">{`${t("register_email")} *`}</label>

              <div className="input-holder">
                <Field
                  type="email"
                  name="mail"
                  id="mail"
                  className="form-control"
                  placeholder="Enter your mail address"
                />
              </div>
              <ErrorMessage name="mail" className="field_error_message">
                {(msg) => (
                  <div
                    style={{
                      color: "#FF6261",
                      fontSize: "13px",
                      marginTop: "5px",
                    }}
                    className="error-msg"
                  >
                    {msg}
                  </div>
                )}
              </ErrorMessage>
            </div>

            <div
              disabled={!hasErrors||!dirty}
              onClick={handleSubmit}
              className={`${Style.signin_button} ${"btn btn-secondary w-100"} ${
                status == "loading" ? Style.otp_continue_btn_loader : ""
              }`}
            >
              {t("sign_in_btn")}
            </div>
            <div className={Style.show_otp_msg_response}>
              {onError ? (<>
                <span className={Style.errorMessage}>
                <p>{onError}</p>
                </span>
              </>):(<></>)}
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default VerifyEmail;
