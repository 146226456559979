import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Home from "../../components/Home";
import PreLogin from "../../components/Layouts/PreLogin";

const HomePage = (darkModeIcon, setDarkModeIcon) => {
  const location = useLocation();

  useEffect(() => {
    (location.pathname === "/") && document.body.classList.add('small-header');
    return () => {
      document.body.classList.remove('small-header');
    }
  }, [location.pathname])
  
  
  return (
    <PreLogin>
      <Home />
    </PreLogin>
  );
};

export default HomePage;
