import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../api";

const initialState = {
  status: "idle",
  newMemberStatus: "idle",
  treeData: [],
};

export const getFamilyTreeData = createAsyncThunk(
  "familyTree/getFamilyTreeData",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("/show-family", params);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getFamilyDetails = createAsyncThunk(
  "familyTree/getFamilyDetails",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("/show-family", params);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addMemberToTree = createAsyncThunk(
  "familyTree/addMemberToTree",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    const data = { ...params, gender: parseInt(params.gender) };

    try {
      const response = await api.post(
        "/create-profile",
        params.gender ? data : params
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const removeMemberFromTree = createAsyncThunk(
  "familyTree/removeMemberFromTree",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/delete-member", params);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateProfilePicture = createAsyncThunk(
  "familyTree/updateProfilePicture",
  async (params, { rejectWithValue }) => {
    const data = { ...params, image: params.img };
    delete data.img;
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/create-profile-pic", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const familyTreeSlice = createSlice({
  name: "familyTree",
  initialState,
  extraReducers: {
    [getFamilyTreeData.pending]: (state) => {
      state.status = "loading";
    },
    [getFamilyTreeData.fulfilled]: (state, action) => {
      console.log(action.payload?.data, "ress tree...");
      state.status = "success";
      state.treeData = action.payload?.data?.result;
    },
    [getFamilyTreeData.rejected]: (state, action) => {
      state.status = "error";
    },
    [addMemberToTree.pending]: (state, action) => {
      state.newMemberStatus = "loading";
    },
    [addMemberToTree.fulfilled]: (state, action) => {
      state.newMemberStatus = "success";
    },
    [addMemberToTree.fulfilled]: (state, action) => {
      state.newMemberStatus = "error";
    },
  },
});

export default familyTreeSlice.reducer;
