import { combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authSlice from "./slices/authSlice";
import gallerySlice from "./slices/gallerySlice";
import HomeSlice from "./slices/HomeSlice";
import familyTreeSlice from "./slices/familyTreeSlice";
import userprofileSlice from "./slices/userprofileSlice";
import userPreferenceSlice from "./slices/preferenceSlice";
import albumSlice from "./slices/albumSlice";
import userImageEditor from "./slices/imageEditSlice";
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["loginData", "registerData"],
};

const authSliceSection = persistReducer(persistConfig, authSlice);

const rootReducers = combineReducers({
  gallery: gallerySlice,
  home: HomeSlice,
  auth: authSliceSection,
  familyTree: familyTreeSlice,
  userprofile:userprofileSlice,
  userpreference:userPreferenceSlice,
  album: albumSlice,
  image_editor:userImageEditor
});

export default rootReducers;
