import React, { useState, useRef } from "react";
import ReactDOM from "react-dom";
import Style from "./AlbumFullView.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import {
  FreeMode,
  Navigation,
  Thumbs,
  Pagination,
  EffectCoverflow,
  EffectFade,
} from "swiper";
import Icons from "../../Layouts/Icons";
import AlbumFullViewImg from "../AlbumFullViewImg";
import { Dropdown } from "react-bootstrap";
import { useDimensions } from "../../../Logic/Dimensions";
import { Link } from "react-router-dom";

const AlbumFullView = ({ setShowFullView, showFullView, data }) => {
  const [fullViewThumbs, setFullViewThumbs] = useState(null);
  const [fullView, setFullView] = useState(null);
  const [fullViewThumbIndex, setFullViewThumbIndex] = useState(0);
  const windowDimensions = useDimensions();

  const handleClick = (e) => {
    const currentDataValue = e?.target?.getAttribute("data-index");
    fullViewThumbs.slideTo(currentDataValue);
    fullView.slideTo(currentDataValue);
  };

  return ReactDOM.createPortal(
    <>
      <div className={Style.album_full_view}>
        <div className={Style.album_full_view_header}>
          <div className={Style.album_full_view_header_leftnav}>
            {windowDimensions.width < 992 ? (
              <button
                className={Style.album_full_view_header_btn}
                onClick={() => setShowFullView(!showFullView)}
              >
                <Icons icon={"back-arrow"} size={14} />
              </button>
            ) : (
              <>{/* Back to album */}</>
            )}
          </div>
          <div className={Style.album_full_view_header_rightnav}>
            {windowDimensions.width < 992 ? (
              <>
                <button className={Style.album_full_view_header_btn_mob}>
                  <Link
                    to={`/dashboard/image-edit/${data[fullViewThumbIndex]?.id}`}
                  >
                    <Icons icon={"edit"} size={17} />
                  </Link>
                </button>
                <button className={Style.album_full_view_header_btn_mob}>
                  <Icons icon={"icon-download"} size={17} />
                </button>
                <button
                  className={`${Style.album_full_view_header_btn_mob} ${Style.tray_actions_btn_no_like}`}
                >
                  <Icons icon={"icon-love"} size={17} />
                </button>
                <button className={Style.album_full_view_header_btn_mob}>
                  <Icons icon={"slideshow"} size={17} />
                </button>
              </>
            ) : (
              <>
                <button
                  className={`btn btn-link ${Style.album_full_view_header_btn}`}
                >
                  <Link
                    to={`/dashboard/image-edit/${data[fullViewThumbIndex]?.id}`}
                  >
                    <Icons icon={"edit"} size={20} /> Edit
                  </Link>
                </button>
                {/* <button
                  className={`btn btn-link ${Style.album_full_view_header_btn}`}
                >
                  <Icons icon={"slideshow"} size={20} /> Slideshow
                </button> */}
              </>
            )}
            <Dropdown className={Style.album_item_options}>
              <Dropdown.Toggle className={Style.album_item_options_btn}>
                <Icons icon={"options"} size={25} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {/* <Dropdown.Item>Add to album</Dropdown.Item> */}
                {/* <Dropdown.Item>Archive</Dropdown.Item> */}
                <Dropdown.Item>Share</Dropdown.Item>
                {/* <Dropdown.Item>Delete </Dropdown.Item> */}
              </Dropdown.Menu>
            </Dropdown>
            {windowDimensions.width >= 992 ? (
              <button
                className={`btn btn-link ${Style.album_full_view_header_close}`}
                onClick={() => setShowFullView(!showFullView)}
              >
                <Icons icon={"icon-close"} size={14} />
              </button>
            ) : (
              <></>
            )}
          </div>
        </div>

        <Swiper
          initialSlide={showFullView?.index}
          keyboard={{ enabled: true }}
          onSwiper={setFullView}
          style={{
            "--swiper-navigation-color": "#fff",
            "--swiper-pagination-color": "#fff",
          }}
          effect={"fade"}
          spaceBetween={10}
          navigation={true}
          thumbs={{ swiper: fullViewThumbs }}
          modules={[Keyboard, FreeMode, Navigation, Thumbs, EffectFade]}
          className={Style.full_view_slider}
          onSlideChange={(swiperCore) => {
            const { activeIndex } = swiperCore;
            setFullViewThumbIndex(activeIndex);
          }}
        >
          {data?.map((item, index) => {
            // console.log(item?.src, "ioio");
            return (
              <SwiperSlide>
                <AlbumFullViewImg imgSrc={item?.src} />
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div className={Style.full_view_slider_thumb_wrap}>
          <Swiper
            onSwiper={setFullViewThumbs}
            spaceBetween={0}
            slidesPerView={"auto"}
            freeMode={false}
            watchSlidesProgress={true}
            modules={[
              FreeMode,
              Navigation,
              Thumbs,
              Pagination,
              EffectCoverflow,
            ]}
            className={Style.full_view_slider_thumb}
            draggable={false}
            allowTouchMove={false}
            preventClicks={true}
            centeredSlides={true}
          >
            {data?.map((item, index) => {
              return (
                <SwiperSlide
                  data-index={index}
                  className={
                    index - fullViewThumbIndex >= 6
                      ? `slide_num_next_7`
                      : fullViewThumbIndex - index >= 6
                      ? `slide_num_prev_7`
                      : index > fullViewThumbIndex
                      ? `slide_num_next_${index - fullViewThumbIndex}`
                      : `slide_num_prev_${fullViewThumbIndex - index}`
                  }
                  onClick={(e) => {
                    handleClick(e);
                  }}
                >
                  <figure>
                    <img src={item?.src?.thumbnail2} alt="" />
                  </figure>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </>,
    document.getElementById("slideshow-root")
  );
};
export default AlbumFullView;
