import React, { forwardRef, useState } from "react";
import Assets from "../Layouts/Assets";
import Icons from "../Layouts/Icons";
import Style from "./SlideshowList.module.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  addImages,
  currentSelectedAlbum,
} from "../../store/slices/gallerySlice";
import useSlideShowEdit from "../../Logic/useSlideShowEdit";


const SlideshowList = React.forwardRef(({ data, action, item, lastIndex, index ,currentSelected }, ref) => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [loadSlideShow, setLoadSlideShow] = useState(false);

    const handleClickSlideItem = (id) => {
      if (!action?.currentActions?.isSlideShowPreSelect) {
        currentSelected(id);
        navigate(`/dashboard/slideshow/edit/${id}`);
      } else {
        dispatch(addImages(id));
      }
    };

    return (
      <div
        ref={lastIndex == index ? ref : null}
        className={`${Style.slideshow_list_item} ${
          action?.currentActions?.isSlideShowPreSelect
            ? Style.preSelectAlbum
            : ""
          } ${loadSlideShow ? "slideshow-loaded" : "slideshow-loading"
        }`}
        onClick={() => handleClickSlideItem(item?.id)}
      >
        <figure className={Style.slideshow_list_item_image}>
          {action.selectedImages?.includes(item?.id) &&
          action?.currentActions?.isSlideShowPreSelect ? (
            <figure className={Style.gallery_select_tick}>
              <Icons icon={"icon-tick"} size={17} />
            </figure>
          ) : (
            <div
              className={`${
                action?.currentActions?.isSlideShowPreSelect &&
                Style.selectionItem
              }`}
            />
          )}
          {/* <img src={item?.src?.thumbnail} alt="" /> */}
          <img src={item?.src?.thumbnail} alt="" onLoad={() => setLoadSlideShow(true)}/>
          <div className={Style.slideshow_play_btn}>
            <Icons icon={"slideshow-play-btn"} size={46} />
          </div>
        </figure>
        {
          loadSlideShow ? (
          <>
            <div className={Style.slideshow_list_item_data}>
              <h4 className={Style.slideshow_name}>{item?.title}</h4>
              {/* <span className={Style.slideshow_duration}>5:19min</span> */}
            </div>
          </>
          ) : (
            <>
              <div className={Style.slideshow_list_item_data}>
                <h4 className={Style.slideshow_name}></h4>
                <span className={Style.slideshow_duration}></span>
              </div>
            </>
          )
        }
      </div>
    );
  }
);

export default SlideshowList;
