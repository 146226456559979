import React, { useEffect, useRef } from "react";
import useGallery from "../../../Logic/useGallery";
import Masonry from "react-masonry-css";
import { useSelector } from "react-redux";
import GalleryImage from "../../Gallery/ImageGallery/GalleryImage";
import Style from "./albumList.module.scss"
import Icons from "../../Layouts/Icons";
import useGalleryImage from "../../../Logic/useGalleryImage";
import useAlbum from "../../../Logic/useAlbum";

const breakpointColumnsObj = {
    default: 5,
    1599: 4,
    991: 3,
    575: 2,
    424: 1
  };
const AlbumList = ({setShowFullView , slug}) => {
  const observer = useRef();
  const imgRootRef = useRef(null);
  const imgItemRef = useRef(null);
  const {getAlbum}=useAlbum()

  const { sections, currentTab,selectedAlbum } = useSelector((state) => state.gallery);
  
  useEffect(() => {
    getAlbum(slug)
  }, [])


  return (
    <div>
      <div className={Style.albumDetails}>
        <div className={Style.backtoPage}  onClick={()=>setShowFullView(false)}>
          <>
          <Icons icon={"back-arrow"} size={16}/>
          <p>Back</p>
          </>
          </div>
          <div className={Style.info}>
            <h2>{sections[currentTab]?.list?.[selectedAlbum]?.album?.title}</h2>
            <p>{sections[currentTab]?.list?.[selectedAlbum]?.album?.description}</p>
          </div>
          </div>
        <div
      ref={imgRootRef}
      className={`${Style.album_gallery} masonry-wrap`}
      data-masonry='{"percentPosition": true }'
    >
    <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {sections[currentTab]?.list?.[selectedAlbum]?.images?.map((image, i) => {
          const lastIndex = 1;
          return (
            <div key={i} ref={lastIndex == i ? imgItemRef : null}>
              <GalleryImage
                handleOpenImageFullView={{}}
                key={image.id}
                image={image}
                i={i}
                albumData={sections[currentTab]?.list?.[selectedAlbum]}
                frame={sections[currentTab]["list"][selectedAlbum]?.album?.frames?.image}
                // moveListItem={moveListItem}
              />
            </div>
          );
        })}
      </Masonry>
     </div>
     </div>
  );
};

export default AlbumList;
