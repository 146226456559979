import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  authActions,
  getRegisterData,
  createRegisterOtp,
  verifyOtp,
  storeCurrentAuthData,
  changeScreen,
} from "../store/slices/authSlice";

export const useRegister = () => {
  const [showModal, setShowModal] = useState(false);
  const [code, setCode] = useState("");
  const [showErrors, setShowErrors] = useState("");
  const [showOtpErrors, setshowOtpErrors] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    registerOtpData,
    registerData,
    registerStatus,
    loginStatus,
    loginData,
    currentAuthData,
    isEditMode,
  } = useSelector((state) => state.auth);

  const registerHandler = async (
    data,
    { resetForm, setShowRegister, setShowOtp }
  ) => {
    // dispatch(storeCurrentAuthData(data));
    let newdata;
    if (isEditMode) {
      newdata = {
        country_dial_code: data.country_dial_code,
        mail: data.mail,
        name: data.name,
        password: data.password,
        phone_number: data.phone_number,
        id: data.user_id,
      };
    } else {
      newdata = data;
    }
    const resultAction = await dispatch(getRegisterData(newdata));
    if (getRegisterData.fulfilled.match(resultAction)) {
      dispatch(storeCurrentAuthData(resultAction.payload.data.result));
      dispatch(changeScreen("otp"));
      resetForm();
    } else {
      if (resultAction.payload.validation) {
        let errors = resultAction.payload.validation.errors;
        Object.keys(data).forEach((key, index) => {
          if (errors[key] != undefined) {
            setShowErrors(errors[key][0]);
            setTimeout(() => {
              setShowErrors("");
            }, 1000);
          }
        });
      }
    }
  };

  const onVerifyOtp = async (code, type) => {
    setshowOtpErrors("");
    setCode(code);
    if (code.length == 4) {
      const params = {
        otp: code,
        user_id: currentAuthData.user_id,
        phone_number: currentAuthData.phone_number,
      };
      const resultAction = await dispatch(verifyOtp(params));
      if (verifyOtp.fulfilled.match(resultAction)) {
        if (type == "login_otp") {
          navigate("/get-started");
        }
      } else {
        setshowOtpErrors(resultAction?.payload?.message);
      }
    } else {
      console.log("check otp inputs....");
    }
  };

  return {
    registerHandler,
    onVerifyOtp,
    registerStatus,
    registerOtpData,
    registerData,
    showModal,
    setShowModal,
    code,
    currentAuthData,
    showErrors,
    showOtpErrors,
    setshowOtpErrors,
  };
};
