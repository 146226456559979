import React, { useState, useRef, useEffect } from "react";
import Icons from "../../Layouts/Icons";
import { useDimensions } from "../../../Logic/Dimensions";
import Style from "./AlbumFullViewImg.module.scss";

const AlbumFullViewImg = ({ imgSrc }) => {
  const windowDimensions = useDimensions();
  const [currentImage, setCurrentImage] = useState(imgSrc?.large);
  const [imageLike, setImageLike] = useState(false);
  const [imageLoading, setImageLoading] = useState(true);

  const handleLoadImage = (e) => {
    setImageLoading(false);
  };

  useEffect(() => {
    if (imageLoading) {
      setCurrentImage(imgSrc?.normal);
    }
  }, [imageLoading]);

  return (
    <>
      <div className={Style.full_view_slider_img_wrap}>
        <figure className={Style.gallery_image_wrap}>
          <figure
            className={Style.slideshow_image}
            style={{
              opacity: imageLoading ? 0.5 : 1,
              transition: "opacity .35s ease-out",
            }}
          >
            <img src={currentImage} alt="" onLoad={(e) => handleLoadImage(e)} />
          </figure>

          {windowDimensions.width < 992 ? (
            <></>
          ) : (
            <>
              <div className={Style.gallery_image_tray}>
                <div className={Style.tray_details}>
                  <div className={Style.gallery_image_tray_author}>
                    Nathan Buffay
                  </div>
                  <div className={Style.gallery_image_tray_post_date}>
                    Posted: 30 Nov 2021
                  </div>
                </div>
                <div className={Style.tray_actions}>
                  <button className={Style.tray_actions_btn}>
                    <Icons icon={"icon-download"} size={14} />
                  </button>
                  <button
                    className={`${Style.tray_actions_btn} ${
                      imageLike
                        ? Style.tray_actions_btn_liked
                        : Style.tray_actions_btn_no_like
                    }`}
                    onClick={() => setImageLike(!imageLike)}
                  >
                    <Icons icon={"icon-love"} size={14} />
                  </button>
                  <button className={Style.tray_actions_btn}>
                    <Icons icon={"icon-trash"} size={14} />
                  </button>
                </div>
              </div>
            </>
          )}
        </figure>
      </div>
    </>
  );
};

export default AlbumFullViewImg;
