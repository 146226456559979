import React, { Suspense, useRef, useEffect } from "react";

import Style from "./familyTree.module.scss";
import Popup from "./Popup";
import SideDrawer from "./SideDrawer";
import DeleteMemberWarning from "./DeleteMemberWarning";
import UseFamilyTree from "../../Logic/FamilyTree/useFamilyTree";
import Icons from "../Layouts/Icons";
import { useDimensions } from "../../Logic/Dimensions";

const FamilyTreePage = () => {
  //main data
  const divRef = useRef();
  const componentRef = useRef();
  const windowDimensions = useDimensions();

  const {
    size,
    zoom,
    myData,
    setData,
    active,
    setActive,
    newData,
    setNewData,
    sideDrawerOpen,
    setSideDrawerOpen,
    drawerData,
    setDrawerData,
    setZoom,
    handlePrint,
    showDeleteMemberModal,
    setShowDeleteMemberModal,
    handleRecenter,
    firstTime,
    setFirstTime,
  } = UseFamilyTree({ divRef, componentRef });

  const propsOut = {
    data: newData,
    setNewData: setNewData,
    active: active,
    checkActive: setActive,
    checkDrawerOpen: setSideDrawerOpen,
    isDrawerOpen: sideDrawerOpen,
    dataDrawer: drawerData,
    mainData: myData,
    setMainData: setData,
    setDrawerData: setDrawerData,
    firstTime,
    setFirstTime,
  };

  window.openModal = () => {
    setShowDeleteMemberModal(true);
  };
  window.closeModal = () => {
    setShowDeleteMemberModal(false);
  };

  useEffect(() => {
    const userGender = myData?.filter((a) => a.id == 1 && a.gender === null);
    const userData = myData?.filter((a) => a.id == 1);
    if (userGender?.length !== 0) {
      setDrawerData(userData);
      setFirstTime(true);
      setSideDrawerOpen(true);
    }
  }, [myData]);

  return (
    <Suspense fallback={<div>loading...</div>}>
      {active && <Popup {...propsOut} />}
      {sideDrawerOpen && <SideDrawer {...propsOut} />}
      {showDeleteMemberModal && <DeleteMemberWarning />}
      <div ref={componentRef} className={Style.tree}>
        <div id="tree" ref={divRef}></div>
        <div className={Style.treeControls}>
          {/* <div onClick={() => handlePrint()}>Print</div>
          <div onClick={handleRecenter}>Re-center</div> */}
          <button
            onClick={() => handlePrint()}
            className="btn btn-curved btn-no-border-white"
          >
            <Icons icon={"icon-print"} size={19} />
            <span>
              Print
            </span>
          </button>
          <button
            onClick={handleRecenter}
            className="btn btn-curved btn-no-border-white"
          >
            <Icons icon={"icon-recenter"} size={19} />
            <span>
              Re-center
            </span>
          </button>
          {windowDimensions.width >= 1280 && (
            <div className={Style.btnDiv}>
              <button
                className={Style.btn_modify}
                onClick={() => zoom > 0.5 && setZoom(zoom - 0.25)}
              >
                <Icons icon={"icon-decrement"} size={15} />
              </button>
              <input
                className={Style.zoomInput}
                value={`${zoom * 100}%`}
                disabled
              />
              <button
                className={Style.btn_modify}
                onClick={() => zoom < 5 && setZoom(zoom + 0.25)}
              >
                <Icons icon={"icon-increment"} size={15} />
              </button>
            </div>
          )}
        </div>
      </div>
    </Suspense>
  );
};

export default FamilyTreePage;
