import React from 'react';
import Icons from '../Layouts/Icons';
import CommonModal from '../Modals/commonModal';
import Style from './ShareModal.module.scss';


const ShareModal = ({ openShare, setOpenShare }) => {
  return (
    <CommonModal 
        className={Style.share_modal}
        showModal={openShare}
        hide={() => setOpenShare(!openShare)}
        content={
            <>
                <h2 className={Style.share_modal_title}>Share to...</h2>
                <ul className={Style.share_modal_options}>
                    <li>
                        <button className={Style.share_modal_options_button}>
                            <Icons icon={"share-whatsapp"} size={16} />
                            <div>Share to Whatsapp</div>
                        </button>
                    </li>
                    <li>
                        <button className={Style.share_modal_options_button}>
                            <Icons icon={"share-email"} size={16} />
                            <div>Share via Email</div>
                        </button>
                    </li>
                    <li>
                        <button className={Style.share_modal_options_button}>
                            <Icons icon={"share-copy-link"} size={16} />
                            <div>Copy Link</div>
                        </button>
                    </li>
                </ul>
            </>
        }
    />
  )
}

export default ShareModal;