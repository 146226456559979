import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Style from "./country.module.scss";
import { useTranslation } from "react-i18next";

const CountryCodeInput = ({
  onNumChange,
  touched,
  errors,
  onBlur,
  values,
  placeholder,
  handleChangeHandler,
  handleChangeCode,
}) => {
  const { i18n, t } = useTranslation(["authentication"]);
  const [countryCode, setCountryCode] = useState("+91");
  const [phoneNumber, setPhoneNumber] = useState("");

  console.log(values,"code input..")

  useEffect(() => {
    setPhoneNumber(values?.phone_number);
  }, [values]);


  useEffect(() => {
    handleChangeCode(countryCode);
  }, [countryCode])
  

  // const onPhoneNumberChange = (e) => {
  //   //if (e.target.value.length < 11) {
  //     setPhoneNumber(e.target.value);
  //     onNumChange(countryCode, e.target.value);
  //  // }
  // };

  const numCode = [
    {
      code: "+91",
    },
    {
      code: "+92",
    },
    {
      code: "+93",
    },
  ];

  return (
    <div className={errors?.phone_number && touched?.phone_number ? "form-group error" : "form-group"}>
      <label htmlFor="signin_input_mobile">{`${t("register_phone")} *`}</label>
      <div className={Style.mobile_input}>
        <input
          type="number"
          name="phone_number"
          placeholder={placeholder}
          id="phone_number"
          value={phoneNumber}
          className="form-control"
          onChange={handleChangeHandler}
          onBlur={onBlur}
        />
        <div className={Style.select_wrap}>
          <Dropdown
            className={`${Style.prelogin_header_nav_language}  ${
              errors?.phone_number && touched?.phone_number
                ? Style.btn_error
                : ""
            }`}
          >
            <Dropdown.Toggle>
              <div className={Style.dropdown_label}>{countryCode}</div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {numCode.map((item) => {
                return (
                  <Dropdown.Item
                    onClick={() => {
                      setCountryCode(item.code);
                    }}
                  >
                    {item.code}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      {errors?.phone_number && touched?.phone_number ? (
        <span className="field_error_message">{errors?.phone_number}</span>
      ) : null}
    </div>
  );
};

export default CountryCodeInput;
