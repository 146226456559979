import React, { useEffect, useState } from "react";
import { Dropdown, Modal, Button } from "react-bootstrap";
import useGallery from "../../../../Logic/useGallery";
import Style from "./RightFilteredCategory.module.scss";
import Icons from "../../../Layouts/Icons";
import UploadImageModal from "./UploadImageModal";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import "swiper/css";
import CommonModal from "../../../Modals/commonModal";
import SelectedImageItem from "../SelectedImageItem";
import {
  closeFilterActions,
  closePreSelect,
  currentFilterItem,
  openPreSelect,
  openRearrange,
  clearThumbs,
  callFunction,
  deleteMultipleImages,
  deleteSlideShowItems,
  uploadModalAction,
} from "../../../../store/slices/gallerySlice";
import { useDispatch, useSelector } from "react-redux";
import useAlbum from "../../../../Logic/useAlbum";
import loader from "../../../../assets/images/loader/Spinner.svg";
import { useNavigate } from "react-router-dom";
import ShareModal from "../../../ShareModal";

const options = [
  {
    name: "descending",
    code: "desc",
  },
  {
    name: "ascending",
    code: "asc",
  },
];

const RightFilteredCategory = ({ editGallery }) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const { sections, currentTab, openUploadModal } = useSelector(
    (state) => state.gallery
  );

  const [openAlbumModal, setOpenAlbumModal] = useState(false);
  const [openActionModal, setOpenModal] = useState(false);
  const [deletePhotoModal, setDeletePhotoModal] = useState(false);
  const [show, setShow] = useState(false);
  const [text, setText] = useState("Select All");
  const [slideShowFiltertext, setSlideShowFiltertext] = useState("Select All");

  const [icon, setIcon] = useState(<Icons icon={"action-select"} size={17} />);
  const { t } = useTranslation(["dashboard"]);
  const [currentFilter, setcurrentFilter] = useState(options[0].name);
  const [selectedAlbum, setSelectedAlbum] = useState(0);

  const [openShare, setOpenShare] = useState(false);

  const {
    addNewAlbum,
    getAlbumsList,
    albumData,
    albumListStatus,
    isAlbumCreating,
    isAddtoToAlbum,
    handleEditAlbum,
    isAlbumEditing,
    handleClearAlbumState,
    multiDeleteAlbums,
  } = useAlbum();

  const {
    selectAllImageHandler,
    unSelectAllImagesHandler,
    handleFilterImages,
    saveReArrange,
    multiDelete,
    handleArchive,
  } = useGallery();

  useEffect(() => {
    if (openAlbumModal) {
      getAlbumsList();
    }
  }, [openAlbumModal]);

  const textChangeHandler = () => {
    setText((prevState) => {
      if (prevState === "Unselect all") {
        unSelectAllImagesHandler();
        return "Select all";
      } else {
        selectAllImageHandler();
        return "Unselect all";
      }
    });
    setIcon((prevState) => {
      if (prevState === <Icons icon={"action-select"} size={17} />) {
        return <Icons icon={"action-unselect"} size={17} />;
      } else {
        return <Icons icon={"action-select"} size={17} />;
      }
    });
  };

  const slideShowChangeHandler = () => {
    setSlideShowFiltertext((prevState) => {
      if (prevState === "Unselect all") {
        unSelectAllImagesHandler();
        return "Select all";
      } else {
        selectAllImageHandler();
        return "Unselect all";
      }
    });
    setIcon((prevState) => {
      if (prevState === <Icons icon={"action-select"} size={17} />) {
        return <Icons icon={"action-unselect"} size={17} />;
      } else {
        return <Icons icon={"action-select"} size={17} />;
      }
    });
  };

  const handlePreSelection = () => {
    if (
      currentTab == "slideshow" &&
      !sections?.slideshow?.currentActions?.imageGalleryOpen
    ) {
      const slideshowItemsAction = (state) => {
        state.sections.slideshow = {
          ...state.sections.slideshow,
          currentActions: { isSlideShowPreSelect: true },
        };
      };
      dispatch(callFunction(slideshowItemsAction));
    } else if (!editGallery) {
      dispatch(openPreSelect());
    }
  };

  const handleReArrange = () => {
    dispatch(openRearrange());
    dispatch(closePreSelect());
  };

  const handleCloseReArrange = () => {
    dispatch(closeFilterActions());
  };

  const handleSaveReArrangedImages = () => {
    saveReArrange();
  };

  const handleClickFilter = (data) => {
    setcurrentFilter(data.name);
    dispatch(currentFilterItem(data.code));
    handleFilterImages(data.code);
  };

  const hideSelectHandler = () => {
    dispatch(closeFilterActions());
  };

  const handleDelete = () => {
    if (currentTab == "album") {
      multiDeleteAlbums();
    } else {
      multiDelete();
    }
    setDeletePhotoModal(!deletePhotoModal);
  };

  const handleEditAlbumItem = (index, data) => {
    if (isAlbumEditing == "idle") {
      setSelectedAlbum(index);
      handleEditAlbum(data);
    }
  };

  const handleModal = () => {
    setOpenAlbumModal(!openAlbumModal);
    handleClearAlbumState();
  };

  const handleNavigateTo = () => {
    setOpenAlbumModal(!openAlbumModal);
    navigate("album");
  };

  const handleOpenShare = () => {
    setOpenShare(!openShare);
  };

  const PreSelectOptions = () => {
    return (
      <div className={Style.selection_options}>
        <button
          className={`${Style.dashboard_btn} ${"btn btn-border-grey"}`}
          type="button"
          onClick={() => textChangeHandler()}
        >
          {icon}
          {text}(
          {sections[currentTab]?.selectedImages?.length > 0
            ? sections[currentTab]?.selectedImages?.length
            : 0}
          )
        </button>
        <Dropdown className={Style.dashboard_actions}>
          <Dropdown.Toggle className={Style.dashboard_actions_btn}>
            <div className={Style.dashboard_actions_btn_label}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className={Style.dashboard_select_dropdown}>
            {currentTab == "album" ? (
              <>
                <Dropdown.Item onClick={() => handleOpenShare()}>
                  Share
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => setDeletePhotoModal(!openActionModal)}
                >
                  Delete
                </Dropdown.Item>
              </>
            ) : currentTab == "archive" ? (
              <>
                <Dropdown.Item
                  onClick={() => setOpenAlbumModal(!openAlbumModal)}
                >
                  Add to album
                </Dropdown.Item>

                <Dropdown.Item onClick={() => handleOpenShare()}>
                  Share
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleClickArchive()}>
                  {currentTab == "archive" ? "Unarchive" : "Archive"}
                </Dropdown.Item>
                {/* <Dropdown.Item
                  onClick={() => setDeletePhotoModal(!openActionModal)}
                >
                  Delete
                </Dropdown.Item> */}
              </>
            ) : (
              <>
                <Dropdown.Item
                  onClick={() => setOpenAlbumModal(!openAlbumModal)}
                >
                  Add to album
                </Dropdown.Item>
                {/* <Dropdown.Item>Slideshow</Dropdown.Item> */}
                <Dropdown.Item onClick={() => handleOpenShare()}>
                  Share
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleClickArchive()}>
                  {currentTab == "archive" ? "Unarchive" : "Archive"}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => setDeletePhotoModal(!openActionModal)}
                >
                  Delete
                </Dropdown.Item>
              </>
            )}
          </Dropdown.Menu>
        </Dropdown>
        <button
          onClick={hideSelectHandler}
          className={Style.dashboard_actions_close}
        >
          <Icons icon={"icon-close"} size={14} />
        </button>
      </div>
    );
  };

  const CommonOptions = () => {
    return (
      <>
        {currentTab !== "favorites" ? (
          <button
            className={`${Style.dashboard_btn} ${"btn btn-border-grey"}`}
            onClick={() => handlePreSelection()}
          >
            <Icons icon={"icon-square"} size={17} />
            {t("select")}
          </button>
        ) : (
          ""
        )}

        {currentTab == "all" ? (
          <button
            onClick={() => handleReArrange()}
            className={`${Style.dashboard_btn} ${"btn btn-border-grey"}`}
          >
            <Icons icon={"icon-grid"} size={17} />
            {t("re_arrange")}
          </button>
        ) : (
          ""
        )}
        <Dropdown
          className={`${Style.dashboard_dropdown} ${Style.dashboard_btn}`}
        >
          <Dropdown.Toggle
            className={`${Style.dashboard_btn} ${"btn btn-border-grey"}`}
          >
            <div className={Style.dashboard_dropdown_label}>
              {currentFilter}
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {options.map((item, i) => {
              return (
                <Dropdown.Item
                  onClick={() => {
                    handleClickFilter(item);
                  }}
                  key={i}
                >
                  {item.name}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  };

  const handleClickArchive = () => {
    handleArchive();
  };

  const AddImageSection = () => {
    return (
      <button
        className={`${Style.add_btn} ${
          Style.dashboard_btn
        } ${"btn btn-border-secondary"}`}
        onClick={() => {
          dispatch(uploadModalAction(true));
          // setOpenModal(true);
          dispatch(clearThumbs());
        }}
      >
        <Icons icon={"add"} size={12} />
        {t("add")}
      </button>
    );
  };

  const handleAddClick = () => {
    if (sections[currentTab]?.currentActions?.mode == "update") {
      let id = sections[currentTab]?.currentActions?.slideInfo;
      navigate(`/dashboard/slideshow/edit/${id}`);
    } else {
      navigate("/dashboard/slideshow/edit");
    }
  };

  const handleCancel = () => {
    const slideshowGalleryAction = (state) => {
      state.sections.slideshow = {
        ...state.sections.slideshow,
        name: "",
        currentActions: { imageGalleryOpen: false },
        selectedImages: [],
      };
    };
    dispatch(callFunction(slideshowGalleryAction));
  };

  const handleCancelSlideShowActions = () => {
    const slideshowItemsAction = (state) => {
      state.sections.slideshow = {
        ...state.sections.slideshow,
        currentActions: { isSlideShowPreSelect: false },
        selectedImages: [],
      };
    };
    dispatch(callFunction(slideshowItemsAction));
  };

  const handledeleteSlideShowItems = () => {
    let slideshow_id = sections[currentTab].selectedImages;
    dispatch(deleteMultipleImages());
    dispatch(deleteSlideShowItems({ slideshow_id }));
  };

  const SlideShowOptions = () => {
    return (
      <>
        {sections[currentTab]?.currentActions?.preselect ? (
          <button
            className={`${Style.dashboard_btn} ${"btn btn-border-grey"}`}
            type="button"
            onClick={() => textChangeHandler()}
          >
            {icon}
            {text} (
            {sections[currentTab]?.selectedImages?.length > 0
              ? sections[currentTab]?.selectedImages?.length
              : 0}
            )
          </button>
        ) : (
          ""
        )}
        <button
          onClick={() => handleCancel()}
          className={`${Style.dashboard_btn} ${"btn btn-border-grey"}`}
        >
          Cancel
        </button>
        <button
          onClick={() => handleAddClick()}
          className={`${Style.dashboard_btn} ${"btn btn-primary"}`}
        >
          Add
        </button>
      </>
    );
  };

  const SlideShowfilter = () => {
    return (
      <>
        {sections[currentTab]?.currentActions?.isSlideShowPreSelect ? (
          <button
            className={`${Style.dashboard_btn} ${"btn btn-border-grey"}`}
            type="button"
            onClick={() => slideShowChangeHandler()}
          >
            {icon}
            {slideShowFiltertext} (
            {sections[currentTab]?.selectedImages?.length > 0
              ? sections[currentTab]?.selectedImages?.length
              : 0}
            )
          </button>
        ) : (
          ""
        )}
        <Dropdown className={Style.dashboard_actions}>
          <Dropdown.Toggle className={Style.dashboard_actions_btn}>
            <div className={Style.dashboard_actions_btn_label}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className={Style.dashboard_select_dropdown}>
            <>
              <Dropdown.Item onClick={handledeleteSlideShowItems}>
                Delete
              </Dropdown.Item>
            </>
          </Dropdown.Menu>
        </Dropdown>
        <button
          onClick={handleCancelSlideShowActions}
          className={Style.dashboard_actions_close}
        >
          <Icons icon={"icon-close"} size={14} />
        </button>
      </>
    );
  };

  const RearrangeOptions = () => {
    return (
      <div>
        <button
          className={`${Style.dashboard_btn} ${
            Style.rearrange_cancel_btn
          } ${"btn btn-border-grey"}`}
          type="button"
          onClick={() => handleCloseReArrange()}
        >
          Done
        </button>
      </div>
    );
  };

  const AlbumModal = () => {
    return (
      <>
        <div className={Style.listContainer}>
          {albumData?.map((item, index) => {
            return (
              <div
                className={Style.albumList}
                onClick={() => handleEditAlbumItem(index, item)}
              >
                <SelectedImageItem data={item} />
                {selectedAlbum == index && isAlbumEditing == "loading" ? (
                  <img src={loader} className={Style.spinner}></img>
                ) : selectedAlbum == index && isAlbumEditing == "succeeded" ? (
                  <span
                    className={Style.gotoAction}
                    onClick={() => handleNavigateTo()}
                  >
                    {<Icons icon={"arrow-right2"} size={16} />}
                  </span>
                ) : (
                  ""
                )}
              </div>
            );
          })}
        </div>
        <div
          className={Style.add_to_new_album_btn_wrap}
          onClick={() => addNewAlbum()}
        >
          <button className={Style.add_to_album_btn}>
            <Icons icon={"add"} size={17} />
          </button>
          <span className={Style.new_album_label}>New Album</span>
          {isAlbumCreating == "loading" ? (
            <img src={loader} className={Style.spinner}></img>
          ) : (
            ""
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <div className={Style.right_filtered_category}>
        {sections[currentTab]?.currentActions?.rearrange ? (
          <RearrangeOptions />
        ) : sections[currentTab]?.currentActions?.preselect &&
          currentTab !== "slideshow" ? (
          <PreSelectOptions />
        ) : (
          <div className={Style.right_filtered_category_options}>
            {sections[currentTab]?.currentActions?.preselect ||
            sections?.slideshow?.currentActions?.isSlideShowPreSelect
              ? ""
              : sections[currentTab]?.data?.length > 0 && <CommonOptions />}
            {currentTab == "all" ? <AddImageSection /> : ""}
            {currentTab == "slideshow" &&
              sections?.slideshow?.currentActions?.imageGalleryOpen && (
                <SlideShowOptions />
              )}
            {currentTab == "slideshow" &&
              sections?.slideshow?.currentActions?.isSlideShowPreSelect && (
                <SlideShowfilter />
              )}
          </div>
        )}
      </div>
      <CommonModal
        showModal={openAlbumModal}
        hide={() => handleModal()}
        className={Style.add_to_album}
        title={"Add to album"}
        content={<AlbumModal />}
      />

      <CommonModal
        showModal={deletePhotoModal}
        hide={() => setDeletePhotoModal(!deletePhotoModal)}
        className={Style.delete_this_photo_modal}
        title={""}
        content={
          <>
            <h3 className={Style.delete_title}>Delete this Photo</h3>
            <p className={Style.delete_content}>
              {`Are you sure you want to delete this ${
                currentTab == "album" ? "album" : "photo"
              }?`}
            </p>
            <div className={Style.delete_btn_wrap}>
              <button className="btn-danger" onClick={() => handleDelete()}>
                Continue
              </button>
              <button
                className="btn-outline-gray"
                onClick={() => setDeletePhotoModal(!deletePhotoModal)}
              >
                Cancel
              </button>
            </div>
          </>
        }
      />
      <UploadImageModal
        openActionModal={openUploadModal}
        setOpenModal={setOpenModal}
      />
      <ShareModal openShare={openShare} setOpenShare={setOpenShare} />
    </>
  );
};

export default RightFilteredCategory;
