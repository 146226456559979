import React, { useState, useLayoutEffect } from "react";
import { useDimensions } from "../../../Logic/Dimensions";
import Style from "./GalleryFilters.module.scss";
import LeftFilteredCategory from "./LeftFilteredCategory";
import RightFilteredCategory from "./RightFilteredCategory";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

const GalleryFilters = ({
  editGallery
}) => {
  const [width, height] = useWindowSize();

  return (
    <div className={Style.gallery_filters_wrap}>
      <div className={Style.gallery_filters}>
        {!editGallery ? (
          <LeftFilteredCategory />
        ) : (
          <>
            <div></div>
          </>
        )}

        {width >= 1200 ? (
          <RightFilteredCategory

          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default GalleryFilters;
