import React, { useState, useRef } from "react";
import ReactDOM from "react-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs, Autoplay } from "swiper";
import Style from "./SlideShowFullView.module.scss";
import { useSelector } from "react-redux";
import Icons from "../../Layouts/Icons";
import { useEffect } from "react";

const SlideShowFullView = ({
  effects,
  setShowSlideShow,
  showSlideShow,
  audio,
  slides,
  setActiveThumb,
  showPlayPause,
  setShowPlayPause,
  playTrack,
  trackIndex,
}) => {
  console.log(audio, "hey audio/..");

  const [currentIndex, setCurrentIndex] = useState(1);
  const totalImages = useSelector((state) => state);
  const swiperRef = useRef(null);
  const song = new Audio("");

  const [controlsVisible, setControlsVisible] = useState(true);
  const [isMute, setIsMute] = useState(false);
  
  let mouseTimer = null;
  const disableControls = () => {
    mouseTimer = null;
    setControlsVisible(false);
  }
  window.addEventListener('mousemove', () => {
    mouseTimer && window.clearTimeout(mouseTimer);
    !controlsVisible && setControlsVisible(true);
    mouseTimer = window.setTimeout(disableControls, 3000);
  });


  const slideChangeHandler = (e) => {
    setCurrentIndex(e.realIndex);
  };
  const playToggle = () => {
    setShowPlayPause(false);
    swiperRef.current.swiper.autoplay.stop();
    //audio.pause();
    playTrack(trackIndex);
  };
  const pauseToggle = () => {
    setShowPlayPause(true);
    swiperRef.current.swiper.autoplay.start();
    playTrack(trackIndex);
    // audio.play();
  };
  const slideShowHandler = () => {
    setActiveThumb(null);
    setShowSlideShow(false);
  };
  const previousHandler = () => {
    swiperRef.current.swiper.slidePrev();
    playToggle();
  };
  const nextHandler = () => {
    swiperRef.current.swiper.slideNext();
    playToggle();
  };

  const muteToggle = () => {
    isMute ? setIsMute(false) : setIsMute(true);
  }

  const handleInitialization = (e) => {
    if (showPlayPause) {
      e.autoplay.start();
    }
  };

  return ReactDOM.createPortal(
    <div className={Style.slideshow_fullscreen}>
      <Swiper
        ref={swiperRef}
        loop={true}
        slidesPerView={1}
        spaceBetween={10}
        navigation={true}
        modules={[FreeMode, Navigation, Thumbs, Autoplay]}
        className="mySwiper2"
        onSlideChange={slideChangeHandler}
        onInit={(e) => handleInitialization(e)}
        allowTouchMove={false}
        // onClick={(e) => (e.autoplay = true)}
      >
        {slides?.map((imgItem, index) => {
          return (
            <SwiperSlide
              className={currentIndex === index ? effects : "non"}
              key={imgItem.id}
            >
              <figure className={Style.slideshow_fullscreen_image}>
                <img src={imgItem?.src?.original} alt="" />
              </figure>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div className={`${Style.slideshow_fullscreen_controls} ${controlsVisible && 'controls-visible'}`}>
        <button className={Style.control_btn} onClick={previousHandler}>
          <Icons icon={"prev-arrow"} size={15} />
        </button>
        {!showPlayPause ? (
          <button className={Style.control_btn} onClick={pauseToggle}>
            <Icons icon={"slideshow-play"} size={15} />
          </button>
        ) : (
          <button className={Style.control_btn} onClick={playToggle}>
            <Icons icon={"slideshow-pause"} size={15} />
          </button>
        )}
        <button className={Style.control_btn} onClick={nextHandler}>
          <Icons icon={"next-arrow"} size={15} />
        </button>
        <button className={Style.control_btn} onClick={muteToggle}>
          <Icons icon={isMute ? "icon_mute" : "icon-volume"} size={15} />
        </button>
        <button className={Style.control_btn} onClick={slideShowHandler}>
          <Icons icon={"icon-miniscreen"} size={15} />
        </button>
      </div>
    </div>,
    document.getElementById("slideshow-root")
  );
};

export default SlideShowFullView;
