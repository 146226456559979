import React, { useState, useEffect } from "react";
import Style from "./HomeMemories.module.scss";
import { Container } from "react-bootstrap";
import Assets from "../Layouts/Assets";
import HomeMemoriesSlide from "./HomeMemoriesSlide";
import { useTranslation } from "react-i18next";

const HomeMemories = () => {
  const { t } = useTranslation();
  const [sliderWidth, setSliderWidth] = useState('');

  useEffect(()=>{
    var sliderColumns = document.querySelectorAll('.slider-column');
    let totalWidth = 0;
    sliderColumns.forEach(column => {
      var style = column.currentStyle || window.getComputedStyle(column),
      width = column.offsetWidth,
      margin = parseFloat(style.marginLeft) + parseFloat(style.marginRight),
      padding = parseFloat(style.paddingLeft) + parseFloat(style.paddingRight),
      border = parseFloat(style.borderLeftWidth) + parseFloat(style.borderRightWidth);
      let columnWidth = width + margin - padding + border;
      totalWidth += columnWidth;
    });
    setSliderWidth(totalWidth);
  },[])
  

  return (
    <div className={`${Style.home_memories} anim`}>
      <div className={Style.anim_elements}>
        <figure className={`${Style.anim_elem} ${Style.circle_blue} anim_element anim`}>
          <img src={Assets.memories_circle_blue} alt="circle_blue" />
        </figure>
        <figure className={`${Style.anim_elem} ${Style.circle_green} anim_element anim`}>
          <img src={Assets.memories_circle_green} alt="circle_green" />
        </figure>
        <figure className={`${Style.anim_elem} ${Style.donut_yellow} anim_element anim`}>
          <img src={Assets.memories_donut_yellow} alt="donut_yellow" />
        </figure>
      </div>
      <Container>
        <div
          className={`${
            Style.home_memories_title
          } ${"section_title_block center"}`}
        >
          <h2 className="anim load-anim">{t("mem_title")}</h2>
          <p className="anim load-anim">{t("mem_desc")}</p>
        </div>
      </Container>
      
      <div className={`${Style.home_memories_gallery_wrap} anim load-anim`}>
        {/* <div className={`${Style.home_memories_gallery} slider-gallery`} style={{width: sliderWidth + 'px' }}> */}
        <div className={`${Style.home_memories_gallery} slider-gallery`} style={{'--sliderWidth': sliderWidth + 'px' }}>


          {/* <div className={`${Style.home_memories_column} ${Style.memory_group} slider-column`}>
            <div className={Style.column_top}>
              <div className={Style.column_top_left}>
                <figure className={`${Style.home_memories_image} ${Style.aspect_1}`}>
                  <img src={Assets.memory_1} alt="memory_1"></img>
                </figure>
                <figure className={`${Style.home_memories_image} ${Style.aspect_2}`}>
                  <img src={Assets.memory_2} alt="memory_2"></img>
                </figure>
              </div>
              <div className={Style.column_top_right}>
                <figure className={`${Style.home_memories_image} ${Style.aspect_3}`}>
                  <img src={Assets.memory_3} alt="memory_3"></img>
                </figure>
              </div>
            </div>
            <div className={Style.column_bottom}>
              <figure className={`${Style.home_memories_image} ${Style.aspect_4}`}>
                <img src={Assets.memory_4} alt="memory_4"></img>
              </figure>
            </div>
          </div>

          <div className={`${Style.home_memories_column} slider-column`}>
            <figure className={`${Style.home_memories_image} ${Style.aspect_6}`}>
              <img src={Assets.memory_6} alt="memory_6"></img>
            </figure>
          </div>

          <div className={`${Style.home_memories_column} slider-column`}>
            <figure className={`${Style.home_memories_image} ${Style.aspect_7}`}>
              <img src={Assets.memory_7} alt="memory_7"></img>
            </figure>
            <figure className={`${Style.home_memories_image} ${Style.aspect_8}`}>
              <img src={Assets.memory_8} alt="memory_8"></img>
            </figure>
          </div>

          <div className={`${Style.home_memories_column} slider-column`}>
            <figure className={`${Style.home_memories_image} ${Style.aspect_9}`}>
              <img src={Assets.memory_9} alt="memory_9"></img>
            </figure>
          </div>

          <div className={`${Style.home_memories_column} slider-column`}>
            <figure className={`${Style.home_memories_image} ${Style.aspect_8}`}>
              <img src={Assets.memory_10} alt="memory_10"></img>
            </figure>
            <figure className={`${Style.home_memories_image} ${Style.aspect_7}`}>
              <img src={Assets.memory_11} alt="memory_11"></img>
            </figure>
          </div>

          <div className={`${Style.home_memories_column} slider-column`}>
            <figure className={`${Style.home_memories_image} ${Style.aspect_6}`}>
              <img src={Assets.memory_6} alt="memory_6"></img>
            </figure>
          </div>

          <div className={`${Style.home_memories_column} ${Style.memory_group} slider-column`}>
            <div className={Style.column_top}>
              <div className={Style.column_top_left}>
                <figure className={`${Style.home_memories_image} ${Style.aspect_1}`}>
                  <img src={Assets.memory_1} alt="memory_1"></img>
                </figure>
                <figure className={`${Style.home_memories_image} ${Style.aspect_2}`}>
                  <img src={Assets.memory_2} alt="memory_2"></img>
                </figure>
              </div>
              <div className={Style.column_top_right}>
                <figure className={`${Style.home_memories_image} ${Style.aspect_3}`}>
                  <img src={Assets.memory_3} alt="memory_3"></img>
                </figure>
              </div>
            </div>
            <div className={Style.column_bottom}>
              <figure className={`${Style.home_memories_image} ${Style.aspect_4}`}>
                <img src={Assets.memory_4} alt="memory_4"></img>
              </figure>
            </div>
          </div>

          <div className={`${Style.home_memories_column} slider-column`}>
            <figure className={`${Style.home_memories_image} ${Style.aspect_6}`}>
              <img src={Assets.memory_6} alt="memory_6"></img>
            </figure>
          </div>

          <div className={`${Style.home_memories_column} slider-column`}>
            <figure className={`${Style.home_memories_image} ${Style.aspect_7}`}>
              <img src={Assets.memory_7} alt="memory_7"></img>
            </figure>
            <figure className={`${Style.home_memories_image} ${Style.aspect_8}`}>
              <img src={Assets.memory_8} alt="memory_8"></img>
            </figure>
          </div>

          <div className={`${Style.home_memories_column} slider-column`}>
            <figure className={`${Style.home_memories_image} ${Style.aspect_9}`}>
              <img src={Assets.memory_9} alt="memory_9"></img>
            </figure>
          </div>

          <div className={`${Style.home_memories_column} slider-column`}>
            <figure className={`${Style.home_memories_image} ${Style.aspect_8}`}>
              <img src={Assets.memory_10} alt="memory_10"></img>
            </figure>
            <figure className={`${Style.home_memories_image} ${Style.aspect_7}`}>
              <img src={Assets.memory_11} alt="memory_11"></img>
            </figure>
          </div>

          <div className={`${Style.home_memories_column} slider-column`}>
            <figure className={`${Style.home_memories_image} ${Style.aspect_6}`}>
              <img src={Assets.memory_6} alt="memory_6"></img>
            </figure>
          </div> */}

          {/* <div className={`${Style.home_memories_column} ${Style.memory_group} slider-column`}>
            <div className={Style.column_top}>
              <div className={Style.column_top_left}>
                <figure className={`${Style.home_memories_image} ${Style.aspect_1}`}>
                  <img src={Assets.memory_1} alt="memory_1"></img>
                </figure>
                <figure className={`${Style.home_memories_image} ${Style.aspect_2}`}>
                  <img src={Assets.memory_2} alt="memory_2"></img>
                </figure>
              </div>
              <div className={Style.column_top_right}>
                <figure className={`${Style.home_memories_image} ${Style.aspect_3}`}>
                  <img src={Assets.memory_3} alt="memory_3"></img>
                </figure>
              </div>
            </div>
            <div className={Style.column_bottom}>
              <figure className={`${Style.home_memories_image} ${Style.aspect_4}`}>
                <img src={Assets.memory_4} alt="memory_4"></img>
              </figure>
            </div>
          </div> */}

          <HomeMemoriesSlide />
          <HomeMemoriesSlide />
          <HomeMemoriesSlide />
          <HomeMemoriesSlide />
          






          
        </div>
      








      </div>





      
      
    </div>
  );
};

export default HomeMemories;
