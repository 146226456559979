import { useEffect, useState } from "react";
import {
  addMemberToTree,
  removeMemberFromTree,
  updateProfilePicture,
} from "../../store/slices/familyTreeSlice";
import { useSelector, useDispatch } from "react-redux";
import { updateUserProfile } from "../../store/slices/userprofileSlice";

const UseFamilyDrawer = (props) => {
  const {
    checkDrawerOpen,
    dataDrawer,
    mainData,
    setMainData,
    firstTime,
    setFirstTime,
  } = props;
  const [data, setData] = useState({
    id: "",
    mid: "",
    fid: "",
    pids: "",
    name: "",
    family_name: "",
    gender: "",
    dob: "",
    bio: "",
    img: "",
    connection: "",
    user_id: "",
    family_member_id: "",
  });
  const [formLoading, setFormLoading] = useState(false);
  const userID = useSelector((state) => state.auth.registerData?.user_id);

  const dispatch = useDispatch();

  //check id Exist Or Not
  const idExist = mainData
    ?.map((item) => {
      return item.id === dataDrawer[0].id;
    })
    .includes(true);

  useEffect(() => {
    setData({
      id: dataDrawer[0].id ? dataDrawer[0].id : "",
      mid: dataDrawer[0].mid ? dataDrawer[0].mid : "",
      fid: dataDrawer[0].fid ? dataDrawer[0].fid : "",
      pids: dataDrawer[0].pids?.length > 0 ? dataDrawer[0].pids : [],
      name: dataDrawer[0].name ? dataDrawer[0].name : "",
      family_name: dataDrawer[0].family_name ? dataDrawer[0].family_name : "",
      gender: dataDrawer[0].gender ? dataDrawer[0].gender : "",
      dob: dataDrawer[0].dob ? dataDrawer[0].dob : "",
      bio: dataDrawer[0].bio ? dataDrawer[0].bio : "",
      img: dataDrawer[0].img ? dataDrawer[0].img : "",
      connection: dataDrawer[0].connection ? dataDrawer[0].connection : "",
      family_member_id: dataDrawer[0].family_member_id
        ? dataDrawer[0].family_member_id
        : "",
      user_id: userID,
    });
  }, [dataDrawer]);

  useEffect(() => {
    if (
      typeof data?.img !== "string" &&
      data?.img !==
        "https://cdn.pixabay.com/photo/2018/11/13/21/43/avatar-3814049_960_720.png"
    ) {
      setFormLoading(true);
      dispatch(updateProfilePicture({ img: data.img }))
        .unwrap()
        .then((res) => {
          console.log(res.data.data, "ress profile...");
          setFormLoading(false);
          setData((state) => {
            return {
              ...state,
              img: res?.data?.result?.img,
              large_thumbnail: res?.data?.result?.large_thumbnail,
              medium_thumbnail: res?.data?.result?.medium_thumbnail,
              thumbnail: res?.data?.result?.thumbnail,
              image: res?.data?.result?.image,
            };
          });
        });
    }
  }, [data.img]);

  useEffect(() => {
    const tempData = mainData;
    tempData.map((a, i) => {
      if (!Object.keys(a).includes("user_id")) {
        tempData[i] = { ...tempData[i], user_id: userID };
      }
    });
  }, [mainData]);

  //close drawer
  const handleClose = () => checkDrawerOpen(false);

  //handle Submit
  const handleSubmit = (e) => {
    if (!formLoading) {
      if (idExist) {
        console.log(idExist, data, "tree form...1");
        const newData = mainData;
        newData.filter((item, index) => {
          if (item.id === data.id) {
            newData[index] = data;
          }
        });
        console.log(newData, "kkk tre,,,");
        setMainData(newData);
        checkDrawerOpen(false);
        dispatch(addMemberToTree(data))
          .unwrap()
          .then((r) => {
            let tempData = mainData;
            tempData.family_member_id = r?.data?.result?.family_member_id;
            tempData.img = r?.data?.result?.medium_thumbnail;
            setMainData(tempData);
            if (data.connection == "user") {
              dispatch(updateUserProfile(r?.data?.result));
            }
          });
      } else {
        if (dataDrawer[0]?.newParentId[0].currentId) {
          const updateData = mainData;
          updateData.filter((item, index) => {
            if (item.id === dataDrawer[0]?.newParentId[0]?.currentId) {
              updateData[index].fid = dataDrawer[0]?.newParentId[0]?.fatherId;
              updateData[index].mid = dataDrawer[0]?.newParentId[0]?.motherId;
              dispatch(addMemberToTree(updateData[index]))
                .unwrap()
                .then((r) => {
                  setMainData((state) => {
                    let tempData = state;
                    tempData[index].family_member_id =
                      r?.data?.data?.family_member_id;
                    tempData[index].img = r?.data?.result?.medium_thumbnail;
                    return tempData;
                  });
                });
            }
            if (item.connection === "sibling") {
              updateData[index].fid = dataDrawer[0]?.newParentId[0]?.fatherId;
              updateData[index].mid = dataDrawer[0]?.newParentId[0]?.motherId;
              dispatch(addMemberToTree(updateData[index]))
                .unwrap()
                .then((r) => {
                  setMainData((state) => {
                    let tempData = state;
                    tempData[index].family_member_id =
                      r?.data?.result?.family_member_id;
                    tempData[index].img = r?.data?.result?.medium_thumbnail;
                    return tempData;
                  });
                });
            }
            if (item.id === dataDrawer[0]?.newParentId[0]?.fatherId) {
              updateData[index].pids = [
                dataDrawer[0]?.newParentId[0]?.motherId,
              ];
              dispatch(addMemberToTree(updateData[index]))
                .unwrap()
                .then((r) => {
                  setMainData((state) => {
                    let tempData = state;
                    tempData[index].family_member_id =
                      r?.data?.result?.family_member_id;
                    tempData[index].img = r?.data?.result?.medium_thumbnail;
                    return tempData;
                  });
                });
            }
            if (item.id === dataDrawer[0]?.newParentId[0]?.motherId) {
              updateData[index].pids = [
                dataDrawer[0]?.newParentId[0]?.fatherId,
              ];
              dispatch(addMemberToTree(updateData[index]))
                .unwrap()
                .then((r) => {
                  setMainData((state) => {
                    let tempData = state;
                    tempData[index].family_member_id =
                      r?.data?.result?.family_member_id;
                    tempData[index].img = r?.data?.result?.medium_thumbnail;
                    return tempData;
                  });
                });
            }
            if (
              item.mid === dataDrawer[0]?.newParentId[0]?.motherId &&
              item.mid !== null &&
              item.fid !== data.id
            ) {
              if (item.fid) {
                data.pids = [...data.pids, item.fid];
              }
            }
            if (
              item.fid === dataDrawer[0]?.newParentId[0]?.fatherId &&
              item.fid !== null &&
              item.mid !== data.id
            ) {
              if (item.mid) {
                data.pids = [...data.pids, item.mid];
              }
            }
          });
          dispatch(addMemberToTree(data))
            .unwrap()
            .then((r) => {
              setMainData([
                ...mainData,
                {
                  ...data,
                  img: r?.data?.result?.medium_thumbnail,
                  family_member_id: r?.data?.result?.family_member_id,
                },
              ]);
            });
          checkDrawerOpen(false);
        } else {
          if (dataDrawer[0]?.newPartnerId) {
            const updateSpouse = mainData;
            updateSpouse.filter((item, index) => {
              if (item.id === dataDrawer[0]?.newPartnerId[0]?.currentId) {
                updateSpouse[index].pids = [
                  ...updateSpouse[index].pids,
                  dataDrawer[0]?.newPartnerId[0]?.partnerId,
                ];
                dispatch(addMemberToTree(updateSpouse[index]))
                  .unwrap()
                  .then((r) => {
                    updateSpouse[index] = {
                      ...updateSpouse[index],
                      img: r?.data?.data?.medium_thumbnail,
                      family_member_id: r?.data?.result?.family_member_id,
                    };
                    setMainData([...updateSpouse, data]);
                  });
              }
            });
            dispatch(addMemberToTree(data))
              .unwrap()
              .then((r) => {
                setMainData([
                  ...mainData,
                  {
                    ...data,
                    family_member_id: r?.data?.result?.family_member_id,
                    img: r?.data?.result?.medium_thumbnail,
                  },
                ]);
              });
            checkDrawerOpen(false);
          } else {
            dispatch(addMemberToTree(data))
              .unwrap()
              .then((r) => {
                setMainData([
                  ...mainData,
                  {
                    ...data,
                    img: r?.data?.result?.medium_thumbnail,
                    family_member_id: r?.data?.result?.family_member_id,
                  },
                ]);
              });
            checkDrawerOpen(false);
          }
        }
      }
    }
  };

  let acceptedRemoval = false;
  let showModal = false;
  const acceptedRemovalFunc = () => {
    acceptedRemoval = true;
  };

  window.acceptedRemovalFunc = acceptedRemovalFunc;
  window.showModal = showModal;

  //handle remove Node
  const removePerson = (e) => {
    e?.preventDefault();
    window.closeModal();

    let newData = mainData;

    let idArray = [];

    let checkIfHasChildren = (p) => {
      let res = p.filter((item) => {
        return item?.mid == data?.id || item?.fid == data?.id;
      });
      return res;
    };

    let checkIfhavePartner = () => {
      let res = newData.filter((item) => {
        return item?.pids?.includes(data?.id);
      });
      return res;
    };

    let checkIfUserParent = checkIfHasChildren(newData)?.filter((item) => {
      return item?.connection === "user";
    });
    if (
      (checkIfHasChildren(newData).length !== 0 &&
        checkIfUserParent.length === 0) ||
      (checkIfHasChildren(newData).length !== 0 &&
        checkIfUserParent.length !== 0 &&
        checkIfhavePartner(newData).length === 0 &&
        !acceptedRemoval)
    ) {
      window.openModal();
    } else {
      if (checkIfhavePartner()?.length === 0) {
        if (checkIfUserParent.length !== 0) {
          newData.map((item) => {
            if (item.connection !== "user") {
              idArray.push(item.family_member_id);
            }
          });
          let userIndex = newData.findIndex((obj) => obj.connection === "user");
          newData[userIndex] = {
            ...newData[userIndex],
            mid: null,
            fid: null,
            pids: [],
          };
          dispatch(
            addMemberToTree({
              ...checkIfUserParent[0],
              mid: null,
              fid: null,
              pids: [],
            })
          );
        } else {
          idArray.push(data.family_member_id);
        }
      } else {
        if (
          checkIfHasChildren(newData).length !== 0 &&
          checkIfUserParent.length === 0
        ) {
          checkIfHasChildren(newData).map((child) => {
            newData.map((item) => {
              if (item.id === child.id) {
                idArray.push(item.family_member_id);
              }
            });
          });
          newData.map((item) => {
            if (item.id === data.id) {
              idArray.push(item.family_member_id);
            }
          });
        } else {
          newData.map((item) => {
            if (item.id === data.id) {
              idArray.push(item.family_member_id);
            }
          });
          if (checkIfUserParent.length !== 0) {
            let userIndex = newData.findIndex(
              (obj) => obj.connection === "user"
            );
            if (data.id === newData[userIndex].mid) {
              newData[userIndex] = { ...newData[userIndex], mid: null };
              dispatch(addMemberToTree({ ...checkIfUserParent[0], mid: null }));
            }
            if (data.id === newData[userIndex].fid) {
              newData[userIndex] = { ...newData[userIndex], fid: null };
              dispatch(addMemberToTree({ ...checkIfUserParent[0], fid: null }));
            }
            setMainData(newData);
          }
        }
        checkIfhavePartner()?.map((item) => {
          let tempData = item;
          tempData.pids.splice(tempData?.pids?.indexOf(data.id), 1);
          dispatch(addMemberToTree(tempData));
          newData[
            newData.findIndex(
              (obj) => obj.family_member_id === tempData.family_member_id
            )
          ] = tempData;
          setMainData(newData);
        });
      }

      dispatch(removeMemberFromTree({ id: idArray }))
        .unwrap()
        .then(() => {
          let tempData = newData.filter((item) => {
            return !idArray.includes(item.family_member_id);
          });
          setMainData(tempData);
          checkDrawerOpen(false);
        });
    }
  };

  window.removePerson = removePerson;

  //handle Values onChange
  const handleValue = (e) => {
    if (firstTime === true && e.target.name === "gender") {
      setFirstTime(false);
    }
    const { name, value } = e.target;
    if (name === "img") {
      setData({ ...data, [name]: e.target.files[0] });
    } else if (name === "gender") {
      setData({ ...data, [name]: parseInt(value) });
    } else if (name !== "img" && name !== "gender") {
      setData({ ...data, [name]: value });
    }
  };

  return {
    mainData,
    handleClose,
    handleSubmit,
    data,
    handleValue,
    dataDrawer,
    removePerson,
    formLoading,
    setFormLoading,
    firstTime,
    setFirstTime,
  };
};

export default UseFamilyDrawer;
