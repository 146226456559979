import React, { useEffect } from "react";
import { Dropdown, Button, Modal } from "react-bootstrap";
import Styles from "./commonModal.module.scss";

const CommonModal = ({ showModal, hide, title, content, className }) => {
  useEffect(() => {
    showModal &&
      (document.querySelector("body").style.cssText =
        "overflow: initial !important");
  }, [showModal]);
  return (
    <>
      <Modal
        backdrop="static"
        show={showModal}
        onHide={hide}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={className}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{content}</Modal.Body>
      </Modal>
    </>
  );
};

export default CommonModal;
