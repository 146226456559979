import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Style from "./Footer.module.scss";
import Assets from "../../Assets";
import Icons from "../../Icons";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className="footer-section">
      <div className={Style.footer_top}>
        <Container>
          <Row>
            <Col className={Style.logo_col}>
              <figure className={`${Style.footer_logo} anim load-anim`}>
                <Link to={"/"} title={"Family Photos"}>
                  <img src={localStorage.getItem('theme') == "light" ? Assets.main_logo : Assets.main_logo_dark} alt="Family Photos" />
                </Link>
              </figure>
            </Col>
            <Col className={Style.list_col_1}>
              <ul className={Style.footer_menu_list}>
                <h5 className={`${Style.footer_menu_list_title} anim load-anim`}>
                  {t("footer_link1")}
                </h5>
                <li className="anim load-anim">
                  <Link to={"/"} className="active">{t("footer_link2")}</Link>
                </li>
                <li className="anim load-anim">
                  <Link to={"/"}>{t("footer_link3")}</Link>
                </li>
                <li className="anim load-anim">
                  <Link to={"/"}>{t("footer_link4")}</Link>
                </li>
                <li className="anim load-anim">
                  <Link to={"/contact"}>{t("footer_link5")}</Link>
                </li>
              </ul>
            </Col>
            <Col className={Style.list_col_2}>
              <ul className={Style.footer_menu_list}>
                <h5 className={`${Style.footer_menu_list_title} anim load-anim`}>
                  {t("footer_link6")}
                </h5>
                <li className="anim load-anim">
                  <Link to={"/information-and-guidelines"}>{t("footer_link7")}</Link>
                </li>
                <li className="anim load-anim">
                  <Link to={"/privacy-policy"}>{t("footer_link8")}</Link>
                </li>
                <li className="anim load-anim">
                  <Link to={"/faq"}>{t("footer_link9")}</Link>
                </li>
              </ul>
            </Col>
            <Col className={Style.list_col_3}>
              <ul
                className={`${Style.footer_menu_list} ${Style.footer_socials}`}
              >
                <h5 className={`${Style.footer_menu_list_title} anim load-anim`}>
                  {t("footer_link10")}
                </h5>
                <li>
                  <Link to={"/"}>
                    <Icons icon={"fb"} size={17} className="anim load-anim" />
                  </Link>
                </li>
                <li>
                  <Link to={"/"}>
                    <Icons icon={"twitter"} size={17} className="anim load-anim" />
                  </Link>
                </li>
                <li>
                  <Link to={"/"}>
                    <Icons icon={"linkedin"} size={17} className="anim load-anim" />
                  </Link>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={Style.footer_bottom}>
        <Container>
          <div className={Style.footer_bottom_wrap}>
            <div className={`${Style.copyrights} anim load-anim`}>
              Copyright {new Date().getFullYear()} © familyphotosalbum.com. All rights reserved.
            </div>
            <div className={`${Style.designed_by} anim load-anim`}>
              Designed by{" "}
              <a target="_blank" href="https://webandcrafts.com/">
                Webandcrafts
              </a>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Footer;
