import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDimensions } from "../Logic/Dimensions";
import {
  authActions,
  getRegisterData,
  createRegisterOtp,
  verifyOtp,
  storeCurrentAuthData,
  logOut,
} from "../store/slices/authSlice";

import {
  getCurrentPreferenceStatus,
  updatePreferenceStatus,
  checkPassowrdForDeleteUserAccount,
  DeleteUserAccount,
  getLanguages,
} from "../store/slices/preferenceSlice";
export const usePreference = () => {
  const dispatch = useDispatch();
  const windowDimensions = useDimensions();
  let navigate = useNavigate();

  const {
    sign_me_automatically,
    auto_adjust_positions,
    enable_edit_access,
    availableLanguage,
    selected_language,
    background_theme,
    is_data_loaded_from_api,
  } = useSelector((state) => state.userpreference);

  const errorMessages = {
    1: "Please enter your password.",
    2: "Invalid Password!",
    3: "Something went wrong!",
  };
  const [deleteFormObj, setDeleteFormObj] = useState({
    password: "",
    error: false,
  });
  const [password, setPassword] = useState("");
  const [themes, setTheme] = useState(window.localStorage.getItem('theme'));
  const [checkedOne, setCheckedOne] = useState(false);
  const [checkedTwo, setCheckedTwo] = useState(false);
  const [checkedThree, setCheckedOneThree] = useState(false);
  const [showLang, setShowLang] = useState(false);
  const [languageCategory, setLanguageCategoryCategory] = useState("English");
  const [deleteAccountModal, setDeleteAccountModal] = useState(false);
  const handleLanguageClick = () => setShowLang(true);

  const handleSignMeAutomatically = () => {
    dispatch(
      updatePreferenceStatus({
        action: "sign_me_auto",
        value: !checkedOne ? 1 : 0,
      })
    )
      .unwrap()
      .then((res) => {
        const { status } = res?.data;
        if (status && status !== undefined) {
          setCheckedOne(!checkedOne);
        }
      });
  };
  const handleAutoAdjustPositions = () => {
    dispatch(
      updatePreferenceStatus({
        action: "auto_adjust",
        value: !checkedTwo ? 1 : 0,
      })
    )
      .unwrap()
      .then((res) => {
        const { status } = res?.data;
        if (status && status !== undefined) {
          setCheckedTwo(!checkedTwo);
        }
      });
  };
  const handleEnableEditAccess = () => {
    dispatch(
      updatePreferenceStatus({
        action: "enable_edit_access",
        value: !checkedThree ? 1 : 0,
      })
    )
      .unwrap()
      .then((res) => {
        const { status } = res?.data;
        if (status && status !== undefined) {
          setCheckedOneThree(!checkedThree);
        }
      });
  };

  const languageCategorySwitch = (category) => {
   
    if(languageCategory.name !=category.name){
      setLanguageCategoryCategory(category);

      dispatch(
        updatePreferenceStatus({
          action: "language_id",
          value:category.id,
        })
      );
    }

  };

  const ChangeTheme = (event) => {
    dispatch(
      updatePreferenceStatus({
        action: "background_theme_id",
        value: themes == "light" ? 1 : 2, // (1 dark mode) (2 normal mode)
      })
    )
      .unwrap()
      .then((res) => {
        // setTheme(event.target.value)
      });
  };

  const deleteActionConfirmed = () => {
    // Password Validation

    if (deleteFormObj.password === "") {
      setDeleteFormObj({ ...deleteFormObj, error: true, error_code: 1 });
      return false;
    } else {
      setDeleteFormObj({ ...deleteFormObj, error: false, error_code: "" });
    }

    // Dispatching the API call for the verification of password

    dispatch(
      checkPassowrdForDeleteUserAccount({
        password: deleteFormObj.password,
      })
    )
      .unwrap()
      .then((res) => {
        // Password matched condition & calling final delete API
        const { success } = res?.data;

        if (success === true) {
          dispatch(DeleteUserAccount())
            .unwrap()
            .then((res) => {
              // Delete api call success condition
              const { success } = res?.data;
              if (success === true) {
                dispatch(logOut());
                navigate("/");
              }
            })
            .catch((err) => {
              // Delete api call failed condition
              setDeleteFormObj((state, props) => {
                return { ...state, error: true, error_code: 3 };
              });
            });
        } else {
          // Password mismatch condition & throwing error
          setDeleteFormObj((state, props) => {
            return { ...state, error: true, error_code: 2 };
          });
        }
      })
      .catch((error) => {
        setDeleteFormObj((state, props) => {
          return { ...state, error: true, error_code: 3 };
        });
      });
  };

  useEffect(() => {
    
     if(availableLanguage.length === 0){
      dispatch(getLanguages())
        .unwrap()
        .then((res) => {
         /* const {
 
          } = res.data.result;*/

          console.log(res.data.result);

   
        });
     }
    if (!is_data_loaded_from_api) {
      dispatch(getCurrentPreferenceStatus())
        .unwrap()
        .then((res) => {
          const {
            auto_adjust,
            background,
            enable_edit_access,
            language,
            sign_me_auto,
          } = res.data.result;
          setLanguageCategoryCategory(language);
          setCheckedOne(sign_me_auto == 1 ? true : false);
          setCheckedTwo(auto_adjust == 1 ? true : false);
          setCheckedOneThree(enable_edit_access == 1 ? true : false);
        });
    } else {
      setLanguageCategoryCategory(selected_language);
      setCheckedOne(sign_me_automatically == 1 ? true : false);
      setCheckedTwo(auto_adjust_positions == 1 ? true : false);
      setCheckedOneThree(enable_edit_access == 1 ? true : false);
    }
  }, []);



  useEffect(() => {}, [password]);

  const capitalizeFirstLetter = function (str) {
    // converting first letter to uppercase
    if (str != "" && str != undefined && str != null) {
      const capitalized = str.charAt(0).toUpperCase() + str.slice(1);
      return capitalized;
    } else {
      return;
    }
  };

  return {
    deleteFormObj,
    password,
    themes,
    availableLanguage,
    languageCategory,
    checkedOne,
    checkedTwo,
    checkedThree,
    windowDimensions,
    showLang,
    errorMessages,
    setDeleteFormObj,
    setTheme,
    setPassword,
    setShowLang,
    deleteAccountModal,
    setDeleteAccountModal,
    handleLanguageClick,
    handleSignMeAutomatically,
    handleAutoAdjustPositions,
    handleEnableEditAccess,
    languageCategorySwitch,
    ChangeTheme,
    deleteActionConfirmed,
    capitalizeFirstLetter
  };
};
