import { Formik } from "formik";
import { useDimensions } from "../../../Logic/Dimensions";
import Assets from "../../Layouts/Assets";
import UseFamilyDrawer from "../../../Logic/FamilyTree/useFamilyDrawer";
import Icons from "../../Layouts/Icons";
import Style from "./SideDrawer.module.scss";
import ReactDOM from 'react-dom';
import BackHeader from "../../BackHeader";

const SideDrawer = (props) => {
  const {} = props;
  const windowDimensions = useDimensions();

  const {
    handleClose,
    handleSubmit,
    data,
    handleValue,
    dataDrawer,
    removePerson,
    formLoading,
    firstTime,
  } = UseFamilyDrawer(props);

  return ReactDOM.createPortal (
    <div className={Style.root}>
      <div className={Style.sideDrawerWrapper}>
        {windowDimensions.width >= 576 ? (
          <div className={Style.closeWrapper}>
            <button onClick={handleClose}>x</button>
          </div>
        ) : (
          <BackHeader onClick={handleClose} />
        )}

        <Formik
          initialValues={{
            name: dataDrawer[0]?.name ? dataDrawer[0]?.name : "",
            family_name: dataDrawer[0].family_name
              ? dataDrawer[0].family_name
              : "",
            gender: dataDrawer[0].gender ? dataDrawer[0].gender : "",
            dob: dataDrawer[0].dob ? dataDrawer[0].dob : "",
            bio: dataDrawer[0].bio ? dataDrawer[0].bio : "",
          }}
          validate={(values) => {
            const errors = {};
            if (!values.name) {
              errors.name = "Required";
            }
            if (!values.family_name) {
              errors.family_name = "Required";
            }
            if (!values.gender) {
              errors.gender = "Required";
            }
            if (!values.dob) {
              errors.dob = "Required";
            }
            if (!values.bio) {
              errors.bio = "Required";
            }
            if (values.bio?.length < 3) {
              errors.bio = "Bio should be more than 3 characters";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            handleSubmit();
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form className={Style.drawerWrapper} onSubmit={handleSubmit}>
              <div className={Style.drawerImage}>
                <div className={Style.drawerImageContainer}>
                  <img
                    src={
                      typeof data.img === "string"
                        ? data.img.length !== 0
                          ? data.img
                          : data.connection === "pet"
                          ? Assets.AVATAR_03
                          : "https://cdn.pixabay.com/photo/2018/11/13/21/43/avatar-3814049_960_720.png"
                        : `${URL.createObjectURL(data.img)}`
                    }
                    alt=""
                  />
                </div>
                <div className={Style.drawerImageIcon}>
                  <input
                    onChange={(e) => {
                      handleValue(e);
                      handleChange(e);
                    }}
                    id="dataDrawerProfile"
                    name="img"
                    type="file"
                  />
                  <label htmlFor="dataDrawerProfile"></label>
                </div>
              </div>

              <div className={Style.fieldWrapper}>
                <div className={Style.fieldGroup}>
                  <label>Full Name</label>
                  <input
                    type="text"
                    value={data.name}
                    name={`name`}
                    onChange={(e) => {
                      handleChange(e);
                      handleValue(e);
                    }}
                    onBlur={handleBlur}
                    placeholder="Enter full name"
                  />
                  {errors.name && touched.name && (
                    <span className={Style.error}>{errors.name}</span>
                  )}
                </div>

                <div className={Style.fieldGroup}>
                  <label>Family Name</label>
                  <input
                    type="text"
                    value={data.family_name}
                    name={`family_name`}
                    onChange={(e) => {
                      handleChange(e);
                      handleValue(e);
                    }}
                    onBlur={handleBlur}
                    placeholder="Enter family name"
                  />
                  {errors.family_name && touched.family_name && (
                    <span className={Style.error}>{errors.family_name}</span>
                  )}
                </div>

                <div className={Style.checkBoxWrapper}>
                  <h3 className={Style.title}>Gender</h3>
                  <div className={Style.checkBox_buttons}>
                    <div
                      className={`${Style.checkBoxItem} ${
                        data.gender == 1 ? Style.checked : ""
                      }`}
                    >
                      <input
                        type="radio"
                        name={`gender`}
                        value={1}
                        id="male"
                        onChange={(e) => {
                          handleChange(e);
                          handleValue(e);
                        }}
                        onBlur={handleBlur}
                      />
                      <label htmlFor="male">Male</label>
                    </div>

                    <div
                      className={`${Style.checkBoxItem} ${
                        data.gender == 2 ? Style.checked : ""
                      }`}
                    >
                      <input
                        type="radio"
                        name={`gender`}
                        value={2}
                        id="Female"
                        onChange={(e) => {
                          handleChange(e);
                          handleValue(e);
                        }}
                        onBlur={handleBlur}
                      />
                      <label htmlFor="Female">Female</label>
                    </div>

                    <div
                      className={`${Style.checkBoxItem} ${
                        data.gender == 3 ? Style.checked : ""
                      }`}
                    >
                      <input
                        type="radio"
                        name={`gender`}
                        value={3}
                        id="other"
                        onChange={(e) => {
                          handleChange(e);
                          handleValue(e);
                        }}
                        onBlur={handleBlur}
                      />
                      <label htmlFor="other">Others</label>
                    </div>
                  </div>

                  {errors.gender && touched.gender && (
                    <span className={Style.error}>{errors.gender}</span>
                  )}
                  {firstTime && <span className={Style.error}>Required</span>}
                </div>

                <div className={Style.fieldGroup}>
                  <label>Date of Birth</label>
                  <div className={Style.date_input}>
                    <input
                      type="date"
                      name={`dob`}
                      value={data.dob}
                      max={new Date().toISOString().split("T")[0]}
                      onChange={(e) => {
                        handleChange(e);
                        handleValue(e);
                      }}
                      onBlur={handleBlur}
                    />
                    <Icons icon={"icon-calender"} size={15} />
                  </div>
                  {errors.dob && touched.dob && (
                    <span className={Style.error}>{errors.dob}</span>
                  )}
                </div>

                <div className={Style.fieldGroup}>
                  <label>Bio</label>
                  <div className={Style.textarea}>
                    <textarea
                      type="text"
                      name={`bio`}
                      value={data.bio}
                      onChange={(e) => {
                        handleChange(e);
                        handleValue(e);
                      }}
                      onBlur={handleBlur}
                      placeholder="Add text..."
                    />
                  </div>
                  {errors.bio && touched.bio && (
                    <span className={Style.error}>{errors.bio}</span>
                  )}
                </div>
              </div>

              <div className={Style.actionButton}>
                <button
                  type="submit"
                  className={`${Style.btnItem} ${
                    formLoading && "btn-loader-active"
                  }`}
                >
                  {!formLoading && "Continue"}
                </button>
              </div>

              {dataDrawer[0].name && dataDrawer[0].connection !== "user" && (
                <div className={Style.deleteBtn}>
                  <button className={Style.btn} onClick={removePerson}>
                    Remove Person
                  </button>
                </div>
              )}
            </form>
          )}
        </Formik>
      </div>
    </div>,
    document.getElementById('slideshow-root')
  );
};

export default SideDrawer;
