import React, { useEffect } from "react";
// import HomeAdvertisements from "../HomeAdvertisements";
import HomeBanner from "../HomeBanner";
import HomeBrowsePhotos from "../HomeBrowsePhotos";
import HomeExternalLinks from "../HomeExternalLinks";
import HomeChooseAPlan from "../HomeChooseAPlan";
import HomeCreateAndExplore from "../HomeCreateAndExplore";
import HomeLiveStatus from "../HomeLiveStatus";
import HomeMemories from "../HomeMemories";
import HomeTestimonials from "../HomeTestimonials";
import { useLocation } from "react-router-dom";


const Home = () => {

  const location = useLocation();

  useEffect(() => {
    const allSections = document.querySelectorAll(".anim");
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const element = entry.target;
          element.classList.add("visible");
        }
      });
    });
    allSections.forEach((section) => {
      observer.observe(section);
    });
  }, [location.pathname]);

  return (
    <div className="home-page">
      <HomeBanner/>
      <HomeBrowsePhotos />
      {/* <HomeAdvertisements /> */}
      <HomeExternalLinks />
      <HomeCreateAndExplore />
      <HomeTestimonials />
      <HomeChooseAPlan />
      <HomeMemories />
      <HomeLiveStatus />
    </div>
  );
};

export default Home;
