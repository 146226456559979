import React, { useEffect, useState } from "react";
import { useDimensions } from "../../../Logic/Dimensions";
import { useLogin } from "../../../Logic/useLogin";
import { useRegister } from "../../../Logic/useRegister";
import Footer from "./Footer";
import PreLoginMenu from "./PreLoginMenu";
import PreLoginMobileMenu from "./PreLoginMobileMenu";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const PreLogin = ({ children }) => {
  const windowDimensions = useDimensions();
  const { showRegister, setShowRegister, showSignIn, setShowSignIn } =
    useRegister();
    const { registerData, registerStatus } = useSelector((state) => state.auth);
  const { showForgotPassword } = useLogin();
  const dispatch = useDispatch();

  return (
    <>
      {
        windowDimensions.width > 1280 ? (
        <PreLoginMenu
          setShowSignIn={setShowSignIn}
          setShowRegister={setShowRegister}
          showSignIn={showSignIn}
          showRegister={showRegister}
          showForgotPassword={showForgotPassword}
        />
      ) : (
        <PreLoginMobileMenu
          setShowSignIn={setShowSignIn}
          setShowRegister={setShowRegister}
          showSignIn={showSignIn}
          showRegister={showRegister}
          showForgotPassword={showForgotPassword}
        />
      )}
      {children}

      <Footer />
    </>
  );
};

export default PreLogin;
