import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../api";

const initialState = {
  frameListStatus: "idle",
  albumListStatus: "idle",
  isAlbumCreating: "idle",
  isAddtoToAlbum: "idle",
  allImageStatus: "idle",
  isAlbumEditing: "idle",
  isSlideShowSaving: "idle",
  selectedAlbum: "",
  currentTab: "All",
  sections: {},
  activeUploadIndex: 0,
  activeUploadPercent: 0,
  faileditems: [],
  slideShows: [],
  isSingleImageUpload: false,
  thumbs: [],
  musicList: [],
  frames: [],
  openUploadModal: false,
  isSlideshowFetching: "idle",
};

export const getGalleryImages = createAsyncThunk(
  "gallery/getGalleryImages",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `${params.url}?page=${params.page}&&sort=${params.slug}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateImageFunctions = createAsyncThunk(
  "gallery/updateImageFunctions",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `${params.url}?page=${params.page}&&sort=${params.slug}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const customFetchImages = createAsyncThunk(
  "gallery/customFetchImages",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `${params.url}?page=${params.page}&&sort=${params.slug}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const filterImages = createAsyncThunk(
  "gallery/filterImages",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `${params.url}?page=${params.page}&&sort=${params.slug}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getFavorites = createAsyncThunk(
  "gallery/getFavorites",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`list-favourite`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addToFavorites = createAsyncThunk(
  "gallery/addToFavorites",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`add-favourite`, { image_id: params.id });
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const removeFromFavorites = createAsyncThunk(
  "gallery/removeFromFavorites",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`remove-favourite`, {
        image_id: params.id,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const reArrange = createAsyncThunk(
  "gallery/reArrange",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`image-rearrange`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteImage = createAsyncThunk(
  "gallery/deleteImage",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`delete-images`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addtoArchive = createAsyncThunk(
  "gallery/addtoArchive",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`add-archive`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const removeArchive = createAsyncThunk(
  "gallery/removeArchive",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`remove-archive`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const frameList = createAsyncThunk(
  "gallery/frameList",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`frames-list`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const albumList = createAsyncThunk(
  "gallery/albumList",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `album-list?page=${params.page}&&sort=${params.slug}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createAlbum = createAsyncThunk(
  "gallery/createAlbum",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`create-album`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const removeAlbum = createAsyncThunk(
  "gallery/removeAlbum",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`delete-album`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editAlbum = createAsyncThunk(
  "gallery/editAlbum",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`update-album`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAlbumItem = createAsyncThunk(
  "gallery/getAlbumItem",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`album-view/${params}`);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getMusicList = createAsyncThunk(
  "gallery/getMusicList",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`music-list`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

/***************************************** SLIDESHOW  **********************************************/

export const getSlideShowList = createAsyncThunk(
  "gallery/getSlideShowList",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/list-slideshow?page=${params.page}&&sort=${params.slug}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSlideShow = createAsyncThunk(
  "gallery/getSlideShow",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/view-slideshow/${params}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveSlideShow = createAsyncThunk(
  "gallery/saveSlideShow",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`create-slideshow`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteSlideShowItems = createAsyncThunk(
  "gallery/deleteSlideShowItems",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`delete-slideshow`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const gallerySlice = createSlice({
  name: "gallery",
  initialState,
  reducers: {
    currentTabSelection: (state, action) => {
      state.currentTab = action?.payload;
    },
    uploadModalAction: (state, action) => {
      state.openUploadModal = action.payload;
    },
    reArrangeImage: (state, action) => {
      let draggedIndex = state.sections[state.currentTab].data.findIndex(
        (item) => item.id == action.payload.draggedItem.id
      );
      let changedIndex = state.sections[state.currentTab].data.findIndex(
        (item) => item.id == action.payload.changedItem.id
      );

      state.sections[state.currentTab].data[draggedIndex].position =
        action.payload.changedItem.position;
      state.sections[state.currentTab].data[changedIndex].position =
        action.payload.draggedItem?.position;

      let data1 = state.sections[state.currentTab].data[changedIndex];
      let data2 = state.sections[state.currentTab].data[draggedIndex];

      state.sections[state.currentTab].data[draggedIndex] = data1;
      state.sections[state.currentTab].data[changedIndex] = data2;
    },
    addImages: (state, action) => {
      let newData = current(state.sections[state.currentTab]);
      if (newData?.selectedImages?.includes(action.payload)) {
        //unselect the images
        let filteredIndexs = newData?.selectedImages?.filter((item, index) => {
          return item !== action.payload;
        });
        state.sections[state.currentTab]["selectedImages"] = filteredIndexs;
      } else {
        //select the images
        typeof state.sections[state.currentTab].selectedImages !== "object"
          ? (state.sections[state.currentTab]["selectedImages"] = [
              ...(state.sections[state.currentTab].selectedImages = []),
              action.payload,
            ])
          : (state.sections[state.currentTab]["selectedImages"] = [
              ...(state.sections[state.currentTab].selectedImages = [
                ...state.sections[state.currentTab].selectedImages,
              ]),
              action.payload,
            ]);
      }
    },

    selectAllImages: (state, action) => {
      state.sections[state.currentTab]["selectedImages"] = action.payload;
    },

    unSelectAllImages: (state, action) => {
      state.sections[state.currentTab]["selectedImages"] = [
        ...new Array(0).keys(),
      ];
    },

    removeAllActions: (state, action) => {
      state.sections[state.currentTab]["selectedImages"] = [];
      state.showSelect = false;
    },

    currentFilterItem: (state, action) => {
      state.sections[state.currentTab]["currentActions"] = {
        ...state.sections[state.currentTab]["currentActions"],
        filterOption: action.payload,
      };
    },

    openPreSelect: (state, action) => {
      state.sections[state.currentTab]["currentActions"] = {
        ...state.sections[state.currentTab]["currentActions"],
        preselect: true,
      };
    },

    openRearrange: (state, action) => {
      state.sections[state.currentTab]["currentActions"] = {
        ...state.sections[state.currentTab]["currentActions"],
        rearrange: true,
      };
    },
    reArrangedItems: (state, action) => {
      typeof state.sections[state.currentTab]["currentActions"]
        .rearrangedData !== "object"
        ? (state.sections[state.currentTab]["currentActions"].rearrangedData = [
            ...(state.sections[state.currentTab][
              "currentActions"
            ].rearrangedData = []),
            ...action.payload,
          ])
        : (state.sections[state.currentTab]["currentActions"].rearrangedData = [
            ...(state.sections[state.currentTab][
              "currentActions"
            ].rearrangedData = [
              ...state.sections[state.currentTab]["currentActions"]
                .rearrangedData,
            ]),
            ...action.payload,
          ]);
    },

    closePreSelect: (state, action) => {
      state.sections[state.currentTab]["currentActions"] = {
        ...state.sections[state.currentTab]["currentActions"],
        preselect: false,
      };
    },

    closeFilterActions: (state, action) => {
      state.sections[state.currentTab]["selectedImages"] = [];
      state.sections[state.currentTab]["currentActions"] = {};
    },

    favoriteActions: (state, action) => {
      const favIndex = current(state.sections[state.currentTab].data).findIndex(
        (item) => item.id === action.payload
      );
      state.sections[state.currentTab].data[favIndex] = {
        ...state.sections[state.currentTab].data[favIndex],
        favourite:
          state.sections[state.currentTab].data[favIndex].favourite == 1
            ? 0
            : 1,
      };

      //add and remove favorites state from all tab
      let keyArr = Object.keys(state.sections);
      if (state.sections[state.currentTab].data[favIndex].favourite == 1) {
        if (keyArr.includes("favorites")) {
          state.sections["favorites"]["data"] = [
            ...state.sections.favorites.data,
            state.sections[state.currentTab].data[favIndex],
          ];
        } else {
          state.sections["favorites"] = {
            data: [state.sections[state.currentTab].data[favIndex]],
          };
        }
      } else {
        let alreadyFavoritesIndex = current(
          state.sections["favorites"]["data"]
        ).findIndex((item) => item.id === action.payload);
        let favArry = Object.keys(state.sections["favorites"]["data"]);
        if (favArry?.includes(alreadyFavoritesIndex.toString())) {
          state.sections["favorites"]["data"] = state.sections["favorites"][
            "data"
          ].filter((item) => item.id !== action.payload);
        }
      }

      //remove favorite item from favorite tab
      if (state.currentTab == "favorites") {
        state.sections[state.currentTab].data = state.sections[
          state.currentTab
        ].data.filter((item) => item.id !== action.payload);
      }
    },

    //image uploader section
    addToThumbs: (state, action) => {
      state.thumbs = [...state.thumbs, action.payload];
    },

    clearThumbs: (state, action) => {
      state.thumbs = [];
      state.activeUploadIndex = 0;
      state.activeUploadPercent = 0;
    },

    updateActiveUploadPercentage: (state, action) => {
      state.activeUploadPercent = action.payload;
    },

    // updateUploadedImage: (state, action) => {
    //   state.sections[state.currentTab].data[action.payload.index] =
    //     action.payload.data;
    // },

    updateActiveIndex: (state, action) => {
      state.activeUploadIndex = action.payload;
    },

    getFailedItems: (state, action) => {
      state.faileditems = [...state.faileditems, action.payload];
    },

    singleImageUpload: (state, action) => {
      state.isSingleImageUpload = action.payload;
    },

    // archive

    //instant delete from state
    deleteImageAction: (state, action) => {
      state.sections[state.currentTab].data = state.sections[
        state.currentTab
      ].data.filter((item) => item.id !== action.payload);
    },

    deleteMultipleImages: (state, action) => {
      let selectedItem = current(
        state.sections[state.currentTab].selectedImages
      );

      state.sections[state.currentTab] = {
        ...state.sections[state.currentTab],
        data: state.sections[state.currentTab].data.filter(
          (item, index) => !selectedItem.includes(item.id)
        ),
        selectedImages: [],
      };
    },

    //remove single image from archive list
    removeArchiveFunction: (state, action) => {
      state.sections[state.currentTab].data = state.sections[
        state.currentTab
      ].data.filter((item) => item.id !== action.payload);
    },

    archiveActions: (state, action) => {
      let selectedItem = current(
        state.sections[state.currentTab].selectedImages
      );
      let archivedArry = current(state.sections[state.currentTab].data).filter(
        (item, index) => selectedItem.includes(item.id)
      );
      let keyArr = Object.keys(state.sections);
      if (state.currentTab == "all") {
        //add to archive...
        if (keyArr.includes("archive")) {
          state.sections["archive"]["data"] = [
            ...state.sections.archive.data,
            ...archivedArry,
          ];
        } else {
          state.sections["archive"] = { data: [...archivedArry] };
        }
      }
      //remove from archive list
      else {
        state.sections[state.currentTab].data = state.sections[
          state.currentTab
        ].data.filter((item, index) => !selectedItem.includes(item.id));
      }
    },

    handleremoveFailedItem: (state, action) => {
      state.faileditems = state.faileditems.filter(
        (item) => item !== action.payload
      );
      state.sections[state.currentTab].data = state.sections[
        state.currentTab
      ].data.filter((item, index) => index !== action.payload);
    },

    //album actions

    currentSelectedAlbum: (state, action) => {
      state.selectedAlbum = action.payload;
    },

    removeAlbumFromState: (state, action) => {
      state.sections[state.currentTab].data = state.sections[
        state.currentTab
      ].data.filter((item, index) => item.id !== action.payload);
    },

    clearAlbumState: (state, action) => {
      state.albumListStatus = "idle";
      state.isAlbumCreating = "idle";
      state.isAddtoToAlbum = "idle";
      state.isAlbumEditing = "idle";
    },
    /*********************************** SLIDE SHOW *******************************/

    editCurrentSlideShow: (state, action) => {
      state.sections[state.currentTab] = {
        ...state.sections[state.currentTab],
        name: action.payload,
      };
    },

    deleteImageFromSlideshow: (state, action) => {
      console.log(action.payload, "at action,,,");

      // state.sections.slideshow = {
      // };
    },

    callFunction: (state, action) => {
      action.payload(state, action);
    },
    /*********************************** SLIDE SHOW *******************************/
  },
  extraReducers: {
    [frameList.pending]: (state, action) => {
      state.frameListStatus = "loading";
      state.message = null;
    },
    [frameList.fulfilled]: (state, action) => {
      state.frameListStatus = "succeeded";
      state.frames = action.payload.data.result;
    },
    [frameList.rejected]: (state, action) => {
      state.frameListStatus = "failed";
    },

    [getGalleryImages.pending]: (state, action) => {
      state.allImageStatus = "loading";
      state.message = null;
    },
    [getGalleryImages.fulfilled]: (state, action) => {
      state.allImageStatus = "succeeded";
      if (state.currentTab == "slideshow") {
        state.sections["all"] = {
          ...state.sections["all"],
          ...action?.payload?.data?.result,
        };
      } else {
        state.sections[state.currentTab] = {
          ...state.sections[state.currentTab],
          ...action?.payload?.data?.result,
        };
      }
    },
    [getGalleryImages.rejected]: (state, action) => {
      state.allImageStatus = "failed";
    },

    [updateImageFunctions.pending]: (state, action) => {
      state.allImageStatus = "loading";
      state.message = null;
    },
    [updateImageFunctions.fulfilled]: (state, action) => {
      state.allImageStatus = "succeeded";
      state.sections["favorites"] = action?.payload?.data?.result;
    },
    [updateImageFunctions.rejected]: (state, action) => {
      state.allImageStatus = "failed";
    },

    [customFetchImages.pending]: (state, action) => {
      state.allImageStatus = "loading";
      state.message = null;
    },
    [customFetchImages.fulfilled]: (state, action) => {
      state.allImageStatus = "succeeded";
      state.sections[state.currentTab].data = [
        ...state.sections[state.currentTab].data,
        ...action?.payload?.data?.result.data,
      ];
      state.sections[state.currentTab] = {
        ...state.sections[state.currentTab],
        current_page: action?.payload?.data?.result?.current_page,
      };
    },
    [customFetchImages.rejected]: (state, action) => {
      state.allImageStatus = "failed";
    },

    [filterImages.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [filterImages.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.sections[state.currentTab].data =
        action?.payload?.data?.result?.data;
    },
    [filterImages.rejected]: (state, action) => {
      state.status = "failed";
    },

    [addtoArchive.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [addtoArchive.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.sections[state.currentTab]["currentActions"] = {
        ...state.sections[state.currentTab]["currentActions"],
        preselect: false,
      };
      state.sections[state.currentTab]["selectedImages"] = [];
    },
    [addtoArchive.rejected]: (state, action) => {
      state.status = "failed";
    },

    [removeArchive.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [removeArchive.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.sections[state.currentTab]["currentActions"] = {
        ...state.sections[state.currentTab]["currentActions"],
        preselect: false,
      };
    },
    [removeArchive.rejected]: (state, action) => {
      state.status = "failed";
    },

    [reArrange.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [reArrange.fulfilled]: (state, action) => {
      state.status = "succeeded";
      // state.sections[state.currentTab]["currentActions"] = {
      //   ...state.sections[state.currentTab]["currentActions"],
      //   rearrange: false,
      // }
    },
    [reArrange.rejected]: (state, action) => {
      state.status = "failed";
    },

    //album

    [albumList.pending]: (state, action) => {
      state.albumListStatus = "loading";
      state.message = null;
    },
    [albumList.fulfilled]: (state, action) => {
      state.albumListStatus = "succeeded";
      state.sections["album"] = action.payload.data.result;
    },
    [albumList.rejected]: (state, action) => {
      state.albumListStatus = "failed";
    },

    [createAlbum.pending]: (state, action) => {
      state.isAlbumCreating = "loading";
      state.message = null;
    },
    [createAlbum.fulfilled]: (state, action) => {
      state.isAlbumCreating = "succeeded";
      console.log(action.payload.data.data, "jkkk ress...");
      state.sections["album"].data = [
        ...state.sections["album"].data,
        action.payload.data.result,
      ];
      state.sections[state.currentTab]["currentActions"] = {
        ...state.sections[state.currentTab]["currentActions"],
        preselect: false,
      };
      state.sections[state.currentTab]["selectedImages"] = [];
    },
    [createAlbum.rejected]: (state, action) => {
      state.isAlbumCreating = "failed";
    },

    [removeAlbum.pending]: (state, action) => {
      state.isAlbumRemoved = "loading";
      state.message = null;
    },
    [removeAlbum.fulfilled]: (state, action) => {
      state.isAlbumRemoved = "succeeded";
    },
    [removeAlbum.rejected]: (state, action) => {
      state.isAlbumRemoved = "failed";
    },

    [editAlbum.pending]: (state, action) => {
      state.isAlbumEditing = "loading";
      state.message = null;
    },
    [editAlbum.fulfilled]: (state, action) => {
      state.isAlbumEditing = "succeeded";
      let albumIndex = state.sections["album"].data.findIndex(
        (item) => item.id == action.payload.data.result.id
      );
      state.sections["album"].data[albumIndex] = action.payload.data.result;
      state.sections[state.currentTab]["currentActions"] = {
        ...state.sections[state.currentTab]["currentActions"],
        preselect: false,
      };
      state.sections[state.currentTab]["selectedImages"] = [];
    },
    [editAlbum.rejected]: (state, action) => {
      state.isAlbumEditing = "failed";
    },

    [getAlbumItem.pending]: (state, action) => {
      state.isAlbumList = "loading";
      state.message = null;
    },

    [getAlbumItem.fulfilled]: (state, action) => {
      state.isAlbumList = "succeeded";

      typeof state.sections[state.currentTab]["list"] !== "object"
        ? (state.sections[state.currentTab]["list"] = {
            [state.selectedAlbum]: { ...action.payload.data.result },
          })
        : (state.sections[state.currentTab]["list"] = {
            ...state.sections[state.currentTab]["list"],
            [state.selectedAlbum]: { ...action.payload.data.result },
          });
    },
    [getAlbumItem.rejected]: (state, action) => {
      state.isAlbumList = "failed";
    },

    /*********************************** SLIDE SHOW *******************************/

    [getSlideShowList.pending]: (state) => {
      state.isSlideshowFetching = "loading";
    },
    [getSlideShowList.fulfilled]: (state, action) => {
      state.isSlideshowFetching = "succeeded";
      state.sections[state.currentTab] = {
        ...state.sections[state.currentTab],
        ...action?.payload?.data?.result,
      };
    },

    [getSlideShowList.rejected]: (state, action) => {
      state.isSlideshowFetching = "failed";
    },

    [getSlideShow.pending]: (state) => {
      state.status = "loading";
    },
    [getSlideShow.fulfilled]: (state, action) => {
      state.status = "success";

      state.sections.slideshow = {
        ...state.sections.slideshow,
        currentSlideShow: {
          ...state.sections.slideshow.currentSlideShow,
          [state.sections.slideshow.currentSlideShowID]:
            action.payload?.data?.result,
        },
      };
    },
    [getSlideShow.rejected]: (state, action) => {
      state.status = "failed";
    },

    [saveSlideShow.pending]: (state) => {
      state.isSlideShowSaving = "loading";
    },
    [saveSlideShow.fulfilled]: (state, action) => {
      state.isSlideShowSaving = "success";
      let slideIndex = state.sections["slideshow"].data.findIndex(
        (item) => item.id == action.payload.data.result.id
      );
      if (slideIndex == -1) {
        state.sections["slideshow"].data = [
          ...state.sections["slideshow"].data,
          action.payload.data.result,
        ];
        state.sections["slideshow"]["currentActions"] = {};
        state.sections["slideshow"].selectedImages = [];
        state.sections["slideshow"].currentSlideShowID = "";
      } else {
        state.sections["slideshow"].data[slideIndex] =
          action.payload.data.result;
        state.sections["slideshow"]["currentActions"] = {};
        state.sections["slideshow"].selectedImages = [];
        state.sections["slideshow"].currentSlideShowID = "";
      }
    },
    [saveSlideShow.rejected]: (state, action) => {
      state.isSlideShowSaving = "failed";
    },
    /*********************************** SLIDE SHOW *******************************/

    [getMusicList.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getMusicList.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.musicList = action.payload.data.result;
    },
    [getMusicList.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});

export const {
  galleryActions,
  addImages,
  reArrangeImage,
  selectAllImages,
  unSelectAllImages,
  removeAllActions,
  currentFilterItem,
  openPreSelect,
  openRearrange,
  closePreSelect,
  closeFilterActions,
  favoriteActions,
  currentTabSelection,
  reArrangedItems,
  preUploadImage,
  updateActiveUploadPercentage,
  updateActiveIndex,
  updateUploadedImage,
  addtoArchiveFunction,
  deleteImageAction,
  deleteMultipleImages,
  removeArchiveFunction,
  archiveActions,
  getFailedItems,
  handleremoveFailedItem,
  singleImageUpload,
  addToThumbs,
  clearThumbs,
  removeAlbumFromState,
  clearAlbumState,
  currentSelectedAlbum,
  editCurrentSlideShow,
  callFunction,
  uploadModalAction,
  deleteImageFromSlideshow,
} = gallerySlice.actions;

export default gallerySlice.reducer;
