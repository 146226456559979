import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDimensions } from "../../Logic/Dimensions";
import { Dropdown, Col, Row, Form, Button, ListGroup } from "react-bootstrap";
import Assets from "../Layouts/Assets";
import GalleryBlankPage from "../BlankGallery";
import Icons from "../Layouts/Icons";
import Style from "./Album.module.scss";
import AlbumFullView from "./AlbumFullView";
import AlbumItem from "./AlbumItem";
import { useNavigate } from "react-router-dom";
import useAlbum from "../../Logic/useAlbum";
import CommonModal from "../Modals/commonModal";
import FrameItem from "./FrameItem";
import FitToFrame from "./FitToFrame/FitToFrame";
import AlbumList from "./AlbumList/index";
import useGallery from "../../Logic/useGallery";
import { DashboardLoader } from "../DashboardLoader";

const Album = () => {
  const observer = useRef();
  const albumItemRef = useRef(null);
  let navigate = useNavigate();
  const {
    addNewAlbum,
    getAlbumsList,
    sections,
    currentTab,
    saveAlbum,
    albumData,
    handleSaveAlbum,
    openEditModal,
    setOpenEditModal,
    isAlbumEditing,
    frames,
    getFrameList,
    albumListStatus,
  } = useAlbum();
  const { loadMore } = useGallery();
  const [showAlbum, setShowAlbum] = useState(false);
  const windowDimensions = useDimensions();
  const [showFullView, setShowFullView] = useState(false);
  const [selectedFrame, setSelectedFrame] = useState(framesData[0].frame);

  const [frameData, setFrameData] = useState({});

  useEffect(() => {
    getAlbumsList();
    getFrameList();
  }, []);

  useEffect(() => {
    setFrameData({
      ...frameData,
      frameClass: "frameFit",
    });
  }, []);

  useLayoutEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);

  const [activeId, setActiveId] = useState(null);

  const handleNavigateTo = () => {
    navigate("/dashboard");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFrameData({ ...frameData, [name]: value });
  };

  const handleClickEdit = (data) => {
    setOpenEditModal(true);
    setFrameData(data);
  };

  const handleClickframe = (data, id) => () => {
    setActiveId(id);
    setSelectedFrame(data.frame);
    setFrameData({ ...frameData, frame: data.frame, frame_id: data.id });
  };

  useEffect(() => {
    if (albumItemRef.current) {
      const interceptConfig = {
        root: albumItemRef.current,
        rootMargin: "0px",
        threshold: 0.1,
      };
      observer.current = new IntersectionObserver((entries) => {
        if (entries.every((entry) => entry.isIntersecting)) {
          loadMore("album");
          console.log("hey... am loading.....");
        }
      });
      observer.current.observe(albumItemRef.current);
      return () => {
        observer.current.disconnect();
      };
    }
  }, [albumData]);

  return (
    <div className={Style.album}>
      {albumListStatus == "loading" ? (
        <DashboardLoader data={sections[currentTab]?.data} />
      ) : albumListStatus == "succeeded" && albumData?.length > 0 ? (
        <>
          <div className={Style.album_items_container}>
            {showFullView ? (
              <AlbumList
                setShowFullView={setShowFullView}
                showFullView={showFullView}
                slug={frameData?.id}
              />
            ) : (
              <div className={Style.album_items_row}>
                {albumData?.map((item, i) => {
                  const lastIndex = albumData?.length - 1;
                  return (
                    <div ref={lastIndex == i ? albumItemRef : null}>
                      <AlbumItem
                        frames={frames}
                        data={item}
                        setShowFullView={setShowFullView}
                        showFullView={showFullView}
                        handleClickEdit={handleClickEdit}
                        setOpenEditModal={setOpenEditModal}
                        openEditModal={openEditModal}
                        setFrameData={setFrameData}
                        actions={sections[currentTab]?.currentActions}
                      />
                    </div>
                  );
                })}
                {/* add new album */}
                <div className={Style.add_new_album}>
                  <div
                    className={Style.add_new_album_content}
                    onClick={() => handleNavigateTo()}
                  >
                    <div>
                      <Icons icon={"add"} size={24} />
                      Add new album
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <GalleryBlankPage
          title={`You haven't created any album`}
          description={`The collections you create are shown here`}
          buttonText={`Create Album`}
          handleClick={() => handleNavigateTo()}
        />
      )}
      <CommonModal
        showModal={openEditModal}
        hide={() => setOpenEditModal(!openEditModal)}
        className={Style.edit_my_album}
        content={
          <>
            <Row>
              <Col md className={Style.edit_my_album_leftside}>
                {windowDimensions.width < 576 ? (
                  <>
                    <div className={Style.edit_album_head}>
                      <button
                        className={Style.back_button}
                        onClick={() => setOpenEditModal(!openEditModal)}
                      >
                        <Icons icon={"back-arrow"} size={16} />
                      </button>
                      <h4 className="h5">Edit Album</h4>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <FitToFrame
                  frame={selectedFrame}
                  image={frameData?.src?.large}
                  frameData={frameData}
                  setFrameData={setFrameData}
                />
              </Col>
              <Col md className={Style.edit_my_album_rightside}>
                {windowDimensions.width >= 576 ? (
                  <>
                    <h4 className="h5 mb-4">Edit Album</h4>
                  </>
                ) : (
                  <></>
                )}
                <Form>
                  <Form.Group className="mb-4" controlId="formTitle">
                    <Form.Label className="mb-1">Title</Form.Label>
                    <Form.Control
                      type="text"
                      name="title"
                      placeholder="New Album 01"
                      onChange={(e) => handleChange(e)}
                      value={frameData?.title}
                    />
                  </Form.Group>
                  <Form.Group className="mb-4" controlId="formBasicCheckbox">
                    <Form.Label className="mb-1">Description</Form.Label>
                    <div className="textarea">
                      <Form.Control
                        as="textarea"
                        name="description"
                        rows={3}
                        placeholder="Add a description"
                        onChange={(e) => handleChange(e)}
                        value={frameData?.description}
                      />
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-4">
                    <Form.Label className="mb-1">Add Frame</Form.Label>
                    <div className={Style.select_a_frame_col}>
                      <div className={Style.select_a_frame_row}>
                        {frames?.map((item, index) => {
                          return (
                            <FrameItem
                              frameSrc={item?.frame}
                              frameTitle={item?.frameName}
                              frameSelected={index === activeId ? true : false}
                              key={index}
                              onClickfun={handleClickframe(item, index)}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </Form.Group>
                  <button
                    onClick={(e) => handleSaveAlbum(e, frameData)}
                    className={`w-100 ${Style.btnItem} ${
                      isAlbumEditing == "loading" && "btn-loader-active"
                    }`}
                  >
                    {isAlbumEditing != "loading" && "Continue"}
                  </button>
                  {/* <Button  variant="primary" className={`w-100 ${Style.edit_album_submit_btn} ${isAlbumEditing =="loading" ? "btn-loader-active":""}`} on={()=>handleSaveAlbum(frameData)} >{isAlbumEditing == "loading" ? "": "save"} </Button> */}
                </Form>
              </Col>
            </Row>
          </>
        }
      />
    </div>
  );
};

export default Album;

const framesData = [
  {
    id: 1,
    frameName: "Wooden Black",
    frame: Assets.photo_frame01,
  },
  {
    id: 2,
    frameName: "Cherry Blossom",
    frame: Assets.photo_frame01,
  },
  {
    id: 3,
    frameName: "Wooden Black",
    frame: Assets.photo_frame02,
  },
  {
    id: 4,
    frameName: "Cherry Blossom",
    frame: Assets.photo_frame01,
  },
  {
    id: 5,
    frameName: "Wooden Black",
    frame: Assets.photo_frame02,
  },
  {
    id: 6,
    frameName: "Wooden Black",
    frame: Assets.photo_frame02,
  },
];
