import React, { useState, useEffect } from "react";
import Style from "./HomeBanner.module.scss";
import Assets from "../Layouts/Assets";
import { Dropdown, Container } from "react-bootstrap";
import Icons from "../Layouts/Icons";
import DarkThemeToggler from "../DarkThemeToggler";
import { useTranslation } from "react-i18next";
import { useDimensions } from "../../Logic/Dimensions";
import { useSelector } from "react-redux";

const HomeBanner = ({ darkModeIcon, setDarkModeIcon }) => {
  const [searchCategory, setSearchCategory] = useState("Photos");
  const [videoLoaded, setVideoLoaded] = useState(false);
  // const [bgLoaded, setBgLoaded] = useState(false);
  const windowDimensions = useDimensions();

  const searchCategorySwitch = (category) => {
    setSearchCategory(category);
  };
  
  const { registerData, showSignInComponent, showForgotComponent } = useSelector(
    (state) => state.auth);

  const { t } = useTranslation();


  const handleClickToScroll = () => {
    const element = document.querySelector(".section_browse_photos");
    const offset = 63;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  return (
    <div className={`${Style.home_banner} banner-video`}>
      {(windowDimensions.width < 768) && 
        <div className={Style.banner_gradient}></div>
      }
      <video
        autoPlay
        muted
        loop
        playsInline
        onPlay={() => setVideoLoaded(true)}
        className={videoLoaded ? Style.loaded : ""}
        src={
          windowDimensions.width >= 576
            ? Assets.homeBannerVideo
            : Assets.homeBannerVideoMob
        }
  
      ></video>
      <Container className="h-100">
        <div className={Style.home_banner_content}>
          <h1 className={`${Style.home_banner_title} anim banner-anim`}>
            {t("homebanner_title_1")}
            <span className={Style.home_banner_title_bold}>
              {t("homebanner_title_2")}
            </span>
          </h1>
          <p className={`${Style.home_banner_description} anim banner-anim`}>
            {t("homebanner_desc")}
          </p>
          {registerData?.name ? (
            <div className="search_bar_search no-dark anim banner-anim">
              <div className="search_bar_search_icon">
                <Icons icon={"search"} size={20} />
              </div>
              <input
                type="text"
                placeholder={t("search_here")}
                className="search_bar_search_input"
              />
              <div className="search_bar_search_dropdown">
                <Dropdown style={{ width: "100%" }}>
                  <Dropdown.Toggle className="search_bar_toggle">
                    {searchCategory}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => searchCategorySwitch("Photos")}>
                      Photos
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => searchCategorySwitch("Users")}>
                      {t("users")}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          ) : ""
          }
          <button
            className={Style.home_banner_scrolldown}
            onClick={() => handleClickToScroll()}
          >
            <div className={Style.home_banner_scrolldown_anim}>
              <div className={Style.scroll_me}>
                <div className={Style.chevron}></div>
                <div className={Style.chevron}></div>
                <div className={Style.chevron}></div>
              </div>
            </div>
            {t("homebanner_scroll")}
          </button>
        </div>
       
      </Container>
    </div>
  );
};

export default HomeBanner;
