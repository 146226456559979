import React, { useEffect, useState } from "react";
import Icons from "../../../Layouts/Icons";
import Style from "./RightFilteredCategoryMobile.module.scss";
import { t } from "i18next";
import { Dropdown } from "react-bootstrap";
import useGallery from "../../../../Logic/useGallery";
import { useDispatch, useSelector } from "react-redux";
import {
  currentFilterItem,
  closeFilterActions,
  openPreSelect,
  callFunction,
  deleteMultipleImages,
  deleteSlideShowItems,
  uploadModalAction,
} from "../../../../store/slices/gallerySlice";
import CommonModal from "../../../Modals/commonModal";
import UploadImageModal from "../RightFilteredCategory/UploadImageModal";
import useUploadImageModal from "../../../../Logic/useUploadImageModal";
import { useNavigate } from "react-router-dom";
import useAlbum from "../../../../Logic/useAlbum";
import SelectedImageItem from "../SelectedImageItem";
import loader from "../../../../assets/images/loader/Spinner.svg";

const options = [
  {
    name: "descending",
    code: "desc",
  },
  {
    name: "ascending",
    code: "asc",
  },
];

const RightFilteredCategoryMobile = ({
  editGallery,
  setEditGallery,
  setPreSelect,
  preSelect,
}) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const { sections, currentTab, openUploadModal } = useSelector(
    (state) => state.gallery
  );
  const {
    selectAllImageHandler,
    unSelectAllImagesHandler,
    handleFilterImages,
    multiDelete,
    handleArchive,
  } = useGallery();
  const {
    addNewAlbum,
    getAlbumsList,
    albumData,
    albumListStatus,
    isAlbumCreating,
    isAddtoToAlbum,
    handleEditAlbum,
    isAlbumEditing,
    handleClearAlbumState,
    multiDeleteAlbums,
  } = useAlbum();

  const [selectedAlbum, setSelectedAlbum] = useState(0);
  const [openAlbumModal, setOpenAlbumModal] = useState(false);
  const { handleImageChange } = useUploadImageModal();
  const [currentFilter, setcurrentFilter] = useState(options[0].name);
  const [openActionModal, setOpenModal] = useState(false);
  const [deletePhotoModal, setDeletePhotoModal] = useState(false);
  const [text, setText] = useState("Select All");
  const [icon, setIcon] = useState(<Icons icon={"action-select"} size={17} />);
  const [slideShowFiltertext, setSlideShowFiltertext] = useState("Select All");

  const handlePreSelection = () => {
    if (
      currentTab == "slideshow" &&
      !sections?.slideshow?.currentActions?.imageGalleryOpen
    ) {
      const slideshowItemsAction = (state) => {
        state.sections.slideshow = {
          ...state.sections.slideshow,
          currentActions: { isSlideShowPreSelect: true },
        };
      };
      dispatch(callFunction(slideshowItemsAction));
    } else if (!editGallery) {
      dispatch(openPreSelect());
    }
  };

  useEffect(() => {
    if (openAlbumModal) {
      getAlbumsList();
    }
  }, [openAlbumModal]);

  const textChangeHandler = () => {
    setText((prevState) => {
      if (prevState === "Unselect all") {
        unSelectAllImagesHandler();
        return "Select all";
      } else {
        selectAllImageHandler();
        return "Unselect all";
      }
    });
    setIcon((prevState) => {
      if (prevState === <Icons icon={"action-select"} size={17} />) {
        return <Icons icon={"action-unselect"} size={17} />;
      } else {
        return <Icons icon={"action-select"} size={17} />;
      }
    });
  };

  const hideSelectHandler = () => {
    dispatch(closeFilterActions());
  };

  const handleDelete = () => {
    multiDelete();
    setDeletePhotoModal(!deletePhotoModal);
  };

  const handleClickArchive = () => {
    handleArchive();
  };

  const handleReArrange = () => {
    setPreSelect(false);
  };

  const handleClickFilter = (data) => {
    setcurrentFilter(data.name);
    dispatch(currentFilterItem(data.code));
    handleFilterImages(data.code);
  };

  const handleCloseReArrange = () => {
    setEditGallery(false);
  };
  const handleSaveReArrangedIMages = () => {
    console.log("save/...");
  };

  const handleModal = () => {
    setOpenAlbumModal(!openAlbumModal);
    handleClearAlbumState();
  };

  const handleNavigateTo = () => {
    setOpenAlbumModal(!openAlbumModal);
    navigate("album");
  };

  const handleEditAlbumItem = (index, data) => {
    if (isAlbumEditing == "idle") {
      setSelectedAlbum(index);
      handleEditAlbum(data);
    }
  };

  const handleAddClick = () => {
    if (sections[currentTab]?.currentActions?.mode == "update") {
      let id = sections[currentTab]?.currentActions?.slideInfo;
      navigate(`/dashboard/slideshow/edit/${id}`);
    } else {
      navigate("/dashboard/slideshow/edit");
    }
  };

  const handleCancel = () => {
    const slideshowGalleryAction = (state) => {
      state.sections.slideshow = {
        ...state.sections.slideshow,
        currentActions: { imageGalleryOpen: false },
      };
    };
    dispatch(callFunction(slideshowGalleryAction));
  };

  const slideShowChangeHandler = () => {
    setSlideShowFiltertext((prevState) => {
      if (prevState === "Unselect all") {
        unSelectAllImagesHandler();
        return "Select all";
      } else {
        selectAllImageHandler();
        return "Unselect all";
      }
    });
    setIcon((prevState) => {
      if (prevState === <Icons icon={"action-select"} size={17} />) {
        return <Icons icon={"action-unselect"} size={17} />;
      } else {
        return <Icons icon={"action-select"} size={17} />;
      }
    });
  };

  const handleCancelSlideShowActions = () => {
    const slideshowItemsAction = (state) => {
      state.sections.slideshow = {
        ...state.sections.slideshow,
        currentActions: { isSlideShowPreSelect: false },
        selectedImages: [],
      };
    };
    dispatch(callFunction(slideshowItemsAction));
  };

  const handledeleteSlideShowItems = () => {
    let slideshow_id = sections[currentTab].selectedImages;
    dispatch(deleteMultipleImages());
    dispatch(deleteSlideShowItems({ slideshow_id }));
  };

  const PreSelectOptions = () => {
    return (
      <>
        <button
          className={`${Style.dashboard_btn} ${"btn btn-border-grey"}`}
          type="button"
          onClick={() => textChangeHandler()}
        >
          {icon}
          <p className={Style.selectionText}>
            {text} (
            {sections[currentTab]?.selectedImages?.length > 0
              ? sections[currentTab]?.selectedImages?.length
              : 0}
            )
          </p>
        </button>

        <Dropdown className={`${Style.dashboard_actions}`}>
          <Dropdown.Toggle
            className={`${
              Style.dashboard_actions_btn
            } ${"btn btn-border-grey"}`}
          >
            <div className={Style.dashboard_actions_btn_label}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {currentTab == "album" ? (
              <>
                <Dropdown.Item>Share</Dropdown.Item>
                <Dropdown.Item
                  onClick={() => setDeletePhotoModal(!openActionModal)}
                >
                  Delete
                </Dropdown.Item>
              </>
            ) : (
              <>
                <Dropdown.Item
                  onClick={() => setOpenAlbumModal(!openAlbumModal)}
                >
                  Add to album
                </Dropdown.Item>
                {/* <Dropdown.Item>Slideshow</Dropdown.Item> */}
                <Dropdown.Item>Share</Dropdown.Item>
                <Dropdown.Item onClick={() => handleClickArchive()}>
                  {currentTab == "archive" ? "UnArchive" : "Archive"}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => setDeletePhotoModal(!openActionModal)}
                >
                  Delete
                </Dropdown.Item>
              </>
            )}
          </Dropdown.Menu>
        </Dropdown>
        <button
          onClick={hideSelectHandler}
          className={Style.dashboard_actions_close}
        >
          <Icons icon={"icon-close"} size={14} />
        </button>
      </>
    );
  };

  const CommonOptions = () => {
    return (
      <>
        {currentTab !== "favorites" ? (
          <button
            onClick={() => handlePreSelection()}
            className={`${Style.dashboard_btn} ${"btn btn-border-grey"}`}
          >
            <Icons icon={"icon-square"} size={17} />
          </button>
        ) : (
          ""
        )}
        <Dropdown className={`${Style.dashboard_dropdown}`}>
          <Dropdown.Toggle
            className={`${Style.dashboard_btn} ${"btn btn-border-grey"}`}
          >
            <div className={Style.dashboard_dropdown_label}>
              <Icons icon={"outline-filter"} size={17} />
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {options.map((item, i) => {
              return (
                <Dropdown.Item
                  onClick={() => {
                    handleClickFilter(item);
                  }}
                  key={i}
                >
                  {item.name}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  };

  const AlbumModal = () => {
    return (
      <>
        <div className={Style.listContainer}>
          {albumData?.map((item, index) => {
            return (
              <div
                className={Style.albumList}
                onClick={() => handleEditAlbumItem(index, item)}
              >
                <SelectedImageItem data={item} />
                {selectedAlbum == index && isAlbumEditing == "loading" ? (
                  <img src={loader} className={Style.spinner}></img>
                ) : selectedAlbum == index && isAlbumEditing == "succeeded" ? (
                  <span
                    className={Style.gotoAction}
                    onClick={() => handleNavigateTo()}
                  >
                    {<Icons icon={"arrow-right2"} size={16} />}
                  </span>
                ) : (
                  ""
                )}
              </div>
            );
          })}
        </div>
        <div
          className={Style.add_to_new_album_btn_wrap}
          onClick={() => addNewAlbum()}
        >
          <button className={Style.add_to_album_btn}>
            <Icons icon={"add"} size={17} />
          </button>
          <span className={Style.new_album_label}>New Album</span>
          {isAlbumCreating == "loading" ? (
            <img src={loader} className={Style.spinner}></img>
          ) : (
            ""
          )}
        </div>
      </>
    );
  };

  const AddImageSection = () => {
    return (
      <button
        className={`${Style.dashboard_add_btn} ${"btn btn-border-secondary"}`}
        onClick={() => dispatch(uploadModalAction(true))}
      >
        <Icons icon={"add"} size={12} />
        {t("add")}
      </button>
    );
  };

  const SlideShowOptions = () => {
    return (
      <>
        {sections[currentTab]?.currentActions?.preselect ? (
          <button
            className={`${Style.dashboard_btn} ${"btn btn-border-grey"}`}
            type="button"
            onClick={() => textChangeHandler()}
          >
            {icon}
            {text} (
            {sections[currentTab]?.selectedImages?.length > 0
              ? sections[currentTab]?.selectedImages?.length
              : 0}
            )
          </button>
        ) : (
          ""
        )}
        <button
          onClick={() => handleCancel()}
          className={`${Style.dashboard_btn} ${"btn btn-border-grey"}`}
        >
          Cancel
        </button>
        <button
          onClick={() => handleAddClick()}
          className={`${Style.dashboard_btn} ${"btn btn-primary"}`}
        >
          Add
        </button>
      </>
    );
  };

  const SlideShowfilter = () => {
    return (
      <>
        {sections[currentTab]?.currentActions?.isSlideShowPreSelect ? (
          <button
            className={`${Style.dashboard_btn} ${"btn btn-border-grey"}`}
            type="button"
            onClick={() => slideShowChangeHandler()}
          >
            {icon}
            {slideShowFiltertext} (
            {sections[currentTab]?.selectedImages?.length > 0
              ? sections[currentTab]?.selectedImages?.length
              : 0}
            )
          </button>
        ) : (
          ""
        )}
        <Dropdown className={Style.dashboard_actions}>
          <Dropdown.Toggle className={Style.dashboard_actions_btn}>
            <div className={Style.dashboard_actions_btn_label}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className={Style.dashboard_select_dropdown}>
            <>
              <Dropdown.Item onClick={handledeleteSlideShowItems}>
                Delete
              </Dropdown.Item>
            </>
          </Dropdown.Menu>
        </Dropdown>
        <button
          onClick={handleCancelSlideShowActions}
          className={Style.dashboard_actions_close}
        >
          <Icons icon={"icon-close"} size={14} />
        </button>
      </>
    );
  };

  return (
    <>
      <div>
        {
          <>
            <div className={Style.mobile_gallery_actions}>
              {sections[currentTab]?.currentActions?.preselect &&
              currentTab !== "slideshow" ? (
                <PreSelectOptions />
              ) : sections[currentTab]?.currentActions?.preselect ||
                sections?.slideshow?.currentActions?.isSlideShowPreSelect ? (
                ""
              ) : (
                <CommonOptions />
              )}
              <>
                {currentTab == "all" ? <AddImageSection /> : ""}
                {currentTab == "slideshow" &&
                  sections?.slideshow?.currentActions?.imageGalleryOpen && (
                    <SlideShowOptions />
                  )}
                {currentTab == "slideshow" &&
                  sections?.slideshow?.currentActions?.isSlideShowPreSelect && (
                    <SlideShowfilter />
                  )}
              </>
            </div>
          </>
        }
      </div>

      <CommonModal
        showModal={deletePhotoModal}
        hide={() => setDeletePhotoModal(!deletePhotoModal)}
        className={Style.delete_this_photo_modal}
        title={""}
        content={
          <>
            <h3 className={Style.delete_title}>Delete this Photo</h3>
            <p className={Style.delete_content}>
              Are you sure you want to delete this photo?
            </p>
            <div className={Style.delete_btn_wrap}>
              <button className="btn-danger" onClick={() => handleDelete()}>
                Continue
              </button>
              <button
                className="btn-outline-gray"
                onClick={() => setDeletePhotoModal(!deletePhotoModal)}
              >
                Cancel
              </button>
            </div>
          </>
        }
      />
      <CommonModal
        showModal={openAlbumModal}
        hide={() => handleModal()}
        className={Style.add_to_album}
        title={"Add to album"}
        content={<AlbumModal />}
      />
      <UploadImageModal
        openActionModal={openUploadModal}
        setOpenModal={setOpenModal}
      />
    </>
  );
};

export default RightFilteredCategoryMobile;

// editGallery ? (

//   <div className={Style.save_btn_wrap_mob}>
//     <button
//       className={`${"btn btn-sm btn-mr2 btn-secondary"}`}
//       type="button"
//       onClick={() => handleSaveReArrangedIMages()}
//     >
//       save
//     </button>
//     <button
//       className={`${"btn btn-sm btn-border"} ${Style.dashboard_cancel_btn}`}
//       type="button"
//       onClick={() => handleCloseReArrange()}
//     >
//       cancel
//     </button>

//   </div>
// ) : preSelect ? (

//   <div className={Style.add_btn_wrap_mob}>
//     <button
//       className={`${"btn btn-sm btn-border"} ${Style.dashboard_cancel_btn}`}
//       type="button"
//       onClick={() => handleCloseReArrange()}
//     >
//       cancel
//     </button>
//     <button
//       className={`${"btn btn-sm btn-mr2 btn-secondary"}`}
//       type="button"
//       onClick={() => handleSaveReArrangedIMages()}
//     >
//       Add
//     </button>
//   </div>
// ) :
