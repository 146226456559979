import Style from "./Menu.module.scss";
import { Dropdown, Button, Container } from "react-bootstrap/";
import { Link, Navigate, useLocation } from "react-router-dom";
import Assets from "../Assets";
import Icons from "../Icons";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CommonModal from "../../Modals/commonModal";
import { useEffect, useState } from "react";
import { logOut } from "../../../store/slices/authSlice";
import { useDimensions } from "../../../Logic/Dimensions";
import { getProfileDetails } from "../../../store/slices/userprofileSlice";
import avatar from "../../../assets/avatar.png";
import { useNavigate } from "react-router-dom";
import useAuthChecker from "../../../Logic/useAuthChecker";

const Menu = () => {
  const { registerData, registerStatus } = useSelector((state) => state.auth);
  const { userdata } = useSelector((store) => store.userprofile);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const location = useLocation();

  const { isAuthCompleted } = useAuthChecker();

  const [openModal, setOpenModal] = useState(false);
  const [hasNotification, setHasNotification] = useState(true);

  useEffect(() => {
    if (Object.keys(userdata).length == 0) {
      dispatch(getProfileDetails(registerData?.user_id));
    }
  }, [registerData]);

  const handleLogout = () => {
    dispatch(logOut());
    // loggingOut();
  };

  const loggingOut = () =>
    setTimeout(() => {
      window.location.href = "/";
    }, 1000);

  const { t } = useTranslation(["dashboard"]);
  const windowDimensions = useDimensions();

  const [searchCategory, setSearchCategory] = useState("Photos");

  const searchCategorySwitch = (category) => {
    setSearchCategory(category);
  };

  const handleClickCreateTree = () => {
    navigate("/dashboard/family-tree");
  };

  return (
    <>
      <header className={Style.loggedin_header}>
        <Container className={Style.loggedin_header_container} fluid>
          {windowDimensions.width > 1280 ? (
            <>
              <div className={Style.loggedin_header_wrap}>
                <div className={Style.loggedin_header_left}>
                  <figure className={Style.loggedin_header_logo}>
                    <Link to={"/"} title={"Family Photos"}>
                      <img src={Assets.main_logo} alt="Family Photos" />
                    </Link>
                  </figure>
                  <figure className={Style.loggedin_header_logo}>
                    <Link to={"/"} title={"Family Photos"}>
                      <img src={Assets.main_logo_dark} alt="Family Photos" />
                    </Link>
                  </figure>
                  <div className="search_bar_search">
                    <div className="search_bar_search_icon">
                      <Icons icon={"search"} size={17} />
                    </div>
                    <input
                      type="text"
                      placeholder="Search Here"
                      className="search_bar_search_input"
                    />
                    <div className="search_bar_search_dropdown">
                      <Dropdown style={{ width: "100%" }}>
                        <Dropdown.Toggle
                          className={`search_bar_toggle ${Style.search_bar_toggle}`}
                        >
                          {searchCategory}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => searchCategorySwitch("Photos")}
                          >
                            Photos
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => searchCategorySwitch("Users")}
                          >
                            Users
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
                <nav className={Style.loggedin_header_nav}>
                  <Button
                    variant="primary"
                    className={`${
                      Style.btn_create_tree
                    } ${"btn btn-secondary"}`}
                    onClick={() => handleClickCreateTree()}
                  >
                    <Icons icon={"add"} size={14} />
                    {t("create_a_tree")}
                  </Button>
                  <div
                    className={`${Style.bell_icon} ${
                      hasNotification && Style.active
                    }`}
                  >
                    <Icons icon={"bell-icon"} size={24} />
                  </div>
                  <Dropdown>
                    <Dropdown.Toggle className={Style.profile_dropdown}>
                      <figure className={Style.profile_image}>
                        <img
                          src={
                            isAuthCompleted && userdata?.thumbnail
                              ? userdata?.thumbnail
                              : avatar
                          }
                          alt="profile-pic"
                        />
                      </figure>
                      <div className={Style.profile_details}>
                        <span className={Style.username}>
                          {isAuthCompleted && userdata?.name}
                        </span>
                        <span className={Style.ownership}>Admin</span>
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {location.pathname == "/dashboard" ? (
                        ""
                      ) : (
                        <Dropdown.Item as={Link} to="/dashboard">
                          Dashboard
                        </Dropdown.Item>
                      )}
                      {location.pathname == "/dashboard/user-profile" ? (
                        ""
                      ) : (
                        <Dropdown.Item as={Link} to="/dashboard/user-profile">
                          Your Profile
                        </Dropdown.Item>
                      )}
                      <Dropdown.Item as={Link} to="/">
                        Home
                      </Dropdown.Item>

                      <Dropdown.Item
                        onClick={() => {
                          setOpenModal(true);
                        }}
                      >
                        Log Out
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </nav>
              </div>
            </>
          ) : (
            <>
              <div className={Style.loggedin_header_wrap_mob}>
                <div>
                  <Dropdown>
                    <Dropdown.Toggle className={Style.profile_dropdown_mob}>
                      <figure className={Style.profile_image}>
                        <img
                          src={userdata?.image ? userdata?.image : avatar}
                          alt="profile-pic"
                        />
                      </figure>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {location.pathname == "/dashboard" ? (
                        ""
                      ) : (
                        <Dropdown.Item as={Link} to="/dashboard">
                          Dashboard
                        </Dropdown.Item>
                      )}
                      {location.pathname == "/dashboard/user-profile" ? (
                        ""
                      ) : (
                        <Dropdown.Item as={Link} to="/dashboard/user-profile">
                          Your Profile
                        </Dropdown.Item>
                      )}
                      <Dropdown.Item as={Link} to="/">
                        Home
                      </Dropdown.Item>

                      <Dropdown.Item
                        onClick={() => {
                          setOpenModal(true);
                        }}
                      >
                        Log Out
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className={Style.header_right_btn_wrap_mob}>
                  <Button
                    variant="primary"
                    className={`${Style.btn_create_tree_mob} ${"btn"}`}
                    onClick={() => handleClickCreateTree()}
                  >
                    <Icons icon={"add"} size={14} />
                  </Button>
                  <div className={Style.search_bar_search_icon_mob}>
                    <Icons icon={"search"} size={22} />
                  </div>
                  <div className={`${Style.bell_icon} ${Style.active}`}>
                    <Icons icon={"bell-icon"} size={24} />
                  </div>
                </div>
              </div>
            </>
          )}
        </Container>
        <CommonModal
          showModal={openModal}
          hide={() => setOpenModal(false)}
          className="modal-width logout-modal"
          content={
            <>
              <div className={Style.logoutSection}>
                <div className={`${Style.title} mb-4`}>Are You Sure!</div>

                <div
                  className={`w-100 text-center ${Style.logoutsection_btn_wrap}`}
                >
                  <button
                    onClick={() => handleLogout()}
                    className="btn btn-primary"
                  >
                    Yes
                  </button>
                  <button
                    onClick={() => setOpenModal(false)}
                    className="btn btn-light"
                  >
                    No
                  </button>
                </div>
              </div>
            </>
          }
        />
      </header>
    </>
  );
};

export default Menu;
