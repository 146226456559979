import GetStarted from "../../components/GetStarted";
import CommonLayout from "../../components/Layouts/CommonLayout";

const GetStartedPage = () => {
  return (
    <CommonLayout>
      <GetStarted />
    </CommonLayout>
  );
};

export default GetStartedPage;
