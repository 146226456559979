import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PreLogin from '../../Layouts/PreLogin';

const FAQ = () => {
    const location = useLocation();
  
    useEffect(() => {
      const allSections = document.querySelectorAll(".anim");
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const element = entry.target;
            element.classList.add("visible");
          }
        });
      });
      allSections.forEach((section) => {
        observer.observe(section);
      });
    }, [location.pathname]);
  
  return (
    <PreLogin>
        <div className='faq-page inner-page'>
            <div className='container'>
                <h1 className='policies-title'>FAQ</h1>
                <div className="policies-date">Last updated on: 20 June 2022</div>
                <div className='admin-content-area'>
                    <dl className='default'>
                        <dt><span className='counter-span'></span>How to register?</dt>
                        <dd>
                            Adipisicing in culpa consectetur qui ut ea commodo nulla pariatur velit non cillum ex. Ullamco enim amet culpa dolore non labore. Laborum pariatur incididunt proident commodo proident exercitation cillum exercitation excepteur exercitation et. Occaecat est id duis ut esse sunt Lorem occaecat. Reprehenderit consequat nulla minim exercitation aliqua commodo nulla laboris sunt voluptate laborum ad eu. Occaecat eu anim velit non. Ex exercitation sunt mollit nisi sit ullamco veniam adipisicing commodo minim enim.
                        </dd>
                        <dt><span className='counter-span'></span>How to register?</dt>
                        <dd>
                            Adipisicing in culpa consectetur qui ut ea commodo nulla pariatur velit non cillum ex. Ullamco enim amet culpa dolore non labore. Laborum pariatur incididunt proident commodo proident exercitation cillum exercitation excepteur exercitation et. Occaecat est id duis ut esse sunt Lorem occaecat. Reprehenderit consequat nulla minim exercitation aliqua commodo nulla laboris sunt voluptate laborum ad eu. Occaecat eu anim velit non. Ex exercitation sunt mollit nisi sit ullamco veniam adipisicing commodo minim enim.
                        </dd>
                        <dt><span className='counter-span'></span>How to register?</dt>
                        <dd>
                            Adipisicing in culpa consectetur qui ut ea commodo nulla pariatur velit non cillum ex. Ullamco enim amet culpa dolore non labore. Laborum pariatur incididunt proident commodo proident exercitation cillum exercitation excepteur exercitation et. Occaecat est id duis ut esse sunt Lorem occaecat. Reprehenderit consequat nulla minim exercitation aliqua commodo nulla laboris sunt voluptate laborum ad eu. Occaecat eu anim velit non. Ex exercitation sunt mollit nisi sit ullamco veniam adipisicing commodo minim enim.
                        </dd>
                        <dt><span className='counter-span'></span>How to register?</dt>
                        <dd>
                            Adipisicing in culpa consectetur qui ut ea commodo nulla pariatur velit non cillum ex. Ullamco enim amet culpa dolore non labore. Laborum pariatur incididunt proident commodo proident exercitation cillum exercitation excepteur exercitation et. Occaecat est id duis ut esse sunt Lorem occaecat. Reprehenderit consequat nulla minim exercitation aliqua commodo nulla laboris sunt voluptate laborum ad eu. Occaecat eu anim velit non. Ex exercitation sunt mollit nisi sit ullamco veniam adipisicing commodo minim enim.
                        </dd>
                        <dt><span className='counter-span'></span>How to register?</dt>
                        <dd>
                            Adipisicing in culpa consectetur qui ut ea commodo nulla pariatur velit non cillum ex. Ullamco enim amet culpa dolore non labore. Laborum pariatur incididunt proident commodo proident exercitation cillum exercitation excepteur exercitation et. Occaecat est id duis ut esse sunt Lorem occaecat. Reprehenderit consequat nulla minim exercitation aliqua commodo nulla laboris sunt voluptate laborum ad eu. Occaecat eu anim velit non. Ex exercitation sunt mollit nisi sit ullamco veniam adipisicing commodo minim enim.
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
    </PreLogin>
  )
}

export default FAQ;