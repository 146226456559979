
import Style from "./UserProfileGallery.module.scss";
import { useEffect } from "react";
import UserProfileGalleryImages from "../UserProfileGalleryImages";

import useGallery from "../../../Logic/useGallery";
import { useDispatch } from "react-redux";


const UserProfileGallery = () => {
     
    const dispatch = useDispatch();

    const {getImagesForSection} = useGallery();
    
    useEffect(() => {
  
       /* try{
            dispatch(getImagesForSection('All'));
        }catch(e){
            console.log(e);
        }*/
        
    },[]);

    

    const breakpointColumnsObj = {
        default: 5,
        1830: 4,
        1339: 3,
        1199: 4,
        1024: 3,
        800: 2,
        379: 1,
    };
    
    return(
        <>
        <div className={Style.User_profile_gallery_sec}>
            <h2 className={Style.User_profile_gallery_title}>Gallery</h2>
            <UserProfileGalleryImages
                breakpointColumnsObj={breakpointColumnsObj}
            />
        </div>
        </>
    );
};
export default UserProfileGallery;