import Style from "./SearchProfileHeader.module.scss";

import { SearchProfileTabs } from "../SearchProfileTabs";

export const SearchProfileHeader = ({ setContent }) => {
  return (
    <div className={Style.searchprofile}>
      <div className={Style.searchprofile_figure_container}>
        <figure className={Style.searchprofile_figure}>
          <img src="https://hairmanz.com/wp-content/uploads/2020/03/thomas-shelby-peaky-blinders-haircut-8.jpg"></img>
        </figure>
      </div>
      <div>
        <div className={Style.searchprofile_details}>
          <div>
            <div className="h2">Joey M David</div>
            <div className={Style.searchprofile_address}>
              <ul>
                <li className="p">Kalathipparambil(H)</li>
                <li className="p">Male</li>
                <li className="p">Born 14 jan 1995</li>
              </ul>
            </div>
          </div>

          <div className={Style.Button}>
            <button className="">Add to family tree</button>
          </div>
        </div>
        <SearchProfileTabs setContent={setContent} />
      </div>
    </div>
  );
};
