import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getMusicList,
  saveSlideShow,
  getSlideShow,
  callFunction,
  deleteImageFromSlideshow,
} from "../store/slices/gallerySlice";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

const useSlideShowEdit = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let location = useLocation();

  const { sections, currentTab, musicList, isSlideShowSaving } = useSelector(
    (state) => state.gallery
  );

  let musicData;
  let totalImages;
  let filteredImages = [];
  const [slides, setSlides] = useState([]);
  const [effects, setEffects] = useState("effects");
  const [slideData, setSlideData] = useState({
    isEditing: false,
  });

  useEffect(() => {
    const imageIds = sections[currentTab]?.selectedImages;
    if (imageIds) {
      filteredImages = sections["all"]?.data?.filter((d) => {
        return imageIds?.includes(d?.id);
      });
      setSlides(filteredImages);
    }

    if (musicList?.length == 0) {
      dispatch(getMusicList());
    }
  }, []);

  useEffect(() => {
    let currentId = sections[currentTab]?.currentSlideShowID;

    if (sections[currentTab]?.currentSlideShow?.[currentId]?.images) {
      let slideArry = sections[currentTab]?.currentSlideShow?.[
        currentId
      ]?.images?.map((item) => item.id);

      filteredImages = sections["all"]?.data?.filter((d) => {
        return sections[currentTab]?.selectedImages?.includes(d?.id);
      });

      let filterItems = filteredImages?.length > 0 ? filteredImages : [];

      console.log(filterItems, "topeee");

      let uniqueItems = filterItems?.filter((d) => {
        return !sections[currentTab]?.currentSlideShow?.[
          currentId
        ]?.images?.includes(d?.id);
      });

      setSlides(sections[currentTab]?.currentSlideShow?.[currentId]?.images);

      setSlideData({
        ...slideData,
        add: slideArry,
        ...sections[currentTab]?.currentSlideShow?.[currentId]?.slideshow,
      });
    }
  }, [sections[currentTab]?.currentSlideShow]);

  const handleSave = async (data) => {
    const resultAction = await dispatch(saveSlideShow(data));
    if (saveSlideShow.fulfilled.match(resultAction)) {
      console.log("slide show upload  success....");
      setSlideData({
        ...slideData,
        isEditing: false,
      });

      toast.success("Slideshow saved!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error("slideshow action failed", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const getSlideShowsData = (id) => {
    dispatch(getSlideShow(id));
  };

  const addMoreImages = (items, slug) => {
    let prevItems = items.map((item) => item.id);
    const slideshowGalleryAction = (state) => {
      state.sections.slideshow = {
        ...state.sections.slideshow,
        currentActions: {
          ...state.sections.slideshow.currentActions,
          imageGalleryOpen: true,
          preselect: true,
          mode: slug ? "update" : "create",
          slideInfo: slug,
        },
        selectedImages: prevItems,
      };
    };
    dispatch(callFunction(slideshowGalleryAction));
  };

  const deleteImageFromState = (id) => {
    let currentId = sections[currentTab]?.currentSlideShowID;
    let data = {
      currentId: currentId,
      slideIndex: id,
    };
    dispatch(deleteImageFromSlideshow(data));
  };

  const currentSelected = (id) => {
    const slideshowGalleryAction = (state) => {
      state.sections.slideshow = {
        ...state.sections.slideshow,
        currentSlideShowID: id,
      };
    };
    dispatch(callFunction(slideshowGalleryAction));
  };

  const handleGoto = () => {
    const handleClearActions = (state) => {
      state.sections.slideshow = {
        ...state.sections.slideshow,
        currentSlideShowID: "",
        currentSlideShow: {},
        currentActions: {},
      };
    };
    dispatch(callFunction(handleClearActions));
  };

  return {
    musicData,
    slides,
    musicList,
    selectedItems: sections[currentTab]?.selectedImages,
    slideShowState: sections[currentTab],
    handleSave,
    getSlideShowsData,
    addMoreImages,
    setSlides,
    slideData,
    setSlideData,
    currentSelected,
    isSlideShowSaving,
    handleGoto,
    sections,
    deleteImageFromState,
  };
};

export default useSlideShowEdit;
