import React, { useState, useRef, useEffect } from "react";
import { useDimensions } from "../../Logic/Dimensions";
import Icons from "../Layouts/Icons";
import Style from "./ImageEdit.module.scss";
import { Accordion, Col, Form, Nav, Row, Tab } from "react-bootstrap";
import CommonModal from "../Modals/commonModal";
import { Link } from "react-router-dom";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { ImageEdit, loadThumbnailImage } from "../../Logic/useImageEdit";
import { useParams } from "react-router-dom";
import { getMediaURLs } from "../../store/slices/imageEditSlice";
import { useDispatch, useSelector } from "react-redux";

var imageObj;
var imageCopy;

const ImageEditComponent = () => {
  const dispatch = useDispatch();

  const { image_id } = useParams();

  const windowDimensions = useDimensions();
  const [cropOpen, setCropOpen] = useState(true);
  const [openCropModal, setOpenCropModal] = useState(false);
  const [ImageData, setImageData] = useState("");

  const canvasRef = useRef(null);
  const imgRef = useRef(null);

  const [crop_window, SetCrop_window] = useState(false);
  const [crop_apply_btn, SetCrop_apply_btn] = useState(false);
  const [cropselection,SetcropSelection] = useState(false);
  const [aspect, SetAspect] = useState("");

  const [crop, setCrop] = useState({
    unit: "%",
    minWidth: 100,
    maxHeight: 100,
    x: 25,
    y: 25,
  });

  const [img, SetImg] = useState("");

  const [filterState, setFilter] = useState({
    brightness: { value: 50, v: "" },
    contrast: { value: 50, v: "" },
    saturation: { value: 50, v: "" },
  });

  const option_change = function (e) {
    imageObj.hookFilter(e.target.value);
  };

  const rangeAdjust = (e) => {
    let { name, value } = e.target;

    if (value == 50) {
      v = 0;
    } else if (value > 50) {
      var v = (value % 50) / 50;
    } else {
      var v = -Math.abs((value % 50) / 50 - 1);
    }
    setFilter({
      ...filterState,
      [name]: { value: value, v: v },
    });
    
    if(imageObj.cropped){
      imageObj.recallImage(imageObj.cropConfig);
    }else{
      imageObj.recallImage();
    }
    
    imageObj.brightness(filterState.brightness.v);
    imageObj.contrast(filterState.contrast.v);
    imageObj.saturation(filterState.saturation.v);
  };

  const reset = () => {
    setFilter({
      brightness: { value: 50, v: "" },
      contrast: { value: 50, v: "" },
      saturation: { value: 50, v: "" },
    });
    imageObj.reset();
  };

  const save_image = () => {
    //imageObj = Object.assign({}, imageObj)

    //imageObj.ImageUpdate = false;
    // imageObj.saveAll(ImageData.original);
    imageObj.getImageURL();
  };

  const apply_crop = () => {
    imageObj.applyCrop();
    SetCrop_window(false);
    SetCrop_apply_btn(false);
  };

  const cancel_crop = () => {
    SetCrop_window(false);
    SetCrop_apply_btn(false);
  };

  const filters_array = [
    "none",
    "sepia",
    "grayscale",
    "invert",
    "clarendon",
    "moon",
    "lark",
    "reyes",
    "juno",
    "crema",
    "ludwig",
    "aden",
    "perpetua",
    "amaro",
    "mayfair",
    "rise",
    "hudson",
    "valencia",
    "xpro2",
    "sierra",
    "willow",
    "lofi",
    "inkwell",
    "hefe",
    "nashville",
    "stinson",
    "vesper",
    "earlybird",
    "brannan",
    "sutro",
    "toaster",
    "walden",
    "one_thousand_nine_hundred_seventy_seven",
    "kelvin",
    "maven",
    "ginza",
    "skyline",
    "dogpatch",
    "brooklyn",
    "helena",
    "ashby",
    "charmes",
  ];

  const [filterHTML, SetFilterHTML] = useState("");

  const genarate_filter_html = function () {
    var promise = [];
    let html = filters_array.map((filter) => {
      const { data_url, name } = imageObj.getFilterThumbnail(filter);
      return (
        <li className="col-6" onClick={() => imageObj.hookFilter(filter)}>
          <figure className={Style.filter_list_image}>
            <img src={data_url} alt="filter-thumb" />
            <div className={Style.filter_list_image_data}>{name}</div>
          </figure>
        </li>
      );
    });

    SetFilterHTML(html);
  };

  useEffect(() => {
    dispatch(getMediaURLs(image_id))
      .unwrap()
      .then((data) => {
        setImageData(data.data.result.src);
      });
  }, []);

  useEffect(() => {
    var url = ImageData.edit_thumbnail;
    var thub = ImageData.edit_thumbnail;

    imageObj = new ImageEdit(canvasRef, url);
    imageObj.setImageData = setImageData;
    imageObj.thubnailObj = imgRef;
    imageObj.setFilter = setFilter; 

    console.log(imgRef);

    // Filters Thumbnails Generate Starts

    imageObj.imagePromise.then(function () {
      genarate_filter_html();
    });

    // Filters Thumbnails Generate Ends

    window.imageObj = imageObj;
  }, [ImageData]);

  useEffect(() => {
    if (crop_window === true) {
      imgRef.current.click();
    }
  }, [crop_window]);

  return (
    <div className={Style.image_edit}>
      {/* <div className={Style.image_edit_header}>
        <button className={Style.back_button}>
          <Link to="/dashboard">
            <Icons icon={"back-arrow"} size={20} />
            {windowDimensions.width >= 1200 && <span>Back</span>}
          </Link>
        </button>
        <div className={Style.image_edit_actions}>
          <button
            className={`${Style.image_edit_save} btn btn-border`}
            onClick={save_image}
          >
            Save
          </button>

          <button className={`${Style.image_edit_cancel} btn btn-border`}>
            Cancel
          </button>
        </div>
      </div> */}
      <div className={Style.image_edit_display}>
        <div className={Style.image_wrap}>
          <figure
            className={Style.image_edit_image}
            style={{ display: crop_window && "none" }}
          >
            <div ref={canvasRef}></div>
          </figure>

          <ReactCrop
            aspect={aspect}
            crop={crop}
            // minWidth={300}
            // minHeight={200}
            onChange={(c) => {
              setCrop(c);
              SetcropSelection(c);
              imageObj.SetCropConfig(c);
            }}
            onDragStart={() => {SetCrop_apply_btn(true);}}
            className="react-crop"
            style={{ display: !crop_window && "none" }}
          >
            <img src={ImageData.edit_thumbnail} ref={imgRef} />
          </ReactCrop>
        </div>
        <div className={Style.image_edit_sidebar}>
          {windowDimensions.width >= 992 ? (
            <>
              <div className={Style.image_edit_actions}>
                <div className="btn-wrap">
                  <button onClick={save_image} className="btn btn-primary">Save</button>
                </div>
                <div className="btn-wrap">
                  <button className="btn btn-border btn-border-grey">
                    Exit
                  </button>
                </div>
              </div>
              <Tab.Container id="left-tabs-example" defaultActiveKey="edit">
                <Nav variant="pills" className={Style.image_edit_tab_nav}>
                  <Nav.Item>
                    <Nav.Link eventKey="edit">
                      <Icons icon={"crop"} size={15} />
                      Crop
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="adjust">
                      <Icons icon={"outline-filter"} size={15} />
                      Adjust
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="filter">
                      <Icons icon={"icon-filter"} size={15} />
                      Filters
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="edit">
                    <ul className={Style.edit_list}>
                      <li
                        className={`${Style.crop_dropdown} ${
                          cropOpen && Style.crop_open
                        }`}
                      >
                        <ul>
                          <li>
                            <button
                              className={`${Style.small_btn} btn`}
                              onClick={() => {
                                SetCrop_apply_btn(true);
                                SetCrop_window(true);
                                SetAspect(false);
                              }}
                            >
                              Custom
                            </button>
                          </li>
                          <li>
                            <button
                              onClick={() => {
                                SetCrop_apply_btn(true);
                                SetAspect(1 / 1);
                                SetCrop_window(true);
                              }}
                              className={`${Style.small_btn} btn`}
                            >
                              1:1
                            </button>
                          </li>
                          <li>
                            <button
                              onClick={() => {
                                SetCrop_apply_btn(true);
                                SetAspect(16 / 9);
                                SetCrop_window(true);
                              }}
                              className={`${Style.small_btn} btn`}
                            >
                              16:9
                            </button>
                          </li>
                          <li>
                            <button
                              onClick={() => {
                                SetCrop_apply_btn(true);
                                SetAspect(9 / 16);
                                SetCrop_window(true);
                              }}
                              className={`${Style.small_btn} btn`}
                            >
                              9:16
                            </button>
                          </li>
                          <li>
                            <button
                              onClick={() => {
                                SetCrop_apply_btn(true);
                                SetAspect(21 / 9);
                                SetCrop_window(true);
                              }}
                              className={`${Style.small_btn} btn`}
                            >
                              21:9
                            </button>
                          </li>
                          <li>
                            <button
                              onClick={() => {
                                SetCrop_apply_btn(true);
                                SetAspect(9 / 21);
                                SetCrop_window(true);
                              }}
                              className={`${Style.small_btn} btn`}
                            >
                              9:21
                            </button>
                          </li>
                        </ul>
                        {(crop_apply_btn && cropselection !== false ) && (
                          <div className={Style.edit_list_actions}>
                            <div className="btn-wrap">
                              <button
                                className="btn btn-green"
                                onClick={apply_crop}
                              >
                                Apply
                              </button>
                            </div>
                            <div className="btn-wrap">
                              <button className="btn btn-border" onClick={cancel_crop}>Cancel</button>
                            </div>
                          </div>
                        )}
                      </li>
                    </ul>
                  </Tab.Pane>
                  <Tab.Pane eventKey="adjust">
                    <ul className={Style.edit_list}>
                      <li>
                        <h6 className={Style.edit_list_option_title}>
                          Brightness
                        </h6>
                        <div className={Style.edit_list_option}>
                          <Form.Range
                            maxValue={100}
                            minValue={1}
                            value={filterState?.brightness.value}
                            id="brightness"
                            name="brightness"
                            onChange={rangeAdjust}
                          />
                        </div>
                      </li>
                      <li>
                        <h6 className={Style.edit_list_option_title}>
                          contrast
                        </h6>
                        <div className={Style.edit_list_option}>
                          <Form.Range
                            maxValue={100}
                            minValue={1}
                            value={filterState?.contrast.value}
                            id="contrast"
                            name="contrast"
                            onChange={rangeAdjust}
                          />
                        </div>
                      </li>
                      <li>
                        <h6 className={Style.edit_list_option_title}>
                          Saturate
                        </h6>
                        <div className={Style.edit_list_option}>
                          <Form.Range
                            maxValue={100}
                            minValue={1}
                            value={filterState?.saturation.value}
                            onChange={rangeAdjust}
                            id="saturation"
                            name="saturation"
                          />
                        </div>
                      </li>
                    </ul>
                  </Tab.Pane>
                  <Tab.Pane eventKey="filter">
                    <ul className={`${Style.filter_list} row`}>{filterHTML}</ul>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </>
          ) : (
            // <Accordion defaultActiveKey={["0", "1"]} alwaysOpen>
            //   <Accordion.Item eventKey="0">
            //     <Accordion.Header>
            //       <Icons icon={"edit"} size={15} /> Edit
            //     </Accordion.Header>
            //     <Accordion.Body>
            //       <ul className={Style.edit_list}>
            //         <li
            //           className={`${Style.crop_dropdown} ${
            //             cropOpen && Style.crop_open
            //           }`}
            //         >
            //           <button
            //             className="btn btn-primary"
            //             onClick={() =>
            //               cropOpen ? setCropOpen(false) : setCropOpen(true)
            //             }
            //           >
            //             <Icons icon={"crop"} size={18} /> Crop
            //           </button>
            //           <ul>
            //             <li>
            //               <button
            //                 className={`${Style.small_btn} btn`}
            //                 onClick={() => setOpenCropModal(true)}
            //               >
            //                 Custom
            //               </button>
            //             </li>
            //             <li>
            //               <button onClick={() =>{
            //                 SetAspect(1/1);
            //                 setOpenCropModal(true);
            //               }} className={`${Style.small_btn} btn`}>
            //                 1:1
            //               </button>
            //             </li>
            //             <li>
            //               <button  onClick={() =>{
            //                 SetAspect(16/9);
            //                 setOpenCropModal(true);
            //               }} className={`${Style.small_btn} btn`}>
            //                 16:9
            //               </button>
            //             </li>
            //             <li>
            //               <button  onClick={() =>{
            //                 SetAspect(9/16);
            //                 setOpenCropModal(true);
            //               }} className={`${Style.small_btn} btn`}>
            //                 9:16
            //               </button>
            //             </li>
            //             <li>
            //               <button onClick={() =>{
            //                 SetAspect(21/9);
            //                 setOpenCropModal(true);
            //               }}  className={`${Style.small_btn} btn`}>
            //                 21:9
            //               </button>
            //             </li>
            //             <li>
            //               <button onClick={() =>{
            //                 SetAspect(9/21);
            //                 setOpenCropModal(true);
            //               }}  className={`${Style.small_btn} btn`}>
            //                 9:21
            //               </button>
            //             </li>
            //           </ul>
            //         </li>

            //         <li>
            //           <h6 className={Style.edit_list_option_title}>
            //             Brightness
            //           </h6>
            //           <div className={Style.edit_list_option}>
            //             <Form.Range
            //               maxValue={100}
            //               minValue={1}
            //               value={filterState?.brightness.value}
            //               id="brightness"
            //               name="brightness"
            //               onChange={rangeAdjust}
            //             />
            //           </div>
            //         </li>
            //         <li>
            //           <h6 className={Style.edit_list_option_title}>contrast</h6>
            //           <div className={Style.edit_list_option}>
            //             <Form.Range
            //               maxValue={100}
            //               minValue={1}
            //               value={filterState?.contrast.value}
            //               id="contrast"
            //               name="contrast"
            //               onChange={rangeAdjust}
            //             />
            //           </div>
            //         </li>
            //         <li>
            //           <h6 className={Style.edit_list_option_title}>Saturate</h6>
            //           <div className={Style.edit_list_option}>
            //             <Form.Range
            //               maxValue={100}
            //               minValue={1}
            //               value={filterState?.saturation.value}
            //               onChange={rangeAdjust}
            //               id="saturation"
            //               name="saturation"
            //             />
            //           </div>
            //         </li>
            //       </ul>
            //     </Accordion.Body>
            //   </Accordion.Item>
            //   <Accordion.Item eventKey="1">
            //     <Accordion.Header>
            //       <Icons icon={"icon-filter"} size={15} /> Filters
            //     </Accordion.Header>
            //     <Accordion.Body>
            //       <ul className={`${Style.filter_list} row`}>
            //         {filterHTML}
            //       </ul>
            //     </Accordion.Body>
            //   </Accordion.Item>
            // </Accordion>
            <div className={Style.edit_menu_mob}>
              <Tab.Container id="left-tabs-example" defaultActiveKey="edit">
                <Row>
                  <Col>
                    <Nav variant="pills">
                      <Nav.Item>
                        <Nav.Link eventKey="edit" href="#">
                          <Icons icon={"crop"} size={14} /> Crop
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="adjust" href="#">
                          <Icons icon={"outline-filter"} size={14} /> Adjust
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="filter" href="#">
                          <Icons icon={"icon-filter"} size={14} /> Filters
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col>
                    <Tab.Content>
                      <Tab.Pane eventKey="edit">
                        <ul className={Style.edit_list}>
                          <li
                            className={`${Style.crop_dropdown} ${
                              cropOpen && Style.crop_open
                            }`}
                          >
                            <button
                              className="btn btn-primary w-100"
                              onClick={() =>
                                cropOpen
                                  ? setCropOpen(false)
                                  : setCropOpen(true)
                              }
                            >
                              <Icons icon={"crop"} size={18} /> Crop
                            </button>
                            <ul>
                              <li>
                                <button
                                  className={`${Style.small_btn} btn`}
                                  onClick={() => {
                                    SetCrop_window(true);
                                  }}
                                >
                                  Custom
                                </button>
                              </li>
                              <li>
                                <button
                                  onClick={() => {
                                    SetAspect(1 / 1);
                                    SetCrop_window(true);
                                  }}
                                  className={`${Style.small_btn} btn`}
                                >
                                  1:1
                                </button>
                              </li>
                              <li>
                                <button
                                  onClick={() => {
                                    SetAspect(16 / 9);
                                    SetCrop_window(true);
                                  }}
                                  className={`${Style.small_btn} btn`}
                                >
                                  16:9
                                </button>
                              </li>
                              <li>
                                <button
                                  onClick={() => {
                                    SetAspect(9 / 16);
                                    SetCrop_window(true);
                                  }}
                                  className={`${Style.small_btn} btn`}
                                >
                                  9:16
                                </button>
                              </li>
                              <li>
                                <button
                                  onClick={() => {
                                    SetAspect(21 / 9);
                                    SetCrop_window(true);
                                  }}
                                  className={`${Style.small_btn} btn`}
                                >
                                  21:9
                                </button>
                              </li>
                              <li>
                                <button
                                  onClick={() => {
                                    SetAspect(9 / 21);
                                    SetCrop_window(true);
                                  }}
                                  className={`${Style.small_btn} btn`}
                                >
                                  9:21
                                </button>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </Tab.Pane>
                      <Tab.Pane eventKey="adjust">
                        <ul className={Style.edit_list}>
                          {/* <li
                            className={`${Style.crop_dropdown} ${
                              cropOpen && Style.crop_open
                            }`}
                          >
                            <button
                              className="btn btn-primary"
                              onClick={() =>
                                cropOpen
                                  ? setCropOpen(false)
                                  : setCropOpen(true)
                              }
                            >
                              <Icons icon={"crop"} size={16} /> Crop
                            </button>
                            <ul>
                              <li>
                                <button
                                  className={`${Style.small_btn} btn`}
                                  onClick={() => setOpenCropModal(true)}
                                >
                                  Custom
                                </button>
                              </li>
                              <li>
                                <button className={`${Style.small_btn} btn`}>
                                  1:1
                                </button>
                              </li>
                              <li>
                                <button className={`${Style.small_btn} btn`}>
                                  16:9
                                </button>
                              </li>
                              <li>
                                <button className={`${Style.small_btn} btn`}>
                                  9:16
                                </button>
                              </li>
                              <li>
                                <button className={`${Style.small_btn} btn`}>
                                  21:9
                                </button>
                              </li>
                              <li>
                                <button className={`${Style.small_btn} btn`}>
                                  9:21
                                </button>
                              </li>
                            </ul>
                          </li> */}
                          <li>
                            <h6 className={Style.edit_list_option_title}>
                              Opacity
                            </h6>
                            <div className={Style.edit_list_option}>
                              <Form.Range />
                            </div>
                          </li>
                          <li>
                            <h6 className={Style.edit_list_option_title}>
                              Brightness
                            </h6>
                            <div className={Style.edit_list_option}>
                              <Form.Range />
                            </div>
                          </li>
                          <li>
                            <h6 className={Style.edit_list_option_title}>
                              Saturate
                            </h6>
                            <div className={Style.edit_list_option}>
                              <Form.Range />
                            </div>
                          </li>
                        </ul>
                      </Tab.Pane>
                      <Tab.Pane eventKey="filter">
                        <ul className={`${Style.filter_list} row`}>
                          {filterHTML}
                        </ul>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          )}
        </div>
      </div>
      <CommonModal
        showModal={openCropModal}
        hide={() => setOpenCropModal(!openCropModal)}
        className={Style.crop_modal}
        content={
          <>
            <div className={Style.crop_modal_contents}>
              <h2 className={Style.crop_modal_title}>Crop Image</h2>
              <p className={Style.crop_modal_description}>
                Click and draw an area inside the image to crop
              </p>
              {/* <figure className={Style.crop_modal_image}>
                        <img src="https://images.pexels.com/photos/1329711/pexels-photo-1329711.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="crop-img-view" />
                    </figure> */}
              <ReactCrop
                aspect={aspect}
                crop={crop}
                minWidth={300}
                minHeight={200}
                onChange={(c) => {
                  setCrop(c);
                  imageObj.SetCropConfig(c);
                }}
                className="react-crop"
              >
                <img src={ImageData.edit_thumbnail} ref={imgRef} />
              </ReactCrop>
              <div className={Style.crop_modal_actions}>
                <div className={Style.btn_wrap}>
                  <button onClick={apply_crop} className="btn btn-secondary">
                    Crop
                  </button>
                </div>
                <div className={Style.btn_wrap}>
                  <button onClick={cancel_crop} className="btn btn-border">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </>
        }
      />
    </div>
  );
};

export default ImageEditComponent;
