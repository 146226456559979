import Style from "./SearchProfileTabs.module.scss";

export const SearchProfileTabs = ({ setContent }) => {
  return (
    <div className={Style.searchprofiletabs}>
      <ul>
        <li value="overview" onClick={() => setContent("overview")}>
          Overview
        </li>
        <li value="gallery" onClick={() => setContent("gallery")}>
          Gallery
        </li>
      </ul>
    </div>
  );
};
