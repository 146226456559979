import { useState } from "react";
import Style from "./SearchProfileFamilyMembers.module.scss";

export const SearchProfileFamilyMembers = () => {
  const filter = () => {
    {
      setCategory("all");
    }
  };
  const [category, setCategory] = useState("");

  return (
    <div className={Style.familymembers}>
      <div>
        <div className="h5">Family Members</div>
      </div>
      <div>
        <ul>
          <li onClick={filter} value="all" name="all">
            All
          </li>
          <li>Parents</li>
          <li>Siblings</li>
          <li>Partner</li>
          <li>Children</li>
        </ul>
      </div>
      {category && (
        <div>
          <p>all</p>
        </div>
      )}
    </div>
  );
};
