import React, { useState } from "react";
import { Slider } from "antd";
import Style from "./FitToFrame.module.scss";

const FitToFrame = ({ frame, image, frameData, setFrameData }) => {
  const handleCropValue = (value) => {
    setFrameData({
      ...frameData,
      crop_value: value,
      frameClass: "frameFit",
    });
  };

  console.log(image,frameData,"hey,,,album..")

  return (
    <div className={Style.fitToframeSection}>
      <figure
        className={Style.frameFit}
        style={{ borderImageSource: `url(${frameData?.frame})` }}
      >
        <img
          src={image}
          className={Style.frame}
          style={{ transform: `scale(${frameData?.crop_value})` }}
        ></img>
      </figure>

      <div className={Style.sliderSection}>
        <p className={Style.sliderHeader}>Fit Into Frame</p>
        <Slider
          min={1}
          max={2}
          step={0.1}
          //tipFormatter={volumeFormat}
          value={frameData?.crop_value ? frameData?.crop_value : 0}
          onChange={(value) => {
            handleCropValue(value);
          }}
        />
      </div>
    </div>
  );
};

//

export default FitToFrame;
