import React, { useEffect, useState } from "react";
import { useDimensions } from "../../../Logic/Dimensions";
import Assets from "../../Layouts/Assets";
import Icons from "../../Layouts/Icons";
import Style from "./AlbumItem.module.scss";
import { Dropdown, Col, Row, Form, Button, ListGroup } from "react-bootstrap";
import CommonModal from "../../Modals/commonModal";
import FrameItem from "../FrameItem";
import { render } from 'react-dom';
import FitToFrame from "../FitToFrame/FitToFrame";
import useAlbum from "../../../Logic/useAlbum";
import { useDispatch, useSelector } from "react-redux";
import { currentSelectedAlbum } from "../../../store/slices/gallerySlice";
import { useLinkClickHandler } from "react-router-dom";

const AlbumItem = ({
    frames,
    data,
    setShowFullView,
    showFullView,
    handleClickEdit,
    setOpenEditModal,
    openEditModal,
    setFrameData,
    actions,
    key
}) => {

    const dispatch = useDispatch()
    const windowDimensions = useDimensions();
    const windowWidth = windowDimensions.width;
    const { handleRemoveAlbum, selectAlbumItems, sections, currentTab } = useAlbum()

    const [loadAlbum, setLoadAlbum] = useState(false);

    const handleOpen = (data) => {
        dispatch(currentSelectedAlbum(data.id))
        setFrameData(data)
        setShowFullView(true)
    }

    const clickHandler = (id) => {
        selectAlbumItems(id)
    }

    useEffect(() => {
        setTimeout(() => {
            document.querySelectorAll('.album-item').forEach((album, i) => {
                album.classList.add('album-visible');
                album.style.animationDelay = `${i * 0.1}s`;
            });
        }, 100)
        setTimeout(() => {
            document.querySelectorAll('.album-item').forEach((album, i) => {
                album.classList.remove('album-visible');
                album.classList.remove('album-initial-visibility');
            });
        }, 2000)
    }, [])

    return (
        <>
            <div key={key} className={`${Style.album_item} ${actions?.preselect ? Style.preSelectAlbum : ""} `} onClick={() => clickHandler(data?.id)}>
                <div className={`${Style.album_item_img_wrap} ${loadAlbum || (!data?.src?.medium_thumbnail) ? 'img-loaded' : 'img-loading'} ${(!data?.src?.medium_thumbnail) ? 'no-contents' : ''}`}>
                    {sections[currentTab]?.selectedImages?.includes(data?.id) && actions?.preselect ?
                        <div className={Style.gallery_select_tick}>
                            <Icons icon={"icon-tick"} size={17} />
                        </div>
                        :
                        <div className={`${actions?.preselect && Style.selectionItem}`} />
                    }
                    <figure>
                        <img className={`${Style.album_item_main_img} ${data?.src?.medium_thumbnail ? '' : 'm-0'}`} src={(data?.src?.medium_thumbnail) ? data?.src?.medium_thumbnail : Assets.blank_folder} alt="" onLoad={() => setLoadAlbum(true)} />
                        {data?.src?.medium_thumbnail && 
                            <img className={Style.album_item_photo_frame} src={data?.frame} alt="circle_yellow" />
                        }
                    </figure>
                    {windowWidth >= 1280 ? (
                        <>
                            {actions?.preselect ?
                                ""
                                :
                                <div className={Style.album_item_hover_list}>
                                    <ListGroup as="ul">
                                        <ListGroup.Item as="li" onClick={() => handleOpen(data)}>Open</ListGroup.Item>
                                        <ListGroup.Item as="li" onClick={() => handleClickEdit(data)}>Edit Album</ListGroup.Item>
                                        {/* <ListGroup.Item as="li">Download</ListGroup.Item> */}
                                        <ListGroup.Item as="li" onClick={() => handleRemoveAlbum(data?.id)}>Remove Album</ListGroup.Item>
                                    </ListGroup>
                                </div>
                            }

                        </>
                    ) : (<></>)}
                </div>

                {loadAlbum || (!data?.src?.medium_thumbnail) ? (
                    <>
                        <div className={Style.album_item_content}>
                            {windowWidth > 1200 ? (
                                <h4 className={Style.album_item_name}>{data?.title}</h4>
                            ) : (
                                <div className="d-flex">
                                    <h4 className={Style.album_item_name}>{data?.title}</h4>
                                    <div className={Style.ml_auto}>
                                        <Dropdown className={Style.album_item_options}>
                                            <Dropdown.Toggle className={Style.album_item_options_btn}>
                                                <Icons icon={"options"} size={20} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => handleOpen(data)}>Open</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleClickEdit(data)}>Edit Album</Dropdown.Item>
                                                {/* <Dropdown.Item>Download</Dropdown.Item> */}
                                                {/* <Dropdown.Item>Archive</Dropdown.Item> */}
                                                <Dropdown.Item onClick={() => handleRemoveAlbum(data?.id)}>Remove album</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            )}
                            <p className={Style.album_item_dis}>{data?.description}...</p>
                        </div>
                    </>
                ) : (
                    <>
                        <div className={Style.album_item_content}>
                            <h4 className={Style.album_item_name}></h4>
                            <p className={Style.album_item_dis}></p>
                            <p className={Style.album_item_dis}></p>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default AlbumItem;


  //${loadAlbum ? 'img-loaded' : 'img-loading'}