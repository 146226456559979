import Style from "./GalleryImage.module.scss";
import { useEffect, useRef, useState } from "react";
import { useDrag, useDrop, DragPreviewImage } from "react-dnd";
import useGalleryImage from "../../../../Logic/useGalleryImage";
import useGallery from "../../../../Logic/useGallery";
import Icons from "../../../../components/Layouts/Icons";
import { ProgressBar } from "react-bootstrap";
import placeholder1 from "../../../../assets/placeholder-image.svg";
import { handleremoveFailedItem } from "../../../../store/slices/gallerySlice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const GalleryImage = ({
  image,
  i,
  moveListItem,
  handleOpenImageFullView,
  frame,
  albumData,
}) => {
  const someRef = useRef(null);

  const [isActive, setActive] = useState("false");
  const dispatch = useDispatch();

  const handleToggle = () => {
    setActive(!isActive);
  };

  const { toBeDragged, setToBeDragged, handleMouseDown, handleMouseUp } =
    useGalleryImage();
  const {
    addImageHandler,
    handleClickFavorite,
    handleDeleteImages,
    removeFromArchive,
  } = useGallery();
  const {
    sections,
    currentTab,
    activeUploadIndex,
    isSingleImageUpload,
    activeUploadPercent,
    allImageStatus,
    faileditems,
  } = useSelector((state) => state.gallery);
  const [onMouseHover, setOnMouseHover] = useState(false);
  const [onLoadState, setOnLoadState] = useState(false);

  const [{ isDragging }, dragRef] = useDrag({
    type: "item",
    item: { i },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const handleRemove = (e) => {
    setOnLoadState(true);
  };

  const clickHandler = (i) => {
    if (sections[currentTab]?.currentActions?.preselect) {
      addImageHandler(image?.id);
    } else {
      if (currentTab == "all") {
        handleOpenImageFullView(i);
      }
    }
  };
  const [spec, dropRef] = useDrop({
    accept: "item",
    spec: { index: i },
    drop: (item) => {
      moveListItem(item.i, i, sections[currentTab]?.data);
    },
  });

  useEffect(() => {
    if (!isDragging) {
      setToBeDragged(false);
    }
  }, [isDragging]);

  const handleMouseEnter = () => {
    if (!sections[currentTab]?.currentActions?.preselect) {
      if (!sections[currentTab]?.currentActions?.rearrange) {
        setOnMouseHover(true);
      }
    }
  };

  const handleMouseLeave = () => {
    if (!sections[currentTab]?.currentActions?.preselect) {
      setOnMouseHover(false);
    }
  };

  return (
    <div
      className={Style.drop_container}
      ref={sections[currentTab]?.currentActions?.rearrange ? dropRef : someRef}
      style={{
        opacity: isDragging ? 0 : 1,
        // transform: !toBeDragged ? "scale(1)" : "scale(0.95)",
        position: "relative",
      }}
    >
      <div
        className={`${Style.gallery_image_cont} ${
          sections[currentTab]?.currentActions?.rearrange &&
          Style.gallery_image_cont_edit
        } ${
          image.favourite == 1 ? `${Style.favorite_active} favorite-active` : ""
        } ${allImageStatus === "loading" ? "data-loading" : ""}`}
      >
        <figure
          className={`${Style.gallery_image_wrap} ${frame && Style.frameFit} ${
            faileditems?.includes(i) ? Style.image_error : ""
          } ${onMouseHover ? Style.onHover_figure : ""} ${
            !onLoadState ? "img-loading" : "img-loaded"
          }`}
          style={{ borderImageSource: `url(${frame})` }}
        >
          {sections[currentTab]?.selectedImages?.includes(image?.id) &&
          sections[currentTab]?.currentActions?.preselect &&
          !sections[currentTab]?.currentActions?.rearrange ? (
            <figure className={Style.gallery_select_tick}>
              <Icons icon={"icon-tick"} size={17} />
            </figure>
          ) : sections[currentTab]?.currentActions?.preselect ? (
            <div className="selection" />
          ) : (
            ""
          )}
          <img
            style={{
              transform:
                albumData?.album?.crop_value > 0 &&
                ` scale(${albumData?.album?.crop_value})`,
            }}
            ref={
              sections[currentTab]?.currentActions?.rearrange
                ? dragRef
                : someRef
            }
            className={` ${
              image?.src?.thumbnail1 ? Style.placeholder_image_active : ""
            } ${currentTab == "all" && Style.galleryMode}`}
            onClick={() => clickHandler(i)}
            src={image?.src?.thumbnail2}
            alt="Loading"
            onMouseEnter={() => handleMouseEnter()}
            onMouseLeave={() => handleMouseLeave()}
            onMouseDown={() =>
              sections[currentTab]?.currentActions?.rearrange &&
              handleMouseDown()
            }
            onMouseUp={handleMouseUp}
            onLoad={(e) => handleRemove(e)}
          />
          {faileditems?.includes(i) ? (
            <div className={Style.uploadError}>
              <span>{"Image must be at least 500 kilobytes"}</span>
              <div
                className={Style.errorButton}
                onClick={() => dispatch(handleremoveFailedItem(i))}
              >
                Remove
              </div>
            </div>
          ) : (
            ""
          )}
          {image?.src?.thumbnail1 ? (
            <div
              className={`${Style.gallery_image_tray} image_tray`}
              style={{ display: toBeDragged ? "none" : "flex" }}
            >
              <div className={Style.tray_details}>
                <div className={Style.gallery_image_tray_author}>
                  {image?.user?.username}{" "}
                </div>
                <div
                  className={`${Style.gallery_image_tray_post_date} post-date-wrap`}
                >
                  <span className="post-date-label">Posted:</span>{" "}
                  <span className="post-date">
                    {moment(image?.created_at).format("MMMM D, YYYY")}
                  </span>
                </div>
              </div>
              <div className={Style.tray_actions}>
                {currentTab == "archive" ? (
                  <button
                    className={Style.tray_actions_btn}
                    onClick={() => removeFromArchive(image?.id)}
                    onMouseEnter={() => handleMouseEnter()}
                    onMouseLeave={() => handleMouseLeave()}
                  >
                    <Icons icon={"box-remove"} size={14} />
                  </button>
                ) : (
                  <>
                    {/* <button className={Style.tray_actions_btn}   
              onMouseEnter={() => handleMouseEnter()}
              onMouseLeave={() => handleMouseLeave()}
              >
                <Icons icon={"icon-download"} size={14} />
              </button> */}
                    <button
                      className={`${Style.tray_actions_btn} ${Style.favorite_btn} `}
                      onClick={() => handleClickFavorite(image?.id)}
                      onMouseEnter={() => handleMouseEnter()}
                      onMouseLeave={() => handleMouseLeave()}
                    >
                      {/* <Icons icon={"icon-love"} size={14} /> */}
                      <svg
                        id="heart-svg"
                        viewBox="467 392 58 57"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g
                          id="Group"
                          fill="none"
                          fill-rule="evenodd"
                          transform="translate(467 392)"
                        >
                          <path
                            d="M29.144 20.773c-.063-.13-4.227-8.67-11.44-2.59C7.63 28.795 28.94 43.256 29.143 43.394c.204-.138 21.513-14.6 11.44-25.213-7.214-6.08-11.377 2.46-11.44 2.59z"
                            id="heart"
                            fill="#AAB8C2"
                          />
                          <path
                            d="M29.144 20.773c-.063-.13-4.227-8.67-11.44-2.59C7.63 28.795 28.94 43.256 29.143 43.394c.204-.138 21.513-14.6 11.44-25.213-7.214-6.08-11.377 2.46-11.44 2.59z"
                            id="heart"
                            fill="#AAB8C2"
                          />
                          <circle
                            id="main-circ"
                            fill="#E2264D"
                            opacity="0"
                            cx="29.5"
                            cy="29.5"
                            r="1.5"
                          />

                          <g id="grp7" opacity="0" transform="translate(7 6)">
                            <circle
                              id="oval1"
                              fill="#9CD8C3"
                              cx="2"
                              cy="6"
                              r="2"
                            />
                            <circle
                              id="oval2"
                              fill="#8CE8C3"
                              cx="5"
                              cy="2"
                              r="2"
                            />
                          </g>

                          <g id="grp6" opacity="0" transform="translate(0 28)">
                            <circle
                              id="oval1"
                              fill="#CC8EF5"
                              cx="2"
                              cy="7"
                              r="2"
                            />
                            <circle
                              id="oval2"
                              fill="#91D2FA"
                              cx="3"
                              cy="2"
                              r="2"
                            />
                          </g>

                          <g id="grp3" opacity="0" transform="translate(52 28)">
                            <circle
                              id="oval2"
                              fill="#9CD8C3"
                              cx="2"
                              cy="7"
                              r="2"
                            />
                            <circle
                              id="oval1"
                              fill="#8CE8C3"
                              cx="4"
                              cy="2"
                              r="2"
                            />
                          </g>

                          <g id="grp2" opacity="0" transform="translate(44 6)">
                            <circle
                              id="oval2"
                              fill="#CC8EF5"
                              cx="5"
                              cy="6"
                              r="2"
                            />
                            <circle
                              id="oval1"
                              fill="#CC8EF5"
                              cx="2"
                              cy="2"
                              r="2"
                            />
                          </g>

                          <g id="grp5" opacity="0" transform="translate(14 50)">
                            <circle
                              id="oval1"
                              fill="#91D2FA"
                              cx="6"
                              cy="5"
                              r="2"
                            />
                            <circle
                              id="oval2"
                              fill="#91D2FA"
                              cx="2"
                              cy="2"
                              r="2"
                            />
                          </g>

                          <g id="grp4" opacity="0" transform="translate(35 50)">
                            <circle
                              id="oval1"
                              fill="#F48EA7"
                              cx="6"
                              cy="5"
                              r="2"
                            />
                            <circle
                              id="oval2"
                              fill="#F48EA7"
                              cx="2"
                              cy="2"
                              r="2"
                            />
                          </g>

                          <g id="grp1" opacity="0" transform="translate(24)">
                            <circle
                              id="oval1"
                              fill="#9FC7FA"
                              cx="2.5"
                              cy="3"
                              r="2"
                            />
                            <circle
                              id="oval2"
                              fill="#9FC7FA"
                              cx="7.5"
                              cy="2"
                              r="2"
                            />
                          </g>
                        </g>
                      </svg>
                    </button>
                    <button
                      className={Style.tray_actions_btn}
                      onClick={() => handleDeleteImages(image?.id)}
                      onMouseEnter={() => handleMouseEnter()}
                      onMouseLeave={() => handleMouseLeave()}
                    >
                      <Icons icon={"icon-trash"} size={14} />
                    </button>
                  </>
                )}
              </div>
            </div>
          ) : (
            ""
          )}
          {/* {i == activeUploadIndex && image?.src?.thumbnail1 == null && (<ProgressBar now={activeUploadPercent} />)} */}
        </figure>
        {sections[currentTab]?.currentActions?.rearrange && (
          <div
            className={Style.delete_img}
            style={{ display: toBeDragged ? "none" : "flex" }}
          >
            <span>+</span>
          </div>
        )}
      </div>
      <div></div>
    </div>
  );
};

export default GalleryImage;
