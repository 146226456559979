import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../api";


const initialState = {
  list:[],
  frameListStatus:"idle",
  albumListStatus:"idle",
  isAlbumCreating:"idle",
  isAddtoToAlbum:"idle",
}


export const frameList = createAsyncThunk(
  "album/frameList",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`frames-list`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
      
    }
  }
);


  export const albumList = createAsyncThunk(
    "album/albumList",
    async (params, { rejectWithValue }) => {
      const api = await getAxiosInstance();
      try {
        const response = await api.get(`album-list`);
        return response;
      } catch (error) {
        return rejectWithValue(error.response.data);
        
      }
    }
  );

  export const createAlbum = createAsyncThunk(
    "album/createAlbum",
    async (params, { rejectWithValue }) => {
      const api = await getAxiosInstance();
      try {
        const response = await api.post(`create-album`, params);
        return response;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );

  export const removeAlbum = createAsyncThunk(
    "album/removeAlbum",
    async (params, { rejectWithValue }) => {
      const api = await getAxiosInstance();
      try {
        const response = await api.get(`delete-album/${params}`);
        
        return response;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );


  const albumSlice = createSlice({
    name: "album",
    initialState,
    reducers: {},
    extraReducers: {

        [frameList.pending]: (state, action) => {
        state.frameListStatus = "loading";
        state.message = null;
        },
        [frameList.fulfilled]: (state, action) => {
        state.frameListStatus = "succeeded";
        state.frames=action.payload.data.data
        },
        [frameList.rejected]: (state, action) => {
        state.frameListStatus = "failed";
        },
        [albumList.pending]: (state, action) => {
        state.albumListStatus = "loading";
        state.message = null;
        },
        [albumList.fulfilled]: (state, action) => {
        state.albumListStatus = "succeeded";
        state.list=action.payload.data.data
        },
        [albumList.rejected]: (state, action) => {
        state.albumListStatus = "failed";
        },
        
        
        [createAlbum.pending]: (state, action) => {
        state.isAlbumCreating = "loading";

        state.message = null;
        },
        [createAlbum.fulfilled]: (state, action) => {
        state.isAlbumCreating = "succeeded";
        
        },
        [createAlbum.rejected]: (state, action) => {
        state.isAlbumCreating = "failed";
        },

        [removeAlbum.pending]: (state, action) => {
        state.isAlbumRemoved = "loading";
        state.message = null;
        },
        [removeAlbum.fulfilled]: (state, action) => {
        state.isAlbumRemoved = "succeeded";
        },
        [removeAlbum.rejected]: (state, action) => {
        state.isAlbumRemoved = "failed";
        }
    }
  })

  export default albumSlice.reducer;
