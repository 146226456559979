import { useState, useEffect } from "react";
import { getAxiosInstance } from "../api";
import { useDispatch, useSelector } from "react-redux";
import {
  preUploadImage,
  updateActiveUploadPercentage,
  updateActiveIndex,
  updateUploadedImage,
  getFailedItems,
  singleImageUpload,
  addToThumbs,
  clearThumbs,
  callFunction,
} from "../store/slices/gallerySlice";

const BASE_URL = process.env.REACT_APP_APIURL;
let images = [];
let uploadedImages = [];

const useUploadImageModal = () => {
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [issingleImageUpload, setIsSingleImageUpload] = useState(false);
  const { loginData } = useSelector((state) => state.auth);
  const {
    activeUploadIndex,
    isSingleImageUpload,
    activeUploadPercent,
    faileditems,
    thumbs,
  } = useSelector((state) => state.gallery);

  var date = new Date();
  const dispatch = useDispatch();

  const handleDragOver = () => {
    setIsDraggingOver(true);
  };

  const handleDragOut = () => {
    setIsDraggingOver(false);
  };

  const handleDrop = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (!isUploading) {
      uploadImage(e.dataTransfer.files[images.length - activeUploadIndex]);
    }
    images = [...images, ...e.dataTransfer.files];
    let keyArr = Object.keys(e.dataTransfer.files);
    let fileArr = [];
    keyArr.map((a) => fileArr.push(e.dataTransfer.files[a]));
    fileArr.map((a) => {
      resizeImage(a);
    });

    setIsDraggingOver(false);
  };

  const handleImageChange = (e) => {
    if (!isUploading) {
      uploadImage(e.target.files[images.length - activeUploadIndex]);
    }
    images = [...images, ...e.target.files];

    let keyArr = Object.keys(e.target.files);
    let fileArr = [];
    keyArr.map((a) => fileArr.push(e.target.files[a]));
    fileArr.map((a) => resizeImage(a));
  };

  const handleDeleteImage = (i) => {
    let newFileArr = images.filter((_, index) => index !== i);
    let newThumbsArr = thumbs.filter((_, index) => index !== i);
    // setThumbs(newThumbsArr);
    images = newFileArr;
  };

  useEffect(() => {

    if (images.length > activeUploadIndex) {
      uploadImage(images[activeUploadIndex]);
    } else {
      setIsUploading(false);
    }
  }, [activeUploadIndex]);

  /********************  CANVAS  *******************/
  function resizeImage(file) {
    if (file) {
      let imageFile = file;
      var reader = new FileReader();
      reader.onload = function (e) {
        var img = document.createElement("img");
        img.onload = function () {
          var canvas = document.createElement("canvas");
          var ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, 300, 180);
          var dataUrl = canvas.toDataURL(imageFile.type);
          //setThumbs((state) => [...state, dataUrl]);
          console.log("hey, upload..", dataUrl);
          dispatch(addToThumbs(dataUrl));
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(imageFile);
    }
  }

  /********************  CANVAS  *******************/

  const config = {
    onUploadProgress: function (progressEvent) {
      let percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      dispatch(updateActiveUploadPercentage(percentCompleted));
    },
  };

  const uploadImage = async (file) => {
    let data = new FormData();
    data.append("image", file);
    data.append("user_id", 234);
    setIsUploading(true);

    const api = await getAxiosInstance();
    api
      .post(`${BASE_URL}upload-images`, data, config)
      .then((res) => {
        uploadedImages = [...uploadedImages, res.data.result];
        // dispatch(
        //   updateUploadedImage({
        //     data: res.data.result,
        //     index: activeUploadIndex
        //   })
        // );
        dispatch(updateActiveIndex(activeUploadIndex + 1));
        setIsUploading(false)
      })
      .catch((error) => {
        if (error.response.status == 422) {
          // dispatch(getFailedItems(activeUploadIndex))
          dispatch(updateActiveIndex(activeUploadIndex + 1));
           setIsUploading(true)
        }
      });
  };

  const addImagesToState = () => {
    const addSavedImages = (state) => {
      state.sections.all = {
        ...state.sections.all,
        data: [...uploadedImages, ...state.sections.all.data],
      };
    };
    dispatch(callFunction(addSavedImages));
    images = [];
  };

  // console.log(thumbs, "rwww....");

  return {
    images,
    thumbs,
    isDraggingOver,
    setIsDraggingOver,
    handleDragOver,
    handleDragOut,
    handleDrop,
    handleImageChange,
    activeUploadIndex,
    activeUploadPercent,
    handleDeleteImage,
    addImagesToState,
    uploadedImages,
  };
};

export default useUploadImageModal;
