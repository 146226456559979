import React from 'react';
import Style from './HomeExternalLinks.module.scss';
import { Row, Col, Container } from "react-bootstrap";
import Icons from "../../components/Layouts/Icons";
import Assets from "../../components/Layouts/Assets";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, Pagination } from "swiper";
import Advertisements from "../../constants/advertisements";
import { useDimensions } from '../../Logic/Dimensions';


const HomeExternalLinks = () => {
  const windowDimensions = useDimensions();

  return (
    <div className={Style.home_external_links}>
        <Container>
            <div className={`${Style.home_external_links_card_wrap} anim load-anim`}>
                {(windowDimensions.width >= 1439) ? (
                    <>
                        <Row>
                            {Advertisements.map((adv, i) => {
                                return (
                                    <Col key={adv.id}>
                                        <div className={Style.home_external_links_card}>
                                            <a target="_blank" href={adv.url} rel="noreferrer" className={Style.home_external_links_card_overlay}></a>
                                            <figure className={Style.home_external_links_card_image}>
                                                <img
                                                    src={adv.image}
                                                    alt ={`external-link-icon_${i}`}
                                                />
                                            </figure>
                                            <span className={Style.home_external_links_card_link}>
                                                <span className={Style.link_icon}><Icons icon={"external-link"} size={11} /></span>
                                                <span className={Style.link_text}>{adv.url}</span>
                                            </span>
                                        </div>
                                    </Col>
                                )
                            })}
                        </Row>
                    </>
                ) : (
                 <>
                 <Swiper
                   slidesPerView={1}
                   spaceBetween={30}
                   loop={true}
                   pagination={{
                     clickable: true,
                   }}
                   breakpoints={{
                     576: {
                       slidesPerView: 2,
                       spaceBetween: 30,
                       centeredSlides: false,
                     },
                     768: {
                       slidesPerView: 3,
                       spaceBetween: 30,
                       centeredSlides: false,
                     },
                     992: {
                       slidesPerView: 4,
                       spaceBetween: 30,
                       centeredSlides: false,
                     },
                   }}
                   modules={[Autoplay, Pagination]}
                   autoplay={{
                     delay: 2500,
                     disableOnInteraction: false,
                   }}
                   className={Style.home_external_links_slider}
                 >

                     <SwiperSlide>
                        <div className={Style.home_external_links_card}>
                            <a target="_blank" href ="https://www.fpaphotography.com" rel="noreferrer" className={Style.home_external_links_card_overlay}> </a>
                            <figure className={Style.home_external_links_card_image}>
                                <img
                                    src={Assets.ext_link_1}
                                    alt ="external-link-icon"
                                />
                            </figure>
                            <span className={Style.home_external_links_card_link}>
                                <span className={Style.link_icon}><Icons icon={"external-link"} size={11} /></span>
                                <span className={Style.link_text}>fpaphotography.com</span>
                            </span>
                        </div>
                     </SwiperSlide>
                     <SwiperSlide>
                        <div className={Style.home_external_links_card}>
                            <a target="_blank" href ="https://www.fpaphotography.com" rel="noreferrer" className={Style.home_external_links_card_overlay}> </a>
                            <figure className={Style.home_external_links_card_image}>
                                <img
                                    src={Assets.ext_link_2}
                                    alt ="external-link-icon"
                                />
                            </figure>
                            <span className={Style.home_external_links_card_link}>
                                <span className={Style.link_icon}><Icons icon={"external-link"} size={11} /></span>
                                <span className={Style.link_text}>fpaphotography.com</span>
                            </span>
                        </div>
                     </SwiperSlide>
                     <SwiperSlide>
                        <div className={Style.home_external_links_card}>
                            <a target="_blank" href ="https://www.fpaphotography.com" rel="noreferrer" className={Style.home_external_links_card_overlay}> </a>
                            <figure className={Style.home_external_links_card_image}>
                                <img
                                    src={Assets.ext_link_3}
                                    alt ="external-link-icon"
                                />
                            </figure>
                            <span className={Style.home_external_links_card_link}>
                                <span className={Style.link_icon}><Icons icon={"external-link"} size={11} /></span>
                                <span className={Style.link_text}>fpaphotography.com</span>
                            </span>
                        </div>
                     </SwiperSlide>
                     <SwiperSlide>
                        <div className={Style.home_external_links_card}>
                            <a target="_blank" href ="https://www.fpaphotography.com" rel="noreferrer" className={Style.home_external_links_card_overlay}> </a>
                            <figure className={Style.home_external_links_card_image}>
                                <img
                                    src={Assets.ext_link_4}
                                    alt ="external-link-icon"
                                />
                            </figure>
                            <span className={Style.home_external_links_card_link}>
                                <span className={Style.link_icon}><Icons icon={"external-link"} size={11} /></span>
                                <span className={Style.link_text}>fpaphotography.com</span>
                            </span>
                        </div>
                     </SwiperSlide>
                     <SwiperSlide>
                        <div className={Style.home_external_links_card}>
                            <a target="_blank" href ="https://www.fpaphotography.com" rel="noreferrer" className={Style.home_external_links_card_overlay}> </a>
                            <figure className={Style.home_external_links_card_image}>
                                <img
                                    src={Assets.ext_link_5}
                                    alt ="external-link-icon"
                                />
                            </figure>
                            <span className={Style.home_external_links_card_link}>
                                <span className={Style.link_icon}><Icons icon={"external-link"} size={11} /></span>
                                <span className={Style.link_text}>fpaphotography.com</span>
                            </span>
                        </div>
                     </SwiperSlide>

                 </Swiper>
                 </>   
                )}
            </div>
        </Container>
    </div>
  )
}

export default HomeExternalLinks