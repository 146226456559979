import { useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import Style from "./HomeChooseAPlan.module.scss";
import { Container } from "react-bootstrap";
import Assets from "../Layouts/Assets";
import { useDimensions } from "../../Logic/Dimensions";
import { useHome } from "../../Logic/useHome";
import { useTranslation } from "react-i18next";

const HomeChooseAPlan = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { plans, plansData } = useHome();

  useEffect(() => {
    plans();
  }, []);

  useEffect(() => {
    const allSections = document.querySelectorAll(".anim");
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const element = entry.target;
          element.classList.add("visible");
        }
      });
    });
    allSections.forEach((section) => {
      observer.observe(section);
    });
  }, [location.pathname]);

  return (
    <div
      className={`${
        location.pathname !== "/"
          ? `${Style.home_choose_plans} ${Style.home_choose_plans_get_started}`
          : Style.home_choose_plans
      } `}
    >
      <div className={Style.anim_elements}>
        <figure
          className={`${Style.anim_elem} ${Style.circle_green} anim_element anim`}
        >
          <img src={Assets.plan_circle_green} alt="circle_yellow" />
        </figure>
        <figure
          className={`${Style.anim_elem} ${Style.donut_yellow} anim_element anim`}
        >
          <img src={Assets.plan_donut_yellow} alt="circle_yellow" />
        </figure>
      </div>
      <Container>
        <div
          className={`${
            Style.home_choose_plans_title
          } ${"section_title_block center"}`}
        >
          <h2 className="anim load-anim">{t("choose_plan")}</h2>
          <p className="anim load-anim">
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt dolore.
          </p>
        </div>
        <div className={`${Style.home_choose_plans_cards} anim load-anim`}>
          {plansData
            ? plansData.map((item) => {
                return (
                  <div
                    className={`${Style.home_choose_plans_card_wrap}`}
                    key={item.id}
                  >
                    <div className={Style.home_choose_plans_card}>
                      <div className={Style.card_top}>
                        <div
                          className={`${Style.plan_duration} ${Style.plan_monthly}`}
                        >
                          {item.name}
                        </div>
                        <h4 className={Style.plan_amount}>
                          <span className={Style.plan_amount_value}>
                            ${item.price}
                          </span>{" "}
                          / Month
                        </h4>
                        <p className={Style.plan_description}>
                          {item.description}
                        </p>
                        <ul className={Style.plan_features}>
                          <li>Best-in-class sync technology</li>
                          <li>Anytime, anywhere access</li>
                          <li>Integrated cloud content</li>
                        </ul>
                      </div>
                      <div className={Style.home_choose_plans_card_button}>
                        <button className="btn btn-border-primary">
                          Know More
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })
            : [1, 2, 3].map((item, index) => {
                return (
                  <div
                    className={`${Style.home_choose_plans_card_wrap} data-loading`}
                    key={index}
                  >
                    <div className={Style.home_choose_plans_card}>
                      <div className={Style.card_top}>
                        <div
                          className={`${Style.plan_duration} ${Style.plan_monthly}`}
                        ></div>
                        <h4 className={Style.plan_amount}>
                          <span className={Style.plan_amount_value}></span>{" "}
                        </h4>
                        <p className={Style.plan_description}>
                          <span className={Style.lines}></span>
                          <span className={Style.lines}></span>
                          <span className={Style.lines}></span>
                        </p>
                        <ul className={Style.plan_features}>
                          <li></li>
                          <li></li>
                          <li></li>
                        </ul>
                      </div>
                      <div
                        className={Style.home_choose_plans_card_button}
                      ></div>
                    </div>
                  </div>
                );
              })}
        </div>
      </Container>
    </div>
  );
};

export default HomeChooseAPlan;
