import React from 'react';
import Style from "./SelectedImageItem.module.scss";


const SelectedImageItem = ({data}) => {
  console.log(data,"hhh")
    return (
      <div className={Style.selected_image_item}>
          <div className={Style.selected_image}>
            <img src={data?.src?.thumbnail} />
          </div>
          <div className={Style.selected_image_head}>
            <h4 className={Style.selected_img_title}>{data?.title}</h4>
            {/* <p className={Style.selected_img_time}>Jan-30-2022</p> */}
          </div>
      </div>
    )
  }
  
  
  export default SelectedImageItem