import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  authActions,
  getRegisterData,
  createRegisterOtp,
  verifyOtp,
  storeCurrentAuthData,
} from "../store/slices/authSlice";

import {
  getProfileDetails,
  getFamilyMembersData,
} from "../store/slices/userprofileSlice";

export const useProfile = () => {
  var connections_store,tabRouteData = [];
  const dispatch = useDispatch();
  const { user_id } = useSelector((store) => store.auth.registerData);
  const { userdata } = useSelector((store) => store.userprofile);

  const [floater, setFloater] = useState({
    floaterWidth: 32,
    leftValue: 0,
  });

  const connections_array = [
    { parents: ["mother", "father"] },
    "sibling",
    "partner",
    "children",
    "pet",
  ];

  const [familyMembersSelectedTab, setfamilyMembersSelectedTab] =
    useState(connections_array);

  const [TabRouteData, setTabRouteData] = useState([]);

  const familyMembers = useSelector(
    (state) => state.userprofile.familyMembers.data
  );

  useEffect(() => {
    if (Object.keys(userdata).length == 0) {
      // Checking object is empty or not
      dispatch(getProfileDetails(user_id));
    }

    if (familyMembers.length == 0) {
      dispatch(getFamilyMembersData());
    }
  }, []);

  useEffect(() => {
    tabRouteDataGenerator();
  }, [familyMembers]);
  // Click Event for section tab Family Members

  const handleFloater = (e, data) => {
    setfamilyMembersSelectedTab(data);
    setFloater({
      floaterWidth: e.target.offsetWidth + 16,
      leftValue: e.target.offsetLeft - 8,
    });
  };

  const family_filter = (data, connection, attr) => {
    let result = false;
    data?.forEach((data) => {
      if (data.connection === connection) {
        result = data[attr];
      }
    });
    return result;
  };

  const family_filter_array = (data, connection) => {
    return data?.filter((item) =>
      item.connection == connection ? item : false
    );
  };

  const get_count_family_members = (data, connection) => {
    return data?.filter((item) => (item.connection == connection ? item : false))
      .length;
  };

  const goFirstInArrayObject = function (d, daFirst) {
    // Array Object Position Change based on key name

    var r = [];

    d?.forEach((e) => {
      if (e["name"] === daFirst) r.unshift(e);
      else r.push(e);
    });

    return r;
  };

  const goFirstArray = function (d, daFirst) {
    // Array Object Position Change based on key name

    var r = [];

    d?.forEach((e) => {
      if (e === daFirst) r.unshift(e);
      else r.push(e);
    });

    return r;
  };

  const tabRouteDataOrderCorrection = (data) => {
    
    data = goFirstInArrayObject(data, "Childrens");
    data = goFirstInArrayObject(data, "Partners");
    data = goFirstInArrayObject(data, "Siblings");
    data = goFirstInArrayObject(data, "Parents");
    data = goFirstInArrayObject(data, "All");

    return data;
  };

  const tabRouteDataGenerator = () => {
    /**
     * Dashboard -> User Profile -> Family Members Section
     * Family Members Section tab data generates from this function
     */

    connections_store = [
      ...new Set(familyMembers?.map((item) => item.connection)),
    ];

    var wordsPluralOrSingular = [
      { parents: "parents" },
      {
        sibling:
          get_count_family_members(familyMembers, "sibling") >= 2
            ? "siblings"
            : "sibling",
      },
      {
        partner:
          get_count_family_members(familyMembers, "partner") >= 2
            ? "partners"
            : "partner",
      },
      {
        children:
          get_count_family_members(familyMembers, "children") >= 2
            ? "childrens"
            : "children",
      },
      {
        pet:
          get_count_family_members(familyMembers, "pet") >= 2 ? "pets" : "pet",
      },
    ];

    var temp_store = [];

    /*  All tab data */

    if (temp_store.indexOf("all") === -1) {
      /* Checking all tab data was inserted it or not */
      var obj = {
        name: "All",
        path: "",
        relation: [],
      };

      var relationOrderTemp = connections_store.filter(
        // Getting Connections Array Excluding Unwated
        (e) => e !== "user" && e !== "mother" && e !== "father"
      );

      if (is_parents_exist(familyMembers)) {
        // Keeping Parents in the first position.
        obj.relation.push({ parents: ["mother", "father"] });
      }

      /* Order Re-Arrange Starts */

      relationOrderTemp = goFirstArray(relationOrderTemp, "pet");
      relationOrderTemp = goFirstArray(relationOrderTemp, "children");
      relationOrderTemp = goFirstArray(relationOrderTemp, "partner");
      relationOrderTemp = goFirstArray(relationOrderTemp, "sibling");

      /* Order Re-Arrange Ends */

      obj.relation = obj.relation.concat(relationOrderTemp);

      tabRouteData.push(obj);
      temp_store.push("all");
    }

    /*  All tab data */

    connections_store.map((data) => {
      if (data != "user" && data != "mother" && data != "father") {
        /*  Other tab data excluding user,parents */

        if (temp_store.indexOf(data) === -1) {
          let name = wordsPluralOrSingular.find((e) => e[data]);
          name = name == undefined ? {} : name;

          var obj = {
            name: capitalizeFirstLetter(
              name.hasOwnProperty(data) ? name[data] : "Unknown"
            ),
            path: "",
            relation: [data],
          };

          tabRouteData.push(obj);
          temp_store.push(data);
        }

        /*  Other tab data excluding user,parents */
      } else if (data != "user") {
        /*  Parents tab data */

        if (temp_store.indexOf("parents") === -1) {
          var obj = {
            name: "Parents",
            path: "",
            relation: [],
          };

          if (is_parents_exist(familyMembers)) {
            obj.relation.push({ parents: ["mother", "father"] });
          }

          tabRouteData.push(obj);
          temp_store.push("parents");
        }

        /*  Parents tab data */
      }
    });

    setTabRouteData(tabRouteDataOrderCorrection(tabRouteData));
  };

  const capitalizeFirstLetter = function (str) {
    // converting first letter to uppercase
    if (str != "" && str != undefined && str != null) {
      const capitalized = str.charAt(0).toUpperCase() + str.slice(1);
      return capitalized;
    } else {
      return;
    }
  };

  const is_relation_exist = (data, connection) => {
    // Checking relation checking in array
    let is_exist = false;
    data?.forEach((data) => {
      if (data.connection === connection) {
        is_exist = true;
      }
    });
    return is_exist;
  };

  const is_parents_exist = (data) => {
    // Checking parents exists in array
    let is_exist = [];
    data?.forEach((data) => {
      if (data.connection === "father") {
        is_exist.push("father");
      }
      if (data.connection === "mother") {
        is_exist.push("mother");
      }
    });

    return is_exist.length > 1 ? true : false;
  };

  return {
    userdata,
    floater,
    handleFloater,
    familyMembers,
    family_filter,
    is_relation_exist,
    is_parents_exist,
    family_filter_array,
    capitalizeFirstLetter,
    familyMembersSelectedTab,
    TabRouteData,
  };
};
