import React from "react";
import { Link } from "react-router-dom";
import Style from "./FamilyUsersPrice.module.scss";

const FamilyUsersPrice = () => {
  return (
    <div className={`${Style.family_users} amily-users-ad`}>
      <h4 className={Style.family_users_title}>Family Users</h4>
      <div className={Style.family_users_price}>
        <span>$14</span>
         / Year
      </div>
      <p className={Style.family_users_expiry}>Expires on 03 March 2023</p>
      <div className={Style.family_users_text}>Use our Family Features</div>
      <Link to="/get-started" className="btn btn-white w-100 px-2">
        Know More
      </Link>
    </div>
  );
};

export default FamilyUsersPrice;
