import Style from "./UploadImageModal.module.scss";
import useUploadImageModal from "../../../../../Logic/useUploadImageModal";
import CommonModal from "../../../../Modals/commonModal";
import Assets from "../../../../Layouts/Assets";
import { Swiper, SwiperSlide } from "swiper/react";
import Icons from "../../../../Layouts/Icons";
import CircularProgressBar from "../../../../../utils/CircularProgressLoader";
import {
  singleImageUpload,
  uploadModalAction,
} from "../../../../../store/slices/gallerySlice";
import { useDispatch, useSelector } from "react-redux";

const UploadImageModal = ({ openActionModal, setOpenModal }) => {
  const {
    images,
    thumbs,
    activeUploadIndex,
    activeUploadPercent,
    isDraggingOver,
    handleDragOver,
    handleDragOut,
    handleDrop,
    handleImageChange,
    handleDeleteImage,
    addImagesToState,
    uploadedImages,
  } = useUploadImageModal();
  const dispatch = useDispatch();

  const closeUploadModal = () => {
    addImagesToState();
    // setOpenModal(!openActionModal);
    dispatch(uploadModalAction(false));
  };

  return (
    <CommonModal
      className={Style.file_upload_modal}
      showModal={openActionModal}
      hide={() => closeUploadModal()}
      content={
        <div className={Style.file_upload_modal_form}>
          <div
            className={Style.file_upload_draggable_area}
            style={{
              backgroundColor: !isDraggingOver
                ? ""
                : "var(--file-upload-hover-bg)",
            }}
            // style={{ backgroundColor: !isDraggingOver ? "#fafffb" : "#eaf8ea" }}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onDragLeave={handleDragOut}
          >
            <figure className={Style.file_upload_image}>
              <img src={Assets.file_upload_image} alt="file_upload_image" />
            </figure>
            <h4 className={Style.file_upload_info}>
              Drag and drop an image, or{" "}
              <label
                htmlFor="file_upload_input"
                className={Style.file_upload_input_label}
              >
                Browse
              </label>
            </h4>
            <p className={Style.file_upload_info_desc}>
              upload your photos now to bring your family story to life.
            </p>
            <input
              onChange={handleImageChange}
              className={Style.file_upload_input}
              id="file_upload_input"
              multiple
              type="file"
            ></input>
          </div>
          <div className={Style.file_upload_preview}>
            <Swiper slidesPerView={5} spaceBetween={17}>
              {thumbs.map((a, i) => (
                <SwiperSlide key={i} className={Style.file_upload_preview_wrap}>
                  <figure className={Style.file_upload_preview_image}>
                    <img src={a} alt="preview_image" />
                  </figure>
                  {i > activeUploadIndex && (
                    <button
                      onClick={() => handleDeleteImage(i)}
                      className={Style.file_upload_delete}
                    >
                      <Icons icon={"icon-close"} size={5} />
                    </button>
                  )}
                  {i === activeUploadIndex ? (
                    <CircularProgressBar
                      strokeWidth="4"
                      sqSize="30"
                      percentage={activeUploadPercent}
                    />
                  ) : (
                    <>
                      {i > activeUploadIndex ? (
                        <CircularProgressBar
                          strokeWidth="4"
                          sqSize="30"
                          percentage={0}
                        />
                      ) : (
                        // <p>Completed</p>
                        <div className={Style.success_indicator}>
                          <span className={Style.success_check}></span>
                        </div>
                      )}
                    </>
                  )}
                </SwiperSlide>
              ))}
              {thumbs.length < 5 && (
                <>
                  {[...Array(5 - thumbs.length)].map((_, i) => (
                    <SwiperSlide
                      key={i}
                      className={`${Style.file_upload_preview_wrap} ${Style.blank_image}`}
                    >
                      <figure className={Style.file_upload_preview_image}>
                        {/* <img src={Assets.browse_5} alt="preview_image" /> */}
                      </figure>
                    </SwiperSlide>
                  ))}
                </>
              )}
            </Swiper>
            <button
              className={Style.add_more_btn}
              onClick={() => {
                document.getElementById("file_upload_input").click();
              }}
            >
              <div className={Style.add_more_btn_contents}>
                <Icons icon={"add"} size={12} />
                <span>Add more</span>
              </div>
            </button>
          </div>
          <div className={Style.file_upload_btn_wrap}>
            <button
              disabled={
                images.length == activeUploadIndex && uploadedImages.length > 0
                  ? false
                  : true
              }
              className="btn btn-secondary"
              onClick={() => closeUploadModal()}
            >
              Done
            </button>
          </div>
        </div>
      }
    />
  );
};

export default UploadImageModal;
