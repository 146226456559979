import { useRef, useEffect } from "react";
import Style from "./FamilyTreeForm.module.scss";
import useFamilyTreeForm from "../../../Logic/FamilyTree/useFamilyTreeForm";

import CommonLayout from "../../Layouts/CommonLayout";
import { Formik, ErrorMessage } from "formik";
import Icons from "../../Layouts/Icons";

const tabData = ["You", "Mother", "Father"];

const FamilyTreeForm = () => {
  const {
    activeTab,
    handleClick,
    handleFirstRender,
    handleSubmit,
    formData,
    handleValue,
    handleSkip,
  } = useFamilyTreeForm();

  const tabRef = useRef(null);

  useEffect(() => {
    handleFirstRender(tabRef, activeTab.index);
    window.addEventListener("resize", () =>
      handleFirstRender(tabRef, activeTab.index)
    );
  }, [activeTab.index]);

  return (
    <CommonLayout>
      <div className={Style.dashboard}>
        <div className={Style.tabs}>
          {tabData.map((a, i) => (
            <div
              key={a}
              ref={i === activeTab.index ? tabRef : null}
              style={{ color: activeTab.index === i && "white" }}
              onClick={(e) => handleClick(e, i)}
              className={Style.tab}
            >
              {a}
            </div>
          ))}
          <div
            style={{
              top: `${activeTab.top}px`,
              left: `${activeTab.left}px`,
              height: `${activeTab.height}px`,
              width: `${activeTab.width}px`,
            }}
            className={Style.activeTab}
          ></div>
        </div>
        <div className={Style.FamilyTreeForm}>
          <Formik
            initialValues={{
              name: formData[activeTab.index].name,
              family_name: formData[activeTab.index].family_name,
              dob: formData[activeTab.index].dob,
              bio: formData[activeTab.index].bio,
              index: activeTab,
            }}
            enableReinitialize={true}
            validate={(values) => {
              const errors = {};
              if (!values.name) {
                errors.name = "Required";
              }
              if (!values.family_name) {
                errors.family_name = "Required";
              }
              if (!values.dob) {
                errors.dob = "Required";
              }
              if (values.bio.length < 3) {
                errors.bio = "Bio should be longer than three characters";
              }
              if (!values.bio) {
                errors.bio = "Required";
              }
              return errors;
            }}
            onSubmit={(values) => {
              handleSubmit(activeTab.index, values);
            }}
          >
            {({ values, handleChange, handleBlur, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div className={Style.FamilyTreeForm_heading}>
                  <h3>Tell us about yourself</h3>
                  <p>{tabData[activeTab.index]}</p>
                </div>
                <div className="form-group">
                  <label htmlFor="FamilyTreeNameInput">Full Name</label>
                  <div className="input-group">
                    <input
                      className="form-control"
                      name="name"
                      id="FamilyTreeNameInput"
                      placeholder="Enter full name"
                      value={formData[activeTab.index].name}
                      onChange={(e) => {
                        handleChange(e);
                        handleValue(e);
                      }}
                      onBlur={handleBlur}
                    />
                  </div>
                  <ErrorMessage name="name" className="field_error_message">
                    {(msg) => (
                      <div
                        style={{
                          color: "#FF6261",
                          fontSize: "12px",
                          marginTop: "5px",
                        }}
                        className="error-msg"
                      >
                        {msg}
                      </div>
                    )}
                  </ErrorMessage>
                </div>
                <div className="form-group">
                  <label htmlFor="FamilyTreeFamilyNameInput">Family Name</label>
                  <div className="input-group">
                    <input
                      className="form-control"
                      name="family_name"
                      id="FamilyTreeFamilyNameInput"
                      placeholder="Enter family name"
                      value={formData[activeTab.index].family_name}
                      onChange={(e) => {
                        handleChange(e);
                        handleValue(e);
                      }}
                      onBlur={handleBlur}
                    />
                  </div>
                  <ErrorMessage
                    name="family_name"
                    className="field_error_message"
                  >
                    {(msg) => (
                      <div
                        style={{
                          color: "#FF6261",
                          fontSize: "12px",
                          marginTop: "5px",
                        }}
                        className="error-msg"
                      >
                        {msg}
                      </div>
                    )}
                  </ErrorMessage>
                </div>
                <div className={Style.fieldGroup}>
                  <div className="form-group">
                    <label>Date of Birth</label>
                    <div className={Style.date_input}>
                      <input
                        type="date"
                        name={`dob`}
                        max={new Date().toISOString().split("T")[0]}
                        value={formData[activeTab.index].dob}
                        onChange={(e) => {
                          handleChange(e);
                          handleValue(e);
                        }}
                        onBlur={handleBlur}
                      />
                      <Icons icon={"icon-calender"} size={18} />
                    </div>

                    <ErrorMessage name="dob" className="field_error_message">
                      {(msg) => (
                        <div
                          style={{
                            color: "#FF6261",
                            fontSize: "12px",
                            marginTop: "5px",
                          }}
                          className="error-msg"
                        >
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </div>
                <div className="form-group textarea">
                  <label htmlFor="FamilyTreeFamilyNameInput">Bio</label>
                  <div className="input-group">
                    <textarea
                      className="form-control"
                      name="bio"
                      id="FamilyTreeFamilyNameInput"
                      placeholder="Add bio"
                      value={formData[activeTab.index].bio}
                      onChange={(e) => {
                        handleChange(e);
                        handleValue(e);
                      }}
                      onBlur={handleBlur}
                    />
                  </div>
                  <ErrorMessage name="bio" className="field_error_message">
                    {(msg) => (
                      <div
                        style={{
                          color: "#FF6261",
                          fontSize: "12px",
                          marginTop: "5px",
                        }}
                        className="error-msg"
                      >
                        {msg}
                      </div>
                    )}
                  </ErrorMessage>
                </div>
                <button type="submit" className="btn btn-secondary w-100">
                  Continue
                </button>
                <div className={Style.skipPerson}>
                  <p onClick={() => handleSkip(activeTab.index)}>Skip Person</p>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </CommonLayout>
  );
};

export default FamilyTreeForm;
